import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
// utils
import { getAlphabeticalSort } from 'utils/string';
import { GRADE_TITLES } from 'constants/classrooms';
import { buildUrl } from 'utils';
import { useSelector } from 'react-redux';
// containers
import MobileSubcomponentWrapper from 'containers/MobileSubcomponentWrapper';
// components
import AlphabeticalSort from 'components/AlphabeticalSort';
import Button from 'components/Button';
import Text from 'components/Text';
// styles
import classnames from 'classnames/bind';
import styles from './MobileView.module.scss';

const cn = classnames.bind(styles);

const ClassroomsSection = ({ minHeight }) => {
  const { push } = useHistory();
  const [sortAlphabetically, setSortAlphabetically] = useState(true);
  const [searchPhrase, setSearchPhrase] = useState('');
  const { id: programId } = useSelector(({ program }) => program);
  const classrooms = useSelector(({ auth: { user: { classroom_ids = [], school_ids = [], programs = [] } } = {} }) => {
    const currProgram = (programs || []).find(({ id }) => +id === +programId);

    const classroomsMerged =
      (currProgram || {}).schools &&
      currProgram.schools
        .filter(({ id }) => (school_ids || []).some((_id) => +_id === +id))
        .reduce((acc, { classrooms }) => [...acc, ...classrooms], []);
    const assignedClassrooms =
      classroom_ids &&
      classroom_ids.map((classroomId) => classroomsMerged.find(({ id }) => classroomId === id)).filter((_) => !!_);

    return assignedClassrooms;
  });

  const sortedClsrms = classrooms
    .sort(({ name: a }, { name: b }) => getAlphabeticalSort(a, b, sortAlphabetically))
    .filter(({ name }) => name.toLowerCase().includes(searchPhrase.toLowerCase()));

  const generateClassroomRow = (classroom, idx) => (
    <article className={cn('classroom-row')} key={idx}>
      <div className={cn('image-wrapper')} style={{ backgroundColor: classroom.color }}>
        {classroom.cover_image && (
          <img
            className={cn('image-wrapper--image')}
            src={buildUrl(classroom.cover_image.image_url)}
            aria-label="image"
            alt="classroom-cover"
          />
        )}
      </div>
      <div className={cn('classroom-row--texts')}>
        <Text type="body-xs">{GRADE_TITLES[classroom.grade_level]}</Text>
        <Text type="h5" className={cn('classroom-name')}>
          {classroom.name}
        </Text>
      </div>
      <Button
        className={cn('manage-button')}
        size="s"
        variant="filled"
        label="Manage"
        onClick={() =>
          push({
            pathname: `/manage-classroom/${classroom.id}`,
            state: { from: '/profile' },
          })
        }
      />
    </article>
  );

  return (
    <MobileSubcomponentWrapper headingTitle="My Classrooms" fixedHeading>
      <div style={{ minHeight: minHeight ? `${minHeight}px` : '100vh' }}>
        <AlphabeticalSort
          label="Classroom Name"
          sorted={sortAlphabetically}
          onClick={() => setSortAlphabetically((prev) => !prev)}
          withSearch
          onSearchChange={setSearchPhrase}
        />
        <div className={cn('classrooms-wrapper')}>
          {sortedClsrms.map((classroom, idx) => generateClassroomRow(classroom, idx))}
        </div>
      </div>
    </MobileSubcomponentWrapper>
  );
};

export default ClassroomsSection;
