import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// utils
import { useSelector, useDispatch } from 'react-redux';
import { GRADE_TITLES_OPTIONS as selectGradeTitles, GRADE_TITLES, GRADE_COLORS } from 'constants/classrooms';
import { get, difference } from 'lodash';
import { getClassrooms, updateClassroom, disableClassroom, activateClassroom } from 'store/classrooms';
import { resetPassword } from 'store/auth';
import { reinviteUser } from 'store/school';
import { buildUrl, getWeekOrderNumber, isEdgeWeek } from 'utils';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { getDefaultValue } from 'utils/object';
import { GAEmitEvent } from 'utils/googleAnalytics';
import {
  eventCategory,
  eventAction,
  eventLabel,
  customDimensions,
  USER_ROLE,
  PROGRAM_NAME,
  UNIT_NUMBER,
  UNIT_TITLE,
  WEEK_NAME,
  gaRoleLabels,
} from 'constants/googleAnalytics';
// hooks
import { useModalSetter } from 'hooks/useModal';
// components
import ManageClassroomsModal from 'pages/UserProfile/BrowserView/ManageClassroomsModal';
import Text from 'components/Text';
import Button from 'components/Button';
import SelectBox from 'components/SelectBox';
import { ReactComponent as Chevron } from 'icons/chevron.svg';
import Input from 'components/Input';
// icons and styles
import { ReactComponent as EditIcon } from 'icons/edit.svg';
import classnames from 'classnames/bind';
import { ProgramAvatar } from 'pages/ProgramSwitcher/ProgramSwitcher';
import withTabSwitcher from 'hoc/withTabSwitcher';
import SearchInput from 'components/Input/SearchInput';
import TablePagination from 'containers/TablePagination/TablePagination';
import { useTableSortedData } from 'hooks/useTableSortedData';
import Table from 'components/Table/Table';
import DotsDropdown from 'components/Dropdown/DotsDropdown';
import AddContentField from 'components/AddContentField';
import { isAdminUser, isProgramAdmin } from 'constants/user';
import { ReactComponent as AddIcon } from 'icons/add-white.svg';
import styles from './ManageClassroom.module.scss';
import { MODAL_LANGUAGE } from '../helpers';
import WarningModal from './WarningModal';
import { toast } from 'react-toastify';

const cn = classnames.bind(styles);

const schema = yup.object().shape({
  classroomName: yup.string().required('Please provide classroom name.'),
  gradeLevel: yup.string().required('Please choose grade level.').nullable(),
});

const schema2 = yup.object().shape({
  classroomName: yup.string().required('Please provide classroom name.'),
});

export const ClassroomInfo = () => {
  const { classroomId } = useParams();
  const { classroomName, gradeLevel, currentUnit, started: classroomStarted, isActive } = useSelector(
    ({ classrooms }) => {
      const _classroom = classrooms.classrooms.length && classrooms.classrooms.find(({ id }) => id === +classroomId);
      const unit = _classroom && _classroom.current_unit;

      return {
        classroomName: get(_classroom, 'name', ''),
        gradeLevel: get(_classroom, 'grade_level', ''),
        currentUnit: unit,
        isActive: get(_classroom, 'is_active'),
        started: get(_classroom, 'started', true),
      };
    },
  );
  const { role } = useSelector(({ auth }) => auth.user);

  const [gradeLevelMenuOpen, setGradeLevelMenuOpen] = useState(false);
  const [showGradeLevelChangeWarning, setShowGradeLevelChangeWarning] = useState(true);

  const dispatch = useDispatch();
  const setModal = useModalSetter();

  const handleGradeLevelSelectClick = () =>
    !classroomStarted &&
    (showGradeLevelChangeWarning
      ? setModal(
          <WarningModal
            modalLanguage={MODAL_LANGUAGE['grade-level']}
            onAccept={() => {
              setShowGradeLevelChangeWarning(false);
              setGradeLevelMenuOpen(true);
            }}
          />,
        )
      : setGradeLevelMenuOpen((prev) => !prev));

  const handleFormSubmit = (data) => {
    let dataObject = {};
    if (classroomStarted) {
      dataObject = { name: data.classroomName, completed: true };
    } else {
      dataObject = {
        name: data.classroomName,
        grade_level: data.gradeLevel,
        completed: true,
      };
    }

    dispatch(updateClassroom(dataObject, classroomId)).then(() => {
      toast.success('Classroom updated');
      setShowGradeLevelChangeWarning(true);
    });
  };

  const { register, handleSubmit, errors, setValue, clearErrors, reset } = useForm({
    resolver: yupResolver(classroomStarted ? schema2 : schema),
  });

  useEffect(() => {
    reset({
      classroomName,
      gradeLevel,
    });
  }, [classroomName, gradeLevel, reset]);

  return (
    <div className={cn('left-wrapper', 'ph-60 pb-60', 'wrapper')}>
      <form className={cn('form')} onSubmit={handleSubmit(handleFormSubmit)}>
        <div className={cn('input-group')}>
          <Input
            className={cn('standard-input')}
            label="Classroom name"
            name="classroomName"
            inputRef={register({ required: true })}
            errors={errors}
          />
          <SelectBox
            className={cn('standard-input')}
            options={selectGradeTitles}
            label="Grade Level"
            placeholder="Choose a grade level"
            name="gradeLevel"
            errors={errors}
            isDisabled={classroomStarted}
            hookFormProps={{
              register,
              setValue,
              clearErrors,
            }}
            defaultValue={gradeLevel ? getDefaultValue(selectGradeTitles, gradeLevel) : { label: '', value: '' }}
            menuIsOpen={gradeLevelMenuOpen}
            isSearchable={false}
            onClick={() => handleGradeLevelSelectClick()}
          />
        </div>
        <div className={cn('input-group')}>
          <SelectBox
            className={cn('standard-input', 'standard-input--unit')}
            label="Current Unit"
            options={[]}
            placeholder={currentUnit && `Unit ${currentUnit.order_number}`}
            name="currentUnit"
            isDisabled
          />
        </div>
        <div className={cn('input-group')}>
          <Button className={cn('button')} size="bg" label="Save Changes" isSubmit />
        </div>
      </form>
      {isAdminUser(role) && (
        <div className={cn('disable-wrapper', 'pv-25 ph-20 mt-60')}>
          <div className={cn('sub-wrapper')}>
            <Text>{`${isActive ? 'Disable' : 'Activate'} Classroom`}</Text>
            <Text type="body-small" className="mt-5">
              {isActive
                ? `Disabling this classroom will revoke access to all teachers`
                : `Activating this classroom will restore access to all teachers`}
            </Text>
          </div>
          <Button
            size="md"
            variant="black"
            label={`${isActive ? 'Disable' : 'Activate'} Classroom`}
            onClick={() => dispatch(isActive ? disableClassroom(+classroomId) : activateClassroom(+classroomId))}
          />
        </div>
      )}
    </div>
  );
};

export const People = () => {
  const { classroomId, unitId } = useParams();
  const { classroomName, classroomTeachers, schoolTeachers, userIds } = useSelector(({ classrooms }) => {
    const _classroom = classrooms.classrooms.length && classrooms.classrooms.find(({ id }) => id === +classroomId);

    return {
      classroomName: get(_classroom, 'name', ''),
      classroomTeachers: get(classrooms, 'schoolTeachers', []).filter(({ id }) =>
        get(_classroom, 'user_ids', []).includes(id),
      ),
      schoolTeachers: get(classrooms, 'schoolTeachers', []),
      userIds: get(_classroom, 'user_ids', []),
    };
  });

  const { role } = useSelector((state) => state.auth.user);

  const { push } = useHistory();
  const dispatch = useDispatch();

  const renderUserColumn = (props, className, width) => (
    <div className={cn('user-col', className)} style={{ minWidth: width, flexBasis: width }}>
      <div className={cn('user-col__image')}>
        {props.avatar_url && (
          <div className={cn('img-icon-wrapper')}>
            <img className={cn('image')} src={buildUrl(props.avatar_url)} alt="Img" />
          </div>
        )}
      </div>
      <div className={cn('user-name-col', 'ml-40')}>
        <Text type="body-big">{props.first_name ? `${props.first_name} ${props.last_name}` : props.email}</Text>
      </div>
    </div>
  );

  const renderStatusColumn = (props, className, width) => (
    <div className={cn(className)} style={{ minWidth: width, flexBasis: width }}>
      <Text>
        {props.status === 'active' ? (
          'Active'
        ) : props.status === 'disabled' ? (
          'Disabled'
        ) : (
          <span style={{ color: '#54585a' }}>Invite Pending</span>
        )}
      </Text>
    </div>
  );

  const getDropdownOptions = (props) => [
    {
      label: `${props.status === 'active' ? 'Send Password Reset' : 'Resend Invite'}`,
      itemProps: {
        onClick: () =>
          props.status === 'active'
            ? dispatch(resetPassword(props.email, { 'program-id': programId, 'school-id': schoolId }))
            : dispatch(reinviteUser({ email: props.email, role: props.role })),
      },
    },
    {
      label: 'Manage User',
      itemProps: {
        onClick: () => push(`/user-profile/${props.id}?range=${isProgramAdmin(role) ? 'program' : 'school'}`),
      },
    },
    {
      label: <span className={cn('warn-text')}>Remove From Classroom</span>,
      itemProps: {
        onClick: () =>
          dispatch(
            updateClassroom(
              {
                user_ids: [...userIds.filter((id) => +id !== +props.id)],
              },
              +classroomId,
            ),
          ),
      },
    },
  ];

  const renderDropdownColumn = (props, className, width) => (
    <div className={cn(className, 'dots-col')} style={{ minWidth: width, flexBasis: width }}>
      <DotsDropdown className={cn('table-dropdown')} options={getDropdownOptions(props)} />
    </div>
  );

  const { sortState, handleSortClick, handleSearchChange, dataSorted } = useTableSortedData(
    classroomTeachers,
    ['full_name', 'email'],
    'status',
  );

  const columns = [
    {
      label: 'User',
      renderer: renderUserColumn,
      isBordered: false,
      width: 300,
    },
    { label: 'Email', dataPath: 'email', width: 200 },
    { label: 'Status', renderer: renderStatusColumn, width: 130, handleSort: handleSortClick, sorted: sortState },
    { label: '', renderer: renderDropdownColumn, width: 50, sorted: sortState },
  ];

  const { id: programId } = useSelector((state) => state.program);
  const { id: schoolId } = useSelector((state) => state.school);

  const setModal = useModalSetter();
  return (
    <div className={cn('ph-60 pb-60', 'wrapper')}>
      <div className={cn('teachers-heading-wrapper')}>
        <Text type="h5" className={cn('title', 'mv-25')}>
          Teachers
        </Text>
        <SearchInput name="teacherSearch" onChange={handleSearchChange} />
      </div>
      {classroomTeachers.length > 0 && (
        <TablePagination pageSize={10} data={dataSorted}>
          {({ paginatedData }) => <Table columns={columns} data={paginatedData} />}
        </TablePagination>
      )}
      {role !== 'teacher' && !classroomTeachers.length && (
        <AddContentField
          className={cn('add-container', 'mt-20')}
          text="Add your first teacher."
          onClick={() => {
            setModal(
              <ManageClassroomsModal
                notAssigned={difference(schoolTeachers, classroomTeachers)}
                assigned={classroomTeachers}
                programId={programId}
                schoolId={schoolId}
                _classroomId={classroomId}
                _classroomName={classroomName}
              />,
            );
          }}
        />
      )}
    </div>
  );
};

const ManageClassroom = () => {
  const { classroomId } = useParams();
  const dispatch = useDispatch();
  const {
    action,
    push,
    location: { state: historyState = { from: '/classrooms' }, pathname, search },
  } = useHistory();

  const setModal = useModalSetter();

  const { role } = useSelector((state) => state.auth.user);
  const { id: programId, name: programName } = useSelector((state) => state.program);
  const { id: schoolId } = useSelector((state) => state.school);
  const { program, school } = useSelector(({ program: program_ }) => {
    const school_ = program_.schools.length && (program_.schools.find(({ id }) => id === schoolId) || {});
    return {
      program: program_,
      school: school_,
    };
  });

  const {
    classroomName,
    gradeLevel,
    classroomTeachers,
    imageUrl,
    schoolTeachers,
    currentUnit,
    currentWeek,
    completed: classroomCompleted,
  } = useSelector(({ classrooms }) => {
    const _classroom = classrooms.classrooms.length && classrooms.classrooms.find(({ id }) => id === +classroomId);
    const unit = _classroom && _classroom.current_unit;
    return {
      classroomName: get(_classroom, 'name', ''),
      gradeLevel: get(_classroom, 'grade_level', ''),
      classroomTeachers: get(classrooms, 'schoolTeachers', []).filter(({ id }) =>
        get(_classroom, 'user_ids', []).includes(id),
      ),
      imageUrl: get(_classroom, 'cover_image.image_url', ''),
      schoolTeachers: get(classrooms, 'schoolTeachers', []),
      currentUnit: unit,
      currentWeek: get(_classroom, 'current_week', ''),
      completed: get(_classroom, 'completed', false),
    };
  });

  const boundAction = useCallback(() => dispatch(getClassrooms()), [dispatch]);
  useEffect(() => {
    boundAction();
  }, []);

  return (
    <div className={cn('container')}>
      <div className={cn('breadcrumbs-wrapper')}>
        <div className={cn('breadcrumbs', 'mb-25')}>
          <Text className={cn('crumb', { link: true })} onClick={() => push('/program-panel')}>
            {program.name}
          </Text>
          <Chevron className={cn('chevron')} />
          <Text className={cn('crumb', { link: true })} onClick={() => push('/school-panel')}>
            {school.name}
          </Text>
          <Chevron className={cn('chevron')} />
          <Text className={cn('crumb')}>{classroomName}</Text>
        </div>
      </div>

      <div
        className={cn('page-hero')}
        style={{ backgroundColor: GRADE_COLORS[gradeLevel], color: !gradeLevel && '#535759' }}
      >
        <div className={cn('left-content')}>
          <div className={cn('avatar-row')}>
            {!imageUrl && <ProgramAvatar className={cn('avatar')} imageUrl={imageUrl} name={classroomName} />}
            {imageUrl && <div style={{ backgroundImage: `url(${buildUrl(imageUrl)})` }} className={cn('avatar')} />}
            <div className={cn('name-wrapper')}>
              {gradeLevel && <Text type="h6">{GRADE_TITLES[gradeLevel]}</Text>}
              <Text type="h2" style={{ color: !gradeLevel && '#535759' }}>
                {classroomName}
              </Text>
            </div>
          </div>
          {classroomCompleted && currentWeek && (
            <div className={cn('week-row')}>
              <Text>
                {`${getWeekOrderNumber(currentWeek.order_number, true, true)} ${
                  !isEdgeWeek(currentWeek.order_number) ? currentWeek.weeks[0].name : ''
                }`}
              </Text>
              <Button
                className={cn('launch-week-btn')}
                label="Launch Week"
                onClick={() => {
                  push(`/classroom/${classroomId}/unit/${currentUnit.id}/week/${currentWeek.id}`);
                  GAEmitEvent(
                    eventCategory.USER_ACTION,
                    eventAction.MENU_CLICK,
                    eventLabel.launchWeek('Launch Week', pathname + search),
                    {
                      [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                      [customDimensions[PROGRAM_NAME]]: programName,
                      [customDimensions[UNIT_NUMBER]]: get(currentUnit, 'order_number', null),
                      [customDimensions[UNIT_TITLE]]: get(currentUnit, 'units[0].name', null),
                      [customDimensions[WEEK_NAME]]: get(currentWeek, 'weeks[0].name', null),
                    },
                  );
                }}
              />
            </div>
          )}
        </div>
        <div className={cn('right-content')}>
          <div className={cn('image-wrapper')}>
            {imageUrl && <img src={buildUrl(imageUrl)} className={cn('cover-image')} />}
            <div
              aria-hidden
              className={cn('change-image')}
              onClick={() =>
                push(
                  classroomCompleted
                    ? `/set-up-classroom/${classroomId}?cover_image_mode=true`
                    : `/set-up-classroom/${classroomId}`,
                )
              }
            >
              <EditIcon className={cn('icon')} />
              <Text className={cn('text')} type="body-big">
                Change Cover Image
              </Text>
            </div>
          </div>
        </div>
      </div>
      {isAdminUser(role) && (
        <div className={cn('tab-switch-wrap')}>
          <Button
            size="md"
            className={cn('add-teachers-button')}
            label={
              <div className={cn('icon-label')}>
                <AddIcon className="mr-5" />
                <Text>Add a teacher</Text>
              </div>
            }
            onClick={() => {
              setModal(
                <ManageClassroomsModal
                  notAssigned={difference(schoolTeachers, classroomTeachers)}
                  assigned={classroomTeachers}
                  programId={programId}
                  schoolId={schoolId}
                  _classroomId={classroomId}
                  _classroomName={classroomName}
                />,
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

export default withTabSwitcher(ManageClassroom);
