import React from 'react';
// components
import Text from 'components/Text';
import Button from 'components/Button';
import Modal from 'components/Modal';
// styles
import classnames from 'classnames/bind';
import styles from './AdminPanel.module.scss';

const cn = classnames.bind(styles);

const UserActionWarningModal = ({ headerLabel, confirmLabel, onConfirm, renderBody }) => {
  return (
    <Modal className={cn('disable-modal')}>
      {({ closeModal }) => (
        <>
          <Modal.Header className="simple">
            <Text type="h5">{headerLabel}</Text>
            <Modal.CloseButton className="simple" onClick={closeModal} />
          </Modal.Header>
          <Modal.Body className="simple">{renderBody()}</Modal.Body>
          <Modal.Footer>
            <Button className={'mr-20 ph-30'} size="md" variant="filled" label="Cancel" onClick={closeModal} />
            <Button
              className="ph-30"
              size="md"
              variant="filled-red"
              label={confirmLabel}
              onClick={() => {
                onConfirm();
                closeModal();
              }}
            />
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default UserActionWarningModal;
