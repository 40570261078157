import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
// utils
import { get } from 'lodash';
import { GRADE_TITLES } from 'constants/classrooms';
import { toast } from 'react-toastify';
import { markWeekCompleted } from 'store/classrooms';
import { useDispatch, useSelector } from 'react-redux';
import {
  eventCategory,
  eventAction,
  eventLabel,
  USER_ROLE,
  PROGRAM_NAME,
  UNIT_NUMBER,
  UNIT_TITLE,
  WEEK_NAME,
  gaRoleLabels,
  customDimensions,
} from 'constants/googleAnalytics';
import { GAEmitEvent } from 'utils/googleAnalytics';
import { getWeekOrderNumber } from 'utils';
// hoc
import withTabSwitcherMobile from 'hoc/withTabSwitcherMobile';
// components
import CheckButton from 'components/CheckButton';
import Text from 'components/Text';
// icons
import { ReactComponent as ChevronIcon } from 'icons/chevron-grey.svg';
// styles
import classnames from 'classnames/bind';
import styles from './MobileView.module.scss';

const cn = classnames.bind(styles);

const MobileView = ({ classroom = {}, unit = {}, week = {} }) => {
  const { classroomId, unitId, weekId } = useParams();
  const {
    push,
    location: { pathname, search },
  } = useHistory();

  const { role } = useSelector(({ auth }) => auth.user);
  const { name: programName } = useSelector(({ program }) => program);

  const dispatch = useDispatch();

  const { week_progresses: weekProgresses, order_number: currentUnitOrder } = get(
    classroom,
    `unit_progress.${unitId}`,
    {},
  );
  const isCurrentUnit = get(classroom, 'current_unit.id') === +unitId;
  const weekProgRev = weekProgresses ? [...weekProgresses].reverse() : [];
  const lastUnfinished =
    weekProgRev.find(
      ({ finished }, idx) =>
        (!finished && weekProgRev[idx + 1] && weekProgRev[idx + 1].finished) || !weekProgresses[idx + 1],
    ) || {};

  const currentUnitOrderNumber = get(classroom, 'current_unit.order_number');

  const isFuture = isCurrentUnit
    ? lastUnfinished.order_number < week.order_number
    : currentUnitOrderNumber < currentUnitOrder;

  const handleCompleteClick = () => {
    if (isFuture) return;
    !week.finished &&
      GAEmitEvent(
        eventCategory.USER_ACTION,
        eventAction.MENU_CLICK,
        eventLabel.markWeekCompleted('Mark Week Completed', pathname + search),
        {
          [customDimensions[USER_ROLE]]: gaRoleLabels[role],
          [customDimensions[PROGRAM_NAME]]: programName,
          [customDimensions[UNIT_NUMBER]]: get(unit, 'units[0].order_number', null),
          [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
          [customDimensions[WEEK_NAME]]: get(week, `weeks[0].name`, null),
        },
      );
    return dispatch(markWeekCompleted(classroomId, unitId, weekId, !week.finished)).then(
      () =>
        !week.finished &&
        toast.success(
          `Congrats! You Completed ${GRADE_TITLES[classroom.grade_level]} | Week ${
            week.weeks && week.weeks[0].order_number
          }`,
        ),
    );
  };

  return (
    <div className={cn('header-wrapper', 'pv-15')}>
      <ChevronIcon
        className={cn('header-wrapper--icon')}
        onClick={() => push(`/classroom/${classroomId}/unit/${unitId}`)}
      />
      <div className={cn('header-wrapper--texts')}>
        <Text type="h5">{getWeekOrderNumber(week.weeks && week.weeks[0].order_number, false, true)}</Text>
        <Text
          className={cn('header-wrapper--texts--small')}
          type="body-xs"
        >{`${classroom.name} | Unit ${unit.order_number}`}</Text>
      </div>
      <CheckButton
        className={cn('header-wrapper--check')}
        disabled={isFuture}
        isChecked={week.finished}
        onChange={handleCompleteClick}
      />
    </div>
  );
};

export default withTabSwitcherMobile(MobileView);
