import React, { useRef } from 'react';
// icons
import { ReactComponent as SearchIconRegular } from 'icons/search.svg';
import { ReactComponent as SearchIconBold } from 'icons/search-bold.svg';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
// styles
import classnames from 'classnames/bind';
import styles from './Input.module.scss';

const cn = classnames.bind(styles);

const SearchInput = ({
  type = 'text',
  inputRef,
  name,
  onChange,
  withClear = false,
  boldIcon = false,
  wrapperClassName,
  className,
  onClear,
  ...props
}) => {
  const _inputRef = useRef();

  const actualRef = inputRef || _inputRef;
  const SearchIcon = boldIcon ? SearchIconBold : SearchIconRegular;

  const handleClearClick = () => {
    if (actualRef.current) {
      actualRef.current.value = '';
    }
    if (onClear) {
      onClear();
    }
  };

  return (
    <div className={cn('search-input-wrapper', wrapperClassName)}>
      <div className={cn('icon-wrapper')} onClick={() => actualRef.current && actualRef.current.focus()}>
        <SearchIcon className={cn('icon')} />
      </div>
      <input
        className={cn('search-input', className)}
        type={type}
        ref={actualRef}
        name={name}
        placeholder="Search"
        onChange={(e) => onChange(e.target.value)}
        autoComplete="off"
        {...props}
      />
      {withClear && (
        <div className={cn('clear-icon')} onClick={handleClearClick}>
          <CloseIcon className={cn('icon')} />
        </div>
      )}
    </div>
  );
};

export default SearchInput;
