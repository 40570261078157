import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import T from 'prop-types';

const FadeInWrapper = ({ children, options = { duration: 200 }, activeTab }) => {
  const [shouldAnimate, setShouldAnimate] = useState(true);

  useEffect(() => {
    setShouldAnimate(true);
    // eslint-disable-next-line
  }, [activeTab]);

  const fadeStyles = useSpring({
    config: {
      ...options,
    },
    reset: true,
    from: { opacity: 0 },
    to: { opacity: 1 },
    onRest: () => setShouldAnimate(false),
  });

  return <animated.div style={shouldAnimate ? { ...fadeStyles } : {}}>{children}</animated.div>;
};

FadeInWrapper.propTypes = {
  children: T.element,
  options: T.object,
};

export default FadeInWrapper;
