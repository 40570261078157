import { useEffect } from 'react';

const useOnClickOutside = (ref, handler, options = {}) => {
  const { triggerRef, blockerId, isOpen } = options;
  useEffect(() => {
    if (!isOpen) return;
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) return;

      // Do nothing if clicking dropdown trigger's parent element
      if (triggerRef && triggerRef.current && triggerRef.current.contains(event.target)) return;

      // Do nothing if element with blockerId exists
      if (blockerId && document.getElementById(blockerId)) return;
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, isOpen, triggerRef, blockerId]);
};

export default useOnClickOutside;
