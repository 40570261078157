import React, { useState, useRef, useEffect } from 'react';
import T from 'prop-types';
import { capitalizeEachWord } from 'utils/string';
//UI and styles
import FadeInWrapper from 'containers/FadeInWrapper';
import Text from 'components/Text';
import classnames from 'classnames/bind';
import styles from './withTabSwitcher1.module.scss';

const cn = classnames.bind(styles);

// Content padding according to designs
// this should probably defined globally

const withTabSwitcher = (WrappedComponent) => {
  const EnhancedComponent = ({ tabOptions, ...props }) => {
    const [activeTab, setActiveTab] = useState(tabOptions[0].title);
    const [pos, setPos] = useState(0);
    const [off, setOff] = useState(0);
    const sliderRef = useRef();
    const navItemRef = useRef();

    // apply slider width on init
    useEffect(() => {
      // init positioning
      navItemRef.current && navItemRef.current.click();
      // eslint-disable-next-line
    }, []);

    const handleNavItemClick = (e, option) => {
      setActiveTab(option);

      // '- 4' in order to adjust slider correctly
      const sliderLeftOffset = e.target.offsetLeft;
      const sliderWidth = e.target.offsetWidth;
      setPos(sliderWidth);
      setOff(sliderLeftOffset);
    };

    const getNavItems = (options) =>
      options.map((option, i) => (
        <div key={i} ref={i === 0 ? navItemRef : null} onClick={(e) => handleNavItemClick(e, option.title)}>
          <Text className={cn('nav-item', { ['active-tab']: option.title === activeTab })} type="body">
            {capitalizeEachWord(option.title)}
          </Text>
        </div>
      ));

    // This function determines which sub-component to render (active component)
    // Wrapped in FadeIn applies spring fade in animation upon tab switch
    // Important to pass props to child component as they contain store data
    const renderProperComponent = (optionsArray) => {
      const Component = optionsArray.filter(({ title, component }) => title === activeTab)[0].component;
      return typeof Component === 'function' ? (
        <FadeInWrapper>
          <Component {...props} />
        </FadeInWrapper>
      ) : (
        Component
      );
    };

    return (
      <div className={cn('content-wrapper')}>
        <WrappedComponent {...props} />
        <section className={cn('page-tab-nav')}>
          <div className={cn('nav-items')}>{getNavItems(tabOptions)}</div>
          <div className={cn('horizontal-line')}>
            <hr style={{ width: pos, left: off }} />
          </div>
          <div>{renderProperComponent(tabOptions)}</div>
        </section>
      </div>
    );
  };

  EnhancedComponent.propTypes = {
    tabOptions: T.arrayOf(T.object).isRequired,
  };

  return EnhancedComponent;
};

withTabSwitcher.propTypes = {
  WrappedComponent: T.element,
};

export default withTabSwitcher;
