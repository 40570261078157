import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
// hoc
import withTabSwitcherMobile from 'hoc/withTabSwitcherMobile';
// hooks
import { useMeasure } from 'hooks/useMeasure';
import { useModalSetter } from 'hooks/useModal';
import { useBottomMenuSetter } from 'hooks/useBottomMenu';
// utils
import { MODAL_LANGUAGE } from '../helpers';
import { updateClassroom, disableClassroom, activateClassroom } from 'store/classrooms';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from 'store/auth';
import { reinviteUser } from 'store/school';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { getDefaultValue } from 'utils/object';
import { getAlphabeticalSort } from 'utils/string';
import { buildUrl } from 'utils';
import { GRADE_COLORS, GRADE_TITLES, GRADE_TITLES_OPTIONS as selectGradeTitles } from 'constants/classrooms';
import { isProgramAdmin } from 'constants/user';
// components
import BottomMenu from 'components/BottomMenu';
import WarningModal from '../BrowserView/WarningModal';
import PlusFab from 'components/Button/PlusFab';
import Portal from 'components/Portal';
import ManageClassroomsModal from 'pages/UserProfile/BrowserView/ManageClassroomsModal';
import Button from 'components/Button';
import SelectBox from 'components/SelectBox';
import Input from 'components/Input';
import MobileItemRow from 'components/MobileItemRow';
import AlphabeticalSort from 'components/AlphabeticalSort';
import AddContentField from 'components/AddContentField';
import Text from 'components/Text';
// icons
import { ReactComponent as SendIcon } from 'icons/send.svg';
import { ReactComponent as RemoveIcon } from 'icons/remove.svg';
import { ReactComponent as ManageIcon } from 'icons/gear.svg';
import { ReactComponent as ChevronIcon } from 'icons/chevron.svg';
import { ReactComponent as EditIcon } from 'icons/edit.svg';
// styles
import classnames from 'classnames/bind';
import styles from './MobileView.module.scss';
import { toast } from 'react-toastify';

const cn = classnames.bind(styles);

const schema = yup.object().shape({
  classroomName: yup.string().required('Please provide classroom name.'),
  gradeLevel: yup.string().required('Please choose grade level.').nullable(),
});

export const ClassroomInfo = ({
  classroom: { classroomName, gradeLevel, currentUnit, classroomId, isActive, started: classroomStarted },
  isAdmin,
}) => {
  const { errors, register, handleSubmit, setValue, clearErrors } = useForm({
    defaultValues: { classroomName },
    resolver: yupResolver(schema),
  });

  const [gradeLevelMenuOpen, setGradeLevelMenuOpen] = useState(false);
  const [showGradeLevelChangeWarning, setShowGradeLevelChangeWarning] = useState(true);

  const dispatch = useDispatch();
  const setModal = useModalSetter();

  const handleGradeLevelSelectClick = () =>
    !classroomStarted &&
    (showGradeLevelChangeWarning
      ? setModal(
          <WarningModal
            modalLanguage={MODAL_LANGUAGE['grade-level']}
            onAccept={() => {
              setShowGradeLevelChangeWarning(false);
              setGradeLevelMenuOpen(true);
            }}
          />,
        )
      : setGradeLevelMenuOpen((prev) => !prev));

  const handleFormSubmit = (data) => {
    let dataObject = {};
    if (classroomStarted) {
      dataObject = { name: data.classroomName, completed: true };
    } else {
      dataObject = {
        name: data.classroomName,
        grade_level: data.gradeLevel,
        completed: true,
      };
    }
    dispatch(updateClassroom(dataObject, classroomId)).then(() => {
      toast.success('Classroom updated');
      setShowGradeLevelChangeWarning(true);
    });
  };

  return (
    <section className={cn('section')}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className={cn('input-group')}>
          <Input
            className={cn('standard-input')}
            label="Classroom name"
            name="classroomName"
            inputRef={register({ required: true })}
            errors={errors}
          />
          <SelectBox
            className={cn('standard-input')}
            options={selectGradeTitles}
            label="Grade Level"
            placeholder="Choose a grade level"
            name="gradeLevel"
            errors={errors}
            isDisabled={classroomStarted}
            hookFormProps={{
              register,
              setValue,
              clearErrors,
            }}
            defaultValue={gradeLevel ? getDefaultValue(selectGradeTitles, gradeLevel) : { label: '', value: '' }}
            menuIsOpen={gradeLevelMenuOpen}
            isSearchable={false}
            onTouch={handleGradeLevelSelectClick}
          />
        </div>
        <div className={cn('input-group')}>
          <SelectBox
            className={cn('standard-input', 'standard-input--unit')}
            label="Current Unit"
            options={[]}
            placeholder={`Unit ${currentUnit && currentUnit.order_number}`}
            name="currentUnit"
            isDisabled
          />
        </div>
        <div className={cn('input-group')}>
          <Button className={cn('button')} size="bg" label="Save Changes" isSubmit />
        </div>
      </form>
      {isAdmin && (
        <div className={cn('disable-wrapper', 'ph-20 pv-25 mt-30')}>
          <div className={cn('sub-wrapper')}>
            <Text>{isActive ? 'Disable' : 'Activate'} Classroom</Text>
            <Text type="body-small" className="mt-5">
              {isActive
                ? 'Disabling this classroom will revoke access to all teachers'
                : 'Activating this classroom will restore access to all teachers'}
            </Text>
            <Button
              size="md"
              variant="black"
              label={`${isActive ? 'Disable' : 'Activate'} Classroom`}
              className={cn('disable-btn', 'mt-15')}
              onClick={() => dispatch(isActive ? disableClassroom(classroomId) : activateClassroom(classroomId))}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export const Teachers = ({
  classroom: { classroomName, classroomTeachers = [], notAssignedTeachers = [], classroomId, userIds },
  schoolId,
  programId,
}) => {
  const { role } = useSelector(({ auth }) => auth.user);

  const [sortAlphabetically, setSortAlphabetically] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState('');
  const { push } = useHistory();

  const { setBottomMenu, closeMenu } = useBottomMenuSetter();
  const setModal = useModalSetter();
  const dispatch = useDispatch();

  const teachersSorted = classroomTeachers
    .sort(({ full_name: a, email: emailA }, { full_name: b, email: emailB }) =>
      getAlphabeticalSort(a || emailA, b || emailB, sortAlphabetically),
    )
    .filter(({ fullName, email }) => (fullName || email).toLowerCase().includes(searchPhrase.toLowerCase()));

  const usersRenderer = ({ fullName, email, status }) => (
    <div className={cn('row-texts-container')}>
      <Text type="h7">{fullName || email}</Text>
      <Text type="h11">{fullName && email}</Text>
      <Text type="h11" className="mt-5">
        {status === 'active' ? (
          'Active'
        ) : status === 'disabled' ? (
          'Disabled'
        ) : (
          <span style={{ color: '#54585a' }}>Invite Pending</span>
        )}
      </Text>
    </div>
  );

  const MenuDrawer = ({ teacher }) => (
    <BottomMenu menuHeading="Manage">
      <div className={cn('drawer-wrapper')}>
        <div
          className={cn('drawer-option')}
          onClick={() => {
            teacher.status === 'active'
              ? dispatch(resetPassword(teacher.email, { 'program-id': programId, 'school-id': schoolId }))
              : dispatch(reinviteUser({ email: teacher.email, role: teacher.role }));
            closeMenu();
          }}
        >
          <SendIcon className="mr-10" />
          <Text>{teacher.status === 'active' ? 'Send Password Reset' : 'Resend Invite'}</Text>
        </div>
        <div
          className={cn('drawer-option')}
          onClick={() => {
            push(`/user-profile/${teacher.id}?range=${isProgramAdmin(role) ? 'program' : 'school'}`);
            closeMenu();
          }}
        >
          <ManageIcon className="mr-10" />
          <Text>Manage User</Text>
        </div>
        <div
          className={cn('drawer-option')}
          onClick={() => {
            dispatch(
              updateClassroom(
                {
                  user_ids: [...userIds.filter((id) => +id !== +teacher.id)],
                },
                +classroomId,
              ),
            );
            closeMenu();
          }}
        >
          <RemoveIcon className="mr-10" />
          <Text className={cn('warn-text')}>Remove From Classroom</Text>
        </div>
      </div>
    </BottomMenu>
  );

  return (
    <section className={cn('section')}>
      {classroomTeachers.length > 0 ? (
        <div>
          <AlphabeticalSort
            label="User Name"
            sorted={sortAlphabetically}
            onClick={() => setSortAlphabetically((prev) => !prev)}
            withSearch
            onSearchChange={setSearchPhrase}
          />
          {teachersSorted.map((teacher) => (
            <MobileItemRow
              key={`user-${teacher.id}`}
              imageUrl={teacher.avatar_url}
              textsRenderer={() =>
                usersRenderer({ fullName: teacher.full_name, email: teacher.email, status: teacher.status })
              }
              onMenuClick={() => setBottomMenu(<MenuDrawer teacher={teacher} />)}
            />
          ))}
        </div>
      ) : (
        <AddContentField
          text="Add a teacher."
          onClick={() =>
            setModal(
              <ManageClassroomsModal
                notAssigned={notAssignedTeachers}
                assigned={classroomTeachers}
                programId={programId}
                schoolId={schoolId}
                _classroomId={classroomId}
                _classroomName={classroomName}
              />,
            )
          }
        />
      )}
    </section>
  );
};

const MobileView = ({
  programId,
  schoolId,
  classroom: { classroomName, gradeLevel, imageUrl, classroomId, classroomTeachers, notAssignedTeachers },
  isAdmin,
}) => {
  const { goBack, push } = useHistory();

  const setModal = useModalSetter();

  const [bind, { height: headerHeight }] = useMeasure();

  return (
    <>
      <div
        className={cn('cover-wrapper')}
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0) 22%, rgba(0, 0, 0, 0.35) 100% ) ${GRADE_COLORS[gradeLevel]}`,
          height: headerHeight || 155,
        }}
      >
        <div className={cn('chevron-wrapper')} onClick={goBack}>
          <ChevronIcon className={cn('chevron-wrapper--icon')} />
        </div>
        <div className={cn('info-wrapper')}>
          <Text type="h6">{classroomName}</Text>
          <Text type="h11">{GRADE_TITLES[gradeLevel]}</Text>
          <div
            aria-hidden
            className={cn('change-image')}
            onClick={() => push(`/set-up-classroom/${classroomId}?cover_image_mode=true`)}
          >
            <EditIcon className={cn('icon')} />
            <Text className={cn('text')} type="body-big">
              Change Cover Image
            </Text>
          </div>
        </div>
        <img
          {...bind}
          className={cn('cover-wrapper--image')}
          src={imageUrl && buildUrl(imageUrl)}
          aria-label="classroom cover"
        />
      </div>
      {isAdmin && (
        <Portal>
          <PlusFab
            className={cn('menu-fab')}
            onClick={() =>
              setModal(
                <ManageClassroomsModal
                  notAssigned={notAssignedTeachers}
                  assigned={classroomTeachers}
                  programId={programId}
                  schoolId={schoolId}
                  _classroomId={classroomId}
                  _classroomName={classroomName}
                />,
              )
            }
          />
        </Portal>
      )}
    </>
  );
};

export default withTabSwitcherMobile(MobileView);
