import React, { useState, useCallback } from 'react';
// utils
import { useSelector } from 'react-redux';
import { getToggleView, setToggleView } from 'utils/toggleView';
import DisplayOnDesktop from 'containers/DisplayOnDesktop';
import DisplayOnMobile from 'containers/DisplayOnMobile';
// components
import BrowserView from './BrowserView';
import MobileView from './MobileView';
import MainSection from './MobileView/MainSection';

const MOBILE_TAB_OPTIONS = [
  { title: 'all', component: (props) => <MainSection activeTab="all" {...props} /> },
  { title: 'professional prep', component: (props) => <MainSection activeTab="professional prep" {...props} /> },
  { title: 'learning experiences', component: (props) => <MainSection activeTab="learning experiences" {...props} /> },
];

const Favorites = () => {
  const { schools = [] } = useSelector(({ auth }) => auth.user);
  const [listView, setListView] = useState(getToggleView('favorites'));
  const toggleListView = useCallback(
    () =>
      setListView((prev) => {
        setToggleView('favorites', !prev);
        return !prev;
      }),
    [],
  );

  const findCorrespondingSchool = (schoolId) => {
    const school = schools.find(({ id }) => +id === +schoolId);
    return school;
  };

  return (
    <>
      <DisplayOnDesktop>
        <BrowserView findSchool={findCorrespondingSchool} />
      </DisplayOnDesktop>
      <DisplayOnMobile>
        <MobileView
          tabOptions={MOBILE_TAB_OPTIONS}
          toggleListView={toggleListView}
          listView={listView}
          shouldAnimate={false}
          findSchool={findCorrespondingSchool}
        />
      </DisplayOnMobile>
    </>
  );
};

export default Favorites;
