import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// hoc
import withTabSwitcherMobile from 'hoc/withTabSwitcherMobile';
// hooks
import { useBottomMenuSetter } from 'hooks/useBottomMenu';
import { useModalSetter } from 'hooks/useModal';
// utils
import { hasMajorRole } from 'utils/users';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { resetPassword } from 'store/auth';
import {
  createClassroom,
  inviteUser,
  updateSchoolId,
  getSchoolInfo,
  reinviteUser,
  userUpdateLoaded as userUpdateSchool,
  updateSchoolInfo,
  disableClassroom,
  activateClassroom,
  deleteUser,
  revokeSchoolAdminLoaded,
} from 'store/school';
import {
  createSchool,
  inviteUsersProgram,
  getProgram,
  userUpdateLoaded as userUpdateProgram,
  updateProgram,
  disableSchoolProgram,
  activateSchoolProgram,
  deleteUserProgram,
  revokeProgramAdminLoaded,
} from 'store/program';
import { revokeAdmin, toggleDisableUser } from 'store/common';
import { isProgramAdmin, TEACHER_ROLE, ADMIN_ROLE, isSchoolAdmin, isAdminUser } from 'constants/user';
import { GRADE_COLORS, GRADE_TITLES } from 'constants/classrooms';
import { getAlphabeticalSort, obtainInitials } from 'utils/string';
import { buildUrl } from 'utils';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
// components
import UserActionWarningModal from '../UserActionWarningModal';
import AdminBadge from 'components/Badges/AdminBadge';
import NumericIncrementInput from 'components/NumericIncrementInput';
import Modal from 'components/Modal';
import WelcomeModal from 'components/WelcomeModal';
import AdminUserCart from 'components/AdminUserCart';
import Button from 'components/Button';
import Input from 'components/Input';
import AddressSelect from 'components/AddressSelect';
import ImageCropperModal from 'components/ImageCropperModal';
import InviteAdminModal from '../BrowserView/InviteAdminModal';
import CreateInstanceModal from '../BrowserView/CreateInstanceModal';
import BottomMenu from 'components/BottomMenu';
import MobileItemRow from 'components/MobileItemRow';
import AlphabeticalSort from 'components/AlphabeticalSort';
import AddContentField from 'components/AddContentField';
import Text from 'components/Text';
import PlusFab from 'components/Button/PlusFab';
import Portal from 'components/Portal';
// icons
import { ReactComponent as AddIcon } from 'icons/add-white.svg';
import { ReactComponent as CameraIcon } from 'icons/camera-white.svg';
import { ReactComponent as ActivateIcon } from 'icons/check.svg';
import { ReactComponent as RemoveIcon } from 'icons/remove.svg';
import { ReactComponent as DisableIcon } from 'icons/disable.svg';
import { ReactComponent as ManageIcon } from 'icons/gear.svg';
import { ReactComponent as ChevronIcon } from 'icons/chevron-grey.svg';
import { ReactComponent as CreateIcon } from 'icons/graduate-hat.svg';
import { ReactComponent as InviteIcon } from 'icons/send.svg';
// styles
import classnames from 'classnames/bind';
import styles from './MobileView.module.scss';

const cn = classnames.bind(styles);

export const InfoMobile = ({ isProgramPanel }) => {
  const {
    id: programId,
    name: programName,
    schools_number: schoolsInUse,
    classrooms_number: classroomsInUse,
    adminUsers: programAdminUsers = [],
    avatar_url: programImageUrl,
    address: programAddresss,
    schools: allSchools = [],
  } = useSelector(({ program = {} }) => program);
  const { id: myId, licenses = [] } = useSelector(({ auth: { user = {} } = {} }) => {
    const { licenses } = user.programs.find(({ id }) => +id === +programId) || {};

    return { id: user.id, licenses };
  });
  const { role } = useSelector((state) => state.auth.user);
  const {
    id: schoolId,
    name: schoolName,
    avatar_url: schoolImageUrl,
    classroom_limit: classroomLimit,
    classrooms_number: classroomNumber,
    adminUsers: schoolAdminUsers = [],
    address: schoolAddress,
  } = useSelector(({ school = {} }) => school);

  const isSchoolAdm = isSchoolAdmin(role);
  const isProgAdmin = isProgramAdmin(role);

  const imageUrl = isProgramPanel ? programImageUrl : schoolImageUrl;
  const adminUsers = isProgramPanel ? programAdminUsers : schoolAdminUsers;

  const dispatch = useDispatch();
  const setModal = useModalSetter();

  const infoFormSchema = yup.object().shape({
    [isProgramPanel ? 'programName' : 'schoolName']: yup.string().required('This field is required.'),
    address: yup.string().required('This field is required.'),
  });

  const address = isProgramPanel ? programAddresss : schoolAddress;

  const formInitValues = {
    [isProgramPanel ? 'programName' : 'schoolName']: isProgramPanel ? programName : schoolName,
    address,
  };

  const { register, handleSubmit, errors, ...restForm } = useForm({
    defaultValues: { ...formInitValues },
    resolver: yupResolver(infoFormSchema),
    shouldUnregister: false,
  });

  const { register: totalLicRegister, handleSubmit: handleLicSubmit } = useForm({
    defaultValues: { totalLicenses: classroomLimit },
  });

  const onFormSubmit = ({ programName, address, schoolName }) =>
    dispatch(
      isProgramPanel
        ? updateProgram({ name: programName, address }, programId)
        : updateSchoolInfo({ name: schoolName, address }, schoolId),
    );

  const handleAcceptImageCrop = (imgEl, callback) => {
    if (!imgEl) return;

    const formData = new FormData();
    const imageCp = imgEl.slice();
    imageCp.lastModifiedDate = new Date();
    imageCp.name = 'avatar';

    formData.append('resource[avatar]', imageCp);

    return dispatch(
      isProgramPanel ? updateProgram(formData, programId, true) : updateSchoolInfo(formData, schoolId, true),
    ).then(() => typeof callback === 'function' && callback());
  };

  const handleInviteSubmit = (data) => dispatch(isProgramPanel ? inviteUsersProgram(data) : inviteUser(data));

  let licensesData = [];
  if (isProgramPanel) {
    licensesData = [
      {
        type: 'School License',
        total: licenses[0].school_limit,
        inUse: schoolsInUse,
        startDate: licenses[0].start_date,
        endDate: licenses[0].end_date,
      },
      {
        type: 'Classroom License',
        total: licenses[0].classroom_limit,
        inUse: classroomsInUse,
        startDate: licenses[0].start_date,
        endDate: licenses[0].end_date,
      },
    ];
  } else {
    licensesData = [
      {
        type: 'Classroom License',
        total: classroomLimit,
        inUse: classroomNumber || 0,
        startDate: licenses[0].start_date,
        endDate: licenses[0].end_date,
      },
    ];
  }

  const maxClassroomLic =
    licenses[0].classroom_limit -
    allSchools.reduce((acc, curr) => (curr.id !== schoolId ? acc + curr.classroom_limit : acc), 0);

  const UpdateLicensesModal = () => (
    <Modal className={cn('licenses-modal')}>
      {({ closeModal }) => {
        const onChangeLicensesSubmit = ({ totalLicenses }) => {
          if (!isProgramPanel && isProgramAdmin(role)) {
            dispatch(updateSchoolInfo({ classroom_limit: totalLicenses }, schoolId)).then(closeModal);
            return;
          }
          closeModal();
        };

        return (
          <>
            <Modal.Header className="friendly">
              <Text type="h5">Change Classroom Licenses</Text>
              <Modal.CloseButton className="friendly" onClick={closeModal} />
            </Modal.Header>
            <Modal.Body className={cn('modal-body')}>
              <Text type="body-big" className="mb-30">{`Licenses limit: ${maxClassroomLic}`}</Text>
              <NumericIncrementInput
                initValue={classroomLimit}
                bottomLimit={classroomNumber || 0}
                topLimit={maxClassroomLic}
                name="totalLicenses"
                inputRef={totalLicRegister({ required: true })}
              />
            </Modal.Body>
            <Modal.Footer className="friendly">
              <form onSubmit={handleLicSubmit(onChangeLicensesSubmit)}>
                <Button label="Save" size="md" disabled={maxClassroomLic === 0} isSubmit />
              </form>
            </Modal.Footer>
          </>
        );
      }}
    </Modal>
  );

  const handleRevokeAdmin = ({ adminId, roles }) => {
    const currInstanceId = isProgramPanel ? programId : schoolId;
    const currInstanceType = isProgramPanel ? 'program' : 'school';
    const joinedTableId = roles.find(
      ({ id: instanceId, type }) => +instanceId === currInstanceId && type === currInstanceType,
    )?.join_table_record_id;

    const responseHandler = isProgramPanel ? revokeProgramAdminLoaded : revokeSchoolAdminLoaded;

    const requestData = isProgramPanel
      ? {
          programs_users_attributes: [{ id: joinedTableId, role: null }],
        }
      : { schools_users_attributes: [{ id: joinedTableId, role: TEACHER_ROLE }] };

    return dispatch(revokeAdmin(adminId, programId, schoolId, { ...requestData })).then((res) =>
      dispatch(responseHandler(res)),
    );
  };

  return (
    <section className={cn('section')}>
      <div className={cn('instance-image-wrapper')}>
        {imageUrl ? (
          <img className={cn('image', 'no-bg')} src={buildUrl(imageUrl)} aria-hidden alt="image" />
        ) : (
          <div className={cn('image')}>{obtainInitials(isProgramPanel ? programName : schoolName)}</div>
        )}
        <CameraIcon
          className={cn('icon')}
          onClick={() =>
            setModal(
              <ImageCropperModal
                aspectRatio={1 / 1}
                imageUrl={imageUrl}
                onSave={handleAcceptImageCrop}
                className={cn('mobile-avatar-cropper')}
              />,
            )
          }
        />
      </div>
      <form className={cn('info-form', 'mt-20')} onSubmit={handleSubmit(onFormSubmit)}>
        <Input
          label={`${isProgramPanel ? 'Program Name' : 'School Name'}`}
          name={`${isProgramPanel ? 'programName' : 'schoolName'}`}
          inputRef={register({ required: true })}
          errors={errors}
        />
        <AddressSelect
          name="address"
          setValue={(val) => restForm.setValue('address', val)}
          inputRef={register({ required: true })}
          errors={errors}
          defaultValue={address}
        />
        <Button className="mt-20" size="bg" isSubmit label="Save Changes" />
      </form>
      <div className={cn('licenses-wrapper', 'mt-40')}>
        <Text>License Type</Text>
        <div className={cn('hr', 'mt-10 mb-15')} />
        {licensesData.map(({ type, total, inUse, startDate, endDate }, idx) => (
          <div key={`type-${idx}`}>
            <Text className="mv-5" type="h6">
              {type}
            </Text>
            <Text className="mv-5">Total&nbsp;•&nbsp;{total}</Text>
            <Text className="mv-5">In-use&nbsp;•&nbsp;{inUse}</Text>
            <Text className="mv-5">Start Date&nbsp;•&nbsp;{startDate}</Text>
            <Text className="mv-5">End Date&nbsp;•&nbsp;{endDate}</Text>
            <div className={cn('hr', 'mv-15')} />
          </div>
        ))}
        <Text className="ml-auto">
          Questions?{' '}
          <span className={cn('contact-span')} onClick={() => alert('todo')}>
            Contact Sales
          </span>
        </Text>
        {!isProgramPanel && isProgramAdmin(role) && (
          <Button
            className="ml-auto mt-20"
            size="md"
            label="Update Licenses"
            onClick={() => setModal(<UpdateLicensesModal />)}
          />
        )}
      </div>
      <Text type="h5" className="mt-40 mb-25">{`${isProgramPanel ? 'Program' : 'School'} Admin Users`}</Text>
      <Button
        size="bg"
        className={cn('btn-add', 'pl-10 pr-25')}
        label={
          <div className={cn('add-label')}>
            <AddIcon className="mr-5" />
            <Text>{`Invite ${isProgramPanel ? 'Program' : 'School'} Admin`}</Text>
          </div>
        }
        onClick={() =>
          setModal(
            <InviteAdminModal
              name={isProgramPanel ? programName : schoolName}
              onSubmit={handleInviteSubmit}
              onlyAdmin
              isProgram={isProgramPanel}
            />,
          )
        }
      />
      <div className={cn('admins-wrapper', 'mt-35')}>
        {adminUsers.map(
          ({ id: adminId, avatar_url, full_name, first_name, last_name, is_active, email, role, roles }, idx) => (
            <AdminUserCart
              key={`cart-${idx}`}
              className="mv-10"
              imageUrl={avatar_url}
              firstName={first_name}
              lastName={last_name}
              active={is_active}
              email={email}
              role={role}
              onRevoke={
                adminId !== myId
                  ? () =>
                      setModal(
                        <UserActionWarningModal
                          headerLabel="Revoke User"
                          confirmLabel="Revoke"
                          onConfirm={() => handleRevokeAdmin({ adminId, roles })}
                          renderBody={() => (
                            <Text>
                              Are you sure you wish to revoke <strong>{full_name}'s</strong> admin privileges?
                            </Text>
                          )}
                        />,
                      )
                  : null
              }
            />
          ),
        )}
      </div>
    </section>
  );
};

export const PeopleMobile = ({ isProgramPanel }) => {
  const { id: myId, role } = useSelector(({ auth }) => auth.user);
  const { id: programId, name: programName, users: programUsers = [] } = useSelector(({ program = {} }) => program);
  const { id: schoolId, name: schoolName, users: schoolUsers = [] } = useSelector(({ school = {} }) => school);
  const users = isProgramPanel ? programUsers : schoolUsers;

  const { push } = useHistory();

  const [sortAlphabetically, setSortAlphabetically] = useState(true);
  const [searchPhrase, setSearchPhrase] = useState('');

  const { setBottomMenu, closeMenu } = useBottomMenuSetter();

  const dispatch = useDispatch();

  const setModal = useModalSetter();

  const sortedData = users
    .sort(({ full_name: a, email: emailA }, { full_name: b, email: emailB }) =>
      getAlphabeticalSort(a || emailA, b || emailB, sortAlphabetically),
    )
    .filter(
      ({ full_name, email }) =>
        (full_name || '').toLowerCase().includes(searchPhrase.toLowerCase()) ||
        email.toLowerCase().includes(searchPhrase.toLowerCase()),
    );

  const usersRenderer = ({ full_name, email, status }) => (
    <div className={cn('row-texts-container')}>
      <Text type="h7">{full_name || email}</Text>
      <Text type="h11">{full_name && email}</Text>
      <Text type="h11" className="mt-5">
        {status === 'active' ? (
          'Active'
        ) : status === 'disabled' ? (
          'Disabled'
        ) : (
          <span style={{ color: '#54585a' }}>{status === 'pending' ? 'Invite Pending' : 'Not Assigned'}</span>
        )}
      </Text>
    </div>
  );

  const removeUser = isProgramPanel ? deleteUserProgram : deleteUser;

  const PeopleDrawerMenu = ({
    data: { id: userId, status, email, role, roles, is_active: isActive, schoolScopeRoleless, full_name },
  }) => {
    const joinTableId = roles.find(({ id, type }) =>
      isProgramPanel ? +id === +programId && type === 'program' : +id === +schoolId && type === 'school',
    )?.join_table_record_id;

    return (
      <BottomMenu menuHeading="MANAGE USER" className={cn('drawer-menu')}>
        <div
          className={cn('drawer-option')}
          onClick={() => {
            push(`/user-profile/${userId}?range=${isProgramPanel ? 'program' : 'school'}`);
            closeMenu();
          }}
        >
          <ManageIcon />
          <Text className="ml-10">Manage User</Text>
        </div>
        <div
          className={cn('drawer-option')}
          onClick={() => {
            status === 'pending'
              ? dispatch(reinviteUser({ email, role }))
              : dispatch(resetPassword(email, { 'program-id': programId, 'school-id': schoolId }));
            closeMenu();
          }}
        >
          <InviteIcon />
          <Text className="ml-10">{status === 'pending' ? 'Resend Invite' : 'Send Password Reset'}</Text>
        </div>
        {myId !== userId && !schoolScopeRoleless && (
          <div
            className={cn('drawer-option')}
            onClick={() => {
              ['pending', 'not assigned'].includes(status)
                ? dispatch(removeUser(userId, { program_id: programId, school_id: schoolId }))
                : isActive
                ? setModal(
                    <UserActionWarningModal
                      onConfirm={() =>
                        dispatch(
                          toggleDisableUser(
                            userId,
                            {
                              [isProgramPanel ? 'programs_users_attributes' : 'schools_users_attributes']: {
                                id: joinTableId,
                                is_active: false,
                              },
                            },
                            isProgramPanel ? { program_id: programId } : { program_id: programId, school_id: schoolId },
                          ),
                        ).then((res) => dispatch(isProgramPanel ? userUpdateProgram(res) : userUpdateSchool(res)))
                      }
                      renderBody={() => (
                        <Text>
                          Are you sure you wish to disable <strong>{full_name}</strong>? This action will revoke user's
                          access.
                        </Text>
                      )}
                      headerLabel="Disable User"
                      confirmLabel="Disable"
                    />,
                  )
                : dispatch(
                    toggleDisableUser(
                      userId,
                      {
                        [isProgramPanel ? 'programs_users_attributes' : 'schools_users_attributes']: {
                          id: joinTableId,
                          is_active: true,
                        },
                      },
                      isProgramPanel ? { program_id: programId } : { program_id: programId, school_id: schoolId },
                      false,
                    ),
                  ).then((res) => dispatch(isProgramPanel ? userUpdateProgram(res) : userUpdateSchool(res)));
              closeMenu();
            }}
          >
            {['pending', 'not assigned'].includes(status) ? (
              <RemoveIcon />
            ) : isActive ? (
              <DisableIcon />
            ) : (
              <ActivateIcon className={cn('check-icon')} />
            )}
            <Text className="ml-10">
              {['pending', 'not assigned'].includes(status)
                ? 'Remove User'
                : isActive
                ? 'Disable User'
                : 'Activate User'}
            </Text>
          </div>
        )}
      </BottomMenu>
    );
  };

  const badgeRenderer = ({ role }) => <AdminBadge role={role} schoolAdmin={isSchoolAdmin(role)} />;

  return (
    <section className={cn('section')}>
      <div>
        <AlphabeticalSort
          label={'User Name'}
          sorted={sortAlphabetically}
          onClick={() => setSortAlphabetically((prev) => !prev)}
          className="mb-20"
          withSearch
          onSearchChange={setSearchPhrase}
        />
        {sortedData.map((row) => (
          <MobileItemRow
            key={row.id}
            imageUrl={row.avatar_url}
            textsRenderer={() => usersRenderer(row)}
            onMenuClick={() => setBottomMenu(<PeopleDrawerMenu data={row} />)}
            showMenu={hasMajorRole(role, row.role)}
            badgeRenderer={() => isAdminUser(row.role) && badgeRenderer(row)}
          />
        ))}
      </div>
    </section>
  );
};

export const MainTabMobile = ({ isProgramPanel }) => {
  const { role, roles } = useSelector(({ auth }) => auth.user);
  const { id: programId, schools = [], users: programUsers = [], classroomsAvailable } = useSelector(
    ({ program = {} }) => {
      let schools;

      if (isProgramAdmin(role)) {
        schools = get(program, 'schools', []);
      } else {
        schools = (program.schools || []).filter(({ id: _id }) =>
          roles.some(({ id, type, role: iterRole }) => type === 'school' && +id === +_id && isSchoolAdmin(iterRole)),
        );
      }

      return {
        id: get(program, 'id', ''),
        name: get(program, 'name', ''),
        schools,
        users: get(program, 'users', []),
        schoolsAvailable: get(program, 'schoolsAvailable', []),
        classroomsAvailable: get(program, 'classroomsAvailable', []),
      };
    },
  );
  const { id: schoolId, classrooms = [], users: schoolUsers = [] } = useSelector(({ school = {} }) => school);

  const [sortAlphabetically, setSortAlphabetically] = useState(true);
  const [searchPhrase, setSearchPhrase] = useState('');
  const { setBottomMenu, closeMenu } = useBottomMenuSetter();
  const { push } = useHistory();

  const dispatch = useDispatch();

  const setModal = useModalSetter();

  const dataFields = isProgramPanel ? schools : classrooms;

  const sortedData = dataFields
    .sort(({ name: a }, { name: b }) => getAlphabeticalSort(a, b, sortAlphabetically))
    .filter(({ name }) => name.toLowerCase().includes(searchPhrase.toLowerCase()));

  const schoolTextsRenderer = ({ name, classroom_limit, classrooms_number, status, is_active }) => (
    <div className={cn('row-texts-container')}>
      <Text type="h7">{name}</Text>
      <Text type="h11">Classroom Licenses&nbsp;•&nbsp;{`${classrooms_number}/${classroom_limit}`}</Text>
      <Text type="h11" className="mt-5">
        {!is_active ? 'Disabled' : status === 'active' ? 'Active' : 'Incomplete'}
      </Text>
    </div>
  );

  const classroomTextsRenderer = ({ name, grade_level }) => (
    <div className={cn('row-texts-container')}>
      <Text type="h11">{GRADE_TITLES[grade_level]}</Text>
      <Text className={cn('classroom-name')} type="h6">
        {name}
      </Text>
    </div>
  );

  // takes care both of school and classroom
  const handleDisableActivate = (instance) => {
    if (isProgramPanel) {
      if (instance.is_active) {
        dispatch(disableSchoolProgram(instance.id));
      } else {
        dispatch(activateSchoolProgram(instance.id));
      }
    } else {
      if (instance.is_active) {
        dispatch(disableClassroom(instance.id));
      } else {
        dispatch(activateClassroom(instance.id));
      }
    }
    closeMenu();
  };

  const InstanceDrawerMenu = ({ instance }) => (
    <BottomMenu menuHeading={`MANAGE ${isProgramPanel ? 'SCHOOL' : 'CLASSROOM'}`} className={cn('drawer-menu')}>
      <div
        className={cn('drawer-option')}
        onClick={() => {
          if (isProgramPanel) {
            dispatch(updateSchoolId(instance));
            push('/school-panel');
          } else {
            push(`/manage-classroom/${instance.id}`);
          }
          closeMenu();
        }}
      >
        <ManageIcon />
        <Text className="ml-10">{`Manage ${isProgramPanel ? 'School' : 'Classroom'}`}</Text>
      </div>
      {((isProgramPanel && isProgramAdmin(role)) || !isProgramPanel) && (
        <div className={cn('drawer-option')} onClick={() => handleDisableActivate(instance)}>
          {instance.is_active ? <DisableIcon /> : <ActivateIcon className={cn('check-icon')} />}
          <Text className="ml-10">{`${instance.is_active ? 'Disable' : 'Activate'} ${
            isProgramPanel ? 'School' : 'Classroom'
          }`}</Text>
        </div>
      )}
    </BottomMenu>
  );

  const handleSchoolCreate = ({ schoolName: _schoolName, schoolAddress, numLicenses }, users) => {
    const userMails = users.filter((_usr) => typeof _usr === 'string');
    const userIds = users.filter((_usr) => typeof _usr === 'object').map(({ id }) => id);
    return dispatch(
      createSchool({ name: _schoolName, address: schoolAddress, classroom_limit: numLicenses, user_ids: userIds }),
    ).then(({ id: createdSchoolId }) => {
      const toInvite = userMails.map((email) => ({
        email,
        programs_users_attributes: [{ program_id: programId }],
        schools_users_attributes: [{ school_id: createdSchoolId, role: ADMIN_ROLE }],
      }));

      dispatch(inviteUsersProgram(toInvite));
    });
  };

  const handleClassroomCreate = ({ classroomName, gradeLevel }, users) => {
    const userMails = users.filter((_usr) => typeof _usr === 'string');
    const userIds = users.filter((_usr) => typeof _usr === 'object').map(({ id }) => id);
    return dispatch(
      createClassroom({
        name: classroomName,
        grade_level: gradeLevel,
        color: GRADE_COLORS[gradeLevel],
        user_ids: userIds,
      }),
    ).then(({ payload: { id: classroomId } }) => {
      const toInvite = userMails.map((email) => ({
        email,
        classroom_ids: [classroomId],
        programs_users_attributes: [{ program_id: programId }],
        schools_users_attributes: [{ school_id: schoolId, role: TEACHER_ROLE }],
      }));

      dispatch(inviteUser(toInvite));
    });
  };

  return (
    <section className={cn('section')}>
      {dataFields.length > 0 ? (
        <div>
          <AlphabeticalSort
            label={isProgramPanel ? 'Schools' : 'Classrooms'}
            sorted={sortAlphabetically}
            onClick={() => setSortAlphabetically((prev) => !prev)}
            className="mb-20"
            withSearch
            onSearchChange={setSearchPhrase}
          />
          {sortedData.map((row) => (
            <MobileItemRow
              key={row.id}
              imageUrl={isProgramPanel ? row.avatar_url : row.cover_image && row.cover_image.image_url}
              name={row.name}
              textsRenderer={() => (isProgramPanel ? schoolTextsRenderer(row) : classroomTextsRenderer(row))}
              onMenuClick={() => setBottomMenu(<InstanceDrawerMenu instance={row} />)}
            />
          ))}
        </div>
      ) : (
        ((isProgramPanel && isProgramAdmin(role)) || !isProgramPanel) && (
          <AddContentField
            text={isProgramPanel ? 'Add your first school.' : 'Add your first classroom.'}
            onClick={() =>
              setModal(
                <CreateInstanceModal
                  isProgram={isProgramPanel}
                  teachers={isProgramPanel ? programUsers.filter(({ role }) => !isProgramAdmin(role)) : schoolUsers}
                  classroomsAvailable={classroomsAvailable}
                  onSubmit={isProgramPanel ? handleSchoolCreate : handleClassroomCreate}
                />,
              )
            }
          />
        )
      )}
    </section>
  );
};

const MobileView = ({ isProgramPanel }) => {
  const {
    id: programId,
    avatar_url: programImageUrl,
    users: programUsers = [],
    isLoading: programLoading,
    schoolsAvailable,
    classroomsAvailable,
  } = useSelector(({ program = {} }) => program);
  const { myId, fName, lName, title = '', role, programName } = useSelector(
    ({ auth: { user: { id: myId, first_name = '', last_name = '', title = '', role, programs = [] } = {} } = {} }) => ({
      myId,
      fName: first_name,
      lName: last_name,
      title,
      role,
      programName: (programs.find(({ id }) => +id === +programId) || {}).name,
    }),
  );
  const {
    id: schoolId,
    name: schoolName,
    avatar_url: schoolImageUrl,
    classroomsAvailable: schoolClassroomsRemaining,
    users: schoolUsers = [],
    isLoading: schoolLoading,
  } = useSelector(({ school = {} }) => school);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!programLoading) {
      dispatch(getProgram(programId));
    }
  }, [dispatch, programId, isProgramPanel]);

  useEffect(() => {
    if (!isProgramPanel && !schoolLoading) {
      dispatch(getSchoolInfo(schoolId));
    }
  }, [dispatch, schoolId, isProgramPanel]);

  const { setBottomMenu, closeMenu } = useBottomMenuSetter();
  const setModal = useModalSetter();

  // useEffect(() => setModal(<WelcomeModal programName={programName} />), []);

  const handleInviteSubmit = (data) => dispatch(isProgramPanel ? inviteUsersProgram(data) : inviteUser(data));

  const handleSchoolCreate = ({ schoolName: _schoolName, schoolAddress, numLicenses }, users) => {
    const userMails = users.filter((_usr) => typeof _usr === 'string');
    const userIds = users.filter((_usr) => typeof _usr === 'object').map(({ id }) => id);
    return dispatch(
      createSchool({ name: _schoolName, address: schoolAddress, classroom_limit: numLicenses, user_ids: userIds }),
    ).then(({ id: createdSchoolId }) => {
      const toInvite = userMails.map((email) => ({
        email,
        programs_users_attributes: [{ program_id: programId }],
        schools_users_attributes: [{ school_id: createdSchoolId, role: ADMIN_ROLE }],
      }));

      dispatch(inviteUsersProgram(toInvite));
    });
  };

  const handleClassroomCreate = ({ classroomName, gradeLevel }, users) => {
    const userMails = users.filter((_usr) => typeof _usr === 'string');
    const userIds = users.filter((_usr) => typeof _usr === 'object').map(({ id }) => id);
    return dispatch(
      createClassroom({
        name: classroomName,
        grade_level: gradeLevel,
        color: GRADE_COLORS[gradeLevel],
        user_ids: userIds,
      }),
    ).then(({ payload: { id: classroomId } }) => {
      const toInvite = userMails.map((email) => ({
        email,
        classroom_ids: [classroomId],
        programs_users_attributes: [{ program_id: programId }],
        schools_users_attributes: [{ school_id: schoolId, role: TEACHER_ROLE }],
      }));

      dispatch(inviteUser(toInvite));
    });
  };

  const DrawerMenu = () => (
    <BottomMenu menuHeading="ADD" className={cn('drawer-menu')}>
      <div
        className={cn('drawer-option')}
        onClick={() => {
          setModal(
            <CreateInstanceModal
              isProgram={isProgramPanel}
              teachers={isProgramPanel ? programUsers.filter(({ role }) => !isProgramAdmin(role)) : schoolUsers}
              classroomsAvailable={classroomsAvailable}
              onSubmit={isProgramPanel ? handleSchoolCreate : handleClassroomCreate}
            />,
          );
          closeMenu();
        }}
      >
        <CreateIcon />
        <Text className="ml-10">{`Create a ${isProgramPanel ? 'School' : 'Classroom'}`}</Text>
      </div>
      <div
        className={cn('drawer-option')}
        onClick={() => {
          setModal(<InviteAdminModal onSubmit={handleInviteSubmit} isProgram={isProgramPanel} />);
          closeMenu();
        }}
      >
        <InviteIcon />
        <Text className="ml-10">Invite Users</Text>
      </div>
    </BottomMenu>
  );

  const { goBack } = useHistory();

  return (
    <>
      <div className={cn('header-wrapper', 'pv-15', 'pb-25')}>
        <Text type="h6">{isProgramPanel ? programName : schoolName}</Text>
        {!isProgramPanel && <ChevronIcon className={cn('header-wrapper--icon')} onClick={goBack} />}
      </div>
      {((isProgramPanel && isProgramAdmin(role)) || !isProgramPanel) && (
        <Portal>
          <PlusFab className={cn('menu-fab')} onClick={() => setBottomMenu(<DrawerMenu />)} />
        </Portal>
      )}
    </>
  );
};

export default withTabSwitcherMobile(MobileView);
