import React from 'react';
import T from 'prop-types';
// UI and styles
import { ReactComponent as DownloadIcon } from 'icons/download.svg';
import { ReactComponent as PdfIcon } from 'icons/pdf.svg';
import Text from 'components/Text';
import classnames from 'classnames/bind';
import styles from './PrintableBox.module.scss';

const cn = classnames.bind(styles);

const PrintableBox = ({ title, fileSize, handleClick, className }) => {
  return (
    <div className={cn('wrapper', className)} onClick={handleClick}>
      <div className={cn('content-left')}>
        <PdfIcon className={cn('pdf-icon')} />
        <div>
          <Text bold>{title}</Text>
          <Text className={cn('dark-gray-text')}>PDF &bull; {Math.floor(fileSize / 1000)}kB</Text>
        </div>
      </div>
      <DownloadIcon className={cn('download-icon')} />
    </div>
  );
};

PrintableBox.propTypes = {
  title: T.string.isRequired,
  fileSize: T.number.isRequired,
  handleClick: T.func.isRequired,
};

export default PrintableBox;
