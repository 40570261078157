import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// hoc
import withTabSwitcherMobile from 'hoc/withTabSwitcherMobile';
// hooks
import { useBottomMenuSetter } from 'hooks/useBottomMenu';
import { useModalSetter } from 'hooks/useModal';
// utils
import { getDefaultValue } from 'utils/object';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { GRADE_TITLES } from 'constants/classrooms';
import { getAlphabeticalSort } from 'utils/string';
import { difference } from 'lodash';
import { TITLE_OPTIONS_LABELS, TITLE_OPTIONS, isProgramAdmin, TEACHER_ROLE } from 'constants/user';
// components
import UserActionWarningModal from 'pages/AdminPanel/UserActionWarningModal';
import Portal from 'components/Portal';
import PlusFab from 'components/Button/PlusFab';
import Button from 'components/Button';
import Input from 'components/Input';
import SelectBox from 'components/SelectBox';
import BottomMenu from 'components/BottomMenu';
import AlphabeticalSort from 'components/AlphabeticalSort';
import MobileItemRow from 'components/MobileItemRow';
import ManageClassroomsModal from '../BrowserView/ManageClassroomsModal';
import AddContentField from 'components/AddContentField';
import Text from 'components/Text';
// icons
import { ReactComponent as DisableIcon } from 'icons/disable.svg';
import { ReactComponent as ManageIcon } from 'icons/gear.svg';
import { ReactComponent as ChevronIcon } from 'icons/chevron-grey.svg';
// styles
import classnames from 'classnames/bind';
import styles from './MobileView.module.scss';

const cn = classnames.bind(styles);

export const UserInfoMobile = ({
  title,
  firstName,
  lastName,
  email,
  isActive,
  fullName,
  targetRoleObj,
  onRevoke,
  onDisable,
}) => {
  const { userId } = useParams();
  const { id: myId } = useSelector(({ auth }) => auth.user);

  const { register, errors, clearErrors, handleSubmit, ...restForm } = useForm();

  const dispatch = useDispatch();
  const setModal = useModalSetter();

  const handleFormSubmit = (_) => {};

  return (
    <section className={cn('section')}>
      <form className={cn('creds-wrapper')} onSubmit={handleSubmit(handleFormSubmit)}>
        <SelectBox
          label="Title"
          name="title"
          hookFormProps={{ register, errors, clearErrors, ...restForm }}
          className={cn('title-select')}
          options={TITLE_OPTIONS}
          defaultValue={title && getDefaultValue(TITLE_OPTIONS, title)}
          isDisabled
        />
        <div className={cn('sub-wrapper')}>
          <Input label="First Name" name="firstName" className={cn('input', 'mr-40')} disabled value={firstName} />
          <Input label="Last Name" name="lastName" className={cn('input')} disabled value={lastName} />
          <Input label="Program Email Address" disabled name="email" className={cn('input')} placeholder={email} />
        </div>
      </form>
      {+myId !== +userId && targetRoleObj && (
        <div className={cn('disable-wrapper', 'ph-20 pv-25 mt-30')}>
          <div className={cn('sub-wrapper')}>
            <Text>{`${targetRoleObj.is_active ? 'Disable' : 'Activate'} User`}</Text>
            <Text type="body-small" className="mt-5">
              {targetRoleObj.is_active
                ? `Disabling this user will revoke this user's access.`
                : `Activating this user will restore this user's access.`}
            </Text>
          </div>
          <Button
            size="md"
            variant="black"
            label={`${targetRoleObj.is_active ? 'Disable' : 'Activate'} User`}
            className={cn('disable-btn', 'mt-15')}
            onClick={() =>
              targetRoleObj.is_active
                ? setModal(
                    <UserActionWarningModal
                      onConfirm={onDisable}
                      renderBody={() => (
                        <Text>
                          Are you sure you wish to disable <strong>{fullName}</strong>? This action will revoke user's
                          access.
                        </Text>
                      )}
                      headerLabel="Disable User"
                      confirmLabel="Disable"
                    />,
                  )
                : onDisable(false)
            }
          />
        </div>
      )}
      {+myId !== +userId && targetRoleObj && targetRoleObj.role && targetRoleObj.role !== TEACHER_ROLE && (
        <Button
          className={cn('revoke-btn', 'mt-30')}
          size="md"
          variant="black"
          label={`Revoke ${isProgramAdmin(targetRoleObj.role) ? 'Program' : 'School'} Admin Role`}
          onClick={() =>
            setModal(
              <UserActionWarningModal
                headerLabel="Revoke User"
                confirmLabel="Revoke"
                onConfirm={() => onRevoke({ adminId: userId })}
                renderBody={() => (
                  <Text>
                    Are you sure you wish to revoke <strong>{fullName}'s</strong> admin privileges?
                  </Text>
                )}
              />,
            )
          }
        />
      )}
    </section>
  );
};

export const ClassroomsMobile = ({ classrooms, allClassrooms, fullName, programId, schoolId }) => {
  const [sortAlphabetically, setSortAlphabetically] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState('');

  const { userId } = useParams();
  const { push } = useHistory();

  const setModal = useModalSetter();
  const { setBottomMenu, closeMenu } = useBottomMenuSetter();

  const sortedClassrooms = classrooms
    .sort(({ name: a }, { name: b }) => getAlphabeticalSort(a, b, sortAlphabetically))
    .filter(({ name }) => name.toLowerCase().includes(searchPhrase.toLowerCase()));

  const classroomTextsRenderer = ({ name, grade_level }) => (
    <div className={cn('row-texts-container')}>
      <Text type="h11">{GRADE_TITLES[grade_level]}</Text>
      <Text className={cn('classroom-name')} type="h6">
        {name}
      </Text>
    </div>
  );

  const ClassroomDrawerMenu = ({ classroom }) => (
    <BottomMenu menuHeading="MANAGE" className={cn('drawer-menu')}>
      <div
        className={cn('drawer-option')}
        onClick={() => {
          push(`/manage-classroom/${classroom.id}`);
          closeMenu();
        }}
      >
        <ManageIcon />
        <Text className="ml-10">Manage Classroom</Text>
      </div>
      <div className={cn('drawer-option')} onClick={() => alert('todo')}>
        <DisableIcon />
        <Text className="ml-10">Disable Classroom</Text>
      </div>
    </BottomMenu>
  );

  return (
    <section className={cn('section')}>
      {classrooms.length > 0 ? (
        <div>
          <AlphabeticalSort
            label={'Classrooms'}
            sorted={sortAlphabetically}
            onClick={() => setSortAlphabetically((prev) => !prev)}
            className="mb-20"
            withSearch
            onSearchChange={setSearchPhrase}
          />
          {sortedClassrooms.map((row) => (
            <MobileItemRow
              key={row.id}
              imageUrl={row.cover_image && row.cover_image.image_url}
              name={row.name}
              textsRenderer={() => classroomTextsRenderer(row)}
              onMenuClick={() => setBottomMenu(<ClassroomDrawerMenu classroom={row} />)}
            />
          ))}
        </div>
      ) : (
        <AddContentField
          className={cn('add-container')}
          text={`Assign a Classroom.`}
          onClick={() =>
            setModal(
              <ManageClassroomsModal
                fullName={fullName}
                teacherId={+userId}
                notAssigned={difference(allClassrooms, classrooms)}
                assigned={classrooms}
                programId={programId}
                schoolId={schoolId}
              />,
            )
          }
        />
      )}
    </section>
  );
};

const MobileView = ({ title, fullName, allClassrooms, classrooms, programId, schoolId }) => {
  const { goBack } = useHistory();
  const { userId } = useParams();

  const setModal = useModalSetter();

  return (
    <>
      <div className={cn('header-wrapper', 'pv-15', 'pb-25')}>
        <Text type="h6">
          {TITLE_OPTIONS_LABELS[(title || '').toLowerCase()]} {fullName}
        </Text>
        <ChevronIcon className={cn('header-wrapper--icon')} onClick={goBack} />
      </div>
      <Portal>
        <PlusFab
          className={cn('menu-fab')}
          onClick={() =>
            setModal(
              <ManageClassroomsModal
                fullName={fullName}
                teacherId={+userId}
                notAssigned={difference(allClassrooms, classrooms)}
                assigned={classrooms}
                programId={programId}
                schoolId={schoolId}
              />,
            )
          }
        />
      </Portal>
    </>
  );
};

export default withTabSwitcherMobile(MobileView);
