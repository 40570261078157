import React from 'react';
import T from 'prop-types';
import classnames from 'classnames/bind';
// styles
import styles from './Badges.module.scss';

const cn = classnames.bind(styles);

const DraftBadge = ({ className, size = 'sm', ...props }) => (
  <div className={cn('draft-badge', { [`size-${size}`]: size }, className)} {...props}>DRAFT</div>
);

DraftBadge.propTypes = {
  className: T.string,
  size: T.oneOf(['sm', 'lg']),
};

export default DraftBadge;
