import React from 'react';
// components
import PortalDropdown from 'components/Dropdown/PortalDropdown';
import { ReactComponent as DotsIcon } from 'icons/dots.svg';
// utils
import classanmes from 'classnames/bind';

// styles
import styles from './DotsDropdown.module.scss';

const cn = classanmes.bind(styles);

const DotsDropdown = ({
  options,
  rotated = true,
  className,
  dropdownComponent: DropdownComponent = PortalDropdown,
}) => {
  const showingOptions = options.filter(({ isHidden }) => !isHidden);

  if (!showingOptions.length) {
    return null;
  }

  return (
    <DropdownComponent options={showingOptions} isOpenLeft>
      {(isOpen, onTriggerClick, triggerRef) => (
        <div ref={triggerRef} aria-hidden onClick={onTriggerClick} className={cn('dots-wrapper', className)}>
          <DotsIcon className={cn('dots-icon', { rotated })} />
        </div>
      )}
    </DropdownComponent>
  );
};

export default DotsDropdown;
