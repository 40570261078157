import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// utils
import { useDispatch, useSelector } from 'react-redux';
import { getCoverImages, updateClassroom, uploadCoverImage, deleteCoverImage } from 'store/classrooms';
import { isEqual, isEmpty, get } from 'lodash';
// components
import CoverImageCropper from 'components/CoverImageCropper';
import Button from 'components/Button';
import ImageBox from './ImageBox';
import DropzoneBox from './DropzoneBox';
import Text from 'components/Text';
import Modal from 'components/Modal';
// styles
import classnames from 'classnames/bind';
import styles from './BrowserView.module.scss';

const cn = classnames.bind(styles);

const BrowserView = ({ onSelect }) => {
  const [selectedImage, setSelectedImage] = useState({});
  const [cropperProps, setCropperProps] = useState(null);
  const dispatch = useDispatch();
  const { goBack } = useHistory();
  const { classroomId } = useParams();
  const { currentImage, coverImages } = useSelector(({ classrooms }) => {
    const _classroom = classrooms.classrooms.length && classrooms.classrooms.find(({ id }) => id === +classroomId);
    return {
      currentImage: get(_classroom, 'cover_image', {}),
      coverImages: classrooms.coverImages,
    };
  });

  const setCropProps = useCallback((props) => setCropperProps(props), []);

  useEffect(() => {
    setSelectedImage(currentImage);
  }, [dispatch, currentImage, onSelect]);

  useEffect(() => {
    onSelect && onSelect(selectedImage);
  }, [selectedImage, onSelect]);

  useEffect(() => dispatch(getCoverImages(classroomId)), []);

  const handleSaveChanges = () => dispatch(updateClassroom({ cover_image_id: selectedImage.id }, classroomId));

  const handleFileChange = (imageFile, callback) => {
    if (imageFile) {
      const formData = new FormData();
      formData.append('resource[image]', imageFile);
      formData.append('resource[classroom_id]', classroomId);
      return dispatch(uploadCoverImage(formData, classroomId)).then(() => typeof callback === 'function' && callback());
    }
  };

  const handleDeleteImage = () =>
    !isEmpty(selectedImage) &&
    dispatch(deleteCoverImage(selectedImage.id, classroomId)).then(() => setSelectedImage({}));

  const isImageDeletable =
    currentImage && currentImage.id && !(!selectedImage.classroom_id || selectedImage.id === currentImage.id);

  return (
    <>
      {cropperProps && <CoverImageCropper {...cropperProps} onClose={() => setCropperProps(null)} />}
      <DropzoneBox handleFileChange={handleFileChange} setCropperProps={setCropProps} />
      {coverImages.length !== 0 &&
        coverImages.map((image) => (
          <ImageBox
            key={image.id}
            imageUrl={image.image_url}
            handleClick={() => setSelectedImage((prev) => (isEqual(prev, image) ? {} : image))}
            isActive={isEqual(selectedImage, image)}
            isCurrentImage={isEqual(currentImage, image)}
          />
        ))}
    </>
  );

  return (
    <Modal className={cn('modal')}>
      {({ closeModal }) => (
        <div className={cn('modal-container')}>
          <Modal.Header>
            <Text type="h4">Manage Classroom Cover</Text>
            {selectedImage && (
              <div className={cn('action-buttons')}>
                {isImageDeletable && (
                  <Button
                    size="md"
                    label="Delete Photo"
                    className={cn('action-buttons--button', 'action-buttons--button--black')}
                    onClick={handleDeleteImage}
                  />
                )}
                <Button
                  size="md"
                  label="Save Changes"
                  className={cn('action-buttons--button')}
                  onClick={handleSaveChanges}
                />
              </div>
            )}
            <Modal.CloseButton onClick={goBack} />
          </Modal.Header>
          <Modal.Body>
            {cropperProps && <CoverImageCropper {...cropperProps} onClose={() => setCropperProps(null)} />}
            <div className={cn('wrapper', 'p-30')}>
              <div className={cn('wrapper--right')}>
                <DropzoneBox handleFileChange={handleFileChange} setCropperProps={setCropProps} />
              </div>
              {coverImages.length !== 0 &&
                coverImages.map((image) => (
                  <ImageBox
                    key={image.id}
                    imageUrl={image.image_url}
                    handleClick={() => setSelectedImage((prev) => (isEqual(prev, image) ? {} : image))}
                    isActive={isEqual(selectedImage, image)}
                    isCurrentImage={isEqual(currentImage, image)}
                  />
                ))}
            </div>
          </Modal.Body>
        </div>
      )}
    </Modal>
  );
};

export default React.memo(BrowserView);
