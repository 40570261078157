import { toast } from 'react-toastify';
import makeRequest from 'utils/api';

export const RESET_STORE = 'czi/RESET_STORE';
export const INCREMENT_COMPLETED = 'czi/INCREMENT_COMPLETED';
export const CLEAR_COMPLETED = 'czi/CLEAR_COMPLETED';

export const resetStore = () => ({ type: RESET_STORE });

export const incrementCompleted = () => ({ type: INCREMENT_COMPLETED });
export const clearCompleted = () => ({ type: CLEAR_COMPLETED });

export const inviteRespondWithToasts = ({ invited = [], failed = {} }) => {
  const noInvited = invited.length;
  const noFailed = Object.keys(failed).length;

  if (noInvited > 0) {
    toast.success(`Invite sent to ${noInvited} user${noInvited > 1 ? 's' : ''}`, { autoClose: 2000 });
  }
  if (noFailed > 0) {
    Object.keys(failed).forEach((c) => toast.error(`${c} - ${failed[c]}`), { autoClose: 5000 });
    toast.error(`Failed to invite ${noFailed} user${noFailed > 1 ? 's' : ''}`, { autoClose: 2000 });
  }
  return;
};

// side effects

export const revokeAdmin = (userId, programId, schoolId, data) => (dispatch) =>
  makeRequest(`/users/${userId}`, {
    method: 'PATCH',
    data: { resource: data, program_id: programId, school_id: schoolId },
  }).then(({ resource }) => Promise.resolve(resource));

export const toggleDisableUser = (id, data, instanceIds, disable = true) => (dispatch) =>
  makeRequest(`/users/${id}`, { method: 'PATCH', data: { resource: data, ...instanceIds } })
    .then(({ resource }) => {
      toast.success(`User ${disable ? 'disabled' : 'activated'}`);
      return Promise.resolve(resource);
    })
    .catch(() => {});
