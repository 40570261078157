import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// utils
import { GRADE_COLORS, GRADE_TITLES } from 'constants/classrooms';
import { TITLE_OPTIONS_LABELS } from 'constants/user';
import { buildUrl, getWeekOrderNumber } from 'utils';
import { getFavorites, removeFavorite } from 'store/favorites';
import { getToggleView, setToggleView } from 'utils/toggleView';
import { GAEmitEvent } from 'utils/googleAnalytics';
import {
  eventCategory,
  eventAction,
  eventLabel,
  customDimensions,
  USER_ROLE,
  PROGRAM_NAME,
  gaRoleLabels,
} from 'constants/googleAnalytics';
// containers
import FadeInWrapper from 'containers/FadeInWrapper';
// hooks
import { useModalSetter } from 'hooks/useModal';
import { useDispatch, useSelector } from 'react-redux';
// components
import Text from 'components/Text';
import ToggleView from 'components/ToggleView';
import Table from 'components/Table';
import DotsDropdown from 'components/Dropdown/DotsDropdown';
import Button from 'components/Button/Button';
import Modal from 'components/Modal';
import Link from 'components/Link';
import SmallBadge from 'components/SmallBadge';
// styles
import classnames from 'classnames/bind';
import styles from './BrowserView.module.scss';

const cn = classnames.bind(styles);

export const SelectClassroomModal = ({ favorite, findSchool }) => (
  <Modal className={cn('modal-wrapper')}>
    {({ closeModal }) => (
      <>
        <Modal.Header>
          <Text type="h4">Select Classroom</Text>
          <Modal.CloseButton onClick={() => closeModal()} />
        </Modal.Header>
        <Modal.Body>
          <div className={cn('select-classroom')}>
            {favorite.classrooms.map(
              (
                {
                  classroom_name: name,
                  classroom_id: id,
                  week_progress_id: wPId,
                  unit_progress_id: uPId,
                  classroom_cover_image: coverImage,
                  classroom_grade_level: gradeLevel,
                  school_id: schoolId,
                },
                idx,
              ) => {
                const school = findSchool(schoolId);

                return (
                  <Link
                    key={`classroom-${idx}`}
                    to={`/classroom/${id}/unit/${uPId}/week/${wPId}/${
                      favorite.type === 'activity'
                        ? `lesson-plan/${favorite.activity.id}?chapterId=${favorite.id}`
                        : `learning-experience/${favorite.id}`
                    }`}
                  >
                    <div className={cn('classroom-modal-item')}>
                      <div
                        className={cn('classroom-modal-image')}
                        style={{ backgroundColor: GRADE_COLORS[gradeLevel] }}
                      >
                        {coverImage && <img className={cn('image')} src={buildUrl(coverImage)} alt="Img" />}
                      </div>
                      <div className={cn('classroom-name-col')}>
                        <Text type="h11">{GRADE_TITLES[gradeLevel]}</Text>
                        <Text type="h6">{name}</Text>
                        <Text type="body-small">{school.name}</Text>
                      </div>
                    </div>
                  </Link>
                );
              },
            )}
          </div>
        </Modal.Body>
      </>
    )}
  </Modal>
);

const taskColumn = (props, className, width) => {
  const learningExpImg =
    props.learning_experience_chapters && props.learning_experience_chapters.find(({ image_url: img }) => !!img);
  return (
    <div className={cn('task-col')} style={{ minWidth: width, flexBasis: width }}>
      <div
        className={cn('task-col-image')}
        style={
          (props.cover_image_url || props.image_url || learningExpImg) && {
            backgroundImage: `url('${buildUrl(
              props.cover_image_url || props.image_url || (learningExpImg && learningExpImg.image_url),
            )}')`,
            backgroundSize: 'cover',
          }
        }
      >
        <div className={cn('image')} />
      </div>
      <div className={cn('task-name-col')}>
        <Text type="h6">{props.name}</Text>
      </div>
    </div>
  );
};

const levelColumn = (props, className, width) => (
  <div className={cn(className)} style={{ minWidth: width, flexBasis: width }}>
    <Text className={cn('title-path', 'mt-5')}>
      {props.type === 'activity'
        ? [GRADE_TITLES[props.activity.week.unit.grade_level], `Unit ${props.activity.week.unit.order_number}`].join(
            ' | ',
          )
        : [GRADE_TITLES[props.week.unit.grade_level], `Unit ${props.week.unit.order_number}`].join(' | ')}
    </Text>
  </div>
);

const locationColumn = (props, className, width) => (
  <div className={cn(className)} style={{ minWidth: width, flexBasis: width }}>
    <Link isPrimary to>
      <Text className={cn('title-path', 'mt-5')}>
        {props.type === 'activity'
          ? [`${getWeekOrderNumber(props.activity.week.order_number, false, true)}`, props.activity.week.name].join(
              ' | ',
            )
          : [`${getWeekOrderNumber(props.week.order_number, false, true)}`, props.week.name].join(' | ')}
      </Text>
    </Link>
  </div>
);

const BrowserView = ({ findSchool }) => {
  const [isTableView, setIsTableView] = useState(getToggleView('favorites'));
  const { push } = useHistory();
  const [activeTab, setActiveTab] = useState('all');
  const favorites = useSelector(({ favorites: { favorites: favs = [] } = {} }) => {
    switch (activeTab) {
      case 'all':
        return favs;
      case 'professional prep':
        return favs.filter(({ type }) => type === 'activity');
      case 'learning experiences':
        return favs.filter(({ type }) => type === 'learning_experience');
      default:
        return favs;
    }
  });
  const {
    user: { title, first_name: firstName, role },
  } = useSelector((state) => state.auth);
  const { name: programName } = useSelector(({ program }) => program);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFavorites());
  }, [dispatch]);

  const setModal = useModalSetter();

  const handleOpenModal = (favorite) => {
    if (favorite.classrooms.length === 1) {
      const { classroom_id: id, week_progress_id: wPId, unit_progress_id: uPId } = favorite.classrooms[0];
      if (favorite.type === 'activity')
        return () =>
          push(
            `/classroom/${id}/unit/${uPId}/week/${wPId}/lesson-plan/${favorite.activity.id}?chapterId=${favorite.id}`,
          );
      return () => push(`/classroom/${id}/unit/${uPId}/week/${wPId}/learning-experience/${favorite.id}`);
    }
    return () => setModal(<SelectClassroomModal favorite={favorite} push={push} findSchool={findSchool} />);
  };

  const dropdownOptions = (favorite) => [
    {
      label: favorite.type === 'activity' ? 'Launch lesson plan' : 'Launch learning experience',
      itemProps: {
        onClick: handleOpenModal(favorite),
      },
    },
    {
      label: 'Remove from library',
      itemProps: {
        onClick: () => dispatch(removeFavorite(favorite.id, favorite.type)),
      },
    },
  ];

  const renderButtonsColumn = (props, className, width) => (
    <div className={cn(className, 'buttons-column')} style={{ minWidth: width, flexBasis: width }}>
      <Button
        size="s"
        label="Launch"
        onClick={() => setModal(<SelectClassroomModal favorite={props} push={push} findSchool={findSchool} />)}
      />
      <DotsDropdown options={dropdownOptions(props, push, setModal)} className={cn('table-dropdown', 'ml-15')} />
    </div>
  );

  const typeColumn = (props, className, width) => (
    <div className={cn(className)} style={{ minWidth: width, flexBasis: width }}>
      <Text>{props.type === 'activity' ? 'Lesson Plan' : 'Learning experience'}</Text>
    </div>
  );

  const columns = [
    { label: 'Task', renderer: taskColumn, isBordered: false, width: 270 },
    { label: 'Level', renderer: levelColumn, width: 70 },
    { label: 'Location', renderer: locationColumn, width: 210 },
    { label: 'Type', renderer: typeColumn, placeholder: 'N/A', width: 100 },
    { label: '', renderer: renderButtonsColumn, width: 200 },
  ];

  return (
    <div className={cn('wrapper')}>
      <div className={cn('classrooms-header', 'mb-30')}>
        <div className={cn('title-wrapper')}>
          <Text type="h6">
            {title && `${TITLE_OPTIONS_LABELS[title.toLowerCase()]} `}
            {firstName}
          </Text>
          <Text type="h1">My Library</Text>
        </div>
        <ToggleView
          className={cn('toggle-view')}
          defaultIsActive={isTableView}
          onChange={(value) => {
            setIsTableView(value);
            setToggleView('favorites', value);

            GAEmitEvent(eventCategory.USER_ACTION, eventAction.MENU_CLICK, eventLabel.view(value), {
              [customDimensions[USER_ROLE]]: gaRoleLabels[role],
              [customDimensions[PROGRAM_NAME]]: programName,
            });
          }}
        />
      </div>
      <div className="mv-15">
        <Button
          onClick={() => setActiveTab('all')}
          variant={activeTab !== 'all' && 'filled'}
          size="md"
          label="All Favorites"
        />
        <Button
          onClick={() => setActiveTab('professional prep')}
          variant={activeTab !== 'professional prep' && 'filled'}
          size="md"
          label="Professional Prep"
          className="ml-10"
        />
        <Button
          onClick={() => setActiveTab('learning experiences')}
          variant={activeTab !== 'learning experiences' && 'filled'}
          size="md"
          label="Learning Experiences"
          className="ml-10"
        />
      </div>
      {!isTableView && (
        <FadeInWrapper>
          <div className={cn('favorites-wrapper')}>
            {favorites.map((favorite, idx) => {
              const learningExpImg =
                favorite.learning_experience_chapters &&
                favorite.learning_experience_chapters.find(({ image_url: img }) => !!img);
              return (
                <div key={`favorite-${idx}`} className={cn('favorite-card')}>
                  <div
                    className={cn('image')}
                    aria-hidden
                    onClick={handleOpenModal(favorite)}
                    style={
                      favorite.type === 'activity'
                        ? favorite.image_url && {
                            backgroundImage: `url('${buildUrl(favorite.image_url)}')`,
                            backgroundSize: 'contain',
                          }
                        : learningExpImg && {
                            backgroundImage: `url('${buildUrl(favorite.cover_image_url || learningExpImg.image_url)}')`,
                            backgroundSize: 'contain',
                          }
                    }
                  >
                    <div className={cn('image-backdrop')} />
                    <DotsDropdown className={cn('dropdown')} options={dropdownOptions(favorite, push)} />
                  </div>
                  <div className={cn('title-col')}>
                    <Text type="h6">{favorite.name}</Text>
                    <Text className={cn('title-path', 'mt-5')}>
                      {favorite.type === 'activity'
                        ? [
                            GRADE_TITLES[favorite.activity.week.unit.grade_level],
                            `Unit ${favorite.activity.week.unit.order_number}`,
                            `${getWeekOrderNumber(favorite.activity.week.order_number, false, true)}`,
                          ].join(' | ')
                        : [
                            GRADE_TITLES[favorite.week.unit.grade_level],
                            `Unit ${favorite.week.unit.order_number}`,
                            `${getWeekOrderNumber(favorite.week.order_number, false, true)}`,
                          ].join(' | ')}
                    </Text>
                  </div>
                  <div className={cn('title-col')}>
                    <div className={cn('tags-wrapper')}>
                      {favorite.type !== 'activity' &&
                        favorite.learning_experience_tags.map(({ name }, idx) => (
                          <SmallBadge className={cn('tag')} key={`badge-${idx}`} type={name.toLowerCase()} />
                        ))}
                    </div>
                  </div>
                  <div className={cn('buttons-row')}>
                    <Button size="s" label="Launch" onClick={handleOpenModal(favorite)} />
                  </div>
                </div>
              );
            })}
          </div>
        </FadeInWrapper>
      )}
      {isTableView && (
        <FadeInWrapper>
          <Table columns={columns} data={favorites} />
        </FadeInWrapper>
      )}
    </div>
  );
};

export default BrowserView;
