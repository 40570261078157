import React, { useEffect } from 'react';
// utils
import { useModalSetter } from 'hooks/useModal';
import { updateUserData, updateUserAvatar } from 'store/auth';
import { getDefaultValue } from 'utils/object';
import { TITLE_OPTIONS, isAdminUser, isSchoolAdmin, USER_ROLE_LABELS } from 'constants/user';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { buildUrl } from 'utils';
import { useSelector, useDispatch } from 'react-redux';
// components
import AdminBadge from 'components/Badges/AdminBadge';
import ImageCropperModal from 'components/ImageCropperModal';
import Button from 'components/Button';
import SelectBox from 'components/SelectBox';
import Input from 'components/Input';
// containers
import MobileSubcomponentWrapper from 'containers/MobileSubcomponentWrapper';
// icons
import { ReactComponent as CameraIcon } from 'icons/camera-white.svg';
// styles
import classnames from 'classnames/bind';
import styles from './MobileView.module.scss';

const cn = classnames.bind(styles);

const schema = yup.object().shape({
  title: yup.string(),
  firstName: yup.string().required('First name is required.'),
  lastName: yup.string().required('Last name is required.'),
  email: yup.string().email(),
});

const PersonalDataSection = ({ minHeight }) => {
  const {
    id: userId,
    title,
    role,
    first_name: firstName,
    last_name: lastName,
    email,
    avatar_url: avatarUrl,
  } = useSelector(({ auth: { user } = {} }) => user);
  const dispatch = useDispatch();
  const setModal = useModalSetter();

  const initValues = {
    title,
    firstName,
    lastName,
    email,
  };

  const { register, handleSubmit, errors, clearErrors, getValues, reset, ...restForm } = useForm({
    defaultValues: initValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(initValues);
  }, [reset, title]);

  const clearErrorsOnTyping = (field) => clearErrors(field);

  const onSubmit = ({ title, firstName, lastName, email }) =>
    dispatch(updateUserData({ title, first_name: firstName, last_name: lastName, email, id: userId }));

  const handleAcceptImageCrop = (imgEl, callback) => {
    if (!imgEl) return;

    const formData = new FormData();
    const imageCp = imgEl.slice();
    imageCp.lastModifiedDate = new Date();
    imageCp.name = 'avatar';

    formData.append('resource[avatar]', imageCp);
    return dispatch(updateUserAvatar({ formData, userId })).then(() => typeof callback === 'function' && callback());
  };

  return (
    <MobileSubcomponentWrapper headingTitle="Edit Personal Data" fixedHeading>
      <section className={cn('personal-container')} style={{ minHeight: minHeight ? `${minHeight}px` : '100vh' }}>
        <div className={cn('avatar-wrapper')}>
          <CameraIcon
            className={cn('camera')}
            onClick={() =>
              setModal(
                <ImageCropperModal
                  aspectRatio={1 / 1}
                  imageUrl={avatarUrl}
                  onSave={handleAcceptImageCrop}
                  className={cn('mobile-avatar-cropper')}
                />,
              )
            }
          />
          <img src={buildUrl(avatarUrl)} className={cn('avatar-big')} alt="user-avatar" />
          {isAdminUser(role) && (
            <AdminBadge
              className={cn('admin-badge')}
              role={USER_ROLE_LABELS[role]}
              long
              schoolAdmin={isSchoolAdmin(role)}
            />
          )}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectBox
            label="Title"
            name="title"
            hookFormProps={{ register, errors, clearErrors, ...restForm }}
            className={cn('title-select')}
            options={TITLE_OPTIONS}
            defaultValue={title && getDefaultValue(TITLE_OPTIONS, title)}
          />
          <Input
            label="First Name"
            name="firstName"
            className="mr-40"
            inputRef={register({ required: true })}
            errors={errors}
            onChange={() => clearErrorsOnTyping('firstName')}
          />
          <Input
            label="Last Name"
            name="lastName"
            inputRef={register({ required: true })}
            errors={errors}
            onChange={() => clearErrorsOnTyping('lastName')}
          />
          <Input label="District Email Address" name="email" inputRef={register} disabled />
          <Button className={cn('submit-button')} size="bg" isSubmit label="Save Changes" />
        </form>
      </section>
    </MobileSubcomponentWrapper>
  );
};

export default PersonalDataSection;
