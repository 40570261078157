import React from 'react';
import classnames from 'classnames/bind';
import T from 'prop-types';
// styles
import styles from './Button.module.scss';

const buttonTypes = {
  REGULAR: 'regular',
  FAVORITE: 'favorite',
  MARKABLE: 'markable',
  PAGINATION: 'pagination',
};

const cn = classnames.bind(styles);

const getLabel = (labelStr, btnType, isMarked, round) => {
  if (btnType === buttonTypes.FAVORITE && !round) {
    if (isMarked) return 'Added to Library';
    return 'Add to Library';
  }
  if (btnType === buttonTypes.MARKABLE && !round) {
    if (!labelStr) {
      if (isMarked) return 'Task Completed';
      return 'Mark Task Complete';
    }
  }
  return labelStr;
};

const getPrefix = (type) => {
  switch (type) {
    case buttonTypes.REGULAR:
      return buttonTypes.REGULAR;
    case buttonTypes.FAVORITE:
      return buttonTypes.FAVORITE;
    case buttonTypes.MARKABLE:
      return buttonTypes.MARKABLE;
    case buttonTypes.PAGINATION:
      return buttonTypes.PAGINATION;
    default:
      return buttonTypes.REGULAR;
  }
};

const Button = ({
  type,
  isSubmit,
  marked = false,
  size,
  label = '',
  round = false,
  reversed = false,
  variant = '',
  onClick: handleClick,
  className,
  disabled = false,
  dropdownActive = false,
}) => {
  const buttonPrefix = getPrefix(type);

  return (
    <>
      <button
        type={isSubmit ? 'submit' : 'button'}
        className={cn(`${buttonPrefix}-button`, className, {
          [`${buttonPrefix}-button--${size}`]: size,
          [`${buttonPrefix}-button--${size}--${variant}`]: size && variant.length,
          [`${buttonPrefix}-button--marked`]: marked,
          [`${buttonPrefix}-button--round`]: round,
          [`${buttonPrefix}-button--right`]: reversed,
          disabled,
          dropdownActive,
        })}
        onClick={handleClick}
        disabled={disabled}
      >
        {getLabel(label, buttonPrefix, marked, round)}
      </button>
    </>
  );
};

Button.propTypes = {
  type: T.oneOf(Object.values(buttonTypes)),
  marked: T.bool,
  size: T.oneOf(['bg', 'md', 's', 'xs']),
  isSubmit: T.bool,
  label: T.oneOfType([T.string, T.object]),
  round: T.bool,
  reversed: T.bool,
  variant: T.string,
  onClick: T.func,
  className: T.string,
  disabled: T.bool,
};

Button.defaultProps = {
  type: 'regular',
  marked: false,
  size: undefined,
  label: '',
  round: false,
  reversed: false,
  variant: '',
  disabled: false,
};

export default Button;
