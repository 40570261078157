import React from 'react';
import T from 'prop-types';
// utils
import { get } from 'lodash';
import { markLearningExpFinished } from 'store/classrooms';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { buildUrl } from 'utils';
import {
  eventCategory,
  eventAction,
  eventLabel,
  USER_ROLE,
  PROGRAM_NAME,
  UNIT_NUMBER,
  UNIT_TITLE,
  WEEK_NAME,
  gaRoleLabels,
  customDimensions,
} from 'constants/googleAnalytics';
import { GAEmitEvent } from 'utils/googleAnalytics';
// components
import LearningExperienceBox from 'components/LearningExperienceBox';
// styles
import classnames from 'classnames/bind';
import styles from './LearningExperiences.module.scss';

const cn = classnames.bind(styles);

const LearningExperiences = ({ experiences = [], isPreview }) => {
  const {
    params: { classroomId, unitId, weekId },
  } = useRouteMatch();
  const {
    push,
    location: { pathname, search },
  } = useHistory();
  const { week, unit } = useSelector(({ classrooms: { classrooms = [] } = {} }) => {
    const _clsrm = classrooms.find(({ id }) => +id === +classroomId);

    return {
      week: get(_clsrm, `unit_progress[${unitId}].week_progress[${weekId}]`, {}),
      unit: get(_clsrm, `unit_progress[${unitId}]`, {}),
    };
  });
  const { role } = useSelector(({ auth }) => auth.user);
  const { name: programName } = useSelector(({ program }) => program);
  const dispatch = useDispatch();

  const handleFinishedClick = (leId, _finished, name, required) => {
    dispatch(markLearningExpFinished(classroomId, leId, !_finished, unitId, weekId));

    !_finished &&
      required &&
      GAEmitEvent(
        eventCategory.USER_ACTION,
        eventAction.MENU_CLICK,
        eventLabel.requiredActivity(name, 'Learning Experience', pathname + search),
        {
          [customDimensions[USER_ROLE]]: gaRoleLabels[role],
          [customDimensions[PROGRAM_NAME]]: programName,
          [customDimensions[UNIT_NUMBER]]: get(unit, 'units[0].order_number', null),
          [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
          [customDimensions[WEEK_NAME]]: get(week, `weeks[0].name`, null),
        },
      );
  };

  return (
    <div className={cn('wrapper', 'ph-60')}>
      {experiences.length !== 0 &&
        experiences.map(
          (
            {
              id,
              name,
              description,
              badgeType,
              experienceLaunched,
              finished,
              learning_experience_chapters,
              learning_experience_tags,
              cover_image_url,
              draft,
              required,
            },
            i,
          ) => (
            <div className={cn('card-wrapper')} key={i}>
              <LearningExperienceBox
                images={[
                  ...(cover_image_url ? [buildUrl(cover_image_url)] : []),
                  ...learning_experience_chapters.map(({ image_url, link }) =>
                    image_url
                      ? buildUrl(image_url)
                      : link
                      ? `https://cdn.jwplayer.com/v2/media/${link}/poster.jpg?width=720`
                      : null,
                  ),
                ]}
                experienceName={name}
                description={description}
                tags={learning_experience_tags}
                experienceLaunched={experienceLaunched}
                onCompleteClick={() => handleFinishedClick(id, finished, name, required)}
                experienceCompleted={finished}
                isDraft={draft}
                isPreview={isPreview}
                onLaunchClick={() => {
                  push(`/classroom/${classroomId}/unit/${unitId}/week/${weekId}/learning-experience/${id}`);
                  GAEmitEvent(
                    eventCategory.USER_ACTION,
                    eventAction.CLICK,
                    eventLabel.click(
                      'Learning Experience',
                      `${name}_/classroom/${classroomId}/unit/${unitId}/week/${weekId}/learning-experience/${id}`,
                    ),
                    {
                      [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                      [customDimensions[PROGRAM_NAME]]: programName,
                      [customDimensions[UNIT_NUMBER]]: get(unit, 'units[0].order_number', null),
                      [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
                      [customDimensions[WEEK_NAME]]: get(week, `weeks[0].name`, null),
                    },
                  );
                }}
                required={required}
              />
            </div>
          ),
        )}
    </div>
  );
};

LearningExperiences.propTypes = {
  experiences: T.arrayOf(T.object).isRequired,
};

export default LearningExperiences;
