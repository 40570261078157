import { get, zip } from 'lodash';
import makeRequest from 'utils/api';
import { toast } from 'react-toastify';
import {
  CLEAR_COMPLETED,
  incrementCompleted,
  INCREMENT_COMPLETED,
  inviteRespondWithToasts,
  RESET_STORE,
} from './common';
import { PROGRAM_ID_UPDATE_LOADED, PROGRAM_LOADED, toggleDisableClassroomProgramLoaded } from './program';
import { CREATE_CLASSROOM_LOADED, INVITE_USER_LOADED, RESET_SCHOOL, SCHOOL_ID_UPDATTE_LOADED } from './school';
import { cachedSchoolId } from 'utils/auth';
import { USER_STATUSES } from 'constants/user';

// Actions
export const CLASSROOMS_LOADING = 'czi/CLASSROOMS/LOADING';
export const CLASSROOMS_LOADED = 'czi/CLASSROOMS/LOADED';
export const CLASSROOMS_FAILED = 'czi/CLASSROOMS/FAILED';

export const LESSON_PLANS_LOADING = 'czi/LESSON_PLANS/LOADING';
export const LESSON_PLANS_LOADED = 'czi/LESSON_PLANS/LOADED';
export const CLEAR_LESSON_PLAN = 'czi/LESSON_PLANS/CLEAR';

export const LEARNING_EXPERIENCE_LOADING = 'czi/LEARNING_EXPERIENCE/LOADING';
export const LEARNING_EXPERIENCE_LOADED = 'czi/LEARNING_EXPERIENCE/LOADED';

export const CLASSROOM_UNIT_LOADING = 'czi/CLASSROOM_UNIT/LOADING';
export const CLASSROOM_UNIT_LOADED = 'czi/CLASSROOM_UNIT/LOADED';
export const CLASSROOM_UNIT_FAILED = 'czi/CLASSROOM_UNIT/FAILED';

export const ADD_CLASSROOM_LOADING = 'czi/ADD_CLASSROOM/LOADING';
export const ADD_CLASSROOM_LOADED = 'czi/ADD_CLASSROOM/LOADED';
export const ADD_CLASSROOM_FAILED = 'czi/ADD_CLASSROOM/FAILED';

export const UPDATE_CLASSROOM_LOADING = 'czi/UPDATE_CLASSROOM/LOADING';
export const UPDATE_CLASSROOM_LOADED = 'czi/UPDATE_CLASSROOM/LOADED';
export const UPDATE_CLASSROOM_FAILED = 'czi/UPDATE_CLASSROOM/FAILED';

export const TEACHERS_LOADING = 'czi/TEACHERS/LOADING';
export const TEACHERS_LOADED = 'czi/TEACHERS/LOADED';
export const TEACHERS_FAILED = 'czi/TEACHERS/FAILED';

export const CLASSROOM_WEEK_LOADING = 'czi/CLASROOM_WEEK/LOADING';
export const CLASSROOM_WEEK_LOADED = 'czi/CLASROOM_WEEK/LOADED';
export const CLASSROOM_WEEK_FAILED = 'czi/CLASROOM_WEEK/FAILED';

export const COVER_IMAGES_LOADING = 'czi/COVER_IMAGES/LOADING';
export const COVER_IMAGES_LOADED = 'czi/COVER_IMAGES/LOADED';
export const COVER_IMAGES_FAILED = 'czi/COVER_IMAGES/FAILED';

export const UPLOAD_COVER_IMAGE_LOADED = 'czi/UPLOAD_COVER_IMAGE/LOADED';

export const MARK_WEEK_LOADING = 'czi/MARK_WEEK/LOADING';
export const MARK_WEEK_LOADED = 'czi/MARK_WEEK/LOADED';
export const MARK_WEEK_FAILED = 'czi/MARK_WEEK/FAILED';

export const MARK_LESSON_PLAN_LOADING = 'czi/MARK_LESSON_PLAN/LOADING';
export const MARK_LESSON_PLAN_LOADED = 'czi/MARK_LESSON_PLAN/LOADED';
export const MARK_LESSON_PLAN_FAILED = 'czi/MARK_LESSON_PLAN/FAILED';

export const UPDATE_ACTIVITY_NOTES = 'czi/UPDATED_ACTIVITY_NOTES/LOADED';

export const LEARNING_EXPERIENCE_FAVORITED = 'czi/LEARNING_EXPERIENCE_FAVORITED/LOADED';
export const LEARNING_EXPERIENCE_FINISHED = 'czi/LEARNING_EXPERIENCE_FINISHED/LOADED';

export const DISABLE_CLASSROOM_LOADED = 'czi/DISABLE_CLASSROOM/LOADED';
export const ACTIVATE_CLASSROOM_LOADED = 'czi/ACTIVATE_CLASSROOM/LOADED';

export const INVITE_USERS_LOADED = 'czi/INVITE_USERS/LOADED';

export const GET_CURRENT_WEEK_UNIT_LOADING = 'czi/GET_CURRENT_WEEK_UNIT/LOADING';
export const GET_CURRENT_WEEK_UNIT_LOADED = 'czi/GET_CURRENT_WEEK_UNIT/LOADED';
export const GET_CURRENT_WEEK_UNIT_FAILED = 'czi/GET_CURRENT_WEEK_UNIT/FAILED';

// Action creators
export const markWeekLoading = () => ({ type: MARK_WEEK_LOADING });
export const markWeekLoaded = (payload) => ({ type: MARK_WEEK_LOADED, payload });
export const markWeekFailed = () => ({ type: MARK_WEEK_FAILED });

export const markLessonPlanLoading = () => ({ type: MARK_LESSON_PLAN_LOADING });
export const markLessonPlanLoaded = (payload) => ({ type: MARK_LESSON_PLAN_LOADED, payload });
export const markLessonPlanFailed = () => ({ type: MARK_LESSON_PLAN_FAILED });

export const classroomsLoading = () => ({ type: CLASSROOMS_LOADING });
export const classroomsLoaded = (payload) => ({ type: CLASSROOMS_LOADED, payload });
export const classroomsFailed = () => ({ type: CLASSROOMS_FAILED });

export const lessonPlansLoading = () => ({ type: LESSON_PLANS_LOADING });
export const clearLessonPlan = () => ({ type: CLEAR_LESSON_PLAN });
export const lessonPlansLoaded = (payload) => ({ type: LESSON_PLANS_LOADED, payload });

export const getCurrentWeekUnitLoading = () => ({ type: GET_CURRENT_WEEK_UNIT_LOADING });
export const getCurrentWeekUnitLoaded = (payload) => ({ type: GET_CURRENT_WEEK_UNIT_LOADED, payload });
export const getCurrentWeekUnitFailed = () => ({ type: GET_CURRENT_WEEK_UNIT_FAILED });

export const learningExperienceLoading = () => ({ type: LEARNING_EXPERIENCE_LOADING });
export const learningExperienceLoaded = (payload) => ({ type: LEARNING_EXPERIENCE_LOADED, payload });

const markLearningExpFavoriteLoaded = (id, isFavorite) => ({
  type: LEARNING_EXPERIENCE_FAVORITED,
  payload: { id, isFavorite },
});

const markLearningExpFinishedLoaded = (classroomId, id, isFinished, unitId, weekId) => ({
  type: LEARNING_EXPERIENCE_FINISHED,
  payload: { classroomId, id, isFinished, unitId, weekId },
});

export const classroomUnitLoading = () => ({ type: CLASSROOM_UNIT_LOADING });
export const classroomUnitLoaded = (payload, classroomId) => ({
  type: CLASSROOM_UNIT_LOADED,
  payload: { ...payload, classroomId },
});
export const classroomUnitFailed = () => ({ type: CLASSROOM_UNIT_FAILED });

export const classroomWeekLoading = () => ({ type: CLASSROOM_WEEK_LOADING });
export const classroomWeekLoaded = (payload, classroomId, unitProgId) => ({
  type: CLASSROOM_WEEK_LOADED,
  payload: { ...payload, classroomId, unitProgId },
});
export const classroomWeekFailed = () => ({ type: CLASSROOM_WEEK_FAILED });

export const addClassroomLoading = () => ({ type: ADD_CLASSROOM_LOADING });
export const addClassroomFailed = () => ({ type: ADD_CLASSROOM_FAILED });

export const updateClassroomLoading = () => ({ type: UPDATE_CLASSROOM_LOADING });
export const updateClassroomLoaded = (payload) => ({ type: UPDATE_CLASSROOM_LOADED, payload });
export const updateClassroomFailed = () => ({ type: UPDATE_CLASSROOM_FAILED });

export const teachersLoading = () => ({ type: TEACHERS_LOADING });
export const teachersLoaded = (payload) => ({ type: TEACHERS_LOADED, payload });
export const teachersFailed = () => ({ type: TEACHERS_FAILED });

export const coverImagesLoading = () => ({ type: COVER_IMAGES_LOADING });
export const coverImagesLoaded = (payload) => ({ type: COVER_IMAGES_LOADED, payload });
export const coverImagesFailed = () => ({ type: COVER_IMAGES_FAILED });

export const uploadCoverImageLoaded = (payload) => ({ type: UPLOAD_COVER_IMAGE_LOADED, payload });

export const updateActivityNotes = (payload) => ({ type: UPDATE_ACTIVITY_NOTES, payload });

export const disableClassroomLoaded = (payload) => ({ type: DISABLE_CLASSROOM_LOADED, payload });
export const activateClassroomLoaded = (payload) => ({ type: ACTIVATE_CLASSROOM_LOADED, payload });

export const inviteUsersLoaded = (payload) => ({ type: INVITE_USERS_LOADED, payload });

const mapProgramToClassroom = (program) => {
  const schoolId = cachedSchoolId();
  const schools = get(program, 'schools', []);
  const school = schools.find(({ id }) => id === schoolId);
  const classrooms = school ? [school.classrooms || []] : schools.map(({ classrooms: c }) => c);
  return { classrooms: classrooms.flat(), schools };
};

const mapTeachers = (payload = []) =>
  payload.map((teacher) => ({
    ...teacher,
    status: teacher.is_active ? USER_STATUSES[teacher.invitation_status] : 'disabled',
    full_name: teacher.last_name ? `${teacher.first_name} ${teacher.last_name}` : null,
  }));

const initialState = {
  classrooms: [],
  schoolTeachers: [],
  schools: [],
  lessonPlans: {},
  learningExperience: {},
  coverImages: [],
  isLoading: false,
  hasErrors: false,
  isLoadedClassrooms: false,
  completedCount: +(localStorage.getItem('completedCount') || 0),
};

const mergeWeeks = (weekProgress) => {
  const mergedActivities = zip(
    weekProgress.weeks[0].activities.map((i) => ({ ...i, grade_level: weekProgress.weeks[0].grade_level })),
    weekProgress.weeks[1] &&
      weekProgress.weeks[1].activities.map((i) => ({
        ...i,
        grade_level: weekProgress.weeks[1].grade_level,
      })),
  )
    .flat()
    .filter((v) => v);
  const mergedLearningExperiences = zip(
    weekProgress.weeks[0].learning_experiences,
    weekProgress.weeks[1] && weekProgress.weeks[1].learning_experiences,
  )
    .flat()
    .filter((v) => v);

  return {
    ...weekProgress,
    activities: mergedActivities,
    learning_experiences: mergedLearningExperiences,
    printable_urls: [
      ...mergedActivities.reduce((acc, { printable_urls: urls }) => [...acc, ...urls], []),
      ...mergedLearningExperiences.reduce((acc, { printable_urls: urls }) => [...acc, ...urls], []),
    ],
  };
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INCREMENT_COMPLETED: {
      localStorage.setItem('completedCount', state.completedCount + 1);
      return { ...state, completedCount: state.completedCount + 1 };
    }
    case CLEAR_COMPLETED: {
      localStorage.setItem('completedCount', 0);
      return { ...state, completedCount: 0 };
    }
    case CLASSROOMS_LOADING:
    case LESSON_PLANS_LOADING:
    case TEACHERS_LOADING:
    case CLASSROOM_UNIT_LOADING:
    case ADD_CLASSROOM_LOADING:
    case CLASSROOM_WEEK_LOADING:
    case COVER_IMAGES_LOADING:
    case UPDATE_CLASSROOM_LOADING:
      return { ...state, isLoading: true, hasErrors: false };
    case RESET_SCHOOL:
      return { ...state, classrooms: state.schools.map(({ classrooms: c }) => c).flat() };
    case CLASSROOMS_LOADED:
      return {
        ...state,
        classrooms: action.payload.classrooms,
        schools: action.payload.schools,
        isLoading: false,
        hasErrors: false,
        isLoadedClassrooms: true,
      };
    case SCHOOL_ID_UPDATTE_LOADED:
      return {
        ...state,
        classrooms: (state.schools.find(({ id }) => id === action.payload.id) || {}).classrooms || [],
      };
    case CLASSROOM_UNIT_LOADED: {
      const idx = state.classrooms.findIndex(({ id }) => id === +action.payload.classroomId);
      const updClassrooms = [
        ...state.classrooms.slice(0, idx),
        {
          ...state.classrooms[idx],
          unit_progress: { ...get(state.classrooms, `[idx].unit_progress`, []), [action.payload.id]: action.payload },
        },
        ...state.classrooms.slice(idx + 1),
      ];
      return {
        ...state,
        classrooms: updClassrooms,
        isLoading: false,
        hasErrors: false,
      };
    }
    case LEARNING_EXPERIENCE_FAVORITED: {
      return {
        ...state,
        learningExperience: {
          ...state.learningExperience,
          favorite: action.payload.isFavorite,
        },
      };
    }
    case LEARNING_EXPERIENCE_FINISHED: {
      const idx = state.classrooms.findIndex(({ id }) => id === +action.payload.classroomId);
      let updClassrooms;
      const unitProgress = state.classrooms[idx].unit_progress[action.payload.unitId];
      const weekProgress = unitProgress.week_progress[action.payload.weekId];
      if (weekProgress) {
        const learningExps = weekProgress.learning_experiences;
        const learningExpIdx = learningExps.findIndex(({ id }) => id === +action.payload.id);
        updClassrooms = [
          ...state.classrooms.slice(0, idx),
          {
            ...state.classrooms[idx],
            unit_progress: {
              ...state.classrooms[idx].unit_progress,
              [action.payload.unitId]: {
                ...unitProgress,
                week_progress: {
                  ...unitProgress.week_progress,
                  [action.payload.weekId]: {
                    ...weekProgress,
                    learning_experiences: [
                      ...learningExps.slice(0, learningExpIdx),
                      { ...learningExps[learningExpIdx], finished: action.payload.isFinished },
                      ...learningExps.slice(learningExpIdx + 1),
                    ],
                  },
                },
              },
            },
          },
          ...state.classrooms.slice(idx + 1),
        ];
      }
      return {
        ...state,
        classrooms: updClassrooms || state.classrooms,
        learningExperience: {
          ...state.learningExperience,
          finished: action.payload.isFinished,
        },
        isLoading: false,
        hasErrors: false,
      };
    }
    case MARK_WEEK_LOADED: {
      const idx = state.classrooms.findIndex(({ id }) => id === +action.payload.classroomId);
      const unitProgress = state.classrooms[idx].unit_progress[action.payload.unitProgId];

      const updClassrooms = [
        ...state.classrooms.slice(0, idx),
        {
          ...state.classrooms[idx],
          started: true,
          unit_progress: {
            ...state.classrooms[idx].unit_progress,
            [action.payload.unitProgId]: {
              ...unitProgress,
              week_progresses: state.classrooms[idx].unit_progress[action.payload.unitProgId].week_progresses.map(
                ({ id, ...rest }) => {
                  if (id === +action.payload.weekProgId) {
                    return { id, ...rest, finished: action.payload.finished };
                  }
                  return { id, ...rest };
                },
              ),
              week_progress: {
                ...(state.classrooms[idx].unit_progress[action.payload.unitProgId]?.week_progress ?? {}),
                [action.payload.weekProgId]: {
                  ...(state.classrooms[idx].unit_progress[action.payload.unitProgId]?.week_progress?.[
                    action.payload.weekProgId
                  ] ?? {}),
                  finished: action.payload.finished,
                },
              },
            },
          },
        },
        ...state.classrooms.slice(idx + 1),
      ];

      return {
        ...state,
        classrooms: updClassrooms,
        isLoading: false,
        hasErrors: false,
      };
    }
    case CREATE_CLASSROOM_LOADED:
      return { ...state, classrooms: [...state.classrooms, action.payload] };
    case CLASSROOM_WEEK_LOADED: {
      const idx = state.classrooms.findIndex(({ id }) => id === +action.payload.classroomId);
      const unitProgress = get(state, `classrooms[${idx}].unit_progress[${action.payload.unitProgId}]`, {});
      const updClassrooms = [
        ...state.classrooms.slice(0, idx),
        {
          ...state.classrooms[idx],
          unit_progress: {
            ...state.classrooms[idx].unit_progress,
            [action.payload.unitProgId]: {
              ...unitProgress,
              week_progress: {
                ...unitProgress.week_progress,
                [action.payload.id]: mergeWeeks(action.payload),
              },
            },
          },
        },
        ...state.classrooms.slice(idx + 1),
      ];
      return {
        ...state,
        classrooms: updClassrooms,
        isLoading: false,
        hasErrors: false,
      };
    }
    case UPDATE_CLASSROOM_LOADED: {
      const classroomToUpdateIdx = state.classrooms.findIndex(({ id }) => id === +action.payload.data.id);
      const classroomsUpdated = action.payload.leavingClassroom
        ? [...state.classrooms.slice(0, classroomToUpdateIdx), ...state.classrooms.slice(classroomToUpdateIdx + 1)]
        : [
            ...state.classrooms.slice(0, classroomToUpdateIdx),
            action.payload.data,
            ...state.classrooms.slice(classroomToUpdateIdx + 1),
          ];
      return { ...state, classrooms: classroomsUpdated, isLoading: false, hasErrors: false };
    }
    case COVER_IMAGES_LOADED:
      return { ...state, coverImages: action.payload, isLoading: false, hasErrors: false };
    case UPLOAD_COVER_IMAGE_LOADED:
      return { ...state, coverImages: [action.payload, ...state.coverImages] };
    case INVITE_USER_LOADED: {
      const invited = action.payload.invited.map((user) => ({ ...user, classrooms: [...(user.classrooms || [])] }));
      return { ...state, schoolTeachers: [...state.schoolTeachers, ...invited] };
    }
    case CLASSROOMS_FAILED:
    case CLASSROOM_UNIT_FAILED:
    case CLASSROOM_WEEK_FAILED:
    case ADD_CLASSROOM_FAILED:
    case TEACHERS_FAILED:
    case COVER_IMAGES_FAILED:
    case UPDATE_CLASSROOM_FAILED:
      return { ...state, isLoading: false, hasErrors: true };
    case MARK_LESSON_PLAN_LOADED: {
      const idx = state.classrooms.findIndex(({ id }) => id === +action.payload.classroomId);
      const unitProgress = state.classrooms[idx].unit_progress[action.payload.uPId];
      const updClassrooms = [
        ...state.classrooms.slice(0, idx),
        {
          ...state.classrooms[idx],
          unit_progress: {
            ...state.classrooms[idx].unit_progress,
            [action.payload.uPId]: {
              ...unitProgress,
              week_progress: {
                ...unitProgress.week_progress,
                [action.payload.wPId]: {
                  ...unitProgress.week_progress[action.payload.wPId],
                  activities: unitProgress.week_progress[action.payload.wPId].activities.map(({ id, ...rest }) => {
                    if (id === +action.payload.id) return { id, ...rest, finished: action.payload.finished };
                    return { id, ...rest };
                  }),
                },
              },
            },
          },
        },
        ...state.classrooms.slice(idx + 1),
      ];

      return {
        ...state,
        classrooms: updClassrooms,
        lessonPlans: {
          ...state.lessonPlans,
          finished: action.payload.finished,
        },
        isLoading: false,
        hasErrors: false,
      };
    }
    case LESSON_PLANS_LOADED:
      return { ...state, lessonPlans: action.payload, isLoading: false, hasErrors: false };
    case CLEAR_LESSON_PLAN:
      return { ...state, lessonPlans: {}, isLoading: false, hasErrors: false };
    case LEARNING_EXPERIENCE_LOADED:
      return { ...state, learningExperience: action.payload, isLoading: false, hasErrors: false };
    case TEACHERS_LOADED:
      return { ...state, schoolTeachers: [...mapTeachers(action.payload)], isLoading: false, hasErrors: false };
    case PROGRAM_LOADED:
      return { ...state, ...mapProgramToClassroom(action.payload) };
    case ACTIVATE_CLASSROOM_LOADED:
    case DISABLE_CLASSROOM_LOADED: {
      const { id: classroomId } = action.payload;
      const replaceIdx = state.classrooms.findIndex(({ id }) => id === classroomId);

      return {
        ...state,
        classrooms: [
          ...state.classrooms.slice(0, replaceIdx),
          action.payload,
          ...state.classrooms.slice(replaceIdx + 1),
        ],
      };
    }
    case INVITE_USERS_LOADED: {
      const { classroomId: _clsrmId, invited } = action.payload;
      const clsrmIdx = state.classrooms.findIndex(({ id }) => +id === +_clsrmId);
      state.classrooms[clsrmIdx].user_ids = [...state.classrooms[clsrmIdx].user_ids, ...invited.map(({ id }) => id)];

      return { ...state };
    }
    case GET_CURRENT_WEEK_UNIT_LOADED: {
      const { id: classroomId } = action.payload;
      const replaceIdx = state.classrooms.findIndex(({ id }) => id === classroomId);

      return {
        ...state,
        classrooms: [
          ...state.classrooms.slice(0, replaceIdx),
          { ...state.classrooms[replaceIdx], ...action.payload },
          ...state.classrooms.slice(replaceIdx + 1),
        ],
      };
    }
    case PROGRAM_ID_UPDATE_LOADED:
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;

// Side-effects

export const getClassrooms = () => (dispatch) => {
  // dispatch(classroomsLoading());
  // return makeRequest('/classrooms')
  // .then(({ resources }) => dispatch(classroomsLoaded(resources)))
  // .catch(() => dispatch(classroomsFailed()));
};

export const getLessonPLans = (classroomId, activityId) => (dispatch) => {
  dispatch(lessonPlansLoading());
  return makeRequest(`/classrooms/${classroomId}/activities/${activityId}`)
    .then((res) => dispatch(lessonPlansLoaded(res.resource)))
    .catch(() => Promise.reject());
};

export const createClassroom = (data) => (dispatch) => {
  dispatch(addClassroomLoading());
  return makeRequest('/classrooms', {
    method: 'POST',
    data: {
      resource: data,
    },
  })
    .then((res) => {
      dispatch(getClassrooms());
    })
    .catch(() => dispatch(addClassroomFailed()));
};

export const getCurrentWeekUnit = (classroomId) => (dispatch) => {
  dispatch(getCurrentWeekUnitLoading());
  return makeRequest(`/classroom/${classroomId}/current_unit_and_week`)
    .then(({ resource }) => dispatch(getCurrentWeekUnitLoaded(resource)))
    .catch(() => dispatch(getCurrentWeekUnitFailed()));
};

export const updateClassroom = (data, classroomId, leavingClassroom = false) => (dispatch) => {
  dispatch(updateClassroomLoading());
  return makeRequest(`/classrooms/${classroomId}`, {
    method: 'PUT',
    data: {
      resource: data,
    },
  })
    .then((res) => {
      dispatch(updateClassroomLoaded({ data: res.resource, leavingClassroom }));
      return Promise.resolve();
    })
    .catch(() => {
      dispatch(updateClassroomFailed());
    });
};

export const getSchoolTeachers = () => (dispatch) => {
  dispatch(teachersLoading());
  return makeRequest('/users')
    .then((res) => {
      dispatch(teachersLoaded(res.resources));
      return Promise.resolve();
    })
    .catch(() => dispatch(teachersFailed()));
};

export const markFavorite = (type, favoriteId, toMark) => (dispatch) => {
  const url = toMark ? '/favorites' : `/favorites/destroy_by_type/${type}/${favoriteId}`;
  const options = toMark
    ? { method: 'POST', data: { resource: { favorite_type: type, favorite_id: favoriteId } } }
    : { method: 'DELETE' };
  return makeRequest(url, {
    ...options,
  })
    .then((res) => {
      if (res.status === 'ok') return Promise.resolve();
    })
    .catch(() => {});
};

export const getClassroomUnit = (classroomId, unitProgId) => (dispatch) => {
  dispatch(classroomUnitLoading());
  return makeRequest(`/classrooms/${classroomId}/unit_progresses/${unitProgId}`)
    .then(({ resource }) => dispatch(classroomUnitLoaded(resource, classroomId)))
    .catch((err) => {
      dispatch(classroomUnitFailed());
    });
};

export const getClassroomWeek = (classroomId, unitProgId, weekProgId) => (dispatch) => {
  dispatch(classroomWeekLoading());
  return makeRequest(`/classrooms/${classroomId}/unit_progresses/${unitProgId}/week_progresses/${weekProgId}`)
    .then(({ resource }) => dispatch(classroomWeekLoaded(resource, classroomId, unitProgId)))
    .catch(() => dispatch(classroomWeekFailed()));
};

export const markWeekCompleted = (classroomId, unitProgId, weekProgId, isFinished) => (dispatch) => {
  dispatch(markWeekLoading());
  return makeRequest(`/classrooms/${classroomId}/unit_progresses/${unitProgId}/week_progresses/${weekProgId}`, {
    method: 'PUT',
    data: { resource: { finished: isFinished } },
  })
    .then(({ resource: { id, finished } }) =>
      dispatch(markWeekLoaded({ classroomId, unitProgId, weekProgId: id, finished })),
    )
    .then(() => dispatch(getCurrentWeekUnit(classroomId)))
    .then(() => isFinished && dispatch(incrementCompleted()))
    .catch(() => dispatch(markWeekFailed()));
};

export const markLessonPlanCompleted = (classroomId, lessonPlanId, isFinished, paths) => (dispatch) => {
  dispatch(markLessonPlanLoading());
  return makeRequest(`/classrooms/${classroomId}/activities/${lessonPlanId}`, {
    method: 'PUT',
    data: { resource: { finished: isFinished } },
  })
    .then(({ resource: { id, finished } }) => dispatch(markLessonPlanLoaded({ classroomId, id, finished, ...paths })))
    .then(() => isFinished && dispatch(incrementCompleted()))
    .catch(() => dispatch(markLessonPlanFailed()));
};

export const getCoverImages = (classroomId) => (dispatch) => {
  dispatch(coverImagesLoading());
  return makeRequest(`/classrooms/${classroomId}/cover_images`)
    .then((res) => {
      dispatch(coverImagesLoaded(res.resources));
    })
    .catch(() => dispatch(coverImagesFailed()));
};

export const uploadCoverImage = (data, classroomId) => (dispatch) =>
  makeRequest(`/classrooms/${classroomId}/cover_images`, {
    method: 'POST',
    data,
  })
    .then((res) => {
      dispatch(uploadCoverImageLoaded(res.resource));
      return Promise.resolve();
    })
    .catch((error) => {});

export const deleteCoverImage = (imageId, classroomId) => (dispatch) =>
  makeRequest(`/classrooms/${classroomId}/cover_images/${imageId}`, {
    method: 'DELETE',
  })
    .then(() => {
      dispatch(getCoverImages(classroomId));
      return Promise.resolve();
    })
    .catch(() => {});

export const updateUserNotes = ({ text, classroomId, activityId }) => (dispatch) =>
  makeRequest(`/classrooms/${classroomId}/activities/${activityId}/note_update`, {
    method: 'PUT',
    data: {
      resource: {
        text,
      },
    },
  })
    .then((res) => {
      dispatch(lessonPlansLoaded(res.resource));
    })
    .catch(() => {});

export const getLearningExperience = (classroomId, learningExpId) => (dispatch) => {
  dispatch(learningExperienceLoading());
  return makeRequest(`/classrooms/${classroomId}/learning_experiences/${learningExpId}`)
    .then((res) => dispatch(learningExperienceLoaded(res.resource)))
    .catch(() => Promise.reject());
};

export const markLearningExpFavorite = (id, isFavorite) => (dispatch) => {
  const url = isFavorite ? '/favorites' : `/favorites/destroy_by_type/LearningExperience/${id}`;
  const options = isFavorite
    ? { method: 'POST', data: { resource: { favorite_type: 'LearningExperience', favorite_id: id } } }
    : { method: 'DELETE' };
  return makeRequest(url, {
    ...options,
  })
    .then(() => dispatch(markLearningExpFavoriteLoaded(id, isFavorite)))
    .catch(() => {});
};

export const markLearningExpFinished = (classroomId, id, isFinished, unitProgId, weekId) => (dispatch) =>
  makeRequest(`/classrooms/${classroomId}/learning_experiences/${id}`, {
    method: 'PUT',
    data: { resource: { finished: isFinished } },
  })
    .then(() => dispatch(markLearningExpFinishedLoaded(classroomId, id, isFinished, unitProgId, weekId)))
    .then(() => isFinished && dispatch(incrementCompleted()))
    .catch(() => {});

export const disableClassroom = (classroomId) => (dispatch) =>
  makeRequest(`/classroom/${classroomId}/disable`, { method: 'PUT' })
    .then(({ resource }) => {
      dispatch(disableClassroomLoaded(resource));
      dispatch(toggleDisableClassroomProgramLoaded(resource));
    })
    .then(() => toast.success('Classroom disabled'))
    .catch(() => {});

export const activateClassroom = (classroomId) => (dispatch) =>
  makeRequest(`/classroom/${classroomId}/activate`, { method: 'PUT' })
    .then(({ resource }) => {
      dispatch(activateClassroomLoaded(resource));
      dispatch(toggleDisableClassroomProgramLoaded(resource));
    })
    .then(() => toast.success('Classroom activated'))
    .catch(() => {});

export const inviteUsers = (resource, classroomId) => (dispatch) => {
  return makeRequest('/users/invite', { method: 'POST', data: { invitations: { users_attributes: resource } } })
    .then(({ invited_users: invited, failed_invitations: failed }) => {
      dispatch(inviteUsersLoaded({ invited, classroomId }));
      return Promise.resolve({ invited, failed });
    })
    .then(inviteRespondWithToasts)
    .catch((err) => {});
};

export const getProgressesIds = (classroomId, resourceId, lessonPlan = true) => (dispatch) =>
  makeRequest(
    lessonPlan
      ? `classrooms/${classroomId}/activities/${resourceId}/find_current_classroom_activity_link`
      : `classrooms/${classroomId}/learning_experiences/${resourceId}/find_current_classroom_learning_experience_link`,
  ).then(({ unit_progress_id: unitId, week_progress_id: weekId }) => ({ unitId, weekId }));
