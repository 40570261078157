import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import T from 'prop-types';
// utils
import { GRADE_TITLES } from 'constants/classrooms';
import { buildUrl } from 'utils';
import { getFavorites, removeFavorite } from 'store/favorites';
import { useSelector, useDispatch } from 'react-redux';
import {
  eventCategory,
  eventAction,
  eventLabel,
  USER_ROLE,
  PROGRAM_NAME,
  gaRoleLabels,
  customDimensions,
} from 'constants/googleAnalytics';
import { GAEmitEvent } from 'utils/googleAnalytics';
// hooks
import { useModalSetter } from 'hooks/useModal';
import { useBottomMenuSetter } from 'hooks/useBottomMenu';
// components
import Portal from 'components/Portal';
import BottomMenu from 'components/BottomMenu';
import Text from 'components/Text';
// icons
import { ReactComponent as RemoveFavIcon } from 'icons/recycle-bin.svg';
import { ReactComponent as LaunchPlanIcon } from 'icons/rocket.svg';
import { ReactComponent as DotsIcon } from 'icons/dots.svg';
import { ReactComponent as ListIcon } from 'icons/list.svg';
// styles
import classnames from 'classnames/bind';
import { SelectClassroomModal } from '../BrowserView/BrowserView';
import styles from './MobileView.module.scss';
import './MobileView.scss';

const cn = classnames.bind(styles);

const BottomDrawer = ({ dispatch, closeDrawer, setModal, history: { push }, fav, findSchool }) => (
  <BottomMenu menuHeading="options">
    <div
      className={cn('menu-option')}
      onClick={() => {
        closeDrawer();

        if (fav.classrooms.length > 1) return setModal(<SelectClassroomModal favorite={fav} findSchool={findSchool} />);
        const { classroom_id, unit_progress_id, week_progress_id } = fav.classrooms[0];

        return push(
          `/classroom/${classroom_id}/unit/${unit_progress_id}/week/${week_progress_id}/lesson-plan/${fav.activity.id}?chapterId=${fav.id}`,
        );
      }}
    >
      <LaunchPlanIcon className={cn('menu-option--icon')} />
      <Text className="no-select">
        {fav.type === 'activity'
          ? 'Launch Lesson Plan'
          : fav.type === 'learning_experience'
          ? 'Launch Learning Experience'
          : ''}
      </Text>
    </div>
    <div
      className={cn('menu-option')}
      onClick={() => {
        return dispatch(removeFavorite(fav.id, fav.type)).then(() => closeDrawer());
      }}
    >
      <RemoveFavIcon className={cn('menu-option--icon')} />
      <Text className="no-select">Remove From My Library</Text>
    </div>
  </BottomMenu>
);

const MainSection = ({ activeTab, toggleListView, listView, findSchool }) => {
  const favorites = useSelector(({ favorites: { favorites: favs = [] } = {} }) => {
    switch (activeTab) {
      case 'all':
        return favs;
      case 'professional prep':
        return favs.filter(({ type }) => type === 'activity');
      case 'learning experiences':
        return favs.filter(({ type }) => type === 'learning_experience');
      default:
        return favs;
    }
  });
  const { role } = useSelector(({ auth }) => auth.user);
  const { name: programName } = useSelector(({ program }) => program);

  const dispatch = useDispatch();
  const history = useHistory();

  const { setBottomMenu, closeMenu } = useBottomMenuSetter();
  const setModal = useModalSetter();

  const boundAction = useCallback(() => dispatch(getFavorites()), [dispatch]);
  useEffect(() => boundAction(), []);

  const generateFavCard = (fav, idx) => {
    const isActivity = fav.type === 'activity';
    const gradePath = isActivity ? fav.activity.week.unit.grade_level : fav.week.unit.grade_level;
    const weekNumber = isActivity ? fav.activity.week.order_number : fav.week.order_number;
    const favName = isActivity ? fav.activity.name : fav.name;
    const description = fav.name;
    const learningExpImg =
      fav.learning_experience_chapters && fav.learning_experience_chapters.find(({ image_url: img }) => !!img);
    const image = fav.image_url || (learningExpImg && learningExpImg.image_url);

    return (
      <article
        className={cn('card-wrapper', { 'list-view': listView })}
        key={idx}
        style={{ animationDelay: listView && `${idx * 0.02}s` }}
      >
        <div
          className={cn('card-wrapper--menu', { 'list-view': listView })}
          onClick={() =>
            setBottomMenu(
              <BottomDrawer
                dispatch={dispatch}
                closeDrawer={closeMenu}
                setModal={setModal}
                history={history}
                fav={fav}
                findSchool={findSchool}
              />,
            )
          }
        >
          <DotsIcon className={cn('menu-icon')} />
        </div>
        <div
          className={cn('card', { 'list-view': listView })}
          style={{
            backgroundImage: image && `url('${buildUrl(image)}')`,
            backgroundSize: image ? 'contain' : '90%',
          }}
        />
        <div className={cn('text-wrapper', { 'list-view': listView })}>
          <Text className={cn('text-wrapper--name')}>{description}</Text>
          <Text type="body-small" className={cn('text-wrapper--desc')}>
            {GRADE_TITLES[gradePath]}
            &nbsp;|&nbsp;Week&nbsp;
            {weekNumber}
          </Text>
          <Text type="body-small" className={cn('text-wrapper--desc')}>
            {favName}
          </Text>
        </div>
      </article>
    );
  };

  return (
    <div className={cn('main-wrapper')}>
      <Portal>
        <div
          className="favorites-toggle-view"
          onClick={() => {
            toggleListView();
            GAEmitEvent(eventCategory.USER_ACTION, eventAction.MENU_CLICK, eventLabel.view(!listView), {
              [customDimensions[USER_ROLE]]: gaRoleLabels[role],
              [customDimensions[PROGRAM_NAME]]: programName,
            });
          }}
        >
          <ListIcon className={cn('favorites-toggle-view--icon')} />
        </div>
      </Portal>
      <div className={cn('favs-wrapper', { 'list-view': listView })}>
        {(favorites || []).map((fav, idx) => generateFavCard(fav, idx))}
      </div>
    </div>
  );
};

MainSection.propTypes = {
  activeTab: T.string.isRequired,
};

export default MainSection;
