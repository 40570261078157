import React, { useCallback, useEffect, useState } from 'react';
import T from 'prop-types';
import classnames from 'classnames/bind';
// hooks
import { useSpring, animated } from 'react-spring';
// styles
import styles from './Navbar.module.scss';

const cn = classnames.bind(styles);

const AnimatedUnderline = ({ children, isActive: isActiveProp = false, className, onMouseEnter, onMouseLeave }) => {
  const [isActive, setIsActive] = useState(isActiveProp);

  useEffect(() => {
    setIsActive(isActiveProp);
  }, [isActiveProp]);

  const props = useSpring({ width: isActive ? '100%' : '-1%' });
  const handleMouseEnter = useCallback(() => {
    if (onMouseEnter) {
      onMouseEnter();
    }
    setIsActive(true);
  }, [setIsActive, onMouseEnter]);
  const handleMouseLeave = useCallback(() => {
    if (onMouseLeave) {
      onMouseLeave();
    }
    setIsActive(false);
  }, [setIsActive, onMouseLeave]);

  return (
    <div className={cn('underline-wrapper', className)} aria-hidden>
      {typeof children === 'function'
        ? children({ onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave })
        : children}
      <animated.div className={cn('underline')} style={props} />
    </div>
  );
};

AnimatedUnderline.propTypes = {
  children: T.any,
  isActive: T.bool,
  className: T.string,
  onMouseEnter: T.func,
  onMouseLeave: T.func,
};

export default AnimatedUnderline;
