export const PREK3 = 'pre_k3';
export const PREK4 = 'pre_k4';
export const UNSET = '';

export const GRADE_TITLES = {
  [UNSET]: 'None',
  [PREK3]: 'PreK3',
  [PREK4]: 'PreK4',
};

export const GRADE_COLORS = {
  [PREK3]: '#9d1d96',
  [PREK4]: '#00c4b3',
  [UNSET]: '#e3e3e3',
  [null]: '#e3e3e3',
};

export const GRADE_LEVELS = [PREK3, PREK4];
export const GRADE_TITLES_OPTIONS = GRADE_LEVELS.map((grade) => ({ label: GRADE_TITLES[grade], value: grade }));
export const GRADE_TITLES_OPTIONS_CREATE = [UNSET, ...GRADE_LEVELS].map((grade) => ({
  label: GRADE_TITLES[grade],
  value: grade,
}));
export const GRADE_COLORS_REVERTED = GRADE_LEVELS.reduce((acc, curr) => ({ ...acc, [GRADE_COLORS[curr]]: curr }), {});
