import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// utils
import { isIE } from 'react-device-detect';
import { markLessonPlanCompleted } from 'store/classrooms';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import withTabSwitcher from 'hoc/withTabSwitcher';
import { get, isEmpty } from 'lodash';
import {
  eventCategory,
  eventAction,
  eventLabel,
  USER_ROLE,
  PROGRAM_NAME,
  UNIT_NUMBER,
  UNIT_TITLE,
  WEEK_NAME,
  gaRoleLabels,
  customDimensions,
} from 'constants/googleAnalytics';
import { GAEmitEvent } from 'utils/googleAnalytics';
// components
import Link from 'components/Link';
import Text from 'components/Text';
import Button from 'components/Button';
// icons
import { ReactComponent as PrintIcon } from 'icons/print.svg';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
// styles
import classnames from 'classnames/bind';
import './BrowserView.scss';
import { getWeekOrderNumber } from 'utils';
import { GRADE_TITLES } from 'constants/classrooms';
import DraftBadge from 'components/Badges/DraftBadge';
import styles from './BrowserView.module.scss';

const cn = classnames.bind(styles);

const BrowserView = ({ activeTab, handlePrint }) => {
  const [shortenBreadCrumbs, setShortenBreadCrumbs] = useState(false);
  const {
    push,
    location: { pathname, search },
  } = useHistory();
  const { name: lessonName, draft: isDraft, finished, id, required } = useSelector(
    (state) => state.classrooms.lessonPlans,
  );
  const { classroomId, unitId, weekId } = useParams();
  const { unit, week, classroomName } = useSelector(({ classrooms: { classrooms } }) => {
    const classroom = classrooms.find((clsrm) => clsrm.id === +classroomId);
    return {
      unit: get(classroom, `unit_progress[${unitId}]`, {}),
      week: get(classroom, `unit_progress[${unitId}].week_progress[${weekId}]`, {}),
      classroomName: get(classroom, 'name', ''),
    };
  });
  const { role } = useSelector(({ auth }) => auth.user);
  const { name: programName } = useSelector(({ program }) => program);

  const dispatch = useDispatch();
  const currentUnit = unit && unit.order_number;
  const gradeLevel = !isEmpty(unit.units) && GRADE_TITLES[unit.units[0].grade_level];
  const currentWeek = week && week.weeks && week.weeks[0].order_number;

  useEffect(() => {
    const handleScroll = () => setShortenBreadCrumbs(!!(window.scrollY > 20 && !shortenBreadCrumbs));

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => window.scroll(0, 0), [activeTab]);

  const buildNavSection = () => (
    <div
      className={cn('lesson-plans-bread-crumbs', {
        'lesson-plans-bread-crumbs--short': shortenBreadCrumbs,
      })}
    >
      <Link to="/classrooms" isPrimary>
        <Text className={cn('lesson-plans-bread-crumbs--link-text')} type="body-small">
          My Classrooms
        </Text>
      </Link>
      <span className={cn('lesson-plans-bread-crumbs--arrow')}>&rsaquo;</span>
      <Link to={`/classroom/${classroomId}`} isPrimary>
        <Text className={cn('lesson-plans-bread-crumbs--link-text')} type="body-small">
          {classroomName}
        </Text>
      </Link>
      <span className={cn('lesson-plans-bread-crumbs--arrow')}>&rsaquo;</span>
      <Link to={`/classroom/${classroomId}/unit/${unitId}`} isPrimary>
        <Text className={cn('lesson-plans-bread-crumbs--link-text')} type="body-small">
          {`${gradeLevel} | Unit ${currentUnit}`}
        </Text>
      </Link>
      <span className={cn('lesson-plans-bread-crumbs--arrow')}>&rsaquo;</span>
      <Link to={`/classroom/${classroomId}/unit/${unitId}/week/${weekId}`} isPrimary>
        <Text className={cn('lesson-plans-bread-crumbs--link-text')} type="body-small">
          {getWeekOrderNumber(currentWeek, false, true)}
        </Text>
      </Link>
      <span className={cn('lesson-plans-bread-crumbs--arrow')}>&rsaquo;</span>
      <Text className={cn('lesson-plans-bread-crumbs--lesson-name')} type="body-small">
        {lessonName}
      </Text>
    </div>
  );

  return (
    <div className={cn('heading-wrapper', 'pt-10')} style={{ position: isIE && 'fixed' }}>
      <div className={cn('content-left')}>
        <div>
          <Text type="h5" className="flex">
            {getWeekOrderNumber(currentWeek, false, true)}
            &nbsp;&nbsp;|&nbsp;&nbsp;
            {lessonName}
            {isDraft && <DraftBadge className={cn('draft-badge')} />}
          </Text>
          {currentWeek && buildNavSection()}
        </div>
      </div>
      <div className={cn('lesson-plans-content-right')}>
        <Button
          type="markable"
          size="md"
          variant="outlined"
          className="mh-10"
          marked={finished}
          onClick={() => {
            dispatch(markLessonPlanCompleted(classroomId, id, !finished, { uPId: unitId, wPId: weekId }));
            !finished &&
              required &&
              GAEmitEvent(
                eventCategory.USER_ACTION,
                eventAction.MENU_CLICK,
                eventLabel.requiredActivity(lessonName, 'Professional Prep', pathname + search),
                {
                  [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                  [customDimensions[PROGRAM_NAME]]: programName,
                  [customDimensions[UNIT_NUMBER]]: get(unit, 'units[0].order_number', null),
                  [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
                  [customDimensions[WEEK_NAME]]: get(week, `weeks[0].name`, null),
                },
              );
          }}
        />
        <PrintIcon className={cn('lesson-plans-content-right--print', 'mh-10')} onClick={handlePrint} />
        <div className={cn('lesson-plans-content-right--vl', 'mh-10')} />
        <CloseIcon
          className={cn('lesson-plans-content-right--close', 'mh-10')}
          onClick={() => push(`/classroom/${classroomId}/unit/${unitId}/week/${weekId}?tab=getting ready`)}
        />
      </div>
    </div>
  );
};

BrowserView.propTypes = {
  activeTab: T.string.isRequired,
};

export default withTabSwitcher(BrowserView);
