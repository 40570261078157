import React from 'react';
import T from 'prop-types';
import classnames from 'classnames/bind';
// icons
import { ReactComponent as CheckBoxIcon } from 'icons/checkbox.svg';
// styles
import styles from './CheckBox.module.scss';

const cn = classnames.bind(styles);

const CheckBox = ({ className, label, isChecked, onClick: handleClick }) => (
  <div aria-hidden onClick={handleClick} className={cn('wrapper', className)}>
    <CheckBoxIcon className={cn('icon', { isChecked })} />
    {label}
  </div>
);

CheckBox.propTypes = {
  className: T.string,
  label: T.string,
  isChecked: T.bool,
  onClick: T.func,
};

export default CheckBox;
