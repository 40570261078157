import React from 'react';
import T from 'prop-types';
// utils
import { isMobile } from 'react-device-detect';
import { clearHtml } from 'utils';
// components
import CheckButton from 'components/CheckButton';
import ImageCarousel from 'components/ImageCarousel';
import SmallBadge, { badgeTypes } from 'components/SmallBadge';
import Button from 'components/Button';
import Text from 'components/Text';
import DraftBadge from 'components/Badges/DraftBadge';
import TruncateHint from 'components/TruncateHint/TruncateHint';
import RequiredBadge from 'components/Badges/RequiredBadge';
// styles
import classnames from 'classnames/bind';
import styles from './LearningExperienceBox.module.scss';

const cn = classnames.bind(styles);

const LearningExperienceBox = ({
  images = [],
  experienceName,
  description,
  experienceCompleted = false,
  onCompleteClick,
  onLaunchClick,
  tags,
  isDraft,
  required,
  isPreview,
}) => (
  <div className={cn('experience-wrapper')} onClick={() => isMobile && onLaunchClick()}>
    {isDraft && <DraftBadge className={cn('draft-badge')} />}
    <div className={cn('cover-image')} style={{ backgroundImage: `url('${images[0]}')` }} />
    <div className={cn('experience-content--wrapper')}>
      <div className={cn('button-wrapper')} aria-hidden onClick={(e) => e.stopPropagation()}>
        <CheckButton
          isPreview={isPreview}
          isChecked={experienceCompleted}
          onChange={onCompleteClick}
          tooltip={
            !isMobile && (isPreview ? 'Preview Only' : experienceCompleted ? 'Mark Uncompleted' : 'Mark Completed')
          }
        />
      </div>
      <Text type={isMobile ? 'h6' : 'h5'}>{experienceName}</Text>
      <TruncateHint innerHTML={description} lines={3} />
      <div className={cn('tags-wrapper')}>
        {required && <RequiredBadge className={cn('tag')} />}
        {tags.map(({ name }, idx) => (
          <SmallBadge className={cn('tag')} key={`badge-${idx}`} type={name.toLowerCase()} />
        ))}
      </div>
    </div>
    {!isMobile && (
      <div className={cn('buttons-wrapper')}>
        <Button
          className={cn('experience-button')}
          size="md"
          onClick={onLaunchClick}
          label={isPreview ? 'Preview Only' : experienceCompleted ? 'Relaunch Experience' : 'Launch Experience'}
          variant={experienceCompleted ? 'filled' : ''}
        />
      </div>
    )}
  </div>
);

LearningExperienceBox.propTypes = {
  tags: T.array,
  onCompleteClick: T.func,
  onLaunchClick: T.func,
  images: T.arrayOf(T.string).isRequired,
  experienceName: T.string.isRequired,
  description: T.string.isRequired,
  experienceCompleted: T.bool.isRequired,
};

export default LearningExperienceBox;
