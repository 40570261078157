import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// utils
import { useTransition, animated } from 'react-spring';
import { capitalizeEachWord } from 'utils/string';
import { buildUrl } from 'utils';
import { difference, isEmpty } from 'lodash';
// components
import InviteByMailModal from './InviteByMailModal';
import Modal from 'components/Modal';
import ModalAnimWrapper from 'components/Modal/ModalAnimationWrapper';
import Button from 'components/Button';
import Text from 'components/Text';
import UserSelect from 'components/UserSelect';
// icons
import { ReactComponent as AddPersonIcon } from 'icons/add-person.svg';
import { ReactComponent as PlusIcon } from 'icons/green-plus.svg';
// styles
import classnames from 'classnames/bind';
import styles from './AddUsers.module.scss';

const cn = classnames.bind(styles);

const AddUsers = ({ isProgram, instanceName, setUsers, teachers, isInitialyOpen }) => {
  const [contentOpen, setOpen] = useState(isInitialyOpen);
  const [invitePerson, setInvitePerson] = useState(false);
  const [removeTeacher, setRemoveTeacher] = useState({});
  const [usersBySearch, setUsersBySearch] = useState([]);
  const [usersByEmail, setUsersByEmail] = useState([]);

  useEffect(() => setUsers([...usersByEmail, ...usersBySearch]), [usersBySearch, usersByEmail]);

  const subject = isProgram ? 'school administrator' : 'classroom teacher';

  const transitions = useTransition(contentOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0, position: 'absolute', top: -2000 },
    config: { duration: 200 },
  });

  const handleChooseUser = (value) => setUsersBySearch((prev) => [...prev, value]);

  const handleRemoveUser = (email, type) => {
    if (type === 'search') {
      setUsersBySearch((prev) => [...prev.filter(({ email: _email }) => _email !== email)]);
      return;
    }
    setUsersByEmail((prev) => [...prev.filter((_email) => _email !== email)]);
  };

  return (
    <section className={cn('wrapper')}>
      {transitions.map(({ props, item }, key) => {
        const isOpen = item;

        return (
          <animated.div key={key} style={props}>
            {isOpen ? (
              <div className={cn('form-wrapper')}>
                <div className={cn('header')}>
                  <Text type="h6">{`${capitalizeEachWord(subject)} ${isInitialyOpen ? '' : '(Optional)'}`}</Text>
                  {!isInitialyOpen && (
                    <Text action onClick={() => setOpen(false)}>
                      Close
                    </Text>
                  )}
                </div>
                <UserSelect
                  className="mv-15"
                  options={difference(teachers, usersBySearch)}
                  onChange={handleChooseUser}
                />
                {(!!usersByEmail.length || !!usersBySearch.length) && (
                  <div className={cn('admins-wrapper')}>
                    <Text bold className={cn('title', 'mb-15')}>
                      {isProgram ? 'School Admins' : 'Classroom Teachers'}
                    </Text>
                    {!!usersBySearch.length &&
                      usersBySearch.map(
                        ({ first_name: firstName, last_name: lastName, email, avatar_url: imageUrl }, idx) => (
                          <div key={`admin-${idx}`} className={cn('admin-row', 'mb-15')}>
                            <img className={cn('avatar')} src={buildUrl(imageUrl)} aria-hidden alt="Avatar" />
                            <div className={cn('credentials', 'ml-15')}>
                              <Text type="h6">{firstName && lastName ? `${firstName} ${lastName}` : email}</Text>
                              {firstName && <Text className={cn('email')}>{email}</Text>}
                            </div>
                            <Text
                              action
                              className="ml-auto mr-10"
                              onClick={() => setRemoveTeacher({ email, firstName, lastName, type: 'search' })}
                            >
                              Remove
                            </Text>
                          </div>
                        ),
                      )}
                    {!!usersByEmail.length &&
                      usersByEmail.map((email, idx) => (
                        <div key={`email-admin-${idx}`} className={cn('admin-row', 'email-row', 'mb-15')}>
                          <Text>{email}</Text>
                          <Text action className="ml-auto" onClick={() => setRemoveTeacher({ email, type: 'email' })}>
                            Remove
                          </Text>
                        </div>
                      ))}
                  </div>
                )}
                <div className={cn('add-person-wrapper', 'mt-20')} onClick={() => setInvitePerson(true)}>
                  <div className={cn('icon')}>
                    <AddPersonIcon />
                  </div>
                  <Text type="h6" className={cn('invite-member', 'ml-20')}>
                    Invite a New Member to SELF
                  </Text>
                </div>
              </div>
            ) : (
              <div className={cn('add-button')} onClick={() => setOpen(true)}>
                <PlusIcon />
                <Text type="h6" className={cn('text', 'ml-10')}>{`Add a ${subject} (Optional)`}</Text>
              </div>
            )}
          </animated.div>
        );
      })}
      <ModalAnimWrapper>
        {invitePerson && (
          <InviteByMailModal
            setState={setUsersByEmail}
            onClickOutside={() => setInvitePerson(false)}
            onClose={() => setInvitePerson(false)}
          />
        )}
      </ModalAnimWrapper>
      <ModalAnimWrapper>
        {!isEmpty(removeTeacher) && (
          <Modal className={cn('remove-modal')} onClickOutside={() => setRemoveTeacher({})}>
            {(_) => (
              <>
                <Modal.Header className="simple">
                  <Text type="h5">Remove member</Text>
                  <Modal.CloseButton className="simple" onClick={() => setRemoveTeacher({})} />
                </Modal.Header>
                <Modal.Body className="simple">
                  <Text>
                    Are you sure you wish to remove{' '}
                    <strong>
                      {removeTeacher.firstName
                        ? `${removeTeacher.firstName} ${removeTeacher.lastName}`
                        : removeTeacher.email}
                    </strong>{' '}
                    as {subject} for <strong>{instanceName}</strong>?
                  </Text>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className={cn('cancel-button', 'mr-20 ph-30')}
                    size="md"
                    variant="filled"
                    label="Cancel"
                    onClick={() => setRemoveTeacher({})}
                  />
                  <Button
                    className="ph-30"
                    size="md"
                    variant="filled-red"
                    label="Remove"
                    onClick={() => {
                      handleRemoveUser(removeTeacher.email, removeTeacher.type);
                      setRemoveTeacher({});
                    }}
                  />
                </Modal.Footer>
              </>
            )}
          </Modal>
        )}
      </ModalAnimWrapper>
    </section>
  );
};

AddUsers.propTypes = {
  isProgram: T.bool.isRequired,
  instanceName: T.string,
  setUsers: T.func.isRequired,
  teachers: T.arrayOf(T.object).isRequired,
};

export default AddUsers;
