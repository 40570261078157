import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
// utils
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import T from 'prop-types';
import { buildUrl } from 'utils';
import { getDefaultValue } from 'utils/object';
import { TITLE_OPTIONS } from 'constants/user';
// hooks
import { useModalSetter } from 'hooks/useModal';
// components
import ImageCropperModal from 'components/ImageCropperModal';
import Text from 'components/Text';
import Input from 'components/Input';
import PasswordInput from 'components/Input/PasswordInput';
import Button from 'components/Button';
import SelectBox from 'components/SelectBox/SelectBox';
// styles
import classnames from 'classnames/bind';
import styles from './BrowserView.module.scss';

const cn = classnames.bind(styles);

// not sure how creds changing will work, thus this might change later
const schema = yup.object().shape({
  title: yup.string(),
  first_name: yup.string().required('First name is required.'),
  last_name: yup.string().required('Last name is required'),
  email: yup.string().email(),
  current_password: yup.string().test({
    name: 'passwordValidation',
    test(value) {
      if (!value || (value && value.length > 6)) return true;
      return this.createError({
        message: 'Password must be at least 6 characters',
        path: this.path,
      });
    },
  }),
  password: yup.string().min(6, 'Password must be at least 6 characters'),
  password_confirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const ProfileSection = ({ updateUserData, updateUserAvatar }) => {
  const { user } = useSelector(({ auth }) => auth);

  const initValues = {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
  };

  const { register, handleSubmit, errors, clearErrors, getValues, reset, ...restForm } = useForm({
    defaultValues: initValues,
    resolver: yupResolver(schema),
  });

  // ensures to populate form if not set on init render
  useEffect(() => {
    reset({
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      title: user.title,
    });
  }, [user, reset]);

  const setModal = useModalSetter();

  // these may radically change if we decide to go with other aspect ratio or smth
  const photoPreview = (imgUrl) => {
    const sideSize = 170;

    return (
      <img
        alt="avatar"
        aria-label="avatar-image"
        src={buildUrl(imgUrl)}
        style={{ width: `${sideSize}px`, height: `${sideSize}px`, borderRadius: '5px' }}
      />
    );
  };
  const onSaveChanges = (data) => updateUserData({ ...data, email: user.email, id: user.id });

  const clearErrorsOnTyping = (field) => clearErrors(field);

  const handleAcceptImageCrop = (imgEl, callback) => {
    if (!imgEl) return;

    const formData = new FormData();
    const imageCp = imgEl.slice();
    imageCp.lastModifiedDate = new Date();
    imageCp.name = 'avatar';

    formData.append('resource[avatar]', imageCp);
    return updateUserAvatar({ formData, userId: user.id }).then(() => typeof callback === 'function' && callback());
  };

  return (
    <div className={cn('profile-wrapper', 'ph-60')}>
      <form className={cn('profile--content-left')} onSubmit={handleSubmit(onSaveChanges)}>
        <SelectBox
          label="Title"
          name="title"
          hookFormProps={{ register, errors, clearErrors, ...restForm }}
          className={cn('title-select')}
          options={TITLE_OPTIONS}
          tabIndex="1"
          defaultValue={user.title && getDefaultValue(TITLE_OPTIONS, user.title)}
        />
        <div className={cn('profile-fields')}>
          <div className={cn('profile--left-col')}>
            <Input
              label="First Name"
              name="first_name"
              className="mr-40"
              inputRef={register({ required: true })}
              errors={errors}
              tabIndex="2"
              onChange={() => clearErrorsOnTyping('first_name')}
            />
            <Input label="District Email Address" tabIndex="-1" name="email" inputRef={register} disabled />
            <PasswordInput
              label="Current password"
              tabIndex="4"
              name="current_password"
              inputRef={register}
              errors={errors}
              onChange={() => clearErrorsOnTyping('current_password')}
            />
            <PasswordInput
              label="Change password"
              name="password"
              inputRef={register}
              errors={errors}
              tabIndex="5"
              disabled={!getValues('current_password')}
              onChange={() => clearErrorsOnTyping('password')}
            />
          </div>
          <div className={cn('profile--right-col')}>
            <Input
              label="Last Name"
              name="last_name"
              inputRef={register({ required: true })}
              errors={errors}
              tabIndex="3"
              onChange={() => clearErrorsOnTyping('last_name')}
            />
            <PasswordInput
              label="Confirm new password"
              name="password_confirmation"
              inputRef={register}
              disabled={!getValues('current_password')}
              errors={errors}
              tabIndex="6"
              onChange={() => clearErrorsOnTyping('password_confirmation')}
            />
          </div>
        </div>
        <Button type="regular" label="Save Changes" size="bg" isSubmit disabled={!_.isEmpty(errors)} />
      </form>
      <div className={cn('profile--content-right')}>
        <div className={cn('image-wrapper')}>
          <Text>Profile Photo</Text>
          {photoPreview(user.avatar_url)}
          <Button
            className={cn('image-wrapper--button')}
            type="regular"
            size="md"
            label="Upload an image"
            variant="outlined"
            onClick={() =>
              setModal(
                <ImageCropperModal aspectRatio={1 / 1} imageUrl={user.avatar_url} onSave={handleAcceptImageCrop} />,
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

ProfileSection.propTypes = {
  userData: T.shape({
    id: T.number,
    avatar_url: T.string,
    email: T.string.isRequired,
    first_name: T.string.isRequired,
    last_name: T.string.isRequired,
  }),
  updateUserData: T.func.isRequired,
  userDataLoading: T.bool.isRequired,
};

ProfileSection.defaultProps = {
  userData: T.shape({
    avatarUrl: '',
  }),
};

export default ProfileSection;
