import React, { useState, useEffect, useRef, memo } from 'react';
import { useParams } from 'react-router-dom';
// utils
import { get, isEmpty, isEqual } from 'lodash';
import { getCoverImages, updateClassroom, uploadCoverImage, deleteCoverImage } from 'store/classrooms';
import { toast } from 'react-toastify';
import { config } from 'constants/images';
import { useSelector, useDispatch } from 'react-redux';
// hooks
import { useModalSetter } from 'hooks/useModal';
import { useBottomMenuSetter } from 'hooks/useBottomMenu';
// containers
import MobileSubcomponentWrapper from 'containers/MobileSubcomponentWrapper';
import SlideInWrapper from 'containers/SlideInWrapper';
// icons
import { ReactComponent as CameraIcon } from 'icons/camera-black.svg';
// components
import Button from 'components/Button';
import BottomMenu from 'components/BottomMenu';
import Modal from 'components/Modal';
import CoverImageCropper from 'components/CoverImageCropper';
import Text from 'components/Text';
import ImageBox from '../BrowserView/ImageBox';
// styles
import classnames from 'classnames/bind';
import styles from './MobileView.module.scss';
import { isMobile } from 'react-device-detect';

const cn = classnames.bind(styles);

const CoverCropperModal = ({ cropperProps }) => (
  <Modal>
    {({ closeModal }) => (
      <Modal.Body>
        <CoverImageCropper {...cropperProps} onClose={closeModal} />
      </Modal.Body>
    )}
  </Modal>
);

const BottomMenuActions = ({ isDeletable, onDelete, onSave }) => (
  <BottomMenu className={cn('bottom-menu')} menuHeading="options">
    {isDeletable && <Button className={cn('bottom-menu--delete')} size="bg" label="Delete Photo" onClick={onDelete} />}
    <Button className={cn('bottom-menu--save')} size="bg" label="Save As Cover Photo" onClick={onSave} />
  </BottomMenu>
);

const MobileView = ({ onSelect }) => {
  const [selectedImage, setSelectedImage] = useState({});
  const dispatch = useDispatch();
  const { classroomId } = useParams();
  const { currentImage, coverImages } = useSelector(({ classrooms }) => {
    const _classroom = classrooms.classrooms.length && classrooms.classrooms.find(({ id }) => id === +classroomId);
    return {
      currentImage: get(_classroom, 'cover_image', {}),
      coverImages: classrooms.coverImages,
    };
  });
  const uploadInputRef = useRef();

  const setModal = useModalSetter();
  const { setBottomMenu, closeMenu: closeBottomMenu } = useBottomMenuSetter();

  useEffect(() => setSelectedImage(currentImage), [dispatch, currentImage]);

  useEffect(() => onSelect && onSelect(selectedImage), [dispatch, selectedImage, onSelect]);

  useEffect(() => dispatch(getCoverImages(classroomId)), []);

  const handleSaveChanges = () => closeBottomMenu();

  const handleFileChange = (imageFile, callback) => {
    if (imageFile) {
      const formData = new FormData();
      formData.append('resource[image]', imageFile);
      formData.append('resource[classroom_id]', classroomId);
      return dispatch(uploadCoverImage(formData, classroomId)).then(() => typeof callback === 'function' && callback());
    }
  };

  const handleImageInput = ({ target: { files = [] } }) => {
    const imageFile = files[0];
    if (imageFile) {
      if (imageFile.size > config.maxCoverSize)
        return toast.error('File size is too big! Maximum size is 700kB.', { autoClose: 5000 });

      const reader = new FileReader();
      reader.onload = () =>
        setModal(
          <CoverCropperModal
            cropperProps={{ aspectRatio: 21 / 9, onSave: handleFileChange, imageFile: reader.result }}
          />,
        );
      reader.readAsDataURL(imageFile);
      uploadInputRef.current.value = '';
    }
  };

  const handleDeleteImage = () =>
    !isEmpty(selectedImage) &&
    dispatch(deleteCoverImage(selectedImage.id, classroomId)).then(() => {
      setSelectedImage(currentImage);
      closeBottomMenu();
    });

  let isImageDeletable = false;
  if (!currentImage && selectedImage && selectedImage.classroom_id) {
    isImageDeletable = true;
  } else if (currentImage && currentImage.id !== selectedImage.id) {
    isImageDeletable = true;
  }

  const handleImageClick = (image) => {
    setSelectedImage((prev) => (isEqual(prev, image) ? {} : image));
  };

  useEffect(() => {
    if (!isEmpty(selectedImage) && !isEqual(selectedImage, currentImage)) {
      setBottomMenu(
        <BottomMenuActions isDeletable={isImageDeletable} onDelete={handleDeleteImage} onSave={handleSaveChanges} />,
      );
    }
  }, [selectedImage]);

  return (
    <>
      <div
        className={cn('image-upload')}
        aria-label="clickable"
        onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
      >
        <CameraIcon className={cn('image-upload--icon')} />
        <Text className="no-select" type="h6">
          Upload your own photo
        </Text>
        <input
          className={cn('image-upload--input')}
          ref={uploadInputRef}
          type="file"
          accept={config.mimeTypes.join(', ')}
          onChange={handleImageInput}
        />
      </div>
      <div className={cn('images-wrapper')}>
        {(coverImages || []).map((image) => (
          <ImageBox
            key={image.id}
            imageUrl={image.image_url}
            handleClick={() => handleImageClick(image)}
            isActive={isEqual(selectedImage, image)}
            isCurrentImage={isEqual(currentImage, image)}
          />
        ))}
      </div>
    </>
  );
};

export default memo(MobileView);
