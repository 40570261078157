import _ from 'lodash';

export const capitalizeEachWord = (sentence) => _.startCase(sentence);

export const getTitleFromUri = (urlString) => decodeURI(urlString.split(/\//).reverse()[0].replace(/\..*/, ''));

export const getAlphabeticalSort = (a, b, alphWise) =>
  alphWise ? (a || '').localeCompare(b || '') : (b || '').localeCompare(a || '');

export const obtainInitials = (name = '', range = 3) =>
  name
    .split(' ')
    .map((word, idx) => (idx < range ? word.slice(0, 1) : ''))
    .join('')
    .trim()
    .toUpperCase();

export const reduceToCommaSepStringByKey = (key, arr) =>
  arr
    .map((el) => el[key])
    .reduce(
      (acc, curr, idx) =>
        arr.length - 1 !== idx ? (idx > 0 ? `${acc}, ${curr}` : curr) : arr.length > 1 ? `${acc} and ${curr}` : curr,
      '',
    );

export const getLetterInitials = (name = '') => {
  const words = name.toUpperCase().split(' ').filter(Boolean);

  return words.length > 1 ? words[0][0] + words[1][0] : name.substring(0, 2).toUpperCase();
};

export const testForPhrase = (regexp, str) => regexp.test(str);

export const getRequiredPhrase = (regexp, str) => {
  const result = regexp.exec(str);
  if (!result) return result;

  return result[0];
};
