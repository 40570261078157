import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
// utils
import { toast } from 'react-toastify';
import { markLessonPlanCompleted } from 'store/classrooms';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import withTabSwitcherMobile from 'hoc/withTabSwitcherMobile';
import {
  eventCategory,
  eventAction,
  eventLabel,
  USER_ROLE,
  PROGRAM_NAME,
  UNIT_NUMBER,
  UNIT_TITLE,
  WEEK_NAME,
  gaRoleLabels,
  customDimensions,
} from 'constants/googleAnalytics';
import { GAEmitEvent } from 'utils/googleAnalytics';
// hooks
import { useBottomMenuSetter } from 'hooks/useBottomMenu';
// icons
import { ReactComponent as CrossIcon } from 'icons/close.svg';
import { ReactComponent as CheckIcon } from 'icons/check-grey.svg';
import { ReactComponent as DotsIcon } from 'icons/dots-grey.svg';
import { ReactComponent as ChevronIcon } from 'icons/chevron-grey.svg';
// components
import BottomMenu from 'components/BottomMenu';
import Text from 'components/Text';
// styles
import classnames from 'classnames/bind';
import styles from './MobileView.module.scss';
import { getWeekOrderNumber } from 'utils';

const cn = classnames.bind(styles);

const MobileView = () => {
  const { classroomId, unitId, weekId } = useParams();
  const {
    push,
    location: { pathname, search },
  } = useHistory();
  const dispatch = useDispatch();

  const { setBottomMenu, closeMenu } = useBottomMenuSetter();

  const { name: lessonName, id: lessonId, finished, required } = useSelector((state) => state.classrooms.lessonPlans);
  const { unit, week, classroomName } = useSelector(({ classrooms: { classrooms } }) => {
    const classroom = classrooms.find((clsrm) => clsrm.id === +classroomId);
    return {
      unit: get(classroom, `unit_progress[${unitId}]`, {}),
      week: get(classroom, `unit_progress[${unitId}].week_progress[${weekId}]`, {}),
      classroomName: get(classroom, 'name', ''),
    };
  });
  const { role } = useSelector(({ auth }) => auth.user);
  const { name: programName } = useSelector(({ program }) => program);

  const currentUnit = unit && unit.order_number;
  const currentWeek = week && week.weeks && week.weeks[0].order_number;

  const NavigationMenu = () => {
    return (
      <BottomMenu menuHeading="lesson plan options">
        <div className={cn('menu-wrapper')}>
          <div
            className={cn('menu-wrapper--subsection')}
            onClick={() => {
              dispatch(markLessonPlanCompleted(classroomId, lessonId, !finished, { uPId: unitId, wPId: weekId })).then(
                () => {
                  toast.success(finished ? 'You Unmarked this Plan Completed.' : 'Congrats! You Completed this Plan!', {
                    autoClose: 2000,
                  });
                  closeMenu();
                },
              );
              !finished &&
                required &&
                GAEmitEvent(
                  eventCategory.USER_ACTION,
                  eventAction.MENU_CLICK,
                  eventLabel.requiredActivity(lessonName, 'Professional Prep', pathname + search),
                  {
                    [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                    [customDimensions[PROGRAM_NAME]]: programName,
                    [customDimensions[UNIT_NUMBER]]: get(unit, 'units[0].order_number', null),
                    [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
                    [customDimensions[WEEK_NAME]]: get(week, `weeks[0].name`, null),
                  },
                );
            }}
          >
            {finished ? <CrossIcon /> : <CheckIcon />}
            <Text className={cn('ml-10', 'no-select')}>{finished ? 'Mark Uncompleted' : 'Mark Completed'}</Text>
          </div>
        </div>
      </BottomMenu>
    );
  };

  return (
    <div className={cn('wrapper')}>
      <ChevronIcon
        className={cn('wrapper--chevron')}
        onClick={() => push(`/classroom/${classroomId}/unit/${unitId}/week/${weekId}?tab=getting ready`)}
      />
      <div className={cn('wrapper--main')}>
        <Text className={cn('heading')} type="h5">
          {lessonName}
        </Text>
        <div>
          <Text className={cn('breadcrumbs')} type="body-xs">
            {classroomName}&nbsp;&nbsp;|&nbsp;&nbsp;{`Unit ${currentUnit}`}&nbsp;&nbsp;|&nbsp;&nbsp;
            {getWeekOrderNumber(currentWeek, false, true)}
          </Text>
        </div>
      </div>
      <DotsIcon className={cn('wrapper--dots')} onClick={() => setBottomMenu(<NavigationMenu />)} />
    </div>
  );
};

export default withTabSwitcherMobile(MobileView);
