import React from 'react';
import { useHistory } from 'react-router-dom';
// utils
import { markLessonPlanCompleted } from 'store/classrooms';
import { TITLE_OPTIONS_LABELS } from 'constants/user';
import { GRADE_TITLES } from 'constants/classrooms';
import { useSelector, useDispatch } from 'react-redux';
import { buildUrl, getWeekOrderNumber, isEdgeWeek } from 'utils';
import { get } from 'lodash';
import {
  eventCategory,
  eventAction,
  eventLabel,
  USER_ROLE,
  PROGRAM_NAME,
  UNIT_NUMBER,
  UNIT_TITLE,
  WEEK_NAME,
  gaRoleLabels,
  customDimensions,
} from 'constants/googleAnalytics';
import { GAEmitEvent } from 'utils/googleAnalytics';
// hooks
import { useTempStatuses } from 'hooks/useTempStatuses';
// components
import CheckButton from 'components/CheckButton';
import Link from 'components/Link';
import HeartBox from 'components/HeartShapedBox';
import Text from 'components/Text';
// icon
import { ReactComponent as HeartIcon } from 'icons/heart.svg';
// styles
import classnames from 'classnames/bind';
import styles from './MobileView.module.scss';

const cn = classnames.bind(styles);

const GettingReadyMobile = ({ classroom = {}, unit = {}, week: weekProg = {} }) => {
  const week = get(weekProg, 'weeks[0]', {});
  const selReview = week.sel_review;
  const { title = '', first_name = '', role } = useSelector(({ auth: { user = {} } = {} }) => user);
  const { name: programName } = useSelector(({ program }) => program);

  const {
    location: { pathname, search },
  } = useHistory();

  const dispatch = useDispatch();

  const [activityTempStatuses, setActivityTempStatuses] = useTempStatuses(weekProg.activities, [weekProg]);

  const renderActivityRow = (activity) => (
    <article className={cn('activity-row')} key={activity.id}>
      <CheckButton
        className={cn('check-button')}
        isChecked={activity.finished}
        onChange={() => {
          setActivityTempStatuses((prev) => ({ ...prev, [activity.id]: !activityTempStatuses[activity.id] }));
          dispatch(
            markLessonPlanCompleted(classroom.id, activity.id, !activity.finished, {
              uPId: unit.id,
              wPId: weekProg.id,
            }),
          );

          !activity.finished &&
            activity.required &&
            GAEmitEvent(
              eventCategory.USER_ACTION,
              eventAction.MENU_CLICK,
              eventLabel.requiredActivity(activity.name, 'Professional Prep', pathname + search),
              {
                [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                [customDimensions[PROGRAM_NAME]]: programName,
                [customDimensions[UNIT_NUMBER]]: get(unit, 'units[0].order_number', null),
                [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
                [customDimensions[WEEK_NAME]]: get(weekProg, `weeks[0].name`, null),
              },
            );
        }}
      />
      <div className={cn('hr-line')} />
      <Link
        className={cn('clickable', { completed: activityTempStatuses[activity.id] })}
        to={`/classroom/${classroom.id}/unit/${unit.id}/week/${weekProg.id}/lesson-plan/${activity.id}`}
        noHoverColor
        onClick={() =>
          GAEmitEvent(
            eventCategory.USER_ACTION,
            eventAction.CLICK,
            eventLabel.click(
              'Professional Prep',
              `${activity.name}_/classroom/${classroom.id}/unit/${unit.id}/week/${weekProg.id}/lesson-plan/${activity.id}`,
            ),
            {
              [customDimensions[USER_ROLE]]: gaRoleLabels[role],
              [customDimensions[PROGRAM_NAME]]: programName,
              [customDimensions[UNIT_NUMBER]]: get(unit, 'units[0].order_number', null),
              [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
              [customDimensions[WEEK_NAME]]: get(weekProg, `weeks[0].name`, null),
            },
          )
        }
      >
        <div className={cn({ 'clickable--sel': activity.sel_competency })}>
          <Text className={cn('clickable--name')}>{activity.name}</Text>
          {activity.sel_competency && <HeartIcon className={cn('heart-icon')} />}
        </div>
        <Text className={cn('clickable--description')}>{activity.description}</Text>
      </Link>
    </article>
  );

  return (
    <div className={cn('getting-wrapper')}>
      <div className={cn('image-wrapper')}>
        <img className={cn('week-image')} src={buildUrl(week.image_url)} aria-label="week image" alt="Week image." />
        <div className={cn('colorbar')} style={{ backgroundColor: classroom.color }} />
      </div>
      <Text className={cn('bread-crumbs')} type="body-small">{`${
        TITLE_OPTIONS_LABELS[title.toLowerCase()]
      } ${first_name}'s ${GRADE_TITLES[classroom.grade_level]} | ${classroom.name} | Unit ${unit.order_number}`}</Text>
      <Text className={cn('week-name')} type="h4" style={{ color: classroom.color }}>{`${getWeekOrderNumber(
        week.order_number,
        true,
        true,
      )} ${!isEdgeWeek(week.order_number) ? week.name : ''}`}</Text>
      <Text>{week.description}</Text>
      {week.sel_competency && (
        <HeartBox className={cn('target-sel')} targetSel={week.sel_competency} selReview={selReview} />
      )}
      <Text className={cn('plans-text')} type="h6">
        PD Resources
      </Text>
      <section className={cn('lesson-plans')}>
        {(weekProg.activities || []).map((activity) => renderActivityRow(activity))}
        <div className={cn('hr-line')} />
      </section>
    </div>
  );
};

export default GettingReadyMobile;
