import React, { useState } from 'react';
import T from 'prop-types';
// utils
import { capitalizeEachWord } from 'utils/string';
import { useTransition, animated } from 'react-spring';
// components
import Text from 'components/Text';
// styles
import classnames from 'classnames/bind';
import styles from './SideTabMenu.module.scss';

const cn = classnames.bind(styles);

const SideTabMenu = ({ menuOptions = {} }) => {
  const [currentOption, setCurrentOption] = useState(Object.keys(menuOptions)[0]);

  const handleOptionChange = (option) => setCurrentOption(option);

  const transitions = useTransition(currentOption, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0, position: 'absolute', top: -2000 },
    config: {
      duration: 200,
    },
  });

  return (
    <section className={cn('tab-menu-wrapper')}>
      <div className={cn('menu')}>
        {Object.keys(menuOptions).map((option, idx) => (
          <Text
            key={`menu-option-${idx}`}
            className={cn('menu-option', { selected: option === currentOption })}
            onClick={() => handleOptionChange(option)}
          >
            {capitalizeEachWord(option)}
          </Text>
        ))}
      </div>
      {transitions.map(({ props, item }, key) => {
        const Component = menuOptions[item];
        return (
          <animated.div key={key} style={props}>
            {Component && <Component />}
          </animated.div>
        );
      })}
    </section>
  );
};

SideTabMenu.propTypes = {
  menuOptions: T.object,
  children: T.element,
};

export default SideTabMenu;
