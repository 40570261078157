import React, { useEffect, useState } from 'react';
import { isMobileOnly, CustomView } from 'react-device-detect';
import { useDebouncedCallback } from 'use-debounce/lib';
import classnames from 'classnames/bind';
import Text from 'components/Text';
import { ReactComponent as ScreenRotationIcon } from 'icons/rotate-screen.svg';
import styles from './OrientationModal.module.scss';

const cn = classnames.bind(styles);

export const OrientationModal = ({ target = 'portrait' }) => (
  <div className={cn('wrapper')}>
    <div className={cn('modal')}>
      <ScreenRotationIcon className={cn('icon', { isPortrait: target === 'portrait' })} />
      <Text type="h7">{`To view this screen, please rotate your device to ${target}.`}</Text>
    </div>
  </div>
);

const DisplayOnMobile = ({ children }) => {
  const [initViewWidth] = useState(window.innerWidth);
  const initPort = window.innerHeight > window.innerWidth;
  const [isPort, setPort] = useState(initPort);
  const a = useDebouncedCallback((h, w) => setPort(h > w), 500);
  useEffect(() => {
    window.addEventListener('resize', () => {
      a(window.innerHeight, window.innerWidth);
    });
  }, [a]);

  return (
    <CustomView condition={isMobileOnly || initViewWidth < 768}>
      {!isPort && <OrientationModal />}
      {children}
    </CustomView>
  );
};

export default DisplayOnMobile;
