import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// utils
import { isMobile } from 'react-device-detect';
import { buildUrl } from 'utils';
// UI and styles
import { ReactComponent as CheckboxIcon } from 'icons/checkbox-green.svg';
import classnames from 'classnames/bind';
import styles from './ImageBox.module.scss';

const cn = classnames.bind(styles);

const ImageBox = ({ imageUrl, handleClick, isActive, isCurrentImage, ...props }) => {
  const [imgStyles, setImgStyles] = useState({ backgroundColor: '#999999' });
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = buildUrl(imageUrl);
    const maxSize = isMobile ? 145 : 261;

    img.onload = () => {
      let width = img.naturalWidth;
      let height = img.naturalHeight;

      if (width !== maxSize) {
        height = height / (width / maxSize);
        width = maxSize;
      }

      if (height > maxSize) {
        width = width / (height / maxSize);
        height = maxSize;
      }

      setImgStyles((prevStyles) => ({ ...prevStyles, width, height }));
    };
  }, []);

  return (
    <div
      className={cn('box-wrapper', 'box', { ['box-wrapper--active']: isActive })}
      onClick={handleClick}
      onMouseEnter={() => setShouldAnimate(isActive)}
      onMouseLeave={() => setShouldAnimate(false)}
      {...props}
    >
      {isCurrentImage && <div className={cn('current-image')}>CURRENT</div>}
      {isActive && (
        <CheckboxIcon className={cn('box-wrapper--icon')} style={{ transform: shouldAnimate ? 'scale(0)' : '' }} />
      )}
      {imgStyles.width && <img src={buildUrl(imageUrl)} style={imgStyles} aria-label="image" />}
    </div>
  );
};

ImageBox.propTypes = {
  imageUrl: T.string.isRequired,
  handleClick: T.func.isRequired,
  isActive: T.bool.isRequired,
};

export default ImageBox;
