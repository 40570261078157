import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// utils
import { getToggleView, setToggleView } from 'utils/toggleView';
import { get } from 'lodash';
import { TITLE_OPTIONS_LABELS, isProgramAdmin, USER_ROLE_LABELS } from 'constants/user';
import { GRADE_TITLES, GRADE_COLORS } from 'constants/classrooms';
import { buildUrl, getWeekOrderNumber, isEdgeWeek } from 'utils';
import { getAlphabeticalSort } from 'utils/string';
import { updateSchoolId, clearSchoolId } from 'store/school';
import { getUser } from 'store/auth';
import { useSelector, useDispatch } from 'react-redux';
import { GAEmitEvent } from 'utils/googleAnalytics';
import {
  eventCategory,
  eventAction,
  eventLabel,
  USER_ROLE,
  PROGRAM_NAME,
  UNIT_NUMBER,
  UNIT_TITLE,
  WEEK_NAME,
  gaRoleLabels,
  customDimensions,
} from 'constants/googleAnalytics';
// hooks
import { useBottomMenuSetter } from 'hooks/useBottomMenu';
// containers
import MobileSubcomponentWrapper from 'containers/MobileSubcomponentWrapper';
// components
import { ProgramAvatar } from 'pages/ProgramSwitcher/ProgramSwitcher';
import { UnitSelect } from 'pages/Classroom/BrowserView/BrowserView';
import BottomMenu from 'components/BottomMenu';
import UserAvatar from 'components/UserAvatar';
import Link from 'components/Link';
import Text from 'components/Text';
import YouAreHereBadge from 'components/Badges/YouAreHereBadge';
import AlphabeticalSort from 'components/AlphabeticalSort';
// icons
import { ReactComponent as ManageIcon } from 'icons/gear.svg';
import { ReactComponent as ImageIcon } from 'icons/image.svg';
import { ReactComponent as LaunchIcon } from 'icons/rocket.svg';
import { ReactComponent as DotsIcon } from 'icons/dots.svg';
import { ReactComponent as ListIcon } from 'icons/list.svg';
// styles
import classnames from 'classnames/bind';
import styles from './MobileView.module.scss';

const cn = classnames.bind(styles);

const DrawerMenu = ({
  classroom: {
    classroomId,
    gradeLevel,
    classroomName,
    unitNo,
    unitId,
    weekNo,
    weekId,
    completed,
    unit_progresses,
    unitName,
    weekName,
  },
  push,
  closeMenu,
  school,
  userRole,
  draftClassroom,
  programName,
}) => {
  let options = [];
  const dispatch = useDispatch();
  if (completed) {
    options = [
      {
        Icon: LaunchIcon,
        label: `Launch Unit ${unitNo || (!draftClassroom && unit_progresses[unit_progresses.length - 1].order_number)}`,
        onClick: () => {
          dispatch(updateSchoolId(school));
          push(
            `/classroom/${classroomId}/unit/${
              unitId || (!draftClassroom && unit_progresses[unit_progresses.length - 1].id)
            }`,
          );
        },
      },
      {
        Icon: LaunchIcon,
        label: `Launch ${getWeekOrderNumber(weekNo, false, true)}`,
        onClick: () => {
          GAEmitEvent(
            eventCategory.USER_ACTION,
            eventAction.MENU_CLICK,
            eventLabel.launchWeek(
              `Launch ${getWeekOrderNumber(weekNo, false, true)}`,
              location.pathname + location.search,
            ),
            {
              [customDimensions[USER_ROLE]]: gaRoleLabels[userRole],
              [customDimensions[PROGRAM_NAME]]: programName,
              [customDimensions[UNIT_NUMBER]]: unitNo,
              [customDimensions[UNIT_TITLE]]: unitName,
              [customDimensions[WEEK_NAME]]: weekName,
            },
          );
          dispatch(updateSchoolId(school));
          return push(`/classroom/${classroomId}/unit/${unitId}/week/${weekId}`);
        },
      },
      {
        Icon: ImageIcon,
        label: 'Change Cover Image',
        onClick: () => {
          dispatch(updateSchoolId(school));
          push(`/set-up-classroom/${classroomId}?cover_image_mode=true`);
        },
      },
      {
        Icon: ManageIcon,
        label: 'Manage Classroom',
        onClick: () => {
          dispatch(updateSchoolId(school));
          push(`/manage-classroom/${classroomId}`);
        },
      },
    ];
  } else {
    options = [
      {
        Icon: ManageIcon,
        label: 'Set Up Classroom',
        onClick: () => {
          dispatch(updateSchoolId(school));
          push(`/set-up-classroom/${id}`);
        },
      },
    ];
  }

  if (completed && draftClassroom) {
    options = options.slice(2);
  } else if (completed && !weekNo) {
    options = [...options.slice(0, 1), ...options.slice(2)];
  }

  const renderOption = ({ Icon, label, onClick }, idx) => (
    <div
      key={idx}
      className={cn('drawer-option')}
      onClick={() => {
        onClick();
        closeMenu();
      }}
    >
      <Icon className={cn('drawer-option--icon')} />
      <Text>{label}</Text>
    </div>
  );

  return (
    <BottomMenu menuHeading={`${GRADE_TITLES[gradeLevel]} | ${classroomName} classroom options`}>
      {options.map((option, idx) => renderOption(option, idx))}
    </BottomMenu>
  );
};

const MobileView = () => {
  const [sorted, setSorted] = useState(true);
  const [listView, setListView] = useState(getToggleView('classrooms'));
  const dispatch = useDispatch();

  const { push, location } = useHistory();
  const { setBottomMenu, closeMenu, ...rest } = useBottomMenuSetter();

  const { id: schoolId } = useSelector((state) => state.school);
  const {
    user: { title, first_name: firstName, email, school_ids, role, roles, classroom_ids, reloadNeeded },
  } = useSelector((state) => state.auth);

  const { classrooms, isLoading, hasErrors, isLoadedClassrooms } = useSelector((state) => ({
    ...state.classrooms,
    classrooms: state.classrooms.classrooms.filter(({ id, is_active }) => classroom_ids.includes(id) && is_active),
  }));
  const { avatar_url: programImageUrl, name: programName } = useSelector((state) => state.program);
  const schools = useSelector((state) =>
    state.program.schools.filter(({ id }) => school_ids.includes(id) || isProgramAdmin(role)),
  );

  const clsrmsSorted = classrooms.sort(({ name: a }, { name: b }) => getAlphabeticalSort(a, b, sorted));

  useEffect(() => {
    if (reloadNeeded) {
      dispatch(getUser());
    }
  }, [dispatch, reloadNeeded]);

  useEffect(() => {
    if (!isLoading && !hasErrors && !classrooms.length && email && !isLoadedClassrooms) {
      dispatch(getUser());
    }
  }, [dispatch, isLoading, classrooms, hasErrors, email, isLoadedClassrooms]);

  const ALL_SCHOOLS_OPTION = () => ({
    label: `All classrooms (${
      schools
        .map((school) => school.classrooms.filter(({ id, is_active }) => classroom_ids.includes(id) && is_active))
        .flat().length
    })`,
    value: {
      id: 0,
      classrooms: { length: schools.map((school) => school.classrooms).flat().length },
      avatar_url: programImageUrl,
      name: 'All Programs',
    },
  });

  const [selectedSchool, setSelectedSchool] = useState(ALL_SCHOOLS_OPTION());

  const getRoleForSchool = (schoolId) => {
    if (!role) return '';
    if (isProgramAdmin(role)) return '';

    const assocRole = roles.find(({ id, type }) => +schoolId === +id && type === 'school').role;

    return `• ${USER_ROLE_LABELS[assocRole]}`;
  };

  const schoolSelectOptions = [
    ALL_SCHOOLS_OPTION(),
    ...schools.map((school) => ({
      label: `${school.name} (${
        school.classrooms.filter(({ id, is_active }) => classroom_ids.includes(id) && is_active).length
      }) ${getRoleForSchool(school.id)}`,
      value: school,
    })),
  ];

  useEffect(() => {
    if (schoolId !== selectedSchool.value.id) {
      const school = schools && schools.find(({ id }) => id === schoolId);
      if (school) {
        return setSelectedSchool({
          value: school,
          label: `${get(school, 'name')} (${
            get(school, 'classrooms', []).filter(({ id, is_active }) => classroom_ids.includes(id) && is_active).length
          })`,
        });
      }
      if (selectedSchool.value.id !== 0) {
        setSelectedSchool(schoolSelectOptions[0]);
      }
    }
  }, [schoolId, selectedSchool, schools, setSelectedSchool, schoolSelectOptions]);

  const renderSchoolValue = ({ value, label }) => (
    <div className={cn('school-value-wrapper')}>
      <ProgramAvatar className={cn('avatar')} imageUrl={value.avatar_url} name={value.name} />
      <div>
        <Text type="h11">
          {title && `${TITLE_OPTIONS_LABELS[title.toLowerCase()]} `}
          {firstName}
        </Text>
        <Text type="h5">{label}</Text>
      </div>
    </div>
  );

  const renderSchoolOption = ({ label }) => <Text>{label}</Text>;

  const handleSchoolSelect = ({ value }) => {
    if (value.id === 0) {
      dispatch(clearSchoolId());
    } else dispatch(updateSchoolId(value));
    // dispatch(getUser());
  };

  const renderNormalCard = ({
    id,
    name,
    cover_image,
    color,
    grade_level: gradeLevel,
    current_unit,
    current_week: week,
    users,
    school,
    completed,
    unit_progresses,
  }) => {
    const draftClassroom = unit_progresses.length === 0;

    return (
      <>
        {completed ? (
          <article
            key={id}
            className={cn('normal-card-wrapper')}
            onClick={() => {
              if (draftClassroom) return;

              dispatch(updateSchoolId(school));
              push(
                `/classroom/${id}/unit/${
                  current_unit ? current_unit.id : unit_progresses[unit_progresses.length - 1].id
                }`,
              );
            }}
          >
            <div
              className={cn('dots-wrapper')}
              onClick={(e) => {
                e.stopPropagation();
                setBottomMenu(
                  <DrawerMenu
                    classroom={{
                      classroomId: id,
                      gradeLevel,
                      classroomName: name,
                      unitNo: current_unit && current_unit.order_number,
                      unitId: current_unit && current_unit.id,
                      weekNo: week && week.weeks[0].order_number,
                      unitName: get(current_unit, 'units[0].name', null),
                      weekName: get(week, 'weeks[0].name', null),
                      weekId: week && week.id,
                      completed,
                      unit_progresses,
                    }}
                    push={push}
                    location={location}
                    closeMenu={closeMenu}
                    school={school}
                    userRole={role}
                    draftClassroom={draftClassroom}
                    programName={programName}
                  />,
                );
              }}
            >
              <DotsIcon className={cn('dots-wrapper--icon')} />
            </div>
            <div className={cn('image-wrapper')} style={{ backgroundColor: GRADE_COLORS[gradeLevel] }}>
              <img
                className={cn('image-wrapper--image')}
                src={cover_image && buildUrl(cover_image.image_url)}
                aria-hidden
              />
              <Text className={cn('image-wrapper--grade')} type="body-small">
                {GRADE_TITLES[gradeLevel]}
              </Text>
              <Text className={cn('image-wrapper--name')} type="h6">
                {name}
              </Text>
            </div>
            <div className={cn('bottom-section')}>
              {draftClassroom ? (
                <div>
                  <Text type="h5">Classroom unavailable</Text>
                  <Text>An Administrator is currently editing content.</Text>
                </div>
              ) : (
                <>
                  {
                    <Text className={cn('bottom-section--unit')}>{`Unit ${
                      current_unit
                        ? current_unit.order_number
                        : unit_progresses[unit_progresses.length - 1].order_number
                    }: ${
                      current_unit
                        ? current_unit.units[0].name
                        : unit_progresses[unit_progresses.length - 1].units[0].name
                    }`}</Text>
                  }
                  {
                    <Text className={cn('bottom-section--unit-desc')} type="body-small">
                      {current_unit
                        ? current_unit.units[0].description
                        : unit_progresses[unit_progresses.length - 1].units[0].description}
                    </Text>
                  }
                  {week && (
                    <div className={cn('week-progress')}>
                      <Link
                        to={`/classroom/${id}/unit/${current_unit.id}/week/${week.id}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(updateSchoolId(school));
                        }}
                        noHoverColor
                        isPrimary
                      >
                        <div className={cn('current-week')}>
                          <YouAreHereBadge className={cn('current-week--badge')} />
                          <Text className={cn('current-week--name')}>{`${getWeekOrderNumber(
                            week.weeks[0].order_number,
                            false,
                            true,
                          )} ${!isEdgeWeek(week.weeks[0].order_number) ? week.weeks[0].name : ''}`}</Text>
                        </div>
                      </Link>
                    </div>
                  )}
                  <div className={cn('users-wrapper')}>
                    {(users || []).length > 0 &&
                      users.map(({ id, avatar_url: imageUrl }, idx) => (
                        <div key={id} className={cn('users-wrapper--user')} style={{ right: `${idx * 8}px` }}>
                          <UserAvatar url={imageUrl} size="small" zIndex={(users || []).length - idx} isBordered />
                        </div>
                      ))}
                  </div>
                </>
              )}
            </div>
          </article>
        ) : (
          <div
            key={`placeholder-classroom-${id}`}
            style={{ backgroundColor: GRADE_COLORS[gradeLevel] }}
            className={cn('card', 'placeholder-classroom')}
          >
            <div className={cn('text-wrapper')} style={{ color: !gradeLevel && '#535759' }}>
              <Text>{GRADE_TITLES[gradeLevel]}</Text>
              <Text bold type="h3">
                {name}
              </Text>
              <Text>{school.name}</Text>
            </div>
            <div
              aria-hidden
              onClick={() => {
                dispatch(updateSchoolId(school));
                push(`/set-up-classroom/${id}`);
              }}
              className={cn('set-up-classroom')}
            >
              Set Up Classroom
            </div>
          </div>
        )}
      </>
    );
  };

  const renderListCard = (
    {
      id,
      name,
      cover_image,
      grade_level: gradeLevel,
      current_unit,
      current_week: week,
      school,
      completed,
      unit_progresses,
    },
    idx,
  ) => {
    const draftClassroom = unit_progresses.length === 0;

    return (
      <article
        key={id}
        className={cn('list-card-wrapper')}
        style={{ animationDelay: `${idx * 0.02}s` }}
        onClick={() => {
          if (!completed || draftClassroom) return;
          dispatch(updateSchoolId(school));
          push(`/classroom/${id}/unit/${current_unit.id}`);
        }}
      >
        <div className={cn('image-wrapper')} style={{ backgroundColor: GRADE_COLORS[gradeLevel] }}>
          <img
            className={cn('image-wrapper--image')}
            src={cover_image && buildUrl(cover_image.image_url)}
            aria-hidden
          />
        </div>
        <div className={cn('texts-wrapper')}>
          <Text type="body-small">{GRADE_TITLES[gradeLevel]}</Text>
          <Text className={cn('texts-wrapper--classroom')} type="h6">
            {name}
          </Text>
          {week && (
            <Text className={cn('texts-wrapper--week')}>
              {completed &&
                !draftClassroom &&
                `${getWeekOrderNumber(week.weeks[0].order_number, false, true)} ${
                  !isEdgeWeek(week.weeks[0].order_number) ? week.weeks[0].name : ''
                }`}
            </Text>
          )}
          {draftClassroom && <Text className={cn('texts-wrapper--week')}>Classroom unavailable</Text>}
        </div>
        <div
          className={cn('dots-wrapper')}
          onClick={(e) => {
            e.stopPropagation();
            setBottomMenu(
              completed ? (
                <DrawerMenu
                  classroom={{
                    classroomId: id,
                    gradeLevel,
                    classroomName: name,
                    unitNo: current_unit && current_unit.order_number,
                    unitId: current_unit && current_unit.id,
                    weekNo: week && week.weeks[0].order_number,
                    weekId: week && week.id,
                    unitName: get(current_unit, 'units[0].name', null),
                    weekName: get(week, 'weeks[0].name', null),
                    completed,
                    unit_progresses,
                  }}
                  push={push}
                  closeMenu={closeMenu}
                  school={school}
                  draftClassroom={draftClassroom}
                  programName={programName}
                />
              ) : (
                <DrawerMenu
                  classroom={{
                    classroomId: id,
                    gradeLevel,
                    classroomName: name,
                    completed,
                    unit_progresses,
                    unitName: get(current_unit, 'units[0].name', null),
                    weekName: get(week, 'weeks[0].name', null),
                  }}
                  push={push}
                  closeMenu={closeMenu}
                  school
                  draftClassroom={draftClassroom}
                  programName={programName}
                />
              ),
            );
          }}
        >
          <DotsIcon className={cn('dots-wrapper--icon')} />
        </div>
      </article>
    );
  };

  return (
    <MobileSubcomponentWrapper headingTitle="my classrooms" withReturn={false}>
      <UnitSelect
        onSelect={handleSchoolSelect}
        className={cn('school-select', 'mb-20')}
        renderValue={renderSchoolValue}
        renderOption={renderSchoolOption}
        value={selectedSchool}
        options={schoolSelectOptions}
      />
      {clsrmsSorted.length > 0 && (
        <>
          <AlphabeticalSort
            className={cn('sort')}
            label="classroom name"
            sorted={sorted}
            onClick={() => setSorted((prev) => !prev)}
          />
          <ListIcon
            className={cn('sort-icon')}
            onClick={() => {
              setListView((prev) => {
                setToggleView('classrooms', !prev);
                return !prev;
              });
              GAEmitEvent(eventCategory.USER_ACTION, eventAction.MENU_CLICK, eventLabel.view(!listView), {
                [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                [customDimensions[PROGRAM_NAME]]: programName,
              });
            }}
          />
        </>
      )}
      <section className={cn('main-wrapper')}>
        {(clsrmsSorted || []).map((classroom, idx) =>
          listView ? renderListCard(classroom, idx) : renderNormalCard(classroom),
        )}
      </section>
    </MobileSubcomponentWrapper>
  );
};

export default MobileView;
