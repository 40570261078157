import { DateTime } from 'luxon';

export const formatDate = (date) => DateTime.fromFormat(date, 'MM-dd-yyyy').toFormat('MMM dd');

export const getWeekTimeBounds = (week) => {
  return {};
  if (!week) return {};
  const start = DateTime.fromFormat(week.start_date, 'MM-dd-yyyy').startOf('day');
  const now = DateTime.local().startOf('day');
  const end = DateTime.fromFormat(week.end_date, 'MM-dd-yyyy').startOf('day');

  return {
    isPast: now > end,
    isCurrent: start <= now && now <= end,
    isFuture: start > now,
  };
};
