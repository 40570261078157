import React, { useEffect } from 'react';
import T from 'prop-types';
// utils
import { useSelector } from 'react-redux';
import { isSchoolAdmin } from 'constants/user';
// containers
import DisplayOnDesktop from 'containers/DisplayOnDesktop';
import DisplayOnMobile from 'containers/DisplayOnMobile';
// components
import BrowserView from './BrowserView';
import { MainTab, People, Info } from './BrowserView/BrowserView';
import MobileView from './MobileView';
import { MainTabMobile, PeopleMobile, InfoMobile } from './MobileView/MobileView';
import { useHistory } from 'react-router-dom';

const AdminPanel = ({ mode }) => {
  const { role } = useSelector(({ auth }) => auth.user);
  const schoolId = useSelector(({ school }) => school.id);
  const { push } = useHistory();
  let BROWSER_TAB_OPTIONS = [
    { title: mode === 'program' ? 'schools' : 'classrooms', component: MainTab },
    { title: 'people', component: People },
    { title: mode === 'program' ? 'program info' : 'school info', component: Info },
  ];

  let MOBILE_TAB_OPTIONS = [
    { title: mode === 'program' ? 'schools' : 'classrooms', component: MainTabMobile },
    { title: 'people', component: PeopleMobile },
    { title: mode === 'program' ? 'program info' : 'school info', component: InfoMobile },
  ];

  if (isSchoolAdmin(role) && mode === 'program') {
    BROWSER_TAB_OPTIONS = [BROWSER_TAB_OPTIONS[0]];
    MOBILE_TAB_OPTIONS = [MOBILE_TAB_OPTIONS[0]];
  }
  useEffect(() => {
    if (mode !== 'program' && !schoolId) {
      push('/program-admin');
    }
  }, [push, mode, schoolId]);

  return (
    <>
      <DisplayOnDesktop>
        <BrowserView tabOptions={BROWSER_TAB_OPTIONS} isProgramPanel={mode === 'program'} />
      </DisplayOnDesktop>
      <DisplayOnMobile>
        <MobileView tabOptions={MOBILE_TAB_OPTIONS} isProgramPanel={mode === 'program'} />
      </DisplayOnMobile>
    </>
  );
};

AdminPanel.propTypes = {
  mode: T.string.isRequired,
};

export default AdminPanel;
