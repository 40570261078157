export const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#ababab',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '8px',
    marginTop: '0',
    boxShadow: '0 1px 12px 0 #ababab',
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};
