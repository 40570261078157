import React from 'react';
import T from 'prop-types';
// components
import { animated, config, useTransition } from 'react-spring';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './Modal.module.scss';

const cn = classnames.bind(styles);

const ModalAnimatedWrapper = ({ children }) => {
  const transition = useTransition(children || [], null, {
    from: { opacity: 0, transform: 'translateY(-20px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: -2, transform: 'translateY(-20px)', pointerEvents: 'none' },
    config: (_, state) => (state === 'leave' ? config.default : { duration: 300 }),
  });

  return (
    <>
      {transition.map(({ item, key, props: { transform, opacity, pointerEvents } }) => (
        <animated.div className={cn('modal-wrapper')} key={key} style={{ opacity, pointerEvents }}>
          <animated.div className={cn('animation-wrapper')} style={{ transform }}>
            {item}
          </animated.div>
        </animated.div>
      ))}
    </>
  );
};

ModalAnimatedWrapper.propTypes = {
  children: T.any,
};

export default ModalAnimatedWrapper;
