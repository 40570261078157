import makeRequest from 'utils/api';
import { cachedProgramId, cachedSchoolId, clearAuthData } from 'utils/auth';
import { toast } from 'react-toastify';
import { GAEmitEvent } from 'utils/googleAnalytics';
import {
  eventAction,
  eventCategory,
  eventLabel,
  customDimensions,
  USER_ROLE,
  PROGRAM_NAME,
  gaRoleLabels,
} from 'constants/googleAnalytics';
import { get } from 'lodash';
import { resetStore, RESET_STORE } from './common';
import { classroomsLoaded, UPDATE_CLASSROOM_LOADED } from './classrooms';
import { programLoaded } from './program';
import { hasAdminRole, hasTeacherRole } from 'constants/user';
// Actions
export const LOG_IN_LOADING = 'czi/LOG_IN/LOADING';
export const LOG_IN_LOADED = 'czi/LOG_IN/LOG_IN/LOADED';
export const LOG_IN_FAILED = 'czi/LOG_IN/FAILED';
export const USER_DATA_UPDATE_LOADING = 'czi/USER/DATA/UPDATE/LOADING';
export const USER_DATA_UPDATE_LOADED = 'czi/USER_DATA_UPDATE/LOADED';
export const LOG_OUT = 'czi/LOGOUT';
export const SIGN_UP_LOADING = 'czi/SIGN_UP/LOADING';
export const SIGN_UP_LOADED = 'czi/SIGN_UP/LOG_IN/LOADED';
export const SIGN_UP_FAILED = 'czi/SIGN_UP/FAILED';
export const USER_PROFILE_INFO_LOADED = 'czi/USER_PROFILE_INFO/LOADED';
export const USER_UPDATE_CLASSROOMS_LOADED = 'czi/USER_UPDATE_CLASSROOMS/LOADED';
export const FIRST_EVER_LOGIN_CLEAR = 'czi/FIRST_EVER_LOAD_CLEAR';
export const UPDATE_CURRENT_ROLE = 'czi/UPDATE_CURRENT_ROLE';
export const REVOKE_ADMIN_ROLE_PROFILE_LOADED = 'czi/REVOKE_ADMIN_ROLE_PROFILE/LOADED';

// Action creators
export const logInLoading = () => ({ type: LOG_IN_LOADING });
export const logInLoaded = (payload) => ({ type: LOG_IN_LOADED, payload });
export const logInFailed = () => ({ type: LOG_IN_FAILED });
export const updateUserDataLoading = (payload) => ({ type: USER_DATA_UPDATE_LOADING, payload });
export const updateUserDataLoaded = (payload) => ({ type: USER_DATA_UPDATE_LOADED, payload });

export const userLogout = () => ({ type: LOG_OUT });

export const signUpLoading = () => ({ type: SIGN_UP_LOADING });
export const signUpLoaded = (payload) => ({ type: SIGN_UP_LOADED, payload });
export const signUpFailed = () => ({ type: SIGN_UP_FAILED });

export const userProfileInfoLoaded = (payload) => ({ type: USER_PROFILE_INFO_LOADED, payload });

export const userUpdateClassroomsLoaded = (payload) => ({ type: USER_UPDATE_CLASSROOMS_LOADED, payload });

export const firstEverLoginCLEAR = () => ({ type: FIRST_EVER_LOGIN_CLEAR });

export const updateCurrentRole = (payload) => ({ type: UPDATE_CURRENT_ROLE, payload });

export const revokeAdminRoleLoaded = (payload) => ({ type: REVOKE_ADMIN_ROLE_PROFILE_LOADED, payload });

// map
const mapUserProfileInfo = (data) => ({
  ...data,
  full_name: data.last_name ? `${data.first_name} ${data.last_name}` : null,
});

export const mapCurrentUserClassrooms = (userPayload) => {
  const progId = cachedProgramId();
  const schoolId = cachedSchoolId();
  const program = get(userPayload, `programs`, []).find(({ id }) => id === progId);
  const schools = get(program, 'schools', []);
  const school = schools.find(({ id }) => id === schoolId);
  const classrooms = school ? [school.classrooms || []] : schools.map(({ classrooms: c }) => c);
  return { classrooms: classrooms.flat(), schools };
};

const mapCurrentUserProgram = (userPayload) => {
  const progId = cachedProgramId();
  if (progId) return get(userPayload, `programs`, []).find(({ id }) => id === progId) || {};
  return {};
};

// reducer
export const initialState = {
  isLoading: false,
  userDataLoading: false,
  hasErrors: false,
  user: {
    id: undefined,
    first_name: '',
    last_name: '',
    email: '',
    role: '',
    roles: [],
    school_id: undefined,
    avatar_url: null,
    programs: [],
    classroom_ids: [],
    firstEverLogin: localStorage.getItem('firstEverLogin') || false,
    readyToBeSigned: false,
    reloadNeeded: false,
  },
  userProfileInfo: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FIRST_EVER_LOGIN_CLEAR: {
      localStorage.removeItem('firstEverLogin');
      return { ...state, user: { ...state.user, firstEverLogin: false } };
    }
    case LOG_IN_FAILED:
    case SIGN_UP_FAILED:
      return { ...state, isLoading: false, hasErrors: true };
    case LOG_IN_LOADING:
      return { ...state, isLoading: true };
    case SIGN_UP_LOADING:
      return { ...state, hasErrors: true };
    case LOG_IN_LOADED:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
          school_license_active: true,
          readyToBeSigned: true,
          reloadNeeded: false,
        },
        isLoading: false,
        hasErrors: false,
      };
    case SIGN_UP_LOADED:
      // TODO: remove school licence as we got other indicator
      return {
        ...state,
        user: { ...action.payload, school_license_active: true, reloadNeeded: false },
        isLoading: false,
        hasErrors: false,
      };
    case USER_DATA_UPDATE_LOADING:
      return { ...state, userDataLoading: action.payload };
    case USER_DATA_UPDATE_LOADED:
      return { ...state, user: { ...state.user, ...action.payload } };
    case USER_PROFILE_INFO_LOADED:
    case USER_UPDATE_CLASSROOMS_LOADED:
    case REVOKE_ADMIN_ROLE_PROFILE_LOADED:
      return { ...state, userProfileInfo: mapUserProfileInfo(action.payload) };
    case UPDATE_CLASSROOM_LOADED:
      return { ...state, user: { ...state.user, reloadNeeded: true } };
    case UPDATE_CURRENT_ROLE:
      return { ...state, user: { ...state.user, role: action.payload } };
    case LOG_OUT:
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;

// Side effects

export const signIn = (data, isRemembered) => (dispatch) => {
  // dispatch(resetStore());
  dispatch(logInLoading());
  return makeRequest('/users/sign_in', { method: 'post', data, isRemembered })
    .then(({ resource }) => {
      dispatch(programLoaded(mapCurrentUserProgram(resource)));
      return { resource };
    })
    .then(({ resource }) => {
      dispatch(classroomsLoaded(mapCurrentUserClassrooms(resource)));
      return { resource };
    })
    .then(({ resource }) => {
      const mostRelevantRole = resource.roles && resource.roles[0] && resource.roles[0].role;
      const firstProgram = resource.programs && resource.programs[0] && resource.programs[0].name;
      GAEmitEvent(eventCategory.USER_ACTION, eventAction.SIGN_IN, eventLabel.signIn(true), {
        [customDimensions[USER_ROLE]]: gaRoleLabels[mostRelevantRole],
        [customDimensions[PROGRAM_NAME]]: firstProgram,
      });
      dispatch(logInLoaded({ ...resource, firstEverLogin: localStorage.getItem('firstEverLogin') || false }));
    })
    .catch((err) => {
      GAEmitEvent(eventCategory.USER_ACTION, eventAction.SIGN_IN, eventLabel.signIn(false));
      dispatch(logInFailed());
    });
};

export const logoutUser = () => (dispatch) => {
  dispatch(userLogout());
  dispatch(resetStore());
  clearAuthData();
};

export const resetPassword = (email, instanceIds) => () =>
  makeRequest('/users/password', { method: 'post', data: { email, ...instanceIds } })
    .then(() => {
      toast.success(`If your email is correct and your account is active you'll receive the password reset email`, {
        autoClose: 3000,
      });
    })
    .catch(() => {});

export const getUser = () => (dispatch) => {
  // dispatch(resetStore());
  dispatch(logInLoading());
  return makeRequest(`/users/current_user_info`)
    .then((res = {}) => {
      const { resource } = res;
      if (resource) {
        dispatch(programLoaded(mapCurrentUserProgram(resource)));
        return resource;
      }
      return null;
    })
    .then((resource) => {
      if (resource) {
        dispatch(classroomsLoaded(mapCurrentUserClassrooms(resource)));
      }
      return resource;
    })
    .then((resource) => {
      if (resource) {
        dispatch(logInLoaded({ ...resource, firstEverLogin: localStorage.getItem('firstEverLogin') || false }));
      }
      return Promise.resolve(resource);
    })
    .catch((err) => {
      dispatch(logInFailed());
    });
};

export const signUp = (user) => (dispatch) => {
  dispatch(signUpLoading());
  return makeRequest('/users/invitation', { method: 'put', data: { user } })
    .then(({ resource }) => {
      localStorage.setItem('firstEverLogin', 'true');
      if (hasAdminRole(resource.roles)) {
        localStorage.setItem('uncompletedRegistration', 'true');
      }
      dispatch(signUpLoaded({ ...resource, firstEverLogin: true }));
    })
    .catch(() => dispatch(signUpFailed()));
};

export const updateUserData = ({
  current_password: curPass = '',
  password,
  password_confirmation: passConfirm,
  ...data
}) => (dispatch) => {
  dispatch(updateUserDataLoading(true));
  return makeRequest(`/users/${data.id}`, {
    method: 'put',
    data: {
      resource: {
        ...data,
        ...(curPass.length ? { current_password: curPass, password, password_confirmation: passConfirm } : []),
      },
    },
  })
    .then(({ resource }) => {
      dispatch(updateUserDataLoading(false));
      dispatch(updateUserDataLoaded(resource));
      toast.success('Congrats! You did a thing!');
    })
    .catch((err) => {
      dispatch(updateUserDataLoading(false));
    });
};

export const updateUserAvatar = ({ formData, userId }) => (dispatch) =>
  makeRequest(`/users/${userId}`, { method: 'put', data: formData })
    .then(({ resource }) => {
      dispatch(logInLoaded(resource));
      return Promise.resolve();
    })
    .catch((err) => {});

export const createPassword = (data, token, push) => (dispatch) =>
  makeRequest('/users/password', { method: 'put', data: { ...data, reset_password_token: token } })
    .then(() => toast.success(`You've changed your password!`))
    .then(() => {
      setTimeout(() => push('/login'), 1000);
    })
    .catch(() => {});

export const getUserProfileInfo = ({ userId, programId, schoolId }) => (dispatch) =>
  makeRequest(`/programs/${programId}/schools/${schoolId}/users/${userId}`)
    .then(({ resource }) => dispatch(userProfileInfoLoaded(resource)))
    .catch(() => {});

export const updateUserClassrooms = ({ userId, classroomIds, programId, schoolId }) => (dispatch) =>
  makeRequest(`/users/${userId}`, {
    method: 'PATCH',
    data: {
      resource: {
        classroom_ids: classroomIds,
      },
      program_id: programId,
      school_id: schoolId,
    },
  })
    .then(({ resource }) => dispatch(userUpdateClassroomsLoaded(resource)))
    .then(() => dispatch(getUser()))
    .catch(() => {});
