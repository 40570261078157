import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';

const authHeaders = ['id', 'uid', 'access-token', 'client', 'expiry'];
const instanceHeaders = ['program-id', 'school-id'];

const removeLocalStorageItems = (items = []) => items.forEach((item) => localStorage.removeItem(item));

export const setAuthData = (data, isInfinite = true) => {
  const authData =
    data && authHeaders.reduce((acc, key) => ({ ...acc, ...(data[key] ? { [key]: data[key] } : []) }), {});
  if (authData) Cookies.set('auth', JSON.stringify(authData), { expires: isInfinite && 100 });
};

export const getAuthData = () => {
  const data = Cookies.get('auth');
  return data && JSON.parse(data);
};

export const clearAuthData = () => {
  // eslint-disable-next-line no-console
  console.warn('%c Cleared auth data', 'color: blue');
  Cookies.remove('auth');
  Cookies.remove('instanceIds');
  // specify items to remove from local storage
  removeLocalStorageItems(['toggleView']);
};

export const getInstanceData = () => {
  const ids = Cookies.get('instanceIds');
  return ids ? JSON.parse(ids) : {};
};

export const setInstanceIds = (data) =>
  Object.keys(data).some((el) => instanceHeaders.includes(el)) &&
  Cookies.set(
    'instanceIds',
    JSON.stringify(
      instanceHeaders.reduce((acc, key) => ({ ...acc, ...(data[key] ? { [key]: data[key] } : {}) }), getInstanceData()),
    ),
  );

export const cachedProgramId = () => !isEmpty(getInstanceData()) && +getInstanceData()['program-id'];
export const cachedSchoolId = () => !isEmpty(getInstanceData()) && +getInstanceData()['school-id'];
