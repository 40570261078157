export const getTeachersIds = (teachers) => (teachers || []).map((teacher) => teacher.id);

export const sortTeachers = (teachers, userId) =>
  (teachers || []).reduce((arr, curr) => (+curr.id === +userId ? [curr, ...arr] : [...arr, curr]), []);

export const MODAL_LANGUAGE = {
  ['grade-level']: {
    headerText: 'Change Grade Level?',
    bodyText: `Changing grade level of the classroom is only possible for ones that have no progress (completed items) logged. If you're sure you need to change the grade level after you started the classroom, please contact your program administrator.`,
    buttonText: 'Change Grade Level',
  },
  ['leave-classroom']: {
    headerText: 'Leave Classroom Warning',
    bodyText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.  Aliquam erat volutpat. Donec placerat nisl magna, et faucibus arcu condimentum sed. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.  Aliquam erat volutpat. Donec placerat nisl magna, et faucibus arcu condimentum sed.',
    buttonText: 'Leave Classroom',
  },
  ['remove-teacher']: {
    headerText: 'Remove Co-Teacher Warning',
    bodyText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.  Aliquam erat volutpat. Donec placerat nisl magna, et faucibus arcu condimentum sed. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.  Aliquam erat volutpat. Donec placerat nisl magna, et faucibus arcu condimentum sed.',
    buttonText: 'Remove Co-Teacher',
  },
};
