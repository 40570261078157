/**
 * Current view (list/grid) memoization, via localStorage,
 * for pages such as My Classrooms
 */

export const getToggleView = (key = null) => {
  const viewObj = JSON.parse(localStorage.getItem('toggleView'));
  if (!viewObj) {
    return false;
  }
  if (key) {
    return viewObj[key] || false;
  }
  return viewObj;
};

export const setToggleView = (key, value) =>
  localStorage.setItem('toggleView', JSON.stringify({ ...getToggleView(), [key]: value }));
