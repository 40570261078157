import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames/bind';
// components
import Portal from 'components/Portal';
// style
import Text from 'components/Text';
import styles from './Tooltip.module.scss';
import { animated, useSpring } from 'react-spring';

const SPACE_TOP = 12;
const cn = classnames.bind(styles);

const Tooltip = ({ children, text, width = 140, className, isHidden }) => {
  const triggerRef = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [style, setStyle] = useState(null);
  const transition = useSpring({ opacity: isVisible ? 1 : 0 });

  const showTooltip = () => {
    const dimensions = triggerRef.current.getBoundingClientRect();
    const newStyle = {
      width,
      left: dimensions.left + dimensions.width / 2 - width / 2,
      top: dimensions.top + dimensions.height + SPACE_TOP,
    };

    setIsVisible(true);
    setStyle(newStyle);
  };

  const hideTooltip = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', hideTooltip);

    return () => {
      window.removeEventListener('scroll', hideTooltip);
    };
  }, []);

  return (
    <span onMouseOver={showTooltip} onMouseOut={hideTooltip} className={cn('tooltip-trigger', className)} ref={triggerRef}>
      {children}
      {isVisible && !isHidden && (
        <Portal>
          <animated.div className={cn('tooltip-body-wrapper')} style={{ ...style, ...transition }}>
            <div className={cn('tooltip-body')}>
              <Text type="h11">{text}</Text>
            </div>
          </animated.div>
        </Portal>
      )}
    </span>
  );
};

export default Tooltip;
