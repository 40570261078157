import React from 'react';
// components
import DayPicker from 'components/DayPicker';
import Modal from 'components/Modal';
// styles
import classnames from 'classnames/bind';
import styles from './DayPickerModal.module.scss';

const cn = classnames.bind(styles);

const DayPickerModal = ({ className, onAccept, currentDate = null }) => {
  return (
    <Modal>
      {({ closeModal }) => (
        <Modal.Body>
          <DayPicker
            className={cn(className)}
            handleCancelClick={closeModal}
            handleAcceptClick={(selectedDays) => {
              onAccept(selectedDays);
              closeModal();
            }}
            currentDate={currentDate}
          />
        </Modal.Body>
      )}
    </Modal>
  );
};

export default DayPickerModal;
