import React, { useState, useEffect } from 'react';
// utils
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
// components
import Text from 'components/Text';
// icons
import { ReactComponent as CloseIcon } from 'icons/close.svg';
// styles
import classnames from 'classnames/bind';
import styles from './LicenseValidator.module.scss';

const cn = classnames.bind(styles);

const LicenseValidator = () => {
  const { id: programId } = useSelector(({ program }) => program);
  const { email } = useSelector(({ auth }) => auth.user);
  const licenseActive = useSelector(({ auth: { user = {} } = {} }) => {
    const currProgram = user.programs.find(({ id }) => id === programId) || {};
    const licenseActive = !!(currProgram.licenses || []).find(({ active }) => !!active);

    if (isEmpty(currProgram)) {
      return true;
    }

    return licenseActive;
  });

  const [hasActiveLicense, set] = useState(licenseActive);

  useEffect(() => set(licenseActive), [programId]);

  return (
    <>
      {!hasActiveLicense && email && (
        <div className={cn('expired-banner')}>
          <Text>{`Your account for this curriculum program is no longer active. If this information is not correct or if you would like to explore reactivating your account, please email us at SELF@sesame.org`}</Text>
          <CloseIcon className={cn('icon')} onClick={() => set(true)} />
        </div>
      )}
    </>
  );
};

export default LicenseValidator;
