import React, { useState, useRef } from 'react';
import T from 'prop-types';
// utils
import { capitalizeEachWord } from 'utils/string';
// components
import Text from 'components/Text';
// icons
import { ReactComponent as SearchIcon } from 'icons/search-thick.svg';
import { ReactComponent as ArrowIcon } from 'icons/Arrows/LeftBlack.svg';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
// styles
import classnames from 'classnames/bind';
import styles from './AlphabeticalSort.module.scss';

const cn = classnames.bind(styles);

const AlphabeticalSort = ({
  label,
  sorted = false,
  onClick,
  className,
  withSearch = false,
  onSearchChange,
  ...props
}) => {
  const [isSearching, set] = useState(false);
  const inputRef = useRef();

  return (
    <div className={cn('outer-wrapper', className, { searching: isSearching })}>
      <div className={cn('sort-wrapper', { searching: isSearching })} onClick={onClick} {...props}>
        <Text>{capitalizeEachWord(label)}</Text>
        <ArrowIcon className={cn('sort-wrapper--arrow', { ['sort-wrapper--arrow--sorted']: sorted })} />
      </div>
      {withSearch && (
        <>
          <SearchIcon
            className={cn('search-loop', { hidden: isSearching })}
            onClick={() => {
              set(true);
              inputRef.current && inputRef.current.focus();
            }}
          />
          <input
            className={cn('search-input', { active: isSearching })}
            type="text"
            ref={inputRef}
            onChange={(e) => onSearchChange(e.target.value)}
            autoComplete="off"
          />
          {isSearching && (
            <CloseIcon
              className={cn('close-icon')}
              onClick={() => {
                set(false);
                if (inputRef.current) inputRef.current.value = '';
                onSearchChange('');
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

AlphabeticalSort.propTypes = {
  label: T.string.isRequired,
  sorted: T.bool.isRequired,
  onClick: T.func.isRequired,
  className: T.string,
};

export default AlphabeticalSort;
