import React from 'react';
import T from 'prop-types';
// utils
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { buildUrl, removePrintableDuplicates } from 'utils';
import { getTitleFromUri } from 'utils/string';
import {
  eventCategory,
  eventAction,
  eventLabel,
  USER_ROLE,
  PROGRAM_NAME,
  UNIT_NUMBER,
  UNIT_TITLE,
  WEEK_NAME,
  customDimensions,
  gaRoleLabels,
} from 'constants/googleAnalytics';
import { GAEmitEvent } from 'utils/googleAnalytics';
// components
import Text from 'components/Text';
import PrintableBox from 'components/PrintableBox';
// styles
import classnames from 'classnames/bind';
import styles from './Printables.module.scss';

const cn = classnames.bind(styles);

const Printables = ({ week: { printable_urls = [], weeks = [] } = {}, unit: { order_number, units = [] } = {} }) => {
  const { role } = useSelector(({ auth }) => auth.user);
  const { name: programName } = useSelector(({ program }) => program);

  const handlePrintableClick = (uri) => {
    const customDims = {
      [customDimensions[USER_ROLE]]: gaRoleLabels[role],
      [customDimensions[PROGRAM_NAME]]: programName,
      [customDimensions[UNIT_NUMBER]]: order_number,
      [customDimensions[UNIT_TITLE]]: !!units[0] && units[0].name,
      [customDimensions[WEEK_NAME]]: !!weeks[0] && weeks[0].name,
    };
    GAEmitEvent(eventCategory.PDF, eventAction.DOWNLOAD, eventLabel.printables(getTitleFromUri(uri)), customDims);
    GAEmitEvent(
      eventCategory.USER_ACTION,
      eventAction.CLICK,
      eventLabel.click('Printables', getTitleFromUri(uri)),
      customDims,
    );

    window.open(buildUrl(uri), '_blank');
    return;
  };

  return (
    <div className={cn('wrapper', 'pv-30', 'ph-60')}>
      {(printable_urls || []).length ? (
        removePrintableDuplicates(printable_urls).map(({ attachment_url, attachment_size }, i) => (
          <PrintableBox
            key={i}
            className={cn('printable-box')}
            handleClick={() => handlePrintableClick(attachment_url)}
            title={getTitleFromUri(attachment_url)}
            fileSize={attachment_size}
          />
        ))
      ) : (
        <div className={cn('no-printables')}>
          <Text type={isMobile ? 'h5' : 'h3'}>There are no Printables for this Week</Text>
        </div>
      )}
    </div>
  );
};

Printables.propTypes = {
  printable_urls: T.arrayOf(T.string),
};

export default Printables;
