import React from 'react';
// icons
import { ReactComponent as SchoolAdminBadge } from 'icons/star.svg';
import { ReactComponent as ProgramAdminBadge } from 'icons/admin-badge1.svg';
// styles
import classnames from 'classnames/bind';
import styles from './Badges.module.scss';

const cn = classnames.bind(styles);

const AdminBadge = ({ role = null, long = false, schoolAdmin = false, className }) => {
  const Icon = schoolAdmin ? SchoolAdminBadge : ProgramAdminBadge;

  return (
    <>
      {long ? (
        <div className={cn('long-admin-badge', className)}>
          <div className={cn('badge-wrap')}>
            <Icon className={cn('icon', { 'school-admin': schoolAdmin })} />
          </div>
          <div className={cn('text-wrap')}>{role}</div>
        </div>
      ) : (
        <div className={cn('short-admin-badge', className)}>
          <Icon className={cn('icon')} />
        </div>
      )}
    </>
  );
};

export default AdminBadge;
