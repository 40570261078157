import React, { useRef, useState } from 'react';
import classnames from 'classnames/bind';
import T from 'prop-types';
// hooks
import { useModalSetter } from 'hooks/useModal';
import { useHistory, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import useOnClickOutside from 'hooks/useClickOutside';
// validation
import * as yup from 'yup';
// utils
import { isMobile } from 'react-device-detect';
import queryString from 'query-string';
// components
import DayPickerModal from 'components/DayPickerModal';
import SelectBox from 'components/SelectBox';
import Input from 'components/Input/Input';
import Button from 'components/Button';
import PasswordInput from 'components/Input/PasswordInput';
import Text from 'components/Text';
import DayPicker from 'components/DayPicker';
// constants
import { TITLE_OPTIONS } from 'constants/user';
// styles
import { GRADE_COLORS, GRADE_TITLES_OPTIONS } from 'constants/classrooms';
import styles from './Registration.module.scss';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import CheckBox from 'components/CheckBox/CheckBox';

const cn = classnames.bind(styles);

const step1schema = yup.object().shape({
  title: yup.string().required('Title is required'),
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  password: yup
    .string()
    .matches(/^.{6,}$/, 'Password must be at least 6 characters long') // move to const
    .required('Password is required'),
  password_confirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
  email: yup.string().email(),
});

const Step1 = ({ onSubmit }) => {
  const location = useLocation();
  const { email, token } = queryString.parse(location.search);

  const { register, handleSubmit, errors, ...restForm } = useForm({
    resolver: yupResolver(step1schema),
  });

  const handleFormSubmit = (data) => {
    onSubmit({ ...data, invitation_token: token });
  };

  return (
    <form className={cn('form')} onSubmit={handleSubmit(handleFormSubmit)}>
      <Text type="h6">Step 1/2</Text>
      <Text type="h3" className="mb-25">
        Complete Registration
      </Text>
      <SelectBox
        errors={errors}
        name="title"
        label="Title"
        className={cn('title-select')}
        hookFormProps={{ register, errors, ...restForm }}
        options={TITLE_OPTIONS}
      />
      <Input
        errors={errors}
        name="first_name"
        label="First name"
        inputRef={register({ required: true })}
        placeholder="Your first name"
      />
      <Input
        errors={errors}
        name="last_name"
        label="Last name"
        inputRef={register({ required: true })}
        placeholder="Your last name"
      />
      <Input
        errors={errors}
        name="email"
        label="District Email Address"
        value={email}
        disabled
        inputRef={register({ required: true })}
      />
      <PasswordInput
        errors={errors}
        name="password"
        label="Create a password"
        inputRef={register({ required: true })}
      />
      <PasswordInput
        errors={errors}
        name="password_confirmation"
        label="Confirm password"
        inputRef={register({ required: true })}
      />
      <Button isSubmit className={cn('ml-auto mt-30', 'next-step')} type="regular" size="bg" label="Next Step" />
    </form>
  );
};

Step1.propTypes = {
  onSubmit: T.func.isRequired,
};

const step2schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  school_year_start_date: yup
    .mixed()
    .test({
      name: 'startDateValidation',
      test(value) {
        const dt = DateTime.fromISO(value);
        if (dt.weekday === 1) return true;
        return this.createError({
          message: 'Please provide valid date.',
          path: this.path,
        });
      },
    })
    .required(),
  grade_level: yup.string().required('Please select a grade level'),
});

const Step2 = ({ onSubmit }) => {
  const { push } = useHistory();
  const [isMixed, setIsMixed] = useState(false);
  const {
    user: { id },
  } = useSelector(({ auth }) => auth);
  const [dayPickerOpen, setDayPickerOpen] = useState(false);
  const dateInputRef = useRef();
  const dayPickerRef = useRef();
  useOnClickOutside(dayPickerRef, () => setDayPickerOpen(false), { isOpen: dayPickerOpen });

  const setModal = useModalSetter();

  const { register, handleSubmit, errors, getValues, ...restForm } = useForm({
    resolver: yupResolver(step2schema),
  });

  const handleDateClick = (e) => {
    e.preventDefault();
    restForm.clearErrors('school_year_start_date');
    if (isMobile) {
      setModal(
        <DayPickerModal
          className={cn('day-picker')}
          onAccept={(selectedDays) => {
            if (dateInputRef.current) dateInputRef.current.value = selectedDays;
          }}
          currentDate={getValues('school_year_start_date')}
        />,
      );
    } else {
      setDayPickerOpen(true);
    }
  };

  const handleFormSubmit = (data) => {
    onSubmit({ ...data, user_ids: [id], color: GRADE_COLORS[data.grade_level], mixed: isMixed });
  };

  const handleCheckboxClick = () => setIsMixed(!isMixed);

  return (
    <form className={cn('form')} onSubmit={handleSubmit(handleFormSubmit)}>
      <Text type="h6">Step 2/2</Text>
      <Text type="h3" className="mb-25">
        Set up your first classroom
      </Text>
      <Input
        errors={errors}
        name="name"
        label="Classroom name"
        placeholder="Ex. The Bumblebees"
        inputRef={register({ required: true })}
      />
      <Input
        className={cn('date-input')}
        label="School year start date"
        type="text"
        onClick={handleDateClick}
        inputRef={(e) => {
          dateInputRef.current = e;
          register(e, { required: true });
        }}
        onChange={() => restForm.clearErrors('school_year_start_date')}
        name="school_year_start_date"
        errors={errors}
        readonly="readonly"
        placeholder="dd/mm/yyyy"
        isDate
      />
      {dayPickerOpen && (
        <DayPicker
          ref={dayPickerRef}
          className={cn('day-picker')}
          handleCancelClick={() => setDayPickerOpen(false)}
          handleAcceptClick={(selectedDays) => {
            if (dateInputRef.current) dateInputRef.current.value = selectedDays;
            setDayPickerOpen(false);
          }}
        />
      )}
      <SelectBox
        errors={errors}
        label="Grade level"
        name="grade_level"
        placeholder="Choose Grade Level"
        hookFormProps={{ register, errors, ...restForm }}
        options={GRADE_TITLES_OPTIONS}
      />
      <CheckBox className="" label="Mixed" isChecked={isMixed} onClick={handleCheckboxClick} />
      {/* <SelectBox label="Launch Unit" placeholder="Unit 1" isDisabled options={[]} /> */}
      <div className={cn('buttons-wrapper', 'mt-30')}>
        <Button type="regular" variant="outlined" onClick={() => push('/classrooms')} size="bg" label="Skip" />
        <Button isSubmit type="regular" size="bg" label="Finish Setup" />
      </div>
    </form>
  );
};

Step2.propTypes = {
  onSubmit: T.func.isRequired,
};

export { Step1, Step2 };
