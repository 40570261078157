import React, { useState, forwardRef } from 'react';
// utils
import { currentYear } from 'utils/date';
import { TITLE_OPTIONS_LABELS } from 'constants/user';
import { useSelector } from 'react-redux';
import { buildUrl } from 'utils';
// icons
import { ReactComponent as ChevronIcon } from 'icons/chevron-grey.svg';
import { ReactComponent as PersonalDataIcon } from 'icons/user.svg';
import { ReactComponent as ManagePasswordIcon } from 'icons/lock.svg';
import { ReactComponent as ClassroomsIcon } from 'icons/graduate-hat.svg';
// components
import Link from 'components/Link';
import Text from 'components/Text';
// styles
import classnames from 'classnames/bind';
import styles from './MobileView.module.scss';

const cn = classnames.bind(styles);

const EmptyContainer = () => <div className={cn('empty-container')} />;

const MainSection = forwardRef(({ ...props }, ref) => {
  const {
    title,
    first_name: firstName,
    last_name: lastName,
    email,
    avatar_url: avatarUrl,
    programs = [],
  } = useSelector(({ auth: { user } = {} }) => user);
  const [actions, setActions] = useState([
    { link: '/profile/personal-data', label: 'Edit Personal Data', Icon: PersonalDataIcon },
    { link: '/profile/manage-password', label: 'Manage Password', Icon: ManagePasswordIcon },
    { link: '/profile/classrooms', label: 'My Classrooms', Icon: ClassroomsIcon },
  ]);

  return (
    <section className={cn('main-container')}>
      <div className={cn('heading-wrapper')}>
        <Text className={cn('heading-text')} type="h5">
          My Profile
        </Text>
      </div>
      <div ref={ref}>
        <EmptyContainer />
        <Link to="/profile/personal-data">
          <div className={cn('user-wrapper')}>
            <img src={buildUrl(avatarUrl)} className={cn('avatar')} alt="user-avatar" />
            <div className="ml-10">
              <Text className={cn('user-wrapper--texts')} bold>
                {TITLE_OPTIONS_LABELS[title]}
                {firstName}&nbsp;{lastName}
              </Text>
              <Text className={cn('user-wrapper--texts')} type="body-small">
                {email}
              </Text>
            </div>
          </div>
        </Link>
        {programs.length > 1 && (
          <div className={cn('actions-wrapper')}>
            <div className={cn('clickable-section', 'wide')}>
              <div className={cn('hr')} />
              <div className={cn('clickable-section--item')}>
                <Link to="/profile/switch-program" className={cn('clickable-section--item--link')}>
                  <Text>Switch Programs</Text>
                  <ChevronIcon className={cn('chevron-right')} />
                </Link>
              </div>
            </div>
          </div>
        )}
        <EmptyContainer />
        <div className={cn('actions-wrapper')}>
          <div className={cn('clickable-section')}>
            {actions.map((action, idx) => (
              <div key={idx}>
                <div className={cn('clickable-section--item')}>
                  <action.Icon className={cn('clickable-section--item--icon')} />
                  <Link to={action.link} className={cn('clickable-section--item--link')}>
                    <Text>{action.label}</Text>
                    <ChevronIcon className={cn('chevron-right')} />
                  </Link>
                </div>
                {idx !== actions.length - 1 && <div className={cn('hr')} />}
              </div>
            ))}
          </div>
        </div>
        <EmptyContainer />
        <div className={cn('terms-wrapper')}>
          <div className={cn('terms-section')}>
            <Link to="/terms-of-use">
              <div className={cn('terms-section--item')}>
                <Text>Terms of Use</Text>
              </div>
            </Link>
            <div className={cn('hr')} />
            <Link to="/privacy-policy">
              <div className={cn('terms-section--item')}>
                <Text>Privacy Policy</Text>
              </div>
            </Link>
            <div className={cn('hr')} />
            <Link to="mailto: SELF.Curriculum@sesame.org">
              <div className={cn('terms-section--item')}>
                <Text>Contact Us</Text>
              </div>
            </Link>
          </div>
        </div>
        <EmptyContainer />
        <div className={cn('logout-wrapper')}>
          <Link to="/logout">
            <div className={cn('logout-section')}>
              <Text>Logout</Text>
            </div>
          </Link>
        </div>
        <div className={cn('bottom-section')}>
          <Text className={cn('bottom-section--text')}>© {currentYear} Sesame Workshop</Text>
        </div>
      </div>
    </section>
  );
});

export default MainSection;
