import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// utils
import {
  getClassroomUnit,
  getClassroomWeek,
  getLearningExperience,
  markLearningExpFavorite,
  markLearningExpFinished,
} from 'store/classrooms';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { buildUrl, getWeekOrderNumber } from 'utils';
import { animated, useSpring, useTransition } from 'react-spring';
import { getTitleFromUri } from 'utils/string';
import ReactJWPlayer from 'react-jw-player';
import { useSwipeable } from 'react-swipeable';
import { isTablet } from 'react-device-detect';
import {
  eventCategory,
  eventAction,
  eventLabel,
  USER_ROLE,
  PROGRAM_NAME,
  UNIT_NUMBER,
  UNIT_TITLE,
  WEEK_NAME,
  gaRoleLabels,
  customDimensions,
} from 'constants/googleAnalytics';
import { GAEmitEvent } from 'utils/googleAnalytics';
// components
import Text from 'components/Text';
import Button from 'components/Button';
import Link from 'components/Link';
// icons
import { ReactComponent as FullScreenIcon } from 'icons/fullscreen.svg';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ReactComponent as ChevronIcon } from 'icons/chevron.svg';
import { ReactComponent as ResourcesIcon } from 'icons/resources.svg';
import { ReactComponent as CloseFullscreenIcon } from 'icons/exit-fullscreen.svg';
import { ReactComponent as KeyIcon } from 'icons/key.svg';
import { ReactComponent as PdfIcon } from 'icons/pdf-link.svg';
import { ReactComponent as ExternalLinkIcon } from 'icons/external-link.svg';
import { ReactComponent as InternalLinkIcon } from 'icons/internal-link.svg';
// styles
import classnames from 'classnames/bind';
import styles from './LearningExperience.module.scss';
import './LearningExperience.scss';
import DraftBadge from 'components/Badges/DraftBadge';

const cn = classnames.bind(styles);

const getPropperLink = (link) => (link.includes('http') ? link : `//${link}`);

export const Foo = (props) => {
  const [rerender, setRerender] = useState(true);
  const vidTitle = useRef();
  const { role } = useSelector(({ auth }) => auth.user);
  const { name: programName } = useSelector(({ program }) => program);

  useEffect(() => {
    setRerender(true);
  }, [props.videoId]);

  useEffect(() => {
    if (rerender) {
      setRerender(false);
    }
  }, [rerender]);

  const handleVideoLoad = (e) => {
    vidTitle.current = e.item.title;
    props.setVideoTitle(e.item.title);
  };

  const handleEmitEvent = (action, title) => {
    return GAEmitEvent(eventCategory.VIDEO, action, title, {
      [customDimensions[USER_ROLE]]: gaRoleLabels[role],
      [customDimensions[PROGRAM_NAME]]: programName,
      [customDimensions[UNIT_NUMBER]]: get(props.unit, 'units[0].order_number', null),
      [customDimensions[UNIT_TITLE]]: get(props.unit, 'units[0].name', null),
      [customDimensions[WEEK_NAME]]: get(props.week, `weeks[0].name`, null),
    });
  };

  return (
    <>
      {!rerender && (
        <ReactJWPlayer
          className={cn('responsive-iframe')}
          playerId="jw-players"
          onError={(err) => console.log(err)}
          playerScript="https://cdn.jwplayer.com/libraries/fgdQAbWm.js"
          playlist={`https://cdn.jwplayer.com/v2/media/${props.link}`}
          onVideoLoad={handleVideoLoad}
          onPlay={() => handleEmitEvent(eventAction.PLAY, vidTitle.current)}
          onPause={() => handleEmitEvent(eventAction.PAUSE, vidTitle.current)}
          onOneHundredPercent={() => handleEmitEvent(eventAction.END, vidTitle.current)}
        />
      )}
    </>
  );
};

const LearningExperienceView = ({
  orderLabel = '1/2',
  type, // video || image
  src,
  isFullscreen,
  isExpanded,
  exp,
  unit,
  week,
}) => {
  const { role } = useSelector(({ auth }) => auth.user);
  const { name: programName } = useSelector(({ program }) => program);
  const [videoTitle, setVideoTitle] = useState('');

  const propsInsideLeft = useSpring(
    isExpanded
      ? {
          right: '0',
          left: '0%',
          position: 'relative',
          marginRight: 40,
          flexBasis: '40%',
          flexGrow: 1,
          flexShrink: 0,
          transform: 'translateY(0, 0)',
          top: '0%',
          height: '100%',
          // maxWidth: 1000,
          width: '30%',
        }
      : {
          right: '0%',
          left: '3%',
          top: '50%',
          position: 'absolute',
          flexShrink: 0,
          marginRight: 0,
          flexBasis: '40%',
          flexGrow: 1,
          transform: 'translate(0, -50%)',
          height: '92%',
          // maxWidth: 305,
          width: '44%',
        },
  );
  const propsInsideRight = useSpring(
    isExpanded
      ? {
          // left: '0',
          top: '0%',
          right: '0%',
          position: 'relative',
          marginLeft: 40,
          flexBasis: '40%',
          flexGrow: 1,
          flexShrink: 0,
          transform: 'translate(0, 0)',
          height: '100%',
          width: '30%',
          // maxWidth: 1000,
        }
      : {
          right: '3%',
          // left: '50%',
          top: '50%',
          position: 'absolute',
          flexShrink: 0,
          marginLeft: 0,
          flexBasis: '40%',
          flexGrow: 1,
          transform: 'translate(0, -50%)',
          height: '92%',
          width: '44%',
          // maxWidth: 305,
        },
  );
  const propsInsideTop = useSpring(
    isExpanded
      ? { width: '90%', top: '5%', fontSize: 'calc(0.6vh + 0.6vw)', minHeigh: 70 }
      : { width: '90%', top: '5%', fontSize: 'calc(0.8vh + 0.8vw)', minHeight: 100 },
  );
  const propsInsideCenter = useSpring(
    isExpanded
      ? { width: '90%', top: '50%', fontSize: 'calc(0.6vh + 0.6vw)', minHeigh: 70 }
      : { width: '90%', top: '50%', fontSize: 'calc(0.8vh + 0.8vw)', minHeight: 100 },
  );
  const propsInsideBot = useSpring(
    isExpanded
      ? { width: '90%', bottom: '5%', fontSize: 'calc(0.6vh + 0.6vw)', minHeight: 70 }
      : { width: '90%', bottom: '5%', fontSize: 'calc(0.8vh + 0.8vw)', minHeight: 100 },
  );

  const isNoMaxWidth =
    (!['ti', 'di', 'ci'].includes(exp.description_place) &&
      isExpanded &&
      ['ri', 'li'].includes(exp.description_place)) ||
    type === 'video' ||
    isFullscreen;

  const handleVideoWrapperClick = useCallback(() => {
    GAEmitEvent(eventCategory.USER_ACTION, eventAction.CLICK, eventLabel.click('Video', videoTitle), {
      [customDimensions[USER_ROLE]]: gaRoleLabels[role],
      [customDimensions[PROGRAM_NAME]]: programName,
      [customDimensions[UNIT_NUMBER]]: get(unit, 'units[0].order_number', null),
      [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
      [customDimensions[WEEK_NAME]]: get(week, `weeks[0].name`, null),
    });
  }, [videoTitle]);

  return (
    <div
      className={cn('learning-exp-view-wrapper', {
        withPadding: isExpanded && ['ti', 'di', 'ci'].includes(exp.description_place),
      })}
    >
      {exp.description && exp.description_place === 'li' && type !== 'video' && isExpanded && (
        <animated.div
          style={{
            background: exp.description_background_color,
            color: exp.description_text_color,
            ...propsInsideLeft,
          }}
          className={cn('inside-left')}
        >
          <div dangerouslySetInnerHTML={{ __html: exp.description }} />
        </animated.div>
      )}
      {exp.description && exp.description_place === 'lo' && (
        <animated.div
          style={{ background: exp.description_background_color, color: exp.description_text_color }}
          className={cn('outside-left')}
        >
          <div dangerouslySetInnerHTML={{ __html: exp.description }} />
        </animated.div>
      )}
      <div
        className={cn('inner-content-wrapper', {
          noMaxWidth: isNoMaxWidth,
          isExpanded: isExpanded && ['ti', 'di', 'ci', 'li', 'ri'].includes(exp.description_place),
        })}
      >
        {type === 'image' && (
          <div className={cn('image-div-wrapper')}>
            {src && <div className={cn('image-div')} style={{ backgroundImage: `url('${buildUrl(src)}')` }} />}
            {exp.description && exp.description_place === 'ri' && type !== 'video' && !isExpanded && (
              <animated.div
                style={{
                  background: exp.description_background_color,
                  color: exp.description_text_color,
                  ...propsInsideRight,
                }}
                className={cn('inside-right')}
              >
                <div dangerouslySetInnerHTML={{ __html: exp.description }} />
              </animated.div>
            )}
            {exp.description && exp.description_place === 'ti' && type !== 'video' && (
              <animated.div
                style={{
                  background: exp.description_background_color,
                  color: exp.description_text_color,
                  ...propsInsideTop,
                }}
                className={cn('inside-top')}
              >
                <div className={cn('centered')}>
                  <div className={cn('inside-desc')} dangerouslySetInnerHTML={{ __html: exp.description }} />
                </div>
              </animated.div>
            )}
            {exp.description && exp.description_place === 'ci' && type !== 'video' && (
              <animated.div
                style={{
                  background: exp.description_background_color,
                  color: exp.description_text_color,
                  ...propsInsideCenter,
                }}
                className={cn('inside-center')}
              >
                <div className={cn('centered')}>
                  <div className={cn('inside-desc')} dangerouslySetInnerHTML={{ __html: exp.description }} />
                </div>
              </animated.div>
            )}
            {exp.description && exp.description_place === 'di' && type !== 'video' && (
              <animated.div
                style={{
                  background: exp.description_background_color,
                  color: exp.description_text_color,
                  ...propsInsideBot,
                }}
                className={cn('inside-bottom')}
              >
                <div className={cn('centered')}>
                  <div className={cn('inside-desc')} dangerouslySetInnerHTML={{ __html: exp.description }} />
                </div>
              </animated.div>
            )}
            {exp.description && exp.description_place === 'li' && type !== 'video' && !isExpanded && (
              <animated.div
                style={{
                  background: exp.description_background_color,
                  color: exp.description_text_color,
                  ...propsInsideLeft,
                }}
                className={cn('inside-left')}
              >
                <div dangerouslySetInnerHTML={{ __html: exp.description }} />
              </animated.div>
            )}
          </div>
        )}
        {/* {type === 'image' && <img src={buildUrl(src)} className={cn('image-div', { isHalfSize: ['lo', 'ro'].includes(exp.description_place) || (isExpanded && ['ri', 'li'].includes(exp.description_place)) })} />} */}
        {type === 'video' && (
          <div style={{ width: '100%' }} onClick={handleVideoWrapperClick}>
            <Foo videoId={exp.id} link={exp.link} setVideoTitle={setVideoTitle} week={week} unit={unit} />
          </div>
          // https://cdn.jwplayer.com/players/e5EmtlK4-vKCIX9K3.js
        )}
      </div>
      {exp.description && exp.description_place === 'ro' && (
        <animated.div
          style={{ background: exp.description_background_color, color: exp.description_text_color }}
          className={cn('outside-right')}
        >
          <div dangerouslySetInnerHTML={{ __html: exp.description }} />
        </animated.div>
      )}
      {exp.description && exp.description_place === 'ri' && type !== 'video' && isExpanded && (
        <animated.div
          style={{
            background: exp.description_background_color,
            color: exp.description_text_color,
            ...propsInsideRight,
          }}
          className={cn('inside-right')}
        >
          <div dangerouslySetInnerHTML={{ __html: exp.description }} />
        </animated.div>
      )}
    </div>
  );
};

const LearningExperience = () => {
  const dispatch = useDispatch();
  const { push, location } = useHistory();
  const [currentChapter, setCurrentChapter] = useState(0);
  const [isReverse, setIsReverse] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isExpandedRes, setIsExpandedRes] = useState(false);
  const { classroomId, unitId, weekId, learnExpId } = useParams();
  const [isExpanded, setIsExpanded] = useState(false);
  const { week, unit, isLoading, hasErrors, classrooms, classroom, learnExp } = useSelector(
    ({ classrooms: { classrooms, learningExperience, isLoading: _isLoading, hasErrors: _hasErrors } }) => {
      const _classroom = classrooms.find(({ id }) => +classroomId === id);
      return {
        week: get(_classroom, `unit_progress[${unitId}].week_progress[${weekId}]`, {}),
        unit: get(_classroom, `unit_progress[${unitId}]`, {}),
        isLoading: _isLoading,
        hasErrors: _hasErrors,
        classrooms,
        learnExp: learningExperience,
        classroom: _classroom || {},
      };
    },
  );
  const {
    user: { role },
  } = useSelector(({ auth }) => auth);
  const { id: schoolId } = useSelector(({ school }) => school);
  const { name: programName } = useSelector(({ program }) => program);

  const isDraft = get(learnExp, 'draft', false);
  const wrapperRef = useRef();

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.addEventListener('fullscreenchange', () => {
        if (!document.fullscreenElement) setIsFullscreen(false);
      });
    }
  }, [wrapperRef.current]);

  const [isHintShown, setIsHintShown] = useState(false);
  const handleSetFullscreen = () => {
    if (!wrapperRef.current) return;

    if (wrapperRef.current.requestFullscreen) {
      wrapperRef.current.requestFullscreen();
    } else if (wrapperRef.current.webkitRequestFullscreen) {
      /* Safari */
      wrapperRef.current.webkitRequestFullscreen();
    } else if (wrapperRef.current.msRequestFullscreen) {
      /* IE11 */
      wrapperRef.current.msRequestFullscreen();
    }
    setIsFullscreen(true);
  };

  const handleCloseFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }

    setIsFullscreen(false);
  };

  useEffect(() => {
    let t;
    const isMounted = true;
    if (!isFullscreen) {
      setIsHintShown(false);
    }
    if (isFullscreen && !isHintShown) {
      setIsHintShown(true);
      t = setTimeout(() => {
        isMounted && setIsHintShown(false);
      }, 5000);
    }
    return () => {
      if (t && isMounted) {
        clearTimeout(t);
      }
    };
  }, [isFullscreen]);

  const chapters = learnExp.learning_experience_chapters || [];

  const handleNext = useCallback(
    (e) => {
      if (e && e.event && [...e.event.target.classList].join('').includes('jw-')) return;
      setIsReverse(false);
      setIsExpanded(false);
      setCurrentChapter((currentChapter + 1) % chapters.length);
    },
    [currentChapter, chapters.length],
  );
  const handlePrev = useCallback(
    (e) => {
      if (e && e.event && [...e.event.target.classList].join('').includes('jw-')) return;
      setIsReverse(true);
      setIsExpanded(false);
      setCurrentChapter(currentChapter - 1 < 0 ? chapters.length - 1 : currentChapter - 1);
    },
    [currentChapter, chapters.length],
  );

  const handleKeyPress = useCallback(
    ({ keyCode, isComposing }) => {
      if (isComposing) return;
      switch (keyCode) {
        // case 27:
        //   return handleExitFullscreen();
        case 37:
          return handlePrev();
        case 39:
          return handleNext();
        default:
      }
    },
    [push, classroomId, unitId, weekId, handlePrev, handleNext],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const resourcesProps = useSpring({
    right: isExpandedRes ? 0 : -300,
  });

  const { week_progresses: weekProgresses } = get(classroom, `unit_progress.${unitId}`, {});

  useEffect(() => {
    schoolId && dispatch(getLearningExperience(classroomId, learnExpId)).catch(() => push('/classrooms'));
  }, [dispatch, location.pathname, schoolId]);

  useEffect(() => {
    if (classroom && isEmpty(weekProgresses) && !isLoading && !hasErrors)
      dispatch(getClassroomUnit(classroomId, unitId));
  }, [dispatch, classroomId, unitId, weekProgresses, isLoading, hasErrors, classroom]);

  useEffect(() => {
    if (!isEmpty(classrooms) && isEmpty(week) && !isLoading && !hasErrors)
      dispatch(getClassroomWeek(classroomId, unitId, weekId));
  }, [dispatch, classroomId, unitId, weekId, week, isLoading, hasErrors, classrooms]);

  // const chapter = chapters[currentChapter] || {};
  const props = useSpring(isExpanded ? { flexGrow: 2 } : { flexGrow: 0, height: 0 });

  const transitions = useTransition(currentChapter, null, {
    from: { opacity: 0, left: `${!isReverse ? '100%' : '-100%'}` },
    enter: { opacity: 1, left: '0' },
    leave: { opacity: 0, left: `${isReverse ? '100%' : '-100%'}` },
  });

  const hintProps = useSpring(isHintShown ? { opacity: 1 } : { opacity: 0 });
  const handlers = useSwipeable({
    onSwipedLeft: (e) => handleNext(e),
    onSwipedRight: (e) => handlePrev(e),
  });

  const customDims = {
    [customDimensions[USER_ROLE]]: gaRoleLabels[role],
    [customDimensions[PROGRAM_NAME]]: programName,
    [customDimensions[UNIT_NUMBER]]: get(unit, 'order_number', null),
    [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
    [customDimensions[WEEK_NAME]]: get(week, 'weeks[0].name', null),
  };

  const handleFavoriteClick = (id, isFavorite) => {
    isFavorite &&
      GAEmitEvent(
        eventCategory.USER_ACTION,
        eventAction['FAVORITE'],
        eventLabel.toggleFavorite(learnExp.name, 'LearningExperience', location.pathname + location.search),
        customDims,
      );
    return dispatch(markLearningExpFavorite(id, isFavorite));
  };
  const handleFinishedClick = (...params) => dispatch(markLearningExpFinished(...params));
  const handlePrintableClick = (uri) => {
    GAEmitEvent(
      eventCategory.PDF,
      eventAction.DOWNLOAD,
      eventLabel.learningExperiences(getTitleFromUri(uri)),
      customDims,
    );
    GAEmitEvent(
      eventCategory.USER_ACTION,
      eventAction.CLICK,
      eventLabel.click('Learning Experiences', getTitleFromUri(uri)),
      customDims,
    );

    return window.open(buildUrl(uri), '_blank');
  };
  return (
    <div className={cn('wrapper')} ref={wrapperRef}>
      <div className={cn('header')}>
        <div className={cn('col-left')}>
          <Text className="flex" type="h5">
            {week.order_number && `${getWeekOrderNumber(week.order_number, false, true)} | ${learnExp.name}`}
            {isDraft && <DraftBadge className={cn('draft-badge')} />}
          </Text>
          <div className={cn('breadcrumbs')}>
            <Link to="/classrooms" isPrimary>
              <Text type="body-small">My Classrooms</Text>
            </Link>
            <span>&rsaquo;</span>
            <Link to={`/classroom/${classroomId}/unit/${unitId}`} isPrimary>
              <Text type="body-small">
                {classroom.name && unit.order_number && `${classroom.name} | Unit ${unit.order_number}`}
              </Text>
            </Link>
            <span>&rsaquo;</span>
            <Link to={`/classroom/${classroomId}/unit/${unitId}/week/${weekId}/?tab=learning experiences`} isPrimary>
              <Text type="body-small">
                {week.order_number && `${getWeekOrderNumber(week.order_number, false, true)}`}
              </Text>
            </Link>
            <span>&rsaquo;</span>
            <Text type="body-small">Learning experience</Text>
          </div>
        </div>
        <div className={cn('right-col')}>
          <Button
            type="favorite"
            className="ml-15"
            marked={learnExp.favorite}
            onClick={() => handleFavoriteClick(learnExp.id, !learnExp.favorite)}
          />
          <Button
            type="markable"
            className="ml-15"
            marked={learnExp.finished}
            onClick={() => {
              handleFinishedClick(classroomId, learnExp.id, !learnExp.finished, unitId, weekId);
              !learnExp.finished &&
                learnExp.required &&
                GAEmitEvent(
                  eventCategory.USER_ACTION,
                  eventAction.MENU_CLICK,
                  eventLabel.requiredActivity(
                    learnExp.name,
                    'Learning Experience',
                    location.pathname + location.search,
                  ),
                  {
                    [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                    [customDimensions[PROGRAM_NAME]]: programName,
                    [customDimensions[UNIT_NUMBER]]: get(unit, 'units[0].order_number', null),
                    [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
                    [customDimensions[WEEK_NAME]]: get(week, `weeks[0].name`, null),
                  },
                );
            }}
          />
          <FullScreenIcon className={cn('ml-15', 'fullscreen-icon')} onClick={handleSetFullscreen} />
          <div className={cn('close-icon-wrapper')}>
            <CloseIcon
              className={cn('close-icon')}
              onClick={() => push(`/classroom/${classroomId}/unit/${unitId}/week/${weekId}?tab=learning experiences`)}
            />
          </div>
        </div>
      </div>
      <div className={cn('learning-body', { isFullscreen })}>
        <div {...handlers} className={cn('relative-wrapper')}>
          {isHintShown && !isTablet && (
            <animated.div style={hintProps} className={cn('hint')}>
              <KeyIcon className={cn('key')} />
              <KeyIcon className={cn('key', 'key__right')} />
              <Text>
                Use arrow keys to advance press
                <span className={cn('esc')}>ESC</span>
                key to close
              </Text>
            </animated.div>
          )}
          <div className={cn('order-label')}>
            <Text type="h5">{`${currentChapter + 1}/${chapters.length}`}</Text>
            {isFullscreen && <CloseFullscreenIcon className={cn('close-fullscreen')} onClick={handleCloseFullscreen} />}
          </div>
          {transitions.map(({ props, item }, key) => {
            const chapter = chapters[item] || {};
            return (
              <animated.div key={key} style={props} className={cn('learn-animate-wrap', { isFullscreen })}>
                <LearningExperienceView
                  type={chapter.link ? 'video' : 'image'}
                  src={chapter.image_url || chapter.link || null}
                  isExpanded={isExpanded}
                  exp={chapter}
                  isFullscreen={isFullscreen}
                  unit={unit}
                  week={week}
                />
              </animated.div>
            );
          })}
        </div>
        {!!(
          (learnExp.activities || []).length +
          (learnExp.learning_experiences_links || []).length +
          (learnExp.printable_urls || []).length +
          (learnExp.external_links || []).length
        ) && (
          <animated.div style={resourcesProps} className={cn('resources-nav', { isFullscreen })}>
            <div className={cn('button-wrapper')}>
              <div className={cn('button', { isExpandedRes })} onClick={() => setIsExpandedRes(!isExpandedRes)}>
                {!isExpandedRes && (
                  <>
                    <ResourcesIcon />
                    <span>
                      <Text type="h5">
                        {`(${
                          (learnExp.activities || []).length +
                          (learnExp.learning_experiences_links || []).length +
                          (learnExp.printable_urls || []).length +
                          (learnExp.external_links || []).length
                        })`}
                      </Text>
                    </span>
                  </>
                )}
                {isExpandedRes && <CloseIcon />}
              </div>
            </div>
            <div className={cn('content-wrapper', { isFullscreen })}>
              <Text type="body-big" className="mb-10">
                Resources
              </Text>
              {learnExp.activities.map(({ name, id }, idx) => (
                <Link
                  key={`res1-${idx}`}
                  to={`/classroom/${classroomId}/unit/${unitId}/week/${weekId}/lesson-plan/${id}`}
                  target="_blank"
                  className={cn('resource', { isFullscreen })}
                >
                  <InternalLinkIcon className={cn('res-icon')} />
                  {name}
                </Link>
              ))}
              {learnExp.learning_experiences_links.map(({ name, id }, idx) => (
                <Link
                  key={`res1-${idx}`}
                  to={`/classroom/${classroomId}/unit/${unitId}/week/${weekId}/learning-experience/${id}`}
                  target="_blank"
                  className={cn('resource', { isFullscreen })}
                >
                  <InternalLinkIcon className={cn('res-icon')} />
                  {name}
                </Link>
              ))}
              {learnExp.printable_urls.map(({ attachment_url }, idx) => (
                <Link
                  key={`res2-${idx}`}
                  onClick={() => handlePrintableClick(attachment_url)}
                  className={cn('resource', { isFullscreen })}
                >
                  <PdfIcon className={cn('res-icon')} />
                  {getTitleFromUri(attachment_url)}
                </Link>
              ))}
              {learnExp.external_links.map(({ id, name, url }, idx) => (
                <Link
                  key={`res3-${idx}`}
                  to={{ pathname: getPropperLink(url) }}
                  target="_blank"
                  className={cn('resource', { isFullscreen })}
                >
                  <ExternalLinkIcon className={cn('res-icon')} />
                  {name}
                </Link>
              ))}
            </div>
          </animated.div>
        )}
        {!isFullscreen && chapters.length > 1 && (
          <>
            <Button className={cn('nav-btn', 'nav-btn__back')} type="pagination" onClick={handlePrev} size="bg" />
            <Button
              className={cn('nav-btn', 'nav-btn__forward')}
              type="pagination"
              onClick={handleNext}
              reversed
              size="bg"
            />
          </>
        )}
        <div className={cn('trigger', { isFullscreen })} aria-hidden onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded && <div className={cn('trigger-expanded')} />}
          {!isExpanded && (
            <div className={cn('trigger-collapsed')}>
              <ChevronIcon className={cn('icon')} />
            </div>
          )}
        </div>
        <animated.div style={props} className={cn('bottom-draw')}>
          <div style={{ height: '100%', width: '100%' }}>
            <div
              className={cn('bottom-description')}
              dangerouslySetInnerHTML={{ __html: (chapters[currentChapter] || {}).content }}
            />
          </div>
        </animated.div>
      </div>
    </div>
  );
};

export default LearningExperience;
