import React from 'react';
// hoc
import withTabSwitcherMobile from 'hoc/withTabSwitcherMobile';
// components
import Text from 'components/Text';
// styles
import classnames from 'classnames/bind';
import styles from './MobileView.module.scss';

const cn = classnames.bind(styles);

const MobileView = () => {
  return (
    <div className={cn('heading-wrapper', 'pt-20')}>
      <Text type="h5">My Library</Text>
    </div>
  );
};

export default withTabSwitcherMobile(MobileView);
