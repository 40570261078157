import React, { useState } from 'react';
import T from 'prop-types';
import Tooltip from 'components/Tooltip';
import TruncateMarkup from 'react-truncate-markup';
import cn from 'classnames';
import { clearHtml } from 'utils';
import ReactHtmlParser from 'react-html-parser';

const TruncateHint = ({
  text,
  lines = 1,
  showName = '',
  className = '',
  innerHTML = '',
  tooltipClassName,
  children,
}) => {
  const [isTruncated, setIsTruncated] = useState(false);

  const handleOnTruncate = (wasTruncated) => setIsTruncated(wasTruncated);
  return (
    <span className={cn('position-relative', className)}>
      <Tooltip
        isHidden={!isTruncated}
        width={300}
        className={tooltipClassName}
        text={clearHtml(innerHTML) || children || text}
      >
        <TruncateMarkup onTruncate={handleOnTruncate} lines={lines} ellipsis={<span>&nbsp;...</span>}>
          {innerHTML ? <span>{ReactHtmlParser(innerHTML)}</span> : <span>{showName || text}</span>}
        </TruncateMarkup>
      </Tooltip>
    </span>
  );
};

TruncateHint.propTypes = {
  text: T.string,
  lines: T.number,
  className: T.string,
  tooltipClassName: T.string,
  showName: T.oneOfType([T.string, T.node]),
  children: T.oneOfType([T.string, T.node]),
};

export default TruncateHint;
