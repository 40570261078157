import React, { createContext, useState, useCallback, useContext } from 'react';
import T from 'prop-types';
import ModalAnimatedWrapper from 'components/Modal/ModalAnimationWrapper';

const ModalContext = createContext(() => null);

export const useModalSetter = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modal, setState] = useState(null);
  const setModal = useCallback(
    (component) => {
      setState(component);
    },
    [setState],
  );

  return (
    <ModalContext.Provider value={setModal}>
      {children}
      <ModalAnimatedWrapper>{modal}</ModalAnimatedWrapper>
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: T.any,
};
