import React, { useState, useEffect } from 'react';
import T from 'prop-types';
import { useLocation } from 'react-router-dom';
// utils
import queryString from 'query-string';
import { determineInitTabIndex } from '../helpers';
import { useMeasure } from 'hooks/useMeasure';
import { capitalizeEachWord } from 'utils/string';
import { useTransition, animated } from '@react-spring/web';
// components
import Text from 'components/Text';
// styles
import classnames from 'classnames/bind';
import styles from './withTabSwitcherMobile.module.scss';

const cn = classnames.bind(styles);

const withTabSwitcherMobile = (WrappedComponent) => {
  const EnhancedComponent = ({ tabOptions, shouldAnimate = true, ...props }) => {
    const [slideDirection, setSlideDirection] = useState('');
    const [activeTab, setActiveTab] = useState(tabOptions[0].title);

    const twoNavItems = tabOptions.length === 2;

    const [bind, { height: contentOffsetTop }] = useMeasure();

    const { search, pathname } = useLocation();
    const { tab: searchTabName } = queryString.parse(search);

    useEffect(() => {
      const initRefIndex = determineInitTabIndex(tabOptions, searchTabName);
      const initTab = tabOptions.find((_, idx) => idx === initRefIndex);

      setActiveTab(initTab.title);
      // eslint-disable-next-line
    }, [pathname]);

    const handleNavItemClick = (option) => {
      const prevTabIndex = tabOptions.findIndex(({ title }) => title === activeTab);
      const newTabIndex = tabOptions.findIndex(({ title }) => title === option);
      setSlideDirection(newTabIndex > prevTabIndex ? 'right' : 'left');
      setActiveTab(option);
    };

    const getNavItems = (options) =>
      options.map((option, i) => (
        <div key={i} onClick={() => handleNavItemClick(option.title)}>
          <Text
            className={cn('nav-item', { ['active-tab']: option.title === activeTab, ['wide']: twoNavItems })}
            type="body"
          >
            {capitalizeEachWord(option.title)}
          </Text>
        </div>
      ));

    const transitions = useTransition(null, {
      keys: activeTab,
      from: {
        opacity: 0,
        transform: `translateX(${shouldAnimate ? (slideDirection === 'right' ? '100%' : '-100%') : '0%'})`,
      },
      enter: { opacity: 1, transform: `translateX(0)` },
    });

    const renderProperComponent = (optionsArray) => {
      const Component =
        optionsArray.filter(({ title, ...rest }) => title === activeTab)[0] &&
        optionsArray.filter(({ title, ...rest }) => title === activeTab)[0].component;
      return typeof Component === 'function' ? (
        <>
          {transitions((style) => {
            return (
              <animated.div style={{ ...style }}>
                <Component {...props} slideDirection={slideDirection} activeTab={activeTab} />
              </animated.div>
            );
          })}
        </>
      ) : (
        Component
      );
    };

    return (
      <div className={cn('content-wrapper', 'ph-30', 'pv-45')}>
        <div {...bind} className={cn('header-nav-wrapper')}>
          <WrappedComponent {...props} activeTab={activeTab} />
          <section className={cn('page-tab-nav', { narrow: twoNavItems })}>{getNavItems(tabOptions)}</section>
        </div>
        <div style={{ marginTop: `${contentOffsetTop}px` }}>{renderProperComponent(tabOptions)}</div>
      </div>
    );
  };

  EnhancedComponent.propTypes = {
    tabOptions: T.arrayOf(T.object).isRequired,
  };

  return EnhancedComponent;
};

withTabSwitcherMobile.propTypes = {
  WrappedComponent: T.element,
};

export default withTabSwitcherMobile;
