import React, { createContext, useState, useCallback, useContext } from 'react';
import T from 'prop-types';
// components
import BottomMenuWrapper from 'components/BottomMenu/BottomMenuWrapper';

const BottomMenuContext = createContext(() => null);

export const useBottomMenuSetter = () => useContext(BottomMenuContext);

export const BottomMenuProvider = ({ children }) => {
  const [bottomMenu, setState] = useState(null);
  const setBottomMenu = useCallback(
    (component) => {
      setState(component);
    },
    [setState],
  );

  const closeMenu = () => {
    setState(null);
  };

  const isMenu = Boolean(bottomMenu);

  return (
    <BottomMenuContext.Provider value={{ setBottomMenu, closeMenu, isMenu }}>
      {children}
      <BottomMenuWrapper closeMenu={closeMenu}>{bottomMenu}</BottomMenuWrapper>
    </BottomMenuContext.Provider>
  );
};

BottomMenuProvider.propTypes = {
  children: T.any,
};
