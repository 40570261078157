import React, { useState, useRef } from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
import { handleBlockModal, isBlockedModal } from './helpers';
// hooks
import useOnClickOutside from 'hooks/useClickOutside';
// context
import { useModalSetter } from 'hooks/useModal';
// icons
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ReactComponent as CheckIcon } from 'icons/check.svg';
// styles
import styles from './Modal.module.scss';

const cn = classnames.bind(styles);

const Modal = ({
  children,
  className = '',
  onCloseCallback,
  location = 'center',
  onClickOutside = null,
  modalKey = null,
}) => {
  const [blockModalChecked, setBlockModalChecked] = useState(false);
  const modalRef = useRef(null);
  const setModal = useModalSetter();
  const isBottomLocated = location === 'bottom';

  const closeModal = () => {
    if (onCloseCallback) onCloseCallback();
    setModal(null);
  };

  useOnClickOutside(modalRef, onClickOutside || closeModal, { isOpen: true });

  if (modalKey && isBlockedModal(modalKey)) {
    closeModal();
    return null;
  }

  return (
    <div id="context-modal" ref={modalRef} className={cn('modal', 'modal-root', className, { isBottomLocated })}>
      {children({ closeModal, blockModalChecked, setBlockModalChecked, handleBlockModal, isBlockedModal })}
    </div>
  );
};

const Header = ({ children, className }) => <section className={cn('modal-header', className)}>{children}</section>;

const Body = ({ children, className }) => <section className={cn('modal-body', className)}>{children}</section>;

const Footer = ({ checkable, checked, handleChange, children, className }) => {
  const checkboxRef = useRef();

  return (
    <section className={cn('modal-footer', className, { checkable })}>
      <>
        {checkable && (
          <div className={cn('checkbox-wrap')} onClick={() => checkboxRef.current && checkboxRef.current.click()}>
            <input
              ref={checkboxRef}
              type="checkbox"
              name="show-check"
              checked={checked}
              onChange={(e) => handleChange(e.target.checked)}
              hidden
            />
            <div className={cn('checkbox', 'mr-5', { checked })}>{checked && <CheckIcon className={cn('icon')} />}</div>
            <label htmlFor="show-check">{`Don't warn me again`}</label>
          </div>
        )}
        {children}
      </>
    </section>
  );
};

const CloseButton = ({ className, onClick }) => (
  <div className={cn('close-button', className)}>
    <CloseIcon className={cn('icon')} aria-hidden onClick={onClick} />
  </div>
);

Modal.propTypes = {
  children: T.any,
  className: T.string,
  onCloseCallback: T.func,
  location: T.string,
  onClickOutside: T.func,
  modalKey: T.string,
};

Header.propTypes = {
  children: T.any,
  className: T.string,
};

Body.propTypes = {
  children: T.any,
  className: T.string,
};

Footer.propTypes = {
  children: T.any,
  className: T.string,
  checkable: T.bool,
  checked: T.bool,
  handleChange: T.func,
};

CloseButton.propTypes = {
  className: T.string,
  onClick: T.func,
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;
Modal.CloseButton = CloseButton;

export default Modal;
