import React from 'react';
import T from 'prop-types';
// utils
import { isMobile } from 'react-device-detect';
// components
import Text from 'components/Text';
// icons
import { ReactComponent as HeartIcon } from 'icons/heart.svg';
// styles
import classnames from 'classnames/bind';
import styles from './HeartShapedBox.module.scss';

const cn = classnames.bind(styles);

const HeartShapedBox = ({ targetSel = 'Prosocial Skills', selReview = false, lessonPlans = false, className }) => {
  return (
    <div className={cn('wrapper', className, { ['wrapper--flat']: lessonPlans, review: selReview })}>
      <div className={cn('heart-wrapper', { ['heart-wrapper--flat']: lessonPlans })}>
        <HeartIcon className={cn('heart-icon', { ['heart-icon--flat']: lessonPlans })} />
      </div>
      <Text className={cn('wrapper-sel-text')} type={lessonPlans || isMobile ? 'h5' : 'h4'} bold>
        {selReview ? 'Review' : 'Target'} SEL Competency:&nbsp;
        {lessonPlans && <span className={cn('wrapper-sel-text--span')}>{targetSel}</span>}
      </Text>
      {!lessonPlans && (
        <Text className={cn('wrapper-text')} type="body-big">
          {targetSel}
        </Text>
      )}
    </div>
  );
};

HeartShapedBox.propTypes = {
  targetSel: T.string,
  selReview: T.bool.isRequired,
  lessonPlans: T.bool,
  className: T.string,
};

export default HeartShapedBox;
