import React from 'react';
import T from 'prop-types';
// components
import { Link as ReactRouterLink } from 'react-router-dom';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './Link.module.scss';

const cn = classnames.bind(styles);

const Link = ({ children, className, isPrimary, isRed, noHoverColor, disabled, underlined, to, ...other }) => (
  <ReactRouterLink
    className={cn(className, 'link', { green: isPrimary, red: isRed, noHoverColor, underlined, disabled })}
    to={!disabled && to}
    {...other}
  >
    {children}
  </ReactRouterLink>
);

Link.propTypes = {
  children: T.node,
  className: T.string,
  noHoverColor: T.bool,
  to: T.string,
  isPrimary: T.bool,
};

export default Link;
