import Button from 'components/Button';
import { PasswordInput } from 'components/Input';
import Text from 'components/Text';
import Logo from 'components/Logo';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classnames from 'classnames/bind';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Link from 'components/Link';
import { createPassword } from 'store/auth';
import styles from './CreatePassword.module.scss';

const cn = classnames.bind(styles);
const schema = yup.object().shape({
  password: yup
    .string()
    .matches(/^.{6,}$/, 'Password must be at least 6 characters long') // move to const
    .required('Password is required'),
  password_confirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const CreatePassword = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const { push } = useHistory();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const handleNewPassword = (data) => {
    dispatch(createPassword(data, token, push));
  };

  return (
    <form className={cn('wrapper')} onSubmit={handleSubmit(handleNewPassword)}>
      <Logo type="vFull" className={cn('logo')} />
      <Text className="mb-25" type="h3">
        Reset your password
      </Text>
      <PasswordInput errors={errors} name="password" label="New Password" inputRef={register({ required: true })} />
      <PasswordInput
        errors={errors}
        name="password_confirmation"
        label="Confirm Password"
        inputRef={register({ required: true })}
      />
      <Button
        isSubmit
        className={cn('mh-auto mt-30', 'reset-button')}
        type="regular"
        size="bg"
        label="Reset Password"
      />
      <Link to="/login" isPrimary className="mh-auto mt-25">
        Back to login
      </Link>
    </form>
  );
};

export default CreatePassword;
