import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// utils
import { customStyles } from './selectStyles';
import { isEmpty, isEqual } from 'lodash';
import { isMobile } from 'react-device-detect';
// components
import Select, { components } from 'react-select';
// icons
import { ReactComponent as IndicatorIcon } from 'icons/indicator.svg';
import { ReactComponent as IndicatorDisabledIcon } from 'icons/indicator-disabled.svg';
import { ReactComponent as CheckIcon } from 'icons/check.svg';
// styles
import classnames from 'classnames/bind';
import styles from './SelectBox.module.scss';

const cn = classnames.bind(styles);

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    {props.isDisabled ? <IndicatorDisabledIcon /> : <IndicatorIcon />}
  </components.DropdownIndicator>
);

const SelectBox = ({
  options,
  label = null,
  className,
  name,
  hookFormProps = {},
  errors = {},
  defaultValue,
  onClick = () => {},
  onTouch = () => {},
  ...props
}) => {
  const error = errors[name] && errors[name].message;
  const { register, setValue, clearErrors } = hookFormProps;
  const [currentValue, setCurrentValue] = useState({});

  const handleSelectChange = (option) => {
    setCurrentValue(option);
    if (register) {
      clearErrors(name);
      setValue(name, option ? option.value : '');
    }
  };

  useEffect(() => {
    if (register) {
      register(name);
    }
    if (!isEmpty(defaultValue) && !isEqual(defaultValue, currentValue) && isEmpty(currentValue)) {
      setValue(name, defaultValue.value);
      setCurrentValue(defaultValue);
    }
  }, [register, name, currentValue]);

  const Control = (cprops) => (
    <components.Control
      className={cn('select-control', { 'select-control--error': !!error, open: cprops.menuIsOpen })}
      {...cprops}
    />
  );

  const Option = (oprops) => {
    return (
      <div className={cn('option-wrapper')}>
        <components.Option {...oprops} className={cn('select-option')} />
        {oprops.isSelected && <CheckIcon className={cn('check-icon')} />}
      </div>
    );
  };

  return (
    <div className={cn('wrapper', className)} onClick={onClick} onTouchStart={() => setTimeout(() => onTouch(), 100)}>
      {label && <div className={cn('label-wrapper')}>{label}</div>}
      <Select
        value={defaultValue && currentValue}
        styles={customStyles}
        options={options}
        name={name}
        components={{ Control, DropdownIndicator, Option }}
        onChange={handleSelectChange}
        {...props}
      />
      {error && <span className={cn('error')}>{error}</span>}
    </div>
  );
};

SelectBox.propTypes = {
  options: T.arrayOf(
    T.shape({
      value: T.any,
      label: T.string,
    }),
  ).isRequired,
  label: T.string,
  className: T.string,
  name: T.string,
  hookFormProps: T.shape({
    register: T.func,
    setValue: T.func,
    clearErrors: T.func,
  }),
  errors: T.object,
};

export default SelectBox;
