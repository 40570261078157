import React, { useState } from 'react';
import classnames from 'classnames/bind';
// utils
import { get } from 'lodash';
// components
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Input from 'components/Input';
// styles
import styles from './AddressSelect.module.scss';

const cn = classnames.bind(styles);

const getFullAddress = ({ address, city, country_code, state, zipcode }) => {
  let fullAddress = '';

  if (address) fullAddress += `${address}, `;
  if (city) fullAddress += `${city}, `;
  if (state) fullAddress += `${state}, `;
  if (country_code) fullAddress += `${country_code}, `;
  if (zipcode) fullAddress += `${zipcode}, `;
  fullAddress = fullAddress.trim();

  return fullAddress[fullAddress.length - 1] === ',' ? fullAddress.substring(0, fullAddress.length - 1) : fullAddress;
};

const getAddressItem = (addresses) => (searchKey) => {
  const searched = addresses.find(({ types }) => types.some((type) => type === searchKey));

  return get(searched || {}, 'short_name', '');
};

const parseGeocodeAddress = (results) => {
  const address = results[0];
  const getter = getAddressItem(address.address_components);
  const strNumber = getter('street_number');
  const route = getter('route');

  return {
    address: `${strNumber} ${route}`.trim(),
    city: getter('locality'),
    state: getter('administrative_area_level_1'),
    zipcode: getter('postal_code'),
    country_code: getter('country'),
  };
};

/** 
  @param defaultValue is provided only to mobile instances
  due to unexpected input value behavior
*/
const AddressSelect = ({ name = '', inputRef, errors, disabled, defaultValue, getValue, setValue }) => {
  const [address, setAddress] = useState(defaultValue || '');
  const [selectedAddress, setSelectedAddress] = useState('');

  const handleChange = (adr) => setAddress(adr);
  const handleSelect = (_address) => {
    geocodeByAddress(_address)
      .then(async (results) => {
        const fullAddress = parseGeocodeAddress(results);
        setSelectedAddress(getFullAddress(fullAddress));
        setValue && setValue(getFullAddress(fullAddress));
      })
      .catch((error) => console.warn('Error', error));
  };

  const inputProps = {
    placeholder: 'Ex. 3422 Maple Lane, Forest Hill, MD',
    className: '',
    label: 'Address',
    name,
    inputRef,
    errors,
    disabled,
  };

  return (
    <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect} highlightFirstSuggestion>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        const { value, ...restInput } = getInputProps(inputProps);
        return (
          <div className={cn('dropdown')}>
            <Input
              {...restInput}
              onBlur={() => {
                restInput.onBlur();
                if (!setValue) return;
                if (address.length === 0) {
                  setValue('');
                  return;
                }
                if (selectedAddress && selectedAddress !== address) {
                  setValue(selectedAddress);
                  return;
                }
                setValue(defaultValue);
              }}
            />
            <div className={cn('powered-by-google')}>Powered by Google Places</div>
            <div className={cn('dropdown-container')}>
              <div className={cn('dropdown-body')}>
                {suggestions.map((suggestion, idx) => (
                  <div
                    key={`address-select-${idx}`}
                    {...getSuggestionItemProps(suggestion, {
                      className: cn('dropdown-item-wrap', {
                        active: suggestion.active,
                      }),
                    })}
                  >
                    <span className={cn('dropdown-item')}>{suggestion.description}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default AddressSelect;
