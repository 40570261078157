import makeRequest from 'utils/api';
import { toast } from 'react-toastify';
import { cachedProgramId, setInstanceIds } from 'utils/auth';
import { hasMajorRole } from 'utils/users';
import { isProgramAdmin, USER_STATUSES } from 'constants/user';
import { RESET_STORE, inviteRespondWithToasts } from './common';
import { clearSchoolId } from './school';
import { updateCurrentRole } from './auth';
import { get } from 'lodash-es';

// Actions
export const PROGRAM_LOADING = 'czi/PROGRAM/LOADING';
export const PROGRAM_LOADED = 'czi/PROGRAM/LOADED';
export const PROGRAM_FAILED = 'czi/PROGRAM/FAILED';

export const PROGRAM_UPDATE_LOADED = 'czi/PROGRAM_UPDATE/LOADED';

export const PROGRAM_ID_UPDATE_LOADED = 'czi/PROGRAM_ID_UPDATE/LOADED';

export const SCHOOL_CREATE_LOADED = 'czi/SCHOOL_CREATE/LOADED';

export const PROGRAM_INVITE_USERS_LOADED = 'czi/PROGRAM_INVITE_USERS/LOADED';
export const PROGRAM_USER_UPDATE_LOADED = 'czi/PROGRAM_USER_UPDATE/LOADED';

export const DISABLE_SCHOOL_LOADED = 'czi/DISABLE_SCHOOL/LOADED';
export const ACTIVATE_SCHOOL_LOADED = 'czi/ACTIVATE_SCHOOL/LOADED';

export const DELETE_USER_PROGRAM_LOADED = 'czi/DELETE_USER_PROGRAM/LOADED';

export const REVOKE_PROGRAM_ADMIN_LOADED = 'czi/REVOKE_PROGRAM_ADMIN/LOADED';

export const TOGGLE_DISABLE_CLASSROOM_PROGRAM_LOADED = 'czi/TOGGLE_DISABLE_CLASSROOM_PROGRAM/LOADED';

// Action Creators
export const programLoading = () => ({ type: PROGRAM_LOADING });
export const programLoaded = (payload) => ({ type: PROGRAM_LOADED, payload });
export const programFailed = () => ({ type: PROGRAM_FAILED });

export const programUpdateLoaded = (payload) => ({ type: PROGRAM_UPDATE_LOADED, payload });

export const programIdUpdateLoaded = (payload) => ({ type: PROGRAM_ID_UPDATE_LOADED, payload });

export const schoolCreateLoaded = (payload) => ({ type: SCHOOL_CREATE_LOADED, payload });

export const programInviteUsers = (payload) => ({ type: PROGRAM_INVITE_USERS_LOADED, payload });
export const userUpdateLoaded = (payload) => ({ type: PROGRAM_USER_UPDATE_LOADED, payload });

export const disableSchoolLoaded = (payload) => ({ type: DISABLE_SCHOOL_LOADED, payload });
export const activateSchoolLoaded = (payload) => ({ type: ACTIVATE_SCHOOL_LOADED, payload });

export const userDeleteLoaded = (payload) => ({ type: DELETE_USER_PROGRAM_LOADED, payload });

export const revokeProgramAdminLoaded = (payload) => ({ type: REVOKE_PROGRAM_ADMIN_LOADED, payload });

export const toggleDisableClassroomProgramLoaded = (payload) => ({
  type: TOGGLE_DISABLE_CLASSROOM_PROGRAM_LOADED,
  payload,
});

// utils
const mapProgramInfo = (data) => {
  const classroomsMerged = (data.schools || []).reduce((acc, { classrooms }) => [...acc, ...classrooms], []);

  const parsedUsers = (data.users || []).map((user) => {
    const userRole = user.roles.reduce((acc, curr) => {
      const { role } = curr;
      if (!role) return acc;
      if (acc && hasMajorRole(acc, role)) return acc;
      return role;
    }, '');

    const activeFlag = user.roles.find(({ id, type }) => +id === +data.id && type === 'program').is_active;

    return {
      ...user,
      schools: isProgramAdmin(userRole)
        ? data.schools
        : (user.school_ids &&
            user.school_ids.map((schoolId) => data.schools.find(({ id }) => schoolId === id)).filter((_) => !!_)) ||
          [],
      classrooms: user.classroom_ids
        .map((classroomId) => classroomsMerged.find(({ id }) => classroomId === id))
        .filter((_) => !!_),
      full_name: user.last_name ? `${user.first_name} ${user.last_name}` : null,
      status: activeFlag ? USER_STATUSES[user.invitation_status] : 'disabled',
      role: userRole,
      is_active: activeFlag,
    };
  });
  const adminUsers = parsedUsers.filter(({ role }) => isProgramAdmin(role));

  const { school_limit, classroom_limit } = get(data, 'licenses[0]', {});

  const parsedInfo = {
    ...data,
    users: parsedUsers,
    adminUsers,
    schoolsAvailable: school_limit - data.schools_number,
    classroomsAvailable: classroom_limit - (data.schools || []).reduce((acc, curr) => acc + curr.classroom_limit, 0),
  };

  return { ...parsedInfo };
};

export const updateProgramId = (program) => (dispatch) => {
  setInstanceIds({ 'program-id': program.id });
  dispatch(clearSchoolId());
  dispatch(updateCurrentRole(null));
  dispatch(programIdUpdateLoaded(program));
};

// Initial State
export const initialState = {
  id: cachedProgramId(),
  name: '',
  license_active: false,
  licenses: [],
  schools: [],
  users: [],
  isLoading: false,
  hasErrors: false,
};

// Reducer
export default (state = initialState, action) => {
  let newUsers;
  switch (action.type) {
    case PROGRAM_LOADING:
      return { ...state, isLoading: true };
    case PROGRAM_LOADED:
      return { ...state, ...mapProgramInfo(action.payload), isLoading: false, hasErrors: false };
    case PROGRAM_FAILED:
      return { ...state, isLoading: false, hasErrors: true };
    case PROGRAM_UPDATE_LOADED:
      return { ...state, ...mapProgramInfo(action.payload) };
    case PROGRAM_ID_UPDATE_LOADED:
      return { ...state, ...action.payload };
    case SCHOOL_CREATE_LOADED:
      state.schools_number++;
      return { ...state, ...mapProgramInfo({ ...state, schools: [...state.schools, action.payload] }) };
    case PROGRAM_USER_UPDATE_LOADED:
      const updatedUserIdx = state.users.findIndex(({ id }) => +id === +action.payload.id);
      newUsers = [...state.users.slice(0, updatedUserIdx), action.payload, ...state.users.slice(updatedUserIdx + 1)];
      return {
        ...state,
        ...mapProgramInfo({ ...state, users: newUsers }),
        isLoading: false,
        hasErrors: false,
      };
    case PROGRAM_INVITE_USERS_LOADED:
      return {
        ...state,
        ...mapProgramInfo({ ...state, users: [...state.users, ...action.payload] }),
        hasErrors: false,
        isLoading: false,
      };
    case DISABLE_SCHOOL_LOADED:
      state.schools_number--;
      const { id: schoolId } = action.payload;
      const replaceIdx = state.schools.findIndex(({ id }) => +id === +schoolId);

      return {
        ...state,
        ...mapProgramInfo({
          ...state,
          schools: [...state.schools.slice(0, replaceIdx), action.payload, ...state.schools.slice(replaceIdx + 1)],
        }),
        hasErrors: false,
        isLoading: false,
      };
    case ACTIVATE_SCHOOL_LOADED:
      state.schools_number++;
      const { id: _schoolId } = action.payload;
      const _replaceIdx = state.schools.findIndex(({ id }) => id === _schoolId);

      return {
        ...state,
        ...mapProgramInfo({
          ...state,
          schools: [...state.schools.slice(0, _replaceIdx), action.payload, ...state.schools.slice(_replaceIdx + 1)],
        }),
        hasErrors: false,
        isLoading: false,
      };
    case DELETE_USER_PROGRAM_LOADED:
      return {
        ...state,
        ...mapProgramInfo({ ...state, users: state.users.filter(({ id }) => +id !== +action.payload) }),
      };
    case REVOKE_PROGRAM_ADMIN_LOADED:
      const revokedUserIdx = state.users.findIndex(({ id }) => +id === +action.payload.id);
      newUsers = [...state.users.slice(0, revokedUserIdx), action.payload, ...state.users.slice(revokedUserIdx + 1)];

      return {
        ...state,
        ...mapProgramInfo({ ...state, users: newUsers }),
      };
    case TOGGLE_DISABLE_CLASSROOM_PROGRAM_LOADED:
      const currSchoolIdx = state.schools.findIndex(({ id }) => +id === +action.payload.school_id);
      const currClassroomIdx = state.schools[currSchoolIdx].classrooms.findIndex(
        ({ id }) => +id === +action.payload.id,
      );

      const schoolUpdated = {
        ...state.schools[currSchoolIdx],
        classrooms: [
          ...state.schools[currSchoolIdx].classrooms.slice(0, currClassroomIdx),
          action.payload,
          ...state.schools[currSchoolIdx].classrooms.slice(currClassroomIdx + 1),
        ],
      };

      return {
        ...state,
        schools: [...state.schools.slice(0, currSchoolIdx), schoolUpdated, ...state.schools.slice(currSchoolIdx + 1)],
      };
    case RESET_STORE:
      return { ...initialState, id: undefined };
    default:
      return state;
  }
};

// api
export const getProgram = (programId) => (dispatch) => {
  dispatch(programLoading());
  return makeRequest(`/program/${programId}`)
    .then(({ resource }) => {
      dispatch(programLoaded(resource));
    })
    .catch(() => dispatch(programFailed()));
};

export const updateProgram = (data = {}, programId, isAvatar = false) => (dispatch) => {
  const options = isAvatar ? { method: 'PUT', data } : { method: 'PUT', data: { resource: data } };
  setInstanceIds({ 'program-id': programId });
  return makeRequest(`/program/${programId}`, { ...options })
    .then(({ resource }) => {
      dispatch(programUpdateLoaded(resource));
      toast.success('Updated successfuly');
    })
    .catch(() => {});
};

export const createSchool = (data) => (dispatch) =>
  makeRequest('/school', { method: 'POST', data: { resource: data } })
    .then(({ resource }) => {
      dispatch(schoolCreateLoaded(resource));
      return Promise.resolve(resource);
    })
    .catch(() => {});

export const inviteUsersProgram = (data, userProgId) => (dispatch) => {
  if (userProgId) {
    setInstanceIds({ 'program-id': userProgId });
  }
  return makeRequest('/users/invite', { method: 'POST', data: { invitations: { users_attributes: data } } })
    .then(({ invited_users: invited, failed_invitations: failed }) => {
      dispatch(programInviteUsers(invited));
      return Promise.resolve({ invited, failed });
    })
    .then(inviteRespondWithToasts)
    .catch(() => {});
};

export const disableSchoolProgram = (schoolId) => (dispatch) =>
  makeRequest(`/school/${schoolId}/disable`, { method: 'PUT' })
    .then(({ resource }) => dispatch(disableSchoolLoaded(resource)))
    .then(() => toast.success('School disabled'))
    .catch(() => {});

export const activateSchoolProgram = (schoolId) => (dispatch) =>
  makeRequest(`/school/${schoolId}/activate`, { method: 'PUT' })
    .then(({ resource }) => dispatch(activateSchoolLoaded(resource)))
    .then(() => toast.success('School activated'))
    .catch(() => {});

export const deleteUserProgram = (id, instanceIds) => (dispatch) =>
  makeRequest(`/users/${id}`, { method: 'delete', data: { ...instanceIds } })
    .then((res) => dispatch(userDeleteLoaded(id)))
    .then(() => toast.success('You have removed this user'))
    .catch(() => {});
