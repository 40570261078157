import React, { useState } from 'react';
import T from 'prop-types';
// utils
import { isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { GRADE_TITLES_OPTIONS_CREATE as selectGradeTitles } from 'constants/classrooms';
import { MODAL_KEYS } from 'constants/modal';
// components
import NumericIncrementInput from 'components/NumericIncrementInput';
import AddressSelect from 'components/AddressSelect';
import Modal from 'components/Modal';
import ModalAnimWrapper from 'components/Modal/ModalAnimationWrapper';
import Button from 'components/Button';
import Input from 'components/Input';
import AddUsers from 'components/AddUsers';
import Text from 'components/Text';
import SelectBox from 'components/SelectBox';
import WarningModal from 'components/Modal/WarningModal';
// styles
import classnames from 'classnames/bind';
import styles from './BrowserView.module.scss';

const cn = classnames.bind(styles);

const schemaSchool = yup.object().shape({
  schoolName: yup.string().required('Please enter school name.'),
  schoolAddress: yup.string().required('Please choose school address.'),
  numLicenses: yup.number().min(0, 'You must choose at least one license.'),
});

const schemaClassroom = yup.object().shape({
  classroomName: yup.string().required('Please enter classroom name.'),
  gradeLevel: yup.string(),
});

const CreateInstanceModal = ({
  isProgram,
  name,
  teachers,
  onSubmit,
  classroomsAvailable,
  heading,
  description,
  isTeacherAdd,
}) => {
  const [addedUsers, setAddedUsers] = useState([]);
  const [warningModal, setWarningModal] = useState(false);

  const emailInvitations = addedUsers.filter((el) => typeof el === 'string').length;

  let defaultValues;
  if (isProgram) {
    defaultValues = { schoolName: '', schoolAddress: '' };
  } else {
    defaultValues = { classroomName: '' };
  }

  const { register, handleSubmit, errors, setValue, clearErrors, getValues } = useForm({
    resolver: !isTeacherAdd && yupResolver(isProgram ? schemaSchool : schemaClassroom),
    defaultValues,
  });
  const modalKey = isProgram ? MODAL_KEYS.createSchool : MODAL_KEYS.createClassroom;

  return (
    <Modal className={cn('add-school-modal')}>
      {({ closeModal, isBlockedModal }) => {
        const handleFormSubmit = (data) => {
          if (isBlockedModal(modalKey) || addedUsers.length < 1 || emailInvitations < 1) {
            onSubmit(data, addedUsers);
            closeModal();
            return;
          }

          setWarningModal(true);
        };

        return (
          <>
            <Modal.Header className={cn('friendly')}>
              <div className={cn('header-wrapper')}>
                <Text type="h5">
                  {heading || name
                    ? `Add a ${isProgram ? 'school' : 'classroom'} to ${name}`
                    : `Add a ${isProgram ? 'school' : 'classroom'}`}
                </Text>
                <Text className={cn('text')} type="body-small">
                  {description || `Fill out details below to create a ${isProgram ? 'school' : 'classroom'}`}
                </Text>
              </div>
              <Modal.CloseButton className={cn('friendly')} onClick={closeModal} />
            </Modal.Header>
            <form className={cn('form-wrapper')} onSubmit={handleSubmit(handleFormSubmit)}>
              <Modal.Body className={cn('modal-body', 'p-30')}>
                {!isTeacherAdd && (
                  <>
                    <Input
                      label={isProgram ? 'School Name' : 'Classroom Name'}
                      name={isProgram ? 'schoolName' : 'classroomName'}
                      inputRef={register({ required: true })}
                      placeholder={isProgram ? 'Ex. Maple Lane District' : 'Ex. The Bumblebees'}
                      errors={errors}
                    />
                    {isProgram ? (
                      <AddressSelect
                        name="schoolAddress"
                        inputRef={register({ required: true })}
                        placeholder="Ex. 3422 Maple Lane, Forest Hill, MD"
                        getValue={() => getValues('schoolAddress')}
                        setValue={(val) => setValue('schoolAddress', val)}
                        defaultValue=""
                        errors={errors}
                      />
                    ) : (
                      <SelectBox
                        options={selectGradeTitles}
                        label="Grade Level"
                        placeholder="Choose a grade level"
                        name="gradeLevel"
                        errors={errors}
                        hookFormProps={{
                          register,
                          setValue,
                          clearErrors,
                        }}
                        isSearchable={false}
                      />
                    )}
                    {isProgram && (
                      <div className={cn('licenses-wrapper', 'mt-30')}>
                        <div className={cn('texts')}>
                          <Text type="h6">Classroom Licenses</Text>
                          <Text type="body-small">{classroomsAvailable} currently available</Text>
                        </div>
                        <NumericIncrementInput
                          initValue={0}
                          topLimit={classroomsAvailable}
                          name="numLicenses"
                          inputRef={register({ required: true })}
                        />
                      </div>
                    )}
                    <hr className="mv-20" />
                  </>
                )}
                <AddUsers
                  isInitialyOpen={isTeacherAdd}
                  isProgram={isProgram}
                  instanceName={getValues(isProgram ? 'schoolName' : 'classroomName')}
                  setUsers={setAddedUsers}
                  teachers={teachers}
                />
              </Modal.Body>
              {isMobile ? (
                <div className={cn('button-wrapper', 'ph-30 pv-20')}>
                  <Button className={cn('submit-button', 'ph-50 mt-30')} isSubmit size="bg" label="Save" />
                </div>
              ) : (
                <Modal.Footer className={cn('friendly')}>
                  <Button className="ph-50" isSubmit size="md" label="Save" />
                </Modal.Footer>
              )}
            </form>
            <ModalAnimWrapper>
              {warningModal && (
                <WarningModal
                  onSave={() => {
                    onSubmit(getValues(), addedUsers);
                  }}
                  modalText={`You are about to send ${emailInvitations} email invitation(s). Are you sure?`}
                  modalKey={modalKey}
                  onCancel={() => setWarningModal(false)}
                  saveLabel="Invite Users"
                />
              )}
            </ModalAnimWrapper>
          </>
        );
      }}
    </Modal>
  );
};

CreateInstanceModal.propTypes = {
  isProgram: T.bool.isRequired,
  name: T.string.isRequired,
  teachers: T.arrayOf(T.object).isRequired,
  onSubmit: T.func.isRequired,
  classroomsAvailable: T.number.isRequired,
};

export default CreateInstanceModal;
