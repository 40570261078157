import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// hoc
import withTabSwitcher from 'hoc/withTabSwitcher';
// hooks
import { useModalSetter } from 'hooks/useModal';
import { useTableSortedData } from 'hooks/useTableSortedData';
// utils
import { hasMajorRole } from 'utils/users';
import {
  getProgram,
  updateProgram,
  createSchool,
  inviteUsersProgram,
  userUpdateLoaded as userUpdateProgram,
  disableSchoolProgram,
  activateSchoolProgram,
  deleteUserProgram,
  revokeProgramAdminLoaded,
} from 'store/program';
import {
  getSchoolInfo,
  inviteUser,
  reinviteUser,
  updateSchoolId,
  updateSchoolInfo,
  userUpdateLoaded as userUpdateSchool,
  createClassroom,
  disableSchool,
  activateSchool,
  disableClassroom,
  activateClassroom,
  deleteUser,
  revokeSchoolAdminLoaded,
} from 'store/school';
import { resetPassword } from 'store/auth';
import { revokeAdmin, toggleDisableUser } from 'store/common';
import {
  TITLE_OPTIONS_LABELS,
  isAdminUser,
  isSchoolAdmin,
  isProgramAdmin,
  ADMIN_ROLE,
  PROGRAM_ADMIN_ROLE,
  TEACHER_ROLE,
} from 'constants/user';
import { GRADE_COLORS, GRADE_TITLES } from 'constants/classrooms';
import { buildUrl, getWeekOrderNumber, isEdgeWeek } from 'utils';
import { obtainInitials } from 'utils/string';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { get } from 'lodash';
// containers
import TablePagination from 'containers/TablePagination';
// components
import NumericIncrementInput from 'components/NumericIncrementInput';
import AddressSelect from 'components/AddressSelect';
import Link from 'components/Link';
import SearchInput from 'components/Input/SearchInput';
import SideTabMenu from 'components/SideTabMenu';
import Text from 'components/Text';
import Button from 'components/Button';
import ButtonHoverDropdown from 'components/ButtonHoverDropdown';
import Input from 'components/Input';
import ImageCropperModal from 'components/ImageCropperModal';
import Table from 'components/Table';
import AdminUserCart from 'components/AdminUserCart';
import AddContentField from 'components/AddContentField';
import DotsDropdown from 'components/Dropdown/DotsDropdown';
import WelcomeModal from 'components/WelcomeModal';
// icons
import { ReactComponent as Chevron } from 'icons/chevron.svg';
import AdminBadge from 'components/Badges/AdminBadge';
import { ReactComponent as AddIcon } from 'icons/add-white.svg';
// styles
import classnames from 'classnames/bind';
import { updateClassroom } from 'store/classrooms';
import UserActionWarningModal from '../UserActionWarningModal';
import CreateInstanceModal from './CreateInstanceModal';
import InviteAdminModal from './InviteAdminModal';
import { buildMixedName } from '../../Classrooms/helpers';
import styles from './BrowserView.module.scss';

const cn = classnames.bind(styles);

export const Info = ({ isProgramPanel }) => {
  const {
    id: programId,
    name: programName,
    schools_number: schoolsInUse,
    classrooms_number: classroomsInUse,
    adminUsers: programAdminUsers = [],
    avatar_url: programImageUrl,
    address: programAddresss,
    schools: allSchools = [],
  } = useSelector(({ program = {} }) => program);
  const { id: myId, licenses = [] } = useSelector(({ auth: { user = {} } = {} }) => {
    const { licenses } = user.programs.find(({ id }) => +id === +programId) || {};

    return { id: user.id, licenses };
  });

  const { role } = useSelector((state) => state.auth.user);
  const isSchoolAdm = isSchoolAdmin(role);
  const isProgAdmin = isProgramAdmin(role);

  const {
    id: schoolId,
    name: schoolName,
    avatar_url: schoolImageUrl,
    classroom_limit: classroomLimit,
    classrooms_number: classroomNumber,
    adminUsers: schoolAdminUsers = [],
    address: schoolAddress,
    is_active: isSchoolActive,
  } = useSelector(({ school = {} }) => school);
  const imageUrl = isProgramPanel ? programImageUrl : schoolImageUrl;
  const adminUsers = isProgramPanel ? programAdminUsers : schoolAdminUsers;

  const dispatch = useDispatch();
  const setModal = useModalSetter();

  let licensesData = [];
  if (isProgramPanel) {
    licensesData = [
      {
        type: 'School License',
        total: licenses[0].school_limit,
        inUse: schoolsInUse,
        startDate: licenses[0].start_date,
        endDate: licenses[0].end_date,
      },
      {
        type: 'Classroom License',
        total: licenses[0].classroom_limit,
        inUse: classroomsInUse,
        startDate: licenses[0].start_date,
        endDate: licenses[0].end_date,
      },
    ];
  } else {
    licensesData = [
      {
        type: 'Classroom License',
        total: classroomLimit,
        inUse: classroomNumber || 0,
        startDate: licenses[0].start_date,
        endDate: licenses[0].end_date,
      },
    ];
  }

  const { register: totalLicRegister, handleSubmit: handleLicSubmit, getValues: getLicValues } = useForm({
    defaultValues: { totalLicenses: classroomLimit },
  });

  const onChangeLicensesSubmit = ({ totalLicenses }) =>
    dispatch(updateSchoolInfo({ classroom_limit: totalLicenses }, schoolId));

  const maxClassroomLic =
    licenses[0].classroom_limit -
    allSchools.reduce((acc, curr) => (curr.id !== schoolId ? acc + curr.classroom_limit : acc), 0);

  const licenseColumn = (props, className, width) => (
    <div className={cn(className, 'table-row', 'license-row')} style={{ minWidth: width, flexBasis: width }}>
      <Text>{props.type}</Text>
    </div>
  );

  const renderColumn = (property) => (props, className, width) => (
    <div className={cn(className, 'table-row', 'visible')} style={{ minWidth: width, flexBasis: width }}>
      {!isProgramPanel && isProgAdmin && property === 'total' ? (
        maxClassroomLic > 0 ? (
          <div className={cn('numeric-input-wrapper')}>
            <NumericIncrementInput
              initValue={classroomLimit}
              bottomLimit={classroomNumber || 0}
              topLimit={maxClassroomLic}
              name="totalLicenses"
              inputRef={totalLicRegister({ required: true })}
              small
            />
          </div>
        ) : (
          <Text>{props[property]}</Text>
        )
      ) : (
        <Text>{props[property]}</Text>
      )}
    </div>
  );

  const licenseColumns = [
    { label: 'License Type', renderer: licenseColumn, isBordered: true, width: 300 },
    { label: 'Total', renderer: renderColumn('total'), placeholder: 'N/A', width: 150 },
    { label: 'In use', renderer: renderColumn('inUse'), placeholder: 'N/A', width: 110 },
    { label: 'Start Date', renderer: renderColumn('startDate'), placeholder: 'N/A', width: 110 },
    { label: 'End Date', renderer: renderColumn('endDate'), placeholder: 'N/A', width: 110 },
  ];

  const GeneralInformation = () => {
    const infoFormSchema = yup.object().shape({
      [isProgramPanel ? 'programName' : 'schoolName']: yup.string().required('This field is required.'),
      address: yup.string().required('This field is required.'),
    });

    const formInitValues = {
      [isProgramPanel ? 'programName' : 'schoolName']: isProgramPanel ? programName : schoolName,
      address: isProgramPanel ? programAddresss : schoolAddress,
    };

    const { register, handleSubmit, errors, getValues: getValues1, setValue: setValue1 } = useForm({
      defaultValues: { ...formInitValues },
      resolver: yupResolver(infoFormSchema),
    });

    const onFormSubmit = ({ programName, address, schoolName }) =>
      dispatch(
        isProgramPanel
          ? updateProgram({ name: programName, address }, programId)
          : updateSchoolInfo({ name: schoolName, address }, schoolId),
      );

    const handleAcceptImageCrop = (imgEl, callback) => {
      if (!imgEl) return;

      const formData = new FormData();
      const imageCp = imgEl.slice();
      imageCp.lastModifiedDate = new Date();
      imageCp.name = 'avatar';

      formData.append('resource[avatar]', imageCp);

      return dispatch(
        isProgramPanel ? updateProgram(formData, programId, true) : updateSchoolInfo(formData, schoolId, true),
      ).then(() => typeof callback === 'function' && callback());
    };

    return (
      <div className={cn('general-info-wrapper')}>
        {isProgAdmin && (
          <form className={cn('info-form')} onSubmit={handleSubmit(onFormSubmit)}>
            <Input
              label={`${isProgramPanel ? 'Program Name' : 'School Name'}`}
              name={`${isProgramPanel ? 'programName' : 'schoolName'}`}
              inputRef={register({ required: true })}
              errors={errors}
            />
            <AddressSelect
              name="address"
              getValue={() => getValues1('address')}
              setValue={(val) => setValue1('address', val)}
              defaultValue={isProgramPanel ? programAddresss : schoolAddress}
              inputRef={register({ required: true })}
              errors={errors}
            />
            <Button className="mt-20" size="md" isSubmit label="Save Changes" />
          </form>
        )}
        {isSchoolAdm && isProgramPanel && (
          <form className={cn('info-form')}>
            <Input label="Program Name" value={programName} disabled />
            <Input label="Address" value={programAddresss} disabled />
          </form>
        )}
        {isSchoolAdm && !isProgramPanel && (
          <form className={cn('info-form')} onSubmit={handleSubmit(onFormSubmit)}>
            <Input label="School Name" name="schoolName" inputRef={register({ required: true })} errors={errors} />
            <AddressSelect
              name="address"
              inputRef={register({ required: true })}
              errors={errors}
              getValue={() => getValues1('address')}
              setValue={(val) => setValue1('address', val)}
              defaultValue={isProgramPanel ? programAddresss : schoolAddress}
            />
            <Button className="mt-20" size="md" isSubmit label="Save Changes" />
          </form>
        )}

        <div className={cn('image-wrapper')}>
          <Text className={cn('image-title', 'mb-5')}>Profile Photo</Text>
          {imageUrl ? (
            <img className={cn('program-image', 'no-bg')} src={buildUrl(imageUrl)} aria-hidden alt="Program image" />
          ) : (
            <div className={cn('program-image')}>{obtainInitials(isProgramPanel ? programName : schoolName)}</div>
          )}
          {isProgAdmin && (
            <Button
              className="ph-25 pv-5 mt-15"
              type="regular"
              size="md"
              label="Upload an image"
              variant="outlined"
              onClick={() =>
                setModal(<ImageCropperModal aspectRatio={1 / 1} imageUrl={imageUrl} onSave={handleAcceptImageCrop} />)
              }
            />
          )}
        </div>
        {!isProgramPanel && isProgramAdmin(role) && (
          <div className={cn('revoke-wrapper', 'pv-25', 'ph-20')}>
            <div className={cn('texts')}>
              <Text bold>{isSchoolActive ? 'Disable School' : 'Activate School'}</Text>
              <Text className="mt-5" type="body-small">
                {isSchoolActive
                  ? 'Disabling this school will revoke access to all teachers'
                  : 'Activating this school will restore access to all teachers'}
              </Text>
            </div>
            <Button
              className="ph-30"
              size="md"
              variant="black"
              label={`${isSchoolActive ? 'Disable' : 'Activate'} School`}
              onClick={() => dispatch(isSchoolActive ? disableSchool(schoolId) : activateSchool(schoolId))}
            />
          </div>
        )}
      </div>
    );
  };

  const License = () => (
    <div className={cn('license-wrapper')}>
      <Table
        className={cn('license-table')}
        headerClassName={cn('license-header')}
        columns={licenseColumns}
        data={licensesData}
      />
      {!isProgramPanel && isProgAdmin ? (
        <form onSubmit={handleLicSubmit(onChangeLicensesSubmit)}>
          <div className={cn('actions-wrapper', 'mt-25')}>
            <Text type="body-small">{`Licenses limit: ${maxClassroomLic}`}</Text>
            <Button label="Update Licenses" size="md" disabled={maxClassroomLic === 0} isSubmit />
          </div>
        </form>
      ) : null}
      <div className="mt-25 ml-auto">
        <a className={cn('contact-sales')} href="mailto: SELF.Curriculum@sesame.org">
          Contact Sales
        </a>
      </div>
    </div>
  );

  const AdminUsers = () => {
    const handleInviteSubmit = (data) => dispatch(isProgramPanel ? inviteUsersProgram(data) : inviteUser(data));

    const handleRevokeAdmin = ({ adminId, roles }) => {
      const currInstanceId = isProgramPanel ? programId : schoolId;
      const currInstanceType = isProgramPanel ? 'program' : 'school';
      const joinedTableId = roles.find(
        ({ id: instanceId, type }) => +instanceId === currInstanceId && type === currInstanceType,
      )?.join_table_record_id;

      const responseHandler = isProgramPanel ? revokeProgramAdminLoaded : revokeSchoolAdminLoaded;

      const requestData = isProgramPanel
        ? {
            programs_users_attributes: [{ id: joinedTableId, role: null }],
          }
        : { schools_users_attributes: [{ id: joinedTableId, role: TEACHER_ROLE }] };

      return dispatch(revokeAdmin(adminId, programId, schoolId, { ...requestData })).then((res) =>
        dispatch(responseHandler(res)),
      );
    };

    return (
      <div className={cn('program-users-wrapper')}>
        <div className={cn('heading-wrapper')}>
          <Text type="h5">{`${isProgramPanel ? 'Program' : 'School'} Admin Users`}</Text>
          {(isProgAdmin || (isSchoolAdm && !isProgramPanel)) && (
            <Button
              size="md"
              className={cn('btn-add', 'pl-10 pr-25')}
              label={
                <div className={cn('add-label')}>
                  <AddIcon className="mr-5" />
                  <Text>{`Invite ${isProgramPanel ? 'Program' : 'School'} Admin`}</Text>
                </div>
              }
              onClick={() =>
                setModal(
                  <InviteAdminModal
                    name={isProgramPanel ? programName : schoolName}
                    onSubmit={handleInviteSubmit}
                    onlyAdmin
                    isProgram={isProgramPanel}
                  />,
                )
              }
            />
          )}
        </div>
        <div className={cn('admins-wrapper', 'mt-35')}>
          {adminUsers.map(
            ({ id: adminId, avatar_url, full_name, first_name, last_name, is_active, email, role, roles }, idx) => {
              return (
                <AdminUserCart
                  key={`cart-${idx}`}
                  className="mh-15"
                  imageUrl={avatar_url}
                  firstName={first_name}
                  lastName={last_name}
                  active={is_active}
                  email={email}
                  role={role}
                  onRevoke={
                    adminId !== myId
                      ? () =>
                          setModal(
                            <UserActionWarningModal
                              headerLabel="Revoke User"
                              confirmLabel="Revoke"
                              onConfirm={() => handleRevokeAdmin({ adminId, roles })}
                              renderBody={() => (
                                <Text>
                                  Are you sure you wish to revoke <strong>{full_name}'s</strong> admin privileges?
                                </Text>
                              )}
                            />,
                          )
                      : null
                  }
                />
              );
            },
          )}
        </div>
      </div>
    );
  };

  const menuOptions = {
    'general information': GeneralInformation,
    ...(isProgAdmin ? { [isProgramPanel ? 'license' : 'classroom licenses']: License } : []),
    ...(isSchoolAdm && !isProgramPanel ? { 'classroom licenses': License } : []),
    [isProgramPanel ? 'program admin users' : 'school admin users']: AdminUsers,
  };

  return (
    <section className={cn('info-section')}>
      <SideTabMenu menuOptions={menuOptions} />
    </section>
  );
};

export const People = ({ isProgramPanel }) => {
  const { push } = useHistory();
  const { id: programId, name: programName, users: programUsers = [] } = useSelector(({ program = {} }) => program);
  const { id: schoolId, name: schoolName, users: schoolUsers } = useSelector(({ school = {} }) => school);
  const users = isProgramPanel ? programUsers : schoolUsers;
  const { id: myId, role } = useSelector((state) => state.auth.user);
  const isSchoolAdm = isSchoolAdmin(role);
  const isProgAdmin = isProgramAdmin(role);

  const { sortState, handleSortClick, handleSearchChange, dataSorted } = useTableSortedData(
    users,
    ['full_name', 'email'],
    'status',
  );

  const setModal = useModalSetter();
  const dispatch = useDispatch();

  const handleInviteUsers = (data) => dispatch(isProgramPanel ? inviteUsersProgram(data) : inviteUser(data));

  const renderUserColumn = (props, className, width) => (
    <div className={cn('user-col')} style={{ minWidth: width, flexBasis: width }}>
      <div
        className={cn('user-col__image')}
        style={{
          backgroundColor:
            props.grade_level !== undefined
              ? props.grade_level === null
                ? '#dddddd'
                : GRADE_COLORS[props.grade_level]
              : undefined,
        }}
      >
        {props.avatar_url && (
          <div className={cn('img-icon-wrapper')}>
            <img className={cn('image')} src={buildUrl(props.avatar_url)} alt="Img" />
            {isAdminUser(props.role) && (
              <AdminBadge schoolAdmin={isSchoolAdmin(props.role)} className={cn('badge-people')} />
            )}
          </div>
        )}
      </div>
      <div className={cn('user-name-col', 'ml-40')}>
        <Text type="body-big">{props.first_name ? `${props.first_name} ${props.last_name}` : props.email}</Text>
      </div>
    </div>
  );

  const renderEmailColumn = (props, className, width) => (
    <div className={cn(className)} style={{ minWidth: width, flexBasis: width }}>
      <Text>{props.email}</Text>
    </div>
  );

  const renderAssociationColumn = (property) => (props, className, width) => (
    <>
      {props[property] && (
        <div className={cn(className)} style={{ minWidth: width, flexBasis: width }}>
          {props[property].length ? (
            <div className={cn('assoc-cart-container')}>
              {props[property].map(({ name }, idx) =>
                idx < 2 ? (
                  <div
                    key={`cart-${idx}`}
                    className={cn('round-cart', property)}
                    style={{ zIndex: idx, left: idx * 20 }}
                  >
                    {obtainInitials(name)}
                  </div>
                ) : idx === 2 ? (
                  <div key={`cart-${idx}`} className={cn('round-cart', 'last')} style={{ zIndex: idx, left: idx * 20 }}>
                    +{props[property].length - 2}
                  </div>
                ) : (
                  ''
                ),
              )}
            </div>
          ) : (
            <span style={{ color: '#54585a' }}>N/A</span>
          )}
        </div>
      )}
    </>
  );

  const renderStatusColumn = (props, className, width) => (
    <div className={cn(className)} style={{ minWidth: width, flexBasis: width }}>
      <Text>
        {props.status === 'active' ? (
          'Active'
        ) : props.status === 'disabled' ? (
          'Disabled'
        ) : (
          <span style={{ color: '#54585a' }} title={props.status === 'pending' ? 'Invite Pending' : 'Not Assigned'}>
            {props.status === 'pending' ? 'Invite Pending' : 'Not Assigned'}
          </span>
        )}
      </Text>
    </div>
  );

  const dropdownOptions = ({ is_active: isActive, email, role, roles, status, id: userId, full_name }) => {
    const joinTableId = roles.find(({ id, type }) =>
      isProgramPanel ? +id === +programId && type === 'program' : +id === +schoolId && type === 'school',
    )?.join_table_record_id;

    return [
      {
        label: status === 'pending' ? 'Resend Invite' : 'Send Password Reset',
        itemProps: {
          onClick: () =>
            status === 'pending'
              ? dispatch(reinviteUser({ email, role }))
              : dispatch(resetPassword(email, { 'program-id': programId, 'school-id': schoolId })),
        },
      },
      {
        label: (
          <span className={cn('warn-text')}>
            {['pending', 'not assigned'].includes(status) ? 'Remove User' : isActive ? 'Disable User' : 'Activate User'}
          </span>
        ),
        itemProps: {
          onClick: () =>
            ['pending', 'not assigned'].includes(status)
              ? dispatch(
                  isProgramPanel
                    ? deleteUserProgram(userId, { program_id: programId, school_id: schoolId })
                    : deleteUser(userId, { program_id: programId, school_id: schoolId }),
                )
              : isActive
              ? setModal(
                  <UserActionWarningModal
                    headerLabel="Disable User"
                    confirmLabel="Disable"
                    onConfirm={() =>
                      dispatch(
                        toggleDisableUser(
                          userId,
                          {
                            [isProgramPanel ? 'programs_users_attributes' : 'schools_users_attributes']: {
                              id: joinTableId,
                              is_active: false,
                            },
                          },
                          isProgramPanel ? { program_id: programId } : { program_id: programId, school_id: schoolId },
                        ),
                      ).then((res) => dispatch(isProgramPanel ? userUpdateProgram(res) : userUpdateSchool(res)))
                    }
                    renderBody={() => (
                      <Text>
                        Are you sure you wish to disable <strong>{full_name}</strong>? This action will revoke user's
                        access.
                      </Text>
                    )}
                  />,
                )
              : dispatch(
                  toggleDisableUser(
                    userId,
                    {
                      [isProgramPanel ? 'programs_users_attributes' : 'schools_users_attributes']: {
                        id: joinTableId,
                        is_active: true,
                      },
                    },
                    isProgramPanel ? { program_id: programId } : { program_id: programId, school_id: schoolId },
                    false,
                  ),
                ).then((res) => dispatch(isProgramPanel ? userUpdateProgram(res) : userUpdateSchool(res))),
        },
      },
    ];
  };

  const renderButtonsColumn = (property) => (props, className, width) => {
    let _dropdownOptions = dropdownOptions(props);
    if (props.id === myId || (!isProgramPanel && props.is_active && props.schoolScopeRoleless)) {
      _dropdownOptions.pop();
    }

    return (
      <>
        {props[property] && (
          <div className={cn(className, 'buttons-col')} style={{ minWidth: width, flexBasis: width }}>
            {hasMajorRole(role, props.role) && (
              <>
                <Button
                  className="mr-15"
                  label={!props[property].length ? `Assign ${isProgramPanel ? 'Classroom' : 'to Classroom'}` : 'Manage'}
                  onClick={() => push(`/user-profile/${props.id}?range=${isProgramPanel ? 'program' : 'school'}`)}
                  size="s"
                />
                <DotsDropdown className={cn('table-dropdown')} options={_dropdownOptions} />
              </>
            )}
          </div>
        )}
      </>
    );
  };

  const peopleColumnsProgram = [
    { label: 'User', renderer: renderUserColumn, isBordered: true, width: 330 },
    { label: 'Email', renderer: renderEmailColumn, width: 225 },
    { label: 'Schools', renderer: renderAssociationColumn('schools'), width: 190 },
    { label: 'Classrooms', renderer: renderAssociationColumn('classrooms'), width: 200 },
    { label: 'Status', renderer: renderStatusColumn, width: 80, handleSort: handleSortClick, sorted: sortState },
    ...(isProgAdmin ? [{ label: '', renderer: renderButtonsColumn('classrooms'), width: 230 }] : []),
  ];

  const peopleColumnsSchool = [
    { label: 'User', renderer: renderUserColumn, isBordered: true, width: 450 },
    { label: 'Email', renderer: renderEmailColumn, width: 225 },
    { label: 'Classrooms', renderer: renderAssociationColumn('classrooms'), width: 200 },
    { label: 'Status', renderer: renderStatusColumn, width: 130, handleSort: handleSortClick, sorted: sortState },
    { label: '', renderer: renderButtonsColumn('classrooms'), width: 230 },
  ];

  return (
    <section className={cn('people-section')}>
      {users.length > 0 ? (
        <>
          <div className={cn('heading-wrapper')}>
            <Text type="h5" className={cn('title', 'mv-25')}>
              People
            </Text>
            <SearchInput name="schoolSearch" onChange={handleSearchChange} />
          </div>
          <TablePagination pageSize={10} data={dataSorted}>
            {({ paginatedData }) => (
              <Table
                className={cn('table')}
                columns={isProgramPanel ? peopleColumnsProgram : peopleColumnsSchool}
                data={paginatedData}
              />
            )}
          </TablePagination>
        </>
      ) : (
        (isProgAdmin || isSchoolAdm) && (
          <AddContentField
            className={cn('add-content', 'mt-20')}
            text="Add a user."
            onClick={() =>
              setModal(
                <InviteAdminModal
                  name={isProgramPanel ? programName : schoolName}
                  onSubmit={handleInviteUsers}
                  isProgram={isProgramPanel}
                />,
              )
            }
          />
        )
      )}
    </section>
  );
};

export const MainTab = ({ isProgramPanel }) => {
  const { role, roles } = useSelector(({ auth }) => auth.user);
  const {
    id: programId,
    name: programName,
    schools = [],
    users: programUsers = [],
    schoolsAvailable,
    classroomsAvailable,
  } = useSelector(({ program = {} }) => {
    let schools;

    if (isProgramAdmin(role)) {
      schools = get(program, 'schools', []);
    } else {
      schools = (program.schools || []).filter(({ id: _id }) =>
        roles.some(({ id, type, role: iterRole }) => type === 'school' && +id === +_id && isSchoolAdmin(iterRole)),
      );
    }

    return {
      id: get(program, 'id', ''),
      name: get(program, 'name', ''),
      schools,
      users: get(program, 'users', []),
      schoolsAvailable: get(program, 'schoolsAvailable', []),
      classroomsAvailable: get(program, 'classroomsAvailable', []),
    };
  });
  const {
    id: schoolId,
    classrooms = [],
    name: schoolName,
    users: schoolUsers = [],
    classroomsAvailable: schoolClassroomsReamining,
  } = useSelector(({ school = {} }) => school);

  const { push } = useHistory();

  const { sortState, handleSortClick, handleSearchChange, dataSorted } = useTableSortedData(
    isProgramPanel ? schools : classrooms,
    'name',
    'status',
  );

  const dispatch = useDispatch();

  const setModal = useModalSetter();

  const handleManageSchoolClick = (school) => {
    dispatch(updateSchoolId(school));
    push('/school-panel');
  };

  const handleSchoolCreate = ({ schoolName: _schoolName, schoolAddress, numLicenses }, users) => {
    const userMails = users.filter((_usr) => typeof _usr === 'string');
    const userIds = users.filter((_usr) => typeof _usr === 'object').map(({ id }) => id);
    return dispatch(
      createSchool({ name: _schoolName, address: schoolAddress, classroom_limit: numLicenses, user_ids: userIds }),
    ).then(({ id: createdSchoolId }) => {
      const toInvite = userMails.map((email) => ({
        email,
        programs_users_attributes: [{ program_id: programId }],
        schools_users_attributes: [{ school_id: createdSchoolId, role: ADMIN_ROLE }],
      }));

      dispatch(inviteUsersProgram(toInvite));
    });
  };

  const handleClassroomCreate = ({ classroomName, gradeLevel }, users) => {
    const userMails = users.filter((_usr) => typeof _usr === 'string');
    const userIds = users.filter((_usr) => typeof _usr === 'object').map(({ id }) => id);
    return dispatch(
      createClassroom({
        name: classroomName,
        grade_level: gradeLevel,
        color: GRADE_COLORS[gradeLevel],
        user_ids: userIds,
      }),
    ).then(({ payload: { id: classroomId } }) => {
      const toInvite = userMails.map((email) => ({
        email,
        classroom_ids: [classroomId],
        programs_users_attributes: [{ program_id: programId }],
        schools_users_attributes: [{ school_id: schoolId, role: TEACHER_ROLE }],
      }));

      dispatch(inviteUser(toInvite));
    });
  };

  const renderAssociationColumn = (props, className, width) => (
    <div className={cn('school-col', 'ml-15')} style={{ minWidth: width, flexBasis: width }}>
      <div
        className={cn('school-col__image')}
        style={{
          backgroundColor:
            props.grade_level !== undefined
              ? props.grade_level === null
                ? '#dddddd'
                : GRADE_COLORS[props.grade_level]
              : undefined,
        }}
      >
        {(isProgramPanel && props.avatar_url) || (!isProgramPanel && get(props, 'cover_image.image_url')) ? (
          <div
            className={cn('image-wrapper')}
            style={{
              backgroundColor:
                props.grade_level !== undefined
                  ? props.grade_level === null
                    ? '#dddddd'
                    : GRADE_COLORS[props.grade_level]
                  : undefined,
            }}
          >
            <img className={cn('image')} src={buildUrl(props.avatar_url || props.cover_image.image_url)} alt="Img" />
          </div>
        ) : (
          <div
            className={cn('placeholder', { classroom: !isProgramPanel })}
            style={{
              backgroundColor:
                props.grade_level !== undefined
                  ? props.grade_level === null
                    ? '#dddddd'
                    : GRADE_COLORS[props.grade_level]
                  : undefined,
            }}
          >
            {isProgramPanel && obtainInitials(props.name)}
          </div>
        )}
      </div>
      <div className={cn('school-name-col', 'ml-40')}>
        <Text className={cn('school-text')} type="body-big">
          {props.name}
        </Text>
      </div>
    </div>
  );

  const renderGradeLevelColumn = (props, className, width) => (
    <div className={cn(className)} style={{ minWidth: width, flexBasis: width }}>
      {props.grade_level ? (
        <Text>{`${GRADE_TITLES[props.grade_level]} | Unit 1`}</Text>
      ) : (
        <span style={{ color: '#54585a' }}>N/A</span>
      )}
    </div>
  );

  const renderCurrentWeekColumn = (props, className, width) => (
    <div className={cn(className)} style={{ minWidth: width, flexBasis: width }}>
      {props.current_week ? (
        <Link isPrimary to={`/classroom/${props.id}/unit/${props.current_unit.id}/week/${props.current_week.id}`}>
          <Text>
            {`${getWeekOrderNumber(props.current_week.order_number, true, true)}`}
            {!isEdgeWeek(props.current_week.order_number) && buildMixedName(props.current_week.weeks)}
          </Text>
        </Link>
      ) : (
        <span style={{ color: '#54585a' }}>N/A</span>
      )}
    </div>
  );

  const renderClassroomsColumn = (props, className, width) => (
    <div className={cn(className)} style={{ minWidth: width, flexBasis: width }}>
      <Text>
        {props.classrooms.length > 0 ? (
          `${props.classrooms_number}/${props.classroom_limit}`
        ) : (
          <span style={{ color: '#54585a' }}>N/A</span>
        )}
      </Text>
    </div>
  );

  const renderTeachersColumn = (props, className, width) => (
    <>
      {props.classrooms && (
        <div className={cn(className)} style={{ minWidth: width, flexBasis: width }}>
          <Text>
            {props.classrooms.length > 0 ? props.teachers_number : <span style={{ color: '#54585a' }}>N/A</span>}
          </Text>
        </div>
      )}
    </>
  );

  const renderClassroomTeachersColumn = (props, className, width) => (
    <div className={cn(className)} style={{ minWidth: width, flexBasis: width }}>
      <div className={cn('assoc-cart-container')}>
        {(props.users || []).map(({ avatar_url }, idx) =>
          idx < 2 ? (
            <div
              key={`cart-${idx}`}
              className={cn('round-cart')}
              style={{ zIndex: idx, left: idx * 20, backgroundImage: `url(${buildUrl(avatar_url)})` }}
            />
          ) : idx === 2 ? (
            <div key={`cart-${idx}`} className={cn('round-cart', 'last')} style={{ zIndex: idx, left: idx * 20 }}>
              +{props.users.length - 2}
            </div>
          ) : (
            ''
          ),
        )}
      </div>
      {(props.users || []).length < 1 && <span style={{ color: '#54585a' }}>N/A</span>}
    </div>
  );

  const renderStatusColumn = (props, className, width) => (
    <div className={cn(className)} style={{ minWidth: width, flexBasis: width }}>
      <Text>
        {!props.is_active ? (
          'Disabled'
        ) : props.status === 'active' ? (
          'Active'
        ) : (
          <span style={{ color: '#54585a' }}>Incomplete</span>
        )}
      </Text>
    </div>
  );

  const dropdownOptions = (props) => [
    {
      label: (
        <span className={cn('warn-text')}>
          {isProgramPanel
            ? props.is_active
              ? 'Disable School'
              : 'Activate School'
            : props.is_active
            ? 'Disable Classroom'
            : 'Activate Classroom'}
        </span>
      ),
      itemProps: {
        onClick: () =>
          isProgramPanel
            ? dispatch(props.is_active ? disableSchoolProgram(props.id) : activateSchoolProgram(props.id))
            : dispatch(props.is_active ? disableClassroom(props.id) : activateClassroom(props.id)),
      },
    },
  ];

  const renderButtonsColumn = (props, className, width) => (
    <div className={cn(className, 'buttons-col')} style={{ minWidth: width, flexBasis: width }}>
      <Button
        label={
          isProgramPanel
            ? props.classrooms.length > 0
              ? 'Manage'
              : 'Create Classrooms'
            : props.user_ids.length > 0
            ? 'Manage'
            : 'Invite Teacher'
        }
        onClick={() => (isProgramPanel ? handleManageSchoolClick(props) : push(`/manage-classroom/${props.id}`))}
        size="s"
      />
      {((isProgramPanel && isProgramAdmin(role)) || !isProgramPanel) && (
        <DotsDropdown options={dropdownOptions(props)} className={cn('table-dropdown', 'ml-20')} />
      )}
    </div>
  );

  const schoolColumns = [
    { label: 'School', renderer: renderAssociationColumn, isBordered: true, width: 400 },
    { label: 'Classrooms', renderer: renderClassroomsColumn, width: 220 },
    { label: 'Teachers', renderer: renderTeachersColumn, width: 200 },
    { label: 'Status', renderer: renderStatusColumn, width: 180, handleSort: handleSortClick, sorted: sortState },
    { label: '', renderer: renderButtonsColumn, width: 220 },
  ];

  const classroomColumns = [
    { label: 'Classroom', renderer: renderAssociationColumn, isBordered: true, width: 320 },
    { label: 'Level', renderer: renderGradeLevelColumn, width: 180 },
    { label: 'Current Week', renderer: renderCurrentWeekColumn, width: 220 },
    { label: 'Teacher(s)', renderer: renderClassroomTeachersColumn, width: 200 },
    { label: 'Status', renderer: renderStatusColumn, width: 100, handleSort: handleSortClick, sorted: sortState },
    { label: '', renderer: renderButtonsColumn, width: 200 },
  ];

  return (
    <section className={cn('schools-section')}>
      {(isProgramPanel && schools.length > 0) || classrooms.length > 0 ? (
        <>
          <div className={cn('heading-wrapper')}>
            <Text type="h5" className={cn('title', 'mv-25')}>
              {isProgramPanel ? 'Schools' : 'Classrooms'}
            </Text>
            <SearchInput name={isProgramPanel ? 'schoolSearch' : 'classroomSearch'} onChange={handleSearchChange} />
          </div>
          <TablePagination pageSize={10} data={dataSorted}>
            {({ paginatedData }) => (
              <Table
                className={cn('table')}
                columns={isProgramPanel ? schoolColumns : classroomColumns}
                data={paginatedData}
              />
            )}
          </TablePagination>
        </>
      ) : (
        ((isProgramPanel && isProgramAdmin(role)) || !isProgramPanel) && (
          <AddContentField
            className={cn('add-container', 'mt-20')}
            text={`Add your first ${isProgramPanel ? 'school' : 'classroom'}.`}
            onClick={() =>
              (isProgramPanel && schoolsAvailable > 0) || (!isProgramPanel && schoolClassroomsReamining > 0)
                ? setModal(
                    <CreateInstanceModal
                      isProgram={isProgramPanel}
                      name={isProgramPanel ? programName : schoolName}
                      teachers={isProgramPanel ? programUsers.filter(({ role }) => !isProgramAdmin(role)) : schoolUsers}
                      classroomsAvailable={classroomsAvailable}
                      onSubmit={isProgramPanel ? handleSchoolCreate : handleClassroomCreate}
                    />,
                  )
                : toast.error(
                    `You have reached ${isProgramPanel ? 'schools' : 'classrooms'} limit for current license.`,
                    {
                      autoClose: 3000,
                    },
                  )
            }
          />
        )
      )}
    </section>
  );
};

const BrowserView = ({ isProgramPanel }) => {
  const {
    id: programId,
    avatar_url: programImageUrl,
    users: programUsers = [],
    isLoading: programLoading,
    schoolsAvailable,
    classroomsAvailable,
    name: programName,
    completed: isProgramCompleted,
  } = useSelector(({ program = {} }) => program);
  const { fName, title = '', role, firstEverLogin } = useSelector(
    ({
      auth: { user: { id: myId, first_name = '', last_name = '', title = '', role, firstEverLogin: f } = {} } = {},
    }) => ({
      myId,
      fName: first_name,
      lName: last_name,
      title,
      role,
      firstEverLogin: f,
    }),
  );
  const {
    id: schoolId,
    name: schoolName,
    avatar_url: schoolImageUrl,
    classroomsAvailable: schoolClassroomsRemaining,
    users: schoolUsers = [],
    isLoading: schoolLoading,
  } = useSelector(({ school = {} }) => school);
  const imageUrl = isProgramPanel ? programImageUrl : schoolImageUrl;

  const setModal = useModalSetter();

  const { push } = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    if (!programLoading) {
      dispatch(getProgram(programId));
    }
  }, [dispatch, programId, isProgramPanel]);

  useEffect(() => {
    if (!isProgramPanel && !schoolLoading) {
      dispatch(getSchoolInfo(schoolId));
    }
  }, [dispatch, schoolId, isProgramPanel]);

  const isSchoolAdm = isSchoolAdmin(role);
  const isProgAdmin = isProgramAdmin(role);

  const handleInviteSubmit = (data) => dispatch(isProgramPanel ? inviteUsersProgram(data) : inviteUser(data));

  const handleSchoolCreate = ({ schoolName: _schoolName, schoolAddress, numLicenses }, users) => {
    const userMails = users.filter((_usr) => typeof _usr === 'string');
    const userIds = users.filter((_usr) => typeof _usr === 'object').map(({ id }) => id);
    return dispatch(
      createSchool({ name: _schoolName, address: schoolAddress, classroom_limit: numLicenses, user_ids: userIds }),
    ).then(({ id: createdSchoolId }) => {
      const toInvite = userMails.map((email) => ({
        email,
        programs_users_attributes: [{ program_id: programId }],
        schools_users_attributes: [{ school_id: createdSchoolId, role: ADMIN_ROLE }],
      }));

      dispatch(inviteUsersProgram(toInvite));
    });
  };

  const handleClassroomCreate = ({ classroomName, gradeLevel }, users) => {
    const userMails = users.filter((_usr) => typeof _usr === 'string');
    const userIds = users.filter((_usr) => typeof _usr === 'object').map(({ id }) => id);
    return dispatch(
      createClassroom({
        name: classroomName,
        grade_level: gradeLevel,
        color: GRADE_COLORS[gradeLevel],
        user_ids: userIds,
      }),
    ).then(({ payload: { id: classroomId } }) => {
      const toInvite = userMails.map((email) => ({
        email,
        classroom_ids: [classroomId],
        schools_users_attributes: [{ school_id: schoolId, role: TEACHER_ROLE }],
        programs_users_attributes: [{ program_id: programId }],
      }));

      dispatch(inviteUser(toInvite)).then(({ invited }) =>
        dispatch(updateClassroom({ user_ids: [...userIds, ...invited.map(({ id }) => id)] }, classroomId)),
      );
    });
  };

  useEffect(() => {
    if (firstEverLogin && !isProgramCompleted && !programLoading) setModal(<WelcomeModal programName={programName} />);
  }, [programName, setModal, firstEverLogin, isProgramCompleted, programLoading]);

  const addButtonOptions = [
    {
      label: `Create a ${isProgramPanel ? 'school' : 'classroom'}`,
      itemProps: {
        onClick: () =>
          (isProgramPanel && schoolsAvailable > 0) || (!isProgramPanel && schoolClassroomsRemaining > 0)
            ? setModal(
                <CreateInstanceModal
                  isProgram={isProgramPanel}
                  name={isProgramPanel ? programName : schoolName}
                  teachers={isProgramPanel ? programUsers : schoolUsers}
                  classroomsAvailable={classroomsAvailable}
                  onSubmit={isProgramPanel ? handleSchoolCreate : handleClassroomCreate}
                />,
              )
            : toast.error(`You have reached ${isProgramPanel ? 'schools' : 'classrooms'} limit for current license.`, {
                autoClose: 3000,
              }),
      },
    },
    {
      label: 'Invite users',
      itemProps: {
        onClick: () =>
          setModal(
            <InviteAdminModal
              name={isProgramPanel ? programName : schoolName}
              onSubmit={handleInviteSubmit}
              isProgram={isProgramPanel}
            />,
          ),
      },
    },
  ];

  return (
    <div className={cn('wrapper')}>
      {!isProgramPanel && (
        <div className={cn('breadcrumbs', 'mb-25')}>
          <Text className={cn('crumb', { link: true })} onClick={() => push('/program-panel')}>
            {programName}
          </Text>
          <Chevron className={cn('chevron')} />
          <Text className={cn('crumb')}>{schoolName}</Text>
        </div>
      )}
      <header className={cn('header-section')}>
        <div className={cn('header-section__left')}>
          {imageUrl ? (
            <img src={buildUrl(imageUrl)} className={cn('school-image', 'no-bg')} alt="Assoc logo" />
          ) : (
            <div className={cn('school-image')}>{obtainInitials(isProgramPanel ? programName : schoolName)}</div>
          )}
          <div className={cn('information')}>
            <Text type="h6">
              {TITLE_OPTIONS_LABELS[title.toLowerCase()]}
              &nbsp;
              {fName}
            </Text>
            <Text type="h2">{isProgramPanel ? programName : schoolName}</Text>
          </div>
        </div>
        {(isProgAdmin || (isSchoolAdm && !isProgramPanel)) && (
          <div className={cn('header-section__right')}>
            <ButtonHoverDropdown
              btnClassName={cn('btn-add', 'pr-25 pl-15')}
              btnSize="md"
              withIcon
              btnLabel="Add"
              options={addButtonOptions}
            />
          </div>
        )}
      </header>
    </div>
  );
};

export default withTabSwitcher(BrowserView);
