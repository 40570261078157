import React from 'react';
// utils
import { buildUrl } from 'utils';
import { isSchoolAdmin } from 'constants/user';
// components
import Text from 'components/Text';
import Button from 'components/Button';
import AdminBadge from 'components/Badges/AdminBadge';
// styles
import classnames from 'classnames/bind';
import styles from './AdminUserCart.module.scss';

const cn = classnames.bind(styles);

const AdminUserCart = ({ className, imageUrl, active, firstName, lastName, email, role, onRevoke }) => {
  return (
    <article className={cn('admin-cart-wrapper', className)}>
      <div className={cn('upper-section')}>
        <div className={cn('admin-avatar')} style={{ backgroundImage: imageUrl && `url(${buildUrl(imageUrl)})` }}>
          <AdminBadge schoolAdmin={isSchoolAdmin(role)} className={cn('admin-badge')} />
        </div>
        <div className={cn('status-badge', { inactive: !active })}>{active ? 'ACTIVE' : 'PENDING'}</div>
      </div>
      <div className={cn('bottom-section')}>
        <Text type="h6">
          {firstName}&nbsp;{lastName}
        </Text>
        <Text>{email}</Text>
        {onRevoke && <Button className="ml-auto mt-10" size="md" variant="black" label="Revoke" onClick={onRevoke} />}
      </div>
    </article>
  );
};

export default AdminUserCart;
