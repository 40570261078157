import React, { useState } from 'react';
// components
import Button from 'components/Button';
// styles
import classnames from 'classnames/bind';
import styles from './TablePagination.module.scss';

const cn = classnames.bind(styles);

const TablePagination = ({ pageSize, data, children }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const pagesAmount = data ? Math.ceil(data.length / pageSize) : 0;
  const paginatedData = data ? data.slice(currentPage * pageSize, (currentPage + 1) * pageSize) : [];

  const nextPage = () => setCurrentPage(Math.abs((currentPage + 1) % pagesAmount));
  const prevPage = () => setCurrentPage(Math.abs((currentPage - 1) % pagesAmount));

  const handleCellClick = (page) => setCurrentPage(page);

  return (
    <div className={cn('wrapper')}>
      {children({ paginatedData })}
      {pagesAmount > 1 ? (
        <div className={cn('buttons-wrapper', 'mt-25')}>
          <Button type="pagination" className="mr-10" size="s" onClick={prevPage} disabled={currentPage === 0} />
          <div className={cn('page-cell', { current: currentPage === 0 })} onClick={() => handleCellClick(0)}>
            1
          </div>
          {currentPage - 2 > 0 && <div className={cn('cell-dots')}>...</div>}
          {Array.from([currentPage - 1, currentPage, currentPage + 1]).map(
            (no, idx) =>
              no > 0 &&
              no + 1 < pagesAmount && (
                <div
                  key={`cell-${idx}`}
                  className={cn('page-cell', { current: currentPage === no })}
                  onClick={() => handleCellClick(no)}
                >
                  {no + 1}
                </div>
              ),
          )}
          {currentPage + 3 < pagesAmount && <div className={cn('cell-dots')}>...</div>}
          <div
            className={cn('page-cell', { current: currentPage === pagesAmount - 1 })}
            onClick={() => handleCellClick(pagesAmount - 1)}
          >
            {pagesAmount}
          </div>
          <Button
            type="pagination"
            className="ml-10"
            size="s"
            reversed
            onClick={nextPage}
            disabled={currentPage === pagesAmount - 1}
          />
        </div>
      ) : (
        <div className={cn('empty-placeholder')} />
      )}
    </div>
  );
};

export default TablePagination;
