import { useState, useEffect } from 'react';

export const useTempStatuses = (dataArray = [], dependencies = [], statusFlag = 'finished') => {
  const [tempStatuses, setTempStatuses] = useState({});

  useEffect(() => {
    if (Array.isArray(dataArray)) {
      const statusesObject = dataArray.reduce((acc, curr) => ({ ...acc, [curr.id]: curr[statusFlag] }), {});
      setTempStatuses(statusesObject);
    }
  }, [...dependencies]);

  return [tempStatuses, setTempStatuses];
};
