import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// utils
import { isMobile, isIE } from 'react-device-detect';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { useDebouncedCallback } from 'use-debounce';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserNotes } from 'store/classrooms';
// components
import Text from 'components/Text';
import { Editor } from 'react-draft-wysiwyg';
// icons
import BoldIcon from 'icons/bold.png';
import ItalicIcon from 'icons/italic.png';
import LinkIcon from 'icons/link.png';
import OrderedListIcon from 'icons/ordered-list.png';
import UnorderedListIcon from 'icons/unordered-list.png';
import UnderlineIcon from 'icons/underline.png';
// styles
import classnames from 'classnames/bind';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './UserNotes.module.scss';
import './UserNotes.scss';

const cn = classnames.bind(styles);

const toolbarOptions = {
  options: ['blockType', 'inline', 'link', 'list'],
  inline: {
    bold: { icon: BoldIcon },
    italic: { icon: ItalicIcon },
    underline: { icon: UnderlineIcon },
    strikethrough: { className: cn('hidden') },
    monospace: { className: cn('hidden') },
    superscript: { className: cn('hidden') },
    subscript: { className: cn('hidden') },
  },
  fontSize: {
    options: [15],
  },
  link: {
    options: ['link', 'unordered'],
    link: { icon: LinkIcon },
    dropdownClassName: 'rdw-list-dropdown',
  },
  list: {
    options: ['unordered', 'ordered'],
    unordered: { icon: UnorderedListIcon },
    ordered: { icon: OrderedListIcon },
    inDropdown: isMobile,
  },
};

const MAX_LENGTH = 5000;

const UserNotes = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [parsedNote, setParsedNote] = useState('');
  // helper state which prevents saving on init
  const [appliedChanges, setAppliedChanges] = useState(false);
  const [justUpdated, setJustUpdated] = useState(false);
  const dispatch = useDispatch();
  const { classroomId, activityId } = useParams();
  const { classroomColor, userNote = { text: '' } } = useSelector(({ classrooms }) => {
    const color =
      (classrooms.classrooms.length !== 0 && classrooms.classrooms.find(({ id }) => id === +classroomId).color) || '';
    return {
      classroomColor: color.toLowerCase(),
      userNote: classrooms.lessonPlans.user_note,
    };
  });

  const debouncedSave = useDebouncedCallback(
    (val) => dispatch(updateUserNotes({ text: val, classroomId, activityId })).then(() => setJustUpdated(true)),
    2000,
  );

  const plainText = editorState.getCurrentContent().getPlainText('\u0001');

  const contentBlock = userNote && htmlToDraft(userNote.text);

  useEffect(() => {
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editState = EditorState.createWithContent(contentState);

      setEditorState(editState);
    }
  }, []);

  useEffect(() => {
    setParsedNote(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  }, [editorState]);

  useEffect(() => appliedChanges && debouncedSave(parsedNote), [parsedNote]);

  useEffect(() => {
    if (justUpdated) setTimeout(() => setJustUpdated(false), 2000);
  }, [justUpdated]);

  const onEditorStateChange = (state) => {
    const contentState = state.getCurrentContent();
    const oldContent = editorState.getCurrentContent();
    const text = contentState.getPlainText('\u0001');
    if (contentState === oldContent || text.length <= MAX_LENGTH) {
      setEditorState(state);
      return;
    }
  };

  const handleBeforeInput = (chars) => {
    const totalLength = plainText.length + chars.length;
    return totalLength > MAX_LENGTH;
  };

  return (
    <div className={cn('wrapper')}>
      <div className={cn('container', { 'is-ie': isIE })}>
        <div className={cn('upper-bar')} style={{ backgroundColor: classroomColor }} />
        <div className={cn('editor-wrapper')}>
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            editorClassName={cn('editor')}
            toolbar={toolbarOptions}
            onChange={() => setAppliedChanges(true)}
            handlePastedText={() => false}
            handleBeforeInput={handleBeforeInput}
          />
          {justUpdated && <Text className={cn('editor-wrapper--saved')}>Saved Just Now</Text>}
          <Text>{`${plainText.length} / ${MAX_LENGTH}`}</Text>
        </div>
      </div>
    </div>
  );
};

export default UserNotes;
