import React, { useState, useRef } from 'react';
// hooks
import useOnClickOutside from 'hooks/useClickOutside';
// utils
import { isMobile } from 'react-device-detect';
import { buildUrl } from 'utils';
// components
import Select, { components } from 'react-select';
import Text from 'components/Text';
// icon
import { ReactComponent as SearchIcon } from 'icons/search-bold.svg';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
// styles
import classnames from 'classnames/bind';
import styles from './UserSelect.module.scss';

const cn = classnames.bind(styles);

const customStyles = {
  option: (provided) => ({
    ...provided,
    backgroundColor: 'white',
    color: 'black',
    cursor: 'pointer',
    transition: 'all .2s ease',
    '&:hover': {
      backgroundColor: '#eaeaf1',
    },
    padding: '8px 15px',
  }),
  control: (provided, state) => ({
    ...provided,
    padding: '5px 15px 5px 15px',
    justifyContent: 'flex-start',
    border: `1px solid ${state.menuIsOpen ? '#00b140' : '#e3e3e3'}`,
    boxShadow: state.menuIsOpen ? '0px 0px 0px 5px rgba(0, 177, 64, 0.2)' : 'none',
    '&:hover': {
      borderColor: state.menuIsOpen && '#00b140',
    },
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: '0px 1px 22px 0px rgba(0, 0, 0, 0.2)',
    margin: 0,
    borderRadius: '0 0 8px 8px',
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    maxHeight: 160,
  }),
  indicatorSeparator: () => ({}),
  valueContainer: (provided) => ({
    ...provided,
    cursor: 'text',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#151515',
  }),
  input: (provided) => ({
    ...provided,
    input: {
      width: isMobile && '100vw !important',
    },
  }),
};

const UserSelect = ({ options, className, onChange, ...props }) => {
  const [open, setOpen] = useState(false);
  const selectRef = useRef();

  const handleChange = (value) => {
    setOpen(false);
    typeof onChange === 'function' && onChange(value);
  };

  useOnClickOutside(selectRef, () => setOpen(false), { isOpen: open });

  const Control = (cprops) => (
    <div onClick={() => setOpen(true)} onFocus={() => isMobile && setOpen(true)}>
      <components.Control {...cprops} />
    </div>
  );

  const DropdownIndicator = (dprops) =>
    open && (
      <components.DropdownIndicator {...dprops}>
        <div
          className={cn('close-wrapper', 'ml-auto')}
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
          }}
        >
          <CloseIcon className={cn('icon')} />
        </div>
      </components.DropdownIndicator>
    );

  const ValueContainer = (vprops) => (
    <>
      <SearchIcon className={cn('search-icon')} />
      <components.ValueContainer {...vprops}></components.ValueContainer>
    </>
  );

  return (
    <div ref={selectRef}>
      <Select
        styles={customStyles}
        options={options}
        className={className}
        getOptionLabel={(option) => (
          <div className={cn('label-wrapper')}>
            <img className={cn('avatar')} src={buildUrl(option.avatar_url)} alt="Avatar" aria-hidden />
            {option.full_name && <Text className={cn('name', 'ml-5')}>{option.full_name}&nbsp;•&nbsp;</Text>}
            <Text className={cn('email', { 'mr-left': !option.full_name })}>{option.email}</Text>
            <Text className={cn('add', 'ml-auto')}>Add</Text>
          </div>
        )}
        getOptionValue={(option) => `${option.full_name} ${option.email}`}
        components={{ Control, DropdownIndicator, ValueContainer }}
        onChange={handleChange}
        value={''}
        menuIsOpen={open}
        placeholder="Search a member by name or email"
        inputProps={{ autoComplete: 'none', autoCorrect: 'none' }}
        {...props}
      />
    </div>
  );
};

export default UserSelect;
