import React from 'react';
// components
import PortalDropdown from 'components/Dropdown/PortalDropdown';
import Button from 'components/Button';
import Text from 'components/Text';
// icons
import { ReactComponent as AddIcon } from 'icons/add-white.svg';
// styles
import classanmes from 'classnames/bind';
import styles from './ButtonHoverDropdown.module.scss';

const cn = classanmes.bind(styles);

const ButtonHoverDropdown = ({ options, btnType, btnSize, btnClassName, withIcon, btnLabel, className }) => {
  return (
    <PortalDropdown options={options} isOpenLeft hoverDropdown>
      {(isOpen, onTriggerClick, triggerRef, onClose, openMenu) => (
        <div
          ref={triggerRef}
          aria-hidden
          onMouseEnter={(e) => (!isOpen ? onTriggerClick(e) : openMenu())}
          onMouseLeave={onClose}
          className={cn('button-wrapper', className)}
        >
          <Button
            type={btnType}
            size={btnSize}
            className={btnClassName}
            label={
              withIcon ? (
                <div className={cn('icon-label', { dropdownActive: isOpen })}>
                  <AddIcon className="mr-5" />
                  <Text>{btnLabel}</Text>
                </div>
              ) : (
                btnLabel
              )
            }
            dropdownActive={isOpen}
            onClick={() => {}}
          />
        </div>
      )}
    </PortalDropdown>
  );
};

export default ButtonHoverDropdown;
