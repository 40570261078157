import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames/bind';
// components
import Logo from 'components/Logo';
import Div100vh from 'react-div-100vh';
import Text from 'components/Text';
import Button from 'components/Button';
import SelectBox from 'components/SelectBox';
import Input, { PasswordInput } from 'components/Input';
import AddressSelect from 'components/AddressSelect';
// utils
import { isAndroid } from 'react-device-detect';
import { scrollTo } from 'utils';
import { isEmpty, throttle } from 'lodash';
import makeRequest from 'utils/api';
// validation
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// icons
import { ReactComponent as PlusIcon } from 'icons/rounded-plus.svg';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ReactComponent as CheckIcon } from 'icons/check-green.svg';
// const
import {
  isProgramAdmin,
  TITLE_OPTIONS,
  PROGRAM_ADMIN_ROLE,
  hasProgramAdminRole,
  hasTeacherRole,
  hasAdminRole,
  USER_ROLE_OPTIONS,
  USER_ROLE_LABELS,
} from 'constants/user';
// styles
import { useDispatch, useSelector } from 'react-redux';
import { signUp } from 'store/auth';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import program, { inviteUsersProgram, updateProgram } from 'store/program';
import styles from './AdminRegistration.module.scss';

const cn = classnames.bind(styles);

const step3schema = yup.object().shape({
  program_name: yup.string().required('Program name is required'),
});

const step1schema = yup.object().shape({
  title: yup.string().required('Title is required'),
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  password: yup
    .string()
    .matches(/^.{6,}$/, 'Password must be at least 6 characters long') // move to const
    .required('Password is required'),
  password_confirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
  email: yup.string().email(),
});

const AdminRegistration = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const welcomeRef = useRef(null);
  const step1Ref = useRef(null);
  const step2Ref = useRef(null);
  const step3Ref = useRef(null);
  const step4Ref = useRef(null);
  const [emails, setEmails] = useState([]);
  const [currentEmail, setCurrentEmail] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { token, continues } = queryString.parse(location.search);
  const { role, roles, programs, schools } = useSelector((state) => state.auth.user);
  const { push } = useHistory();
  const uncompletedPrograms = useMemo(
    () =>
      programs.filter(
        ({ id, completed }) =>
          !completed &&
          roles.some(
            ({ id: _id, type, role: _role }) => id === _id && type === 'program' && _role === PROGRAM_ADMIN_ROLE,
          ),
      ),
    [programs],
  );
  const [userData, setUserData] = useState({ roles: [] });
  const { register, handleSubmit, errors, ...restForm } = useForm({
    resolver: yupResolver(step1schema),
  });

  const getRoleDescr = (id, type, role) => {
    if (type === 'program') {
      const prog = programs.find(({ id: pId }) => pId === id) || {};
      return `${USER_ROLE_LABELS[role]} in ${prog.name}`;
    }
    if (type === 'school') {
      const school = schools.find(({ id: scId }) => scId === id) || {};
      return `${USER_ROLE_LABELS[role]} in ${school.name}`;
    }
  };

  const [step2IsCompleted, setStep2isCompleted] = useState(false);
  const [step3IsCompleted, setStep3isCompleted] = useState(false);

  const { register: register1, handleSubmit: handleSubmit1, errors1, ...restForm1 } = useForm({
    resolver: yupResolver(step3schema),
    defaultValues: {
      program_name: 'Program name',
    },
  });

  const TOTAL_STEPS = hasProgramAdminRole(userData.roles) ? 3 - (continues ? 0 : 1) : 2 - (continues ? 0 : 1);

  useEffect(() => {
    if (localStorage.getItem('uncompletedRegistration') && !continues) {
      // localStorage.removeItem('uncompletedRegistration');
      // push('/classrooms');
    }
  }, [continues, push]);

  useEffect(() => {
    if (uncompletedPrograms.length) {
      restForm1.setValue('address', restForm1.getValues('address') || uncompletedPrograms[0].address);
      restForm1.setValue('program_name', uncompletedPrograms[0].name);
    }
  }, [restForm1, uncompletedPrograms]);

  const handleForm1Submit = (data) => {
    if (isEmpty(errors) && !roles.length) {
      dispatch(signUp({ ...data, invitation_token: token }));
    }
  };

  useEffect(() => {
    if (!hasAdminRole(roles) && hasTeacherRole(roles) && !continues) {
      push('/classrooms');
    }
    if (roles.length && step2Ref.current) scrollTo(step2Ref.current, 0);
  }, [push, roles, step2Ref, continues]);

  const handleForm2Submit = (data) => {
    if (isEmpty(errors)) {
      if (roles.length > 1 && step4Ref.current) {
        scrollTo(step4Ref.current, 0);
        setStep2isCompleted(true);
        return;
      }
      if (hasProgramAdminRole(roles) && step3Ref.current) {
        scrollTo(step3Ref.current, 0);
        setStep2isCompleted(true);
        return;
      }
      localStorage.removeItem('uncompletedRegistration');
      push('/classrooms');
    }
  };

  useEffect(() => {
    token &&
      makeRequest(`/users/find_by_token?invitation_token=${token}`)
        .then(({ resource }) => setUserData(resource))
        .catch(() => {
          push('/login');
        });
  }, [token, setUserData, push]);

  const handleForm3Submit = (data) => {
    if (isEmpty(errors1)) {
      scrollTo(step3Ref.current, 0);
      if (emails.length) {
        const toInvite = emails.map((email) => ({
          email,
          programs_users_attributes: [{ program_id: uncompletedPrograms[0].id, role: PROGRAM_ADMIN_ROLE }],
        }));
        dispatch(inviteUsersProgram(toInvite, uncompletedPrograms[0].id));
      }
      if (uncompletedPrograms.length) {
        dispatch(
          updateProgram({ name: data.program_name, address: data.address, completed: true }, uncompletedPrograms[0].id),
        );
      }
      localStorage.removeItem('uncompletedRegistration');
      return push('/classrooms');
    }
  };

  const handleCancelEmail = () => {
    setCurrentEmail('');
    setIsEdit(false);
  };

  const handleAddEmail = () => {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(currentEmail)) {
      setEmails([...emails, currentEmail]);
      setCurrentEmail('');
      setIsEdit(false);
    }
  };

  const handleRemoveEmail = (idx) => setEmails([...emails.slice(0, idx), ...emails.slice(idx + 1)]);
  const handleReviewRolesClick = () => {
    scrollTo(step4Ref.current, 0);
  };

  const handleReviewRolesCompleted = () => {
    if (hasProgramAdminRole(roles) && uncompletedPrograms.length && step3Ref.current) {
      setStep3isCompleted(true);
      scrollTo(step3Ref.current, 0);
      return;
    }
    localStorage.removeItem('uncompletedRegistration');
    push('/switch-program');
  };

  const handleReviewCompleted = () => {
    scrollTo(step2Ref.current, 0);
  };

  const onWinScroll = throttle(() => {
    const scrollTop = window.scrollY;
    const [step1Coord, step2Coord, step4Coord, step3Coord] = [step1Ref, step2Ref, step4Ref, step3Ref].map((a) =>
      a.current ? a.current.offsetTop + 200 : 0,
    );
    if (scrollTop >= step4Coord && step4Ref.current) {
      setCurrentStep(3);
      return;
    }
    if (scrollTop >= step3Coord && step3Ref.current) {
      setCurrentStep(3);
      return;
    }
    if (step2Ref.current && scrollTop >= step2Coord) {
      setCurrentStep(2);
      return;
    }
    if (scrollTop >= step1Coord && step1Ref.current) {
      setCurrentStep(1);
    }
  }, 200);

  useEffect(() => {
    window.addEventListener('scroll', onWinScroll);

    return () => {
      window.removeEventListener('scroll', onWinScroll);
    };
  }, [onWinScroll]);

  // android tablet devices fix for jumping content on keyboard trigger
  useEffect(() => {
    let metaViewport;
    if (isAndroid) {
      document.documentElement.style.setProperty('overflow', 'auto');
      metaViewport = document.querySelector('meta[name=viewport]');
      metaViewport.setAttribute(
        'content',
        'height=' + window.innerHeight + 'px, width=device-width, initial-scale=1.0',
      );
    }

    return () => {
      if (isAndroid) {
        document.documentElement.style.setProperty('overflow', 'initial');
        metaViewport.setAttribute(
          'content',
          'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0',
        );
      }
    };
  }, []);

  return (
    <div className={cn('wrapper')}>
      <Div100vh className={cn('welcome-section')} ref={welcomeRef}>
        <Logo className={cn('logo')} />
        <div className={cn('message-wrapper')}>
          <Text className={cn('title')} type="h1">
            Welcome!
            <br />
            Let&apos;s complete your setup.
          </Text>
          <Text type="h6">
            We just need to do a little housekeeping before you can get started. This shouldn&apos;t take more than 3
            minutes.
          </Text>
          <Button
            className={cn('button')}
            onClick={() => scrollTo(step1Ref.current || step2Ref.current, 0)}
            size="bg"
            label="Let's get started!"
          />
        </div>
      </Div100vh>
      <div className={cn('relative-sections')}>
        {hasAdminRole(roles) && (
          <div className={cn('nav-absolute')}>
            <div className={cn('fixed-nav')}>
              <div className={cn('nav-content')}>
                <div className={cn('current-step')}>
                  <span className={cn('big-number')}>{`0${currentStep}`}</span>
                  {`/0${TOTAL_STEPS}`}
                </div>
                <div className={cn('anchor-steps')}>
                  <Text
                    type="body-big"
                    onClick={() => !continues && scrollTo(step1Ref.current, 0)}
                    className={cn('anchor', { isActive: currentStep === 1, green: !!roles.length })}
                  >
                    {roles.length && <CheckIcon className="mr-5" />}
                    Complete your registration
                  </Text>
                  <Text
                    type="body-big"
                    onClick={handleReviewCompleted}
                    className={cn('anchor', { isActive: currentStep === 2, green: step2IsCompleted })}
                  >
                    {step2IsCompleted && <CheckIcon className="mr-5" />}
                    Review licenses
                  </Text>
                  {roles.length > 1 && (
                    <Text
                      type="body-big"
                      onClick={handleReviewRolesClick}
                      className={cn('anchor', { isActive: currentStep === 3, green: step3IsCompleted })}
                    >
                      {step3IsCompleted && <CheckIcon className="mr-5" />}
                      Review roles
                    </Text>
                  )}
                  {hasProgramAdminRole(roles) && !!uncompletedPrograms.length && (
                    <Text
                      type="body-big"
                      onClick={() => scrollTo(step3Ref.current, 0)}
                      className={cn('anchor', { isActive: roles.length > 1 ? currentStep === 3 : currentStep === 4 })}
                    >
                      Set up your program
                    </Text>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {!continues && (
          <Div100vh
            style={{ minHeight: 800 }}
            className={cn('section', 'section1', {
              expanded: !hasAdminRole(roles),
            })}
            ref={step1Ref}
          >
            <form className={cn('form1')} onSubmit={handleSubmit(handleForm1Submit)} autoComplete="chrome-off">
              <div className="mb-25">
                <Text type="h3">Please register your account</Text>
              </div>
              <SelectBox
                errors={errors}
                name="title"
                label="Title"
                placeholder="Title"
                className={cn('title-select')}
                hookFormProps={{ register, errors, ...restForm }}
                options={TITLE_OPTIONS}
              />
              <Input
                errors={errors}
                name="first_name"
                label="First name"
                inputRef={register({ required: true })}
                placeholder="Your first name"
              />
              <Input
                errors={errors}
                name="last_name"
                label="Last name"
                inputRef={register({ required: true })}
                placeholder="Your last name"
              />
              <Input
                errors={errors}
                name="email"
                label="Program email address"
                value={userData.email || ''}
                disabled
                autoComplete="new-name"
                inputRef={register({ required: true })}
              />
              <PasswordInput
                errors={errors}
                name="password"
                autoComplete="new-password"
                label="Create a password"
                inputRef={register({ required: true })}
              />
              <PasswordInput
                errors={errors}
                autoComplete="new-password-confirm"
                name="password_confirmation"
                label="Confirm password"
                inputRef={register({ required: true })}
              />
              <Button
                className={cn('ml-auto mt-30', 'next-step')}
                isSubmit
                type="regular"
                size="bg"
                label="Next Step"
              />
            </form>
          </Div100vh>
        )}
        {hasAdminRole(roles) && (
          <div style={{ minHeight: '100vh' }} className={cn('section', 'section2')} ref={step2Ref}>
            <form className={cn('form2')} autoComplete="chrome-off">
              <Text type="h3" className="mb-30">
                Review total number of licenses
              </Text>
              {programs.map((program, idx) => (
                <Fragment key={`prog-${idx}`}>
                  {programs.length > 1 && (
                    <Text className="mb-20" type="h4">
                      {program.name}
                    </Text>
                  )}
                  <div className={cn('box-wrapper', 'mb-30')}>
                    {hasProgramAdminRole(roles) && (
                      <div className={cn('box')}>
                        <div className={cn('number')}>{program.licenses[0].school_limit}</div>
                        <Text type="body-small" className={cn('text')}>
                          Schools
                        </Text>
                      </div>
                    )}
                    <div className={cn('box')}>
                      <div className={cn('number')}>{program.licenses[0].classroom_limit}</div>
                      <Text type="body-small" className={cn('text')}>
                        Classrooms
                      </Text>
                    </div>
                  </div>
                </Fragment>
              ))}
              {/* <div className={cn('box-wrapper')}>
              <div className={cn('box')}>
                <div className={cn('number')}>3</div>
                <Text type="body-small" className={cn('text')}>
                  Schools
                </Text>
              </div>
              <div className={cn('box')}>
                <div className={cn('number')}>12</div>
                <Text type="body-small" className={cn('text')}>
                  Schools
                </Text>
              </div>
            </div> */}
              <Button
                className={cn('ml-auto mt-40', 'next-step')}
                onClick={handleForm2Submit}
                disabled={!roles.length}
                type="regular"
                size="bg"
                label="Next Step"
              />
            </form>
          </div>
        )}
        {roles.length > 1 && hasAdminRole(roles) && (
          <Div100vh style={{ minHeight: 700 }} className={cn('section', 'section4')} ref={step4Ref}>
            <div className={cn('form4')}>
              <Text type="h3" className="mb-20">
                Review Roles
              </Text>
              {roles.map(({ role, type, id }) => (
                <Text type="h6" className="mb-10">
                  {getRoleDescr(id, type, role)}
                </Text>
              ))}
              <Button
                className={cn('ml-auto mt-40', 'next-step')}
                disabled={!step2IsCompleted}
                onClick={handleReviewRolesCompleted}
                type="regular"
                size="bg"
                label="Continue"
              />
            </div>
          </Div100vh>
        )}
        {hasProgramAdminRole(roles) && !!uncompletedPrograms.length && (
          <Div100vh style={{ minHeight: 700 }} className={cn('section', 'section3')} ref={step3Ref}>
            <form className={cn('form3')} onSubmit={handleSubmit1(handleForm3Submit)} autoComplete="chrome-off">
              <Text type="h3" className="mb-15">
                Set up your program
              </Text>
              {uncompletedPrograms.length > 0 && (
                <>
                  <Input
                    errors={errors1}
                    name="program_name"
                    label="Program Name"
                    disabled={!step2IsCompleted}
                    inputRef={register1({ required: true })}
                    placeholder="Ex. Maple Lane Program"
                  />
                  <AddressSelect
                    name="address"
                    disabled={!step2IsCompleted}
                    inputRef={register1({ required: true })}
                    getValue={() => restForm1.getValues('address')}
                    setValue={(val) => restForm1.setValue('address', val)}
                    defaultValue={uncompletedPrograms[0] && uncompletedPrograms[0].address}
                    errors={errors}
                  />
                  <div className={cn('brake-line')} />
                  <div className={cn('email-array')}>
                    {emails.map((em, idx) => (
                      <div key={`email-${idx}`} className={cn('email-box')}>
                        <Text>{`${em} • Administrator`}</Text>
                        <CloseIcon className={cn('delete-ico')} onClick={() => handleRemoveEmail(idx)} />
                      </div>
                    ))}
                    {!isEdit && (
                      <div aria-hidden className={cn('add-email')} onClick={() => step2IsCompleted && setIsEdit(true)}>
                        <PlusIcon className={cn('plus')} />
                        <Text type="body-big">Add a another program administrator</Text>
                      </div>
                    )}
                    {isEdit && step2IsCompleted && (
                      <div className={cn('email-input-wrap')}>
                        <Input
                          name="email"
                          label="Enter a Program Email Address"
                          value={currentEmail}
                          onChange={({ target: { value } }) => setCurrentEmail(value)}
                        />
                        <div className={cn('buttons')}>
                          <Button
                            className={cn('email-button')}
                            variant="filled"
                            size="bg"
                            label="Cancel"
                            onClick={handleCancelEmail}
                          />
                          <Button
                            className={cn('email-button', 'ml-15')}
                            size="bg"
                            label="Add"
                            onClick={handleAddEmail}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
              <Button
                className={cn('ml-auto mt-40', 'next-step')}
                disabled={!step2IsCompleted}
                isSubmit={!!uncompletedPrograms.length}
                type="regular"
                size="bg"
                label="Continue"
              />
            </form>
          </Div100vh>
        )}
      </div>
    </div>
  );
};

export default AdminRegistration;
