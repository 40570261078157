import React, { useState, useCallback, useEffect } from 'react';
import classnames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
// utils
import { isMobile, isIE } from 'react-device-detect';
import { get, capitalize } from 'lodash';
import DisplayOnDesktop from 'containers/DisplayOnDesktop';
import DisplayOnMobile from 'containers/DisplayOnMobile';
import { buildUrl, getWeekOrderNumber } from 'utils';
import { GAEmitEvent } from 'utils/googleAnalytics';
import {
  eventCategory,
  eventAction,
  eventLabel,
  customDimensions,
  USER_ROLE,
  PROGRAM_NAME,
  UNIT_NUMBER,
  UNIT_TITLE,
  WEEK_NAME,
  gaRoleLabels,
} from 'constants/googleAnalytics';
// constants
import { GRADE_COLORS, GRADE_TITLES } from 'constants/classrooms';
// components
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ReactComponent as CheckIcon } from 'icons/check.svg';
import Logo from 'components/Logo';
import Link from 'components/Link';
import Text from 'components/Text';
import UserAvatar from 'components/UserAvatar';
import { ADMIN_ROLE, hasAdminRole, hasProgramAdminRole, hasTeacherRole, PROGRAM_ADMIN_ROLE } from 'constants/user';
import { ReactComponent as ChevronIcon } from 'icons/chevron.svg';
import Button from 'components/Button/Button';
import { ProgramAvatar } from 'pages/ProgramSwitcher/ProgramSwitcher';
import { updateProgramId } from 'store/program';
import Collapse from './Collapse';
import AnimatedUnderline from './AnimatedUnderline';
// styles
import styles from './Navbar.module.scss';
import { updateSchoolId } from 'store/school';

const cn = classnames.bind(styles);

const CLASSROOMS = 'CLASSROOMS';
const FAVORITES = 'LIBRARY';
const PROFILE = 'PROFILE';
const PROGRAM_SWITCHER = 'PROGRAM_SWITCHER';
const ADMIN = 'ADMIN';

const SECTIONS = [
  { name: CLASSROOMS, path: '/classrooms' },
  { name: FAVORITES, path: '/favorites' },
  { name: ADMIN, path: '/program-panel' },
  {
    name: PROFILE,
    path: '/profile',
  },
];

const Navbar = () => {
  const [activeMenu, setActiveMenu] = useState('');
  const { pathname } = useLocation();
  const setMenu = useCallback((menu) => () => setActiveMenu(menu), []);
  const {
    first_name: firstName,
    programs,
    avatar_url: avatarUrl,
    role,
    roles,
    school_license_active: sLa,
    classroom_ids: classroomIds,
    school_ids: schoolIds,
  } = useSelector(({ auth }) => auth.user);

  const schools = useSelector((state) =>
    state.program.schools.filter(({ id }) => schoolIds.includes(id) || hasProgramAdminRole(roles)),
  );
  const { id: currentProgramId, name: programName } = useSelector(({ program }) => program);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [isExpiredShow, setIsExpiredShow] = useState(!sLa);
  const { classrooms } = useSelector((state) => state.classrooms);
  const clearActiveMenu = useCallback(() => setActiveMenu(''), []);
  const isAdmin = role === ADMIN_ROLE;
  const isProgramAdmin = role === PROGRAM_ADMIN_ROLE;
  useEffect(() => {
    clearActiveMenu();
  }, [pathname, clearActiveMenu]);

  /**
   * This piece of code takes care of hiding nav while typing something.
   * Applies only on unrotated device.
   *
   * innerHeight value is approximated
   * */
  const [hideMobileNav, setHideMobileNav] = useState(false);
  useEffect(() => {
    if (isMobile) {
      const handleWindowResize = () => {
        if (window.innerHeight > window.innerWidth) setHideMobileNav(window.innerHeight < 450);
      };

      window.addEventListener('resize', handleWindowResize);

      return () => window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  const determineActiveNav = (path) => {
    const currUri = decodeURIComponent(pathname);
    const currentBase = `/${currUri.split(/\//)[1]}`;
    return currentBase === path;
  };

  const generateMobileNavlinkElement = (item, idx) => {
    if (item.name === ADMIN && !(isProgramAdmin || isAdmin)) {
      return;
    }

    const isActive = determineActiveNav(item.path);
    const cName = `mobile--${item.name.toLowerCase()}`;
    const label = capitalize(item.name);

    return (
      <Link
        key={idx}
        className={cn('mobile-item', cName, {
          [`${cName}--active`]: isActive,
        })}
        to={item.path}
        onClick={() =>
          GAEmitEvent(eventCategory.USER_ACTION, eventAction.MENU_CLICK, eventLabel.header(`My ${label}`), {
            [customDimensions[USER_ROLE]]: gaRoleLabels[role],
            [customDimensions[PROGRAM_NAME]]: programName,
          })
        }
      >
        <Text type="body-xs" className={cn(`mobile-text`)}>
          My {label}
        </Text>
      </Link>
    );
  };

  const isProgramSwitchable = programs && programs.length > 1;

  const handleProgramSwitch = (program) => () => {
    dispatch(updateProgramId(program));
    setActiveMenu('');
    if (hasTeacherRole(roles)) {
      push('/classrooms');
    }
  };
  const userClassrooms = schools
    .map(({ classrooms: c }) => c)
    .flat()
    .filter(({ id, is_active }) => classroomIds.includes(id) && is_active);

  return (
    <>
      <DisplayOnDesktop>
        <nav
          className={cn('wrapper', { 'wrapper--active': activeMenu })}
          onMouseLeave={activeMenu !== PROGRAM_SWITCHER ? clearActiveMenu : () => null}
        >
          {isExpiredShow && (
            <div className={cn('license')}>
              School license is not active.
              <CloseIcon className={cn('icon')} onClick={() => setIsExpiredShow(false)} />
            </div>
          )}
          <div className={cn('menu')}>
            <div className={cn('logo-wrapper', { 'is-ie': isIE })}>
              <Logo className={cn('logo', { 'is-ie': isIE })} />
            </div>
            <div className={cn('menu-items')}>
              <AnimatedUnderline
                onMouseEnter={setMenu(CLASSROOMS)}
                className={cn('menu-item')}
                isActive={activeMenu === CLASSROOMS}
              >
                {({ onMouseEnter }) => (
                  <Link
                    to="/classrooms"
                    isPrimary={activeMenu === CLASSROOMS}
                    onMouseEnter={onMouseEnter}
                    onClick={() =>
                      GAEmitEvent(
                        eventCategory.USER_ACTION,
                        eventAction.MENU_CLICK,
                        eventLabel.header('My Classrooms'),
                        {
                          [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                          [customDimensions[PROGRAM_NAME]]: programName,
                        },
                      )
                    }
                  >
                    <Text>My Classrooms</Text>
                  </Link>
                )}
              </AnimatedUnderline>
              {userClassrooms.length > 0 && (
                <AnimatedUnderline
                  onMouseEnter={setMenu(FAVORITES)}
                  className={cn('menu-item')}
                  isActive={activeMenu === FAVORITES}
                >
                  {({ onMouseEnter }) => (
                    <Link
                      to="/favorites"
                      isPrimary={activeMenu === FAVORITES}
                      onMouseEnter={onMouseEnter}
                      onClick={() =>
                        GAEmitEvent(
                          eventCategory.USER_ACTION,
                          eventAction.MENU_CLICK,
                          eventLabel.header('My Library'),
                          {
                            [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                            [customDimensions[PROGRAM_NAME]]: programName,
                          },
                        )
                      }
                    >
                      <Text>My Library</Text>
                    </Link>
                  )}
                </AnimatedUnderline>
              )}
              {(isProgramAdmin || (isAdmin && schools.length > 0)) && (
                <>
                  {(userClassrooms.length || hasTeacherRole(roles)) && <div className={cn('vert-separator')} />}
                  <AnimatedUnderline
                    onMouseEnter={setMenu('Admin')}
                    className={cn('menu-item', 'admin')}
                    isActive={activeMenu === 'Admin'}
                  >
                    {({ onMouseEnter }) => (
                      <Link
                        to="/program-panel"
                        isRed={activeMenu === 'Admin'}
                        onMouseEnter={onMouseEnter}
                        onClick={() =>
                          GAEmitEvent(
                            eventCategory.USER_ACTION,
                            eventAction.MENU_CLICK,
                            eventLabel.header('Admin Panel'),
                            {
                              [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                              [customDimensions[PROGRAM_NAME]]: programName,
                            },
                          )
                        }
                      >
                        <Text>My Admin</Text>
                      </Link>
                    )}
                  </AnimatedUnderline>
                </>
              )}
            </div>
            <div className={cn('profile')} onMouseEnter={setMenu(PROFILE)}>
              <div className={cn('name-wrapper')}>
                <Text className={cn('greeting')}>{`Hello, ${firstName}!`}</Text>
                {programName && <Text type="body-xs">{programName}</Text>}
              </div>
              <UserAvatar url={avatarUrl} />
            </div>
          </div>
          <Collapse isActive={activeMenu === CLASSROOMS && userClassrooms.length > 0}>
            <div className={cn('collapsed-content', 'classrooms', { centered: userClassrooms.length <= 3 })}>
              {userClassrooms.map((item, idx) => {
                const draftClassroom = item.unit_progresses.length === 0;

                return (
                  <Link
                    disabled={draftClassroom}
                    to={`/classroom/${item.id}/unit/${get(item, 'current_unit.id', 0)}`}
                    key={`classroom-${idx}`}
                    tabIndex="-1"
                    className={cn('classroom-wrapper')}
                    onClick={() => {
                      dispatch(updateSchoolId(item.school));
                      GAEmitEvent(eventCategory.USER_ACTION, eventAction.MENU_CLICK, eventLabel.header(item.name), {
                        [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                        [customDimensions[PROGRAM_NAME]]: programName,
                        [customDimensions[UNIT_NUMBER]]: get(item, 'current_unit.order_number', null),
                        [customDimensions[UNIT_TITLE]]: get(item, 'current_unit.units[0].name', null),
                        [customDimensions[WEEK_NAME]]: get(item, 'current_week.weeks[0].name', null),
                      });
                    }}
                  >
                    <div className={cn('image-wrapper')} style={{ background: GRADE_COLORS[item.grade_level] }}>
                      {item.cover_image && (
                        <img
                          src={buildUrl(item.cover_image && item.cover_image.image_url)}
                          alt="classroom"
                          className={cn('image')}
                        />
                      )}
                    </div>
                    <div className={cn('description')}>
                      <Text type="h5" className={cn('text', 'mb-5')}>
                        {item.name}
                      </Text>
                      <Text type="body-small" className={cn('sub-text')}>
                        {GRADE_TITLES[item.grade_level]}
                        &nbsp;&bull;&nbsp;
                        {item.started &&
                          item.current_week &&
                          `Unit ${item.current_unit.order_number}, ${getWeekOrderNumber(
                            item.current_week.order_number,
                            false,
                            true,
                          )}`}
                      </Text>
                    </div>
                  </Link>
                );
              })}
            </div>
          </Collapse>
          <Collapse isActive={activeMenu === PROFILE}>
            <div tabIndex="-1" className={cn('collapsed-content', 'profile-section')}>
              <Link
                to="/profile"
                tabIndex="-1"
                noHoverColor
                onClick={() =>
                  GAEmitEvent(eventCategory.USER_ACTION, eventAction.MENU_CLICK, eventLabel.header('My Profile'), {
                    [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                    [customDimensions[PROGRAM_NAME]]: programName,
                  })
                }
              >
                <Text type="h5" className={cn('profile-link')}>
                  My Profile
                </Text>
              </Link>
              {isProgramSwitchable && (
                <Text
                  type="h5"
                  tabIndex="-1"
                  className={cn('profile-link', 'switch-program')}
                  onClick={() => {
                    setMenu(PROGRAM_SWITCHER)();
                    GAEmitEvent(
                      eventCategory.USER_ACTION,
                      eventAction.MENU_CLICK,
                      eventLabel.header('Switch Program'),
                      {
                        [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                        [customDimensions[PROGRAM_NAME]]: programName,
                      },
                    );
                  }}
                >
                  Switch Program
                  <ChevronIcon className={cn('chevron-right')} />
                </Text>
              )}
              <Link
                to="/logout"
                tabIndex="-1"
                noHoverColor
                onClick={() =>
                  GAEmitEvent(eventCategory.USER_ACTION, eventAction.MENU_CLICK, eventLabel.header('Sign Out'), {
                    [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                    [customDimensions[PROGRAM_NAME]]: programName,
                  })
                }
              >
                <Text type="h5" className={cn('sign-out')}>
                  Sign Out
                </Text>
              </Link>
            </div>
          </Collapse>
          <Collapse className={cn('program-switcher-collapse')} isActive={activeMenu === PROGRAM_SWITCHER}>
            <div style={{ minHeight: programs && programs.length * 100 }} />
            <div className={cn('switcher-wrapper')}>
              <div className={cn('heading')}>
                <Button onClick={setMenu('')} type="pagination" variant="reversed" size="s" className={cn('button')} />
                <Text>Switch Program</Text>
              </div>
              <div className={cn('programs-list')}>
                {(programs || []).map((program, idx) => (
                  <div
                    key={`program-${idx}`}
                    aria-hidden
                    className={cn('program-wrapper')}
                    onClick={handleProgramSwitch(program)}
                  >
                    <ProgramAvatar className={cn('avatar')} imageUrl={program.avatar_url} name={program.name} />
                    <Text className={cn('name')} type="body-big">
                      {program.name}
                    </Text>
                    {program.id === currentProgramId && <CheckIcon className={cn('check')} />}
                  </div>
                ))}
              </div>
            </div>
          </Collapse>
        </nav>
      </DisplayOnDesktop>
      <DisplayOnMobile>
        <nav className={cn('mobile-wrapper', { hidden: hideMobileNav })}>
          {isExpiredShow && (
            <div className={cn('license', 'license-mobile')}>
              School license is not active.
              <CloseIcon className={cn('icon')} onClick={() => setIsExpiredShow(false)} />
            </div>
          )}
          <div className={cn('mobile-links')}>
            {SECTIONS.map((navItem, i) => generateMobileNavlinkElement(navItem, i))}
          </div>
        </nav>
      </DisplayOnMobile>
    </>
  );
};

export default Navbar;
