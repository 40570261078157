import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// hooks
import { useBottomMenuSetter } from 'hooks/useBottomMenu';
// utils
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { buildUrl, getWeekOrderNumber } from 'utils';
import {
  getClassroomUnit,
  getClassroomWeek,
  getLearningExperience,
  markLearningExpFavorite,
  markLearningExpFinished,
} from 'store/classrooms';
import { getTitleFromUri } from 'utils/string';
import { animated, useSpring, useTransition } from 'react-spring';
import { useSwipeable } from 'react-swipeable';
import {
  eventCategory,
  eventAction,
  eventLabel,
  USER_ROLE,
  PROGRAM_NAME,
  UNIT_NUMBER,
  UNIT_TITLE,
  WEEK_NAME,
  gaRoleLabels,
  customDimensions,
} from 'constants/googleAnalytics';
import { GAEmitEvent } from 'utils/googleAnalytics';
// components
import Text from 'components/Text';
import BottomMenu from 'components/BottomMenu';
import Link from 'components/Link';
import { Foo } from '../BrowserView/LearningExperience';
// icons
import { ReactComponent as DotsIcon } from 'icons/dots-grey.svg';
import { ReactComponent as ChevronIcon } from 'icons/chevron-grey.svg';
import { ReactComponent as CrossIcon } from 'icons/close.svg';
import { ReactComponent as DescriptionIcon } from 'icons/description.svg';
import { ReactComponent as CheckIcon } from 'icons/check-grey.svg';
import { ReactComponent as UnmarkedIcon } from 'icons/FavoriteButton/Unmarked.svg';
import { ReactComponent as ResourcesIcon } from 'icons/resources.svg';
import { ReactComponent as ExternalLinkIcon } from 'icons/external-link.svg';
import { ReactComponent as InternalLinkIcon } from 'icons/internal-link.svg';
import { ReactComponent as PdfIcon } from 'icons/pdf-link.svg';
import { ReactComponent as MarkedIcon } from 'icons/FavoriteButton/Marked.svg';
// styles
import classnames from 'classnames/bind';
import styles from './LearningExperienceMobile.module.scss';

const handlePrintableClick = (uri, userRole, programName, week, unit) => {
  GAEmitEvent(eventCategory.PDF, eventAction.DOWNLOAD, eventLabel.learningExperiences(getTitleFromUri(uri)), {
    [customDimensions[USER_ROLE]]: gaRoleLabels[userRole],
    [customDimensions[PROGRAM_NAME]]: programName,
    [customDimensions[UNIT_NUMBER]]: get(unit, 'order_number', null),
    [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
    [customDimensions[WEEK_NAME]]: get(week, 'weeks[0].name', null),
  });
  return window.open(buildUrl(uri), '_blank');
};

const getPropperLink = (link) => (link.includes('http') ? link : `//${link}`);

const cn = classnames.bind(styles);

const LearningExperienceViewMobile = ({ experience, type, onClick, src, week, unit }) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [videoTitle, setVideoTitle] = useState('');
  const expandProps = useSpring(
    !isDescriptionExpanded
      ? { top: '20px', left: '20px', position: 'absolute', width: '0vw', height: '0vh', borderRadius: 8 }
      : { top: '0', left: '0', position: 'fixed', width: '100vw', height: '100vh', borderRadius: 0 },
  );
  const isExpandable = !['ti', 'di'].includes(experience.description_place) && !!experience.description;
  const { role } = useSelector(({ auth }) => auth.user);
  const { name: programName } = useSelector(({ program }) => program);

  useEffect(() => {
    setIsDescriptionExpanded(false);
  }, [experience.id]);

  const handleVideoWrapperClick = useCallback(() => {
    GAEmitEvent(eventCategory.USER_ACTION, eventAction.CLICK, eventLabel.click('Video', videoTitle), {
      [customDimensions[USER_ROLE]]: gaRoleLabels[role],
      [customDimensions[PROGRAM_NAME]]: programName,
      [customDimensions[UNIT_NUMBER]]: get(unit, 'units[0].order_number', null),
      [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
      [customDimensions[WEEK_NAME]]: get(week, `weeks[0].name`, null),
    });
  }, [videoTitle, unit, week]);

  return (
    <div onClick={onClick} className={cn('item-wrapper')}>
      {experience.description_place === 'ti' && (
        <div
          style={{
            background: experience.description_background_color,
            color: experience.description_text_color,
          }}
          className={cn('exp-description')}
        >
          <div dangerouslySetInnerHTML={{ __html: experience.description }} />
        </div>
      )}
      {isExpandable && (
        <animated.div
          style={{
            background: experience.description_background_color,
            color: experience.description_text_color,
            ...expandProps,
          }}
          className={cn('expandable-description', { isDescriptionExpanded })}
          onClick={(e) => {
            e.stopPropagation();
            setIsDescriptionExpanded(true);
          }}
        >
          {!isDescriptionExpanded && <DescriptionIcon />}
          {isDescriptionExpanded && (
            <div className={cn('inner-content')}>
              <CrossIcon
                className={cn('close')}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDescriptionExpanded(false);
                }}
              />
              <div
                style={{ color: experience.description_text_color }}
                dangerouslySetInnerHTML={{ __html: experience.description }}
              />
            </div>
          )}
        </animated.div>
      )}
      {type === 'image' && <div style={{ backgroundImage: `url('${buildUrl(src)}')` }} className={cn('image-div')} />}
      {type === 'video' && (
        <div onClick={handleVideoWrapperClick}>
          <Foo videoId={experience.id} link={experience.link} setVideoTitle={setVideoTitle} />
        </div>
      )}
      {experience.description_place === 'di' && (
        <div
          style={{
            background: experience.description_background_color,
            color: experience.description_text_color,
          }}
          className={cn('exp-description')}
        >
          <div dangerouslySetInnerHTML={{ __html: experience.description }} />
        </div>
      )}
    </div>
  );
};

const ReadingsMenu = ({ isExpanded, setIsExpanded, className, children, isFullscreen }) => {
  const [menuDragOffset, setMenuDragOffset] = useState(0);
  const [isFullOpen, setIsFullOpen] = useState(false);
  const prevTouch = useRef();
  const menuRef = useRef();
  const transform = useSpring({
    bottom: isExpanded
      ? isFullOpen
        ? `calc(0% + ${menuDragOffset}px)`
        : `calc(-30% + ${menuDragOffset}px)`
      : `calc(-90% + 0px)`,
  });
  const opacity = useSpring({ opacity: isExpanded ? 1 : 0 });
  const handleTouchStart = (e) => {
    prevTouch.current = e.touches[0].clientY;
  };

  const handleTriggerClick = () => {
    if (!isExpanded) {
      return setIsExpanded(true);
    }
    if (isExpanded && !isFullOpen) {
      return setIsFullOpen(true);
    }
    setIsExpanded(false);
  };

  useEffect(() => {
    if (!isExpanded) {
      setIsFullOpen(false);
    }
  }, [isExpanded]);

  const handleMenuDrag = (e) => {
    if (menuRef.current) {
      const offset = prevTouch.current - e.touches[0].clientY;
      setMenuDragOffset((prevOffset) => (offset < 0 ? prevOffset + offset : prevOffset < 0 ? prevOffset + offset : 0));
      prevTouch.current = e.touches[0].clientY;
    }
  };

  const handleTouchEnd = (e) => {
    e.stopPropagation();
    if (menuRef.current) {
      const draggedPastHalf = Math.abs(menuDragOffset) > menuRef.current.getBoundingClientRect().height * 0.2;

      if (draggedPastHalf) {
        setMenuDragOffset(0);

        return setIsExpanded(false);
      }
      setMenuDragOffset(0);
    }
  };
  return (
    <animated.div
      ref={menuRef}
      style={{
        bottom: transform.bottom,
      }}
      className={cn('bottom-menu', className, { isExpanded, isFullscreen, isFullOpen })}
    >
      <div
        aria-hidden
        className={cn('trigger')}
        onClick={handleTriggerClick}
        onTouchStart={handleTouchStart}
        onTouchMove={handleMenuDrag}
        onTouchEnd={handleTouchEnd}
      ></div>
      {isExpanded && <div className={cn('divider')} />}
      <animated.div style={opacity} className={cn('content-wrapper')}>
        {children}
      </animated.div>
    </animated.div>
  );
};

const NavigationMenu = ({
  experience: { id, finished, favorite, name, required },
  classroomId,
  unitId,
  weekId,
  unit,
  week,
  programName,
}) => {
  const dispatch = useDispatch();
  const {
    location: { pathname, search },
  } = useHistory();
  const [isFavorite, setIsFavorite] = useState(favorite);
  const [isCompleted, setIsCompleted] = useState(finished);

  const { role } = useSelector(({ auth }) => auth.user);

  useEffect(() => {
    setIsFavorite(favorite);
  }, [favorite]);

  useEffect(() => {
    setIsCompleted(finished);
  }, [finished]);

  const handleFavoriteClick = (_id, _isFavorite) => {
    _isFavorite &&
      GAEmitEvent(
        eventCategory.USER_ACTION,
        eventAction['FAVORITE'],
        eventLabel.toggleFavorite(name, 'LearningExperience', pathname + search),
        {
          [customDimensions[USER_ROLE]]: gaRoleLabels[role],
          [customDimensions[PROGRAM_NAME]]: programName,
          [customDimensions[UNIT_NUMBER]]: get(unit, 'order_number', null),
          [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
          [customDimensions[WEEK_NAME]]: get(week, 'weeks[0].name', null),
        },
      );
    setIsFavorite(_isFavorite);
    dispatch(markLearningExpFavorite(_id, _isFavorite));
  };
  const handleFinishedClick = (cId, lexpId, _finished, _unitId, _weekId) => {
    setIsCompleted(_finished);
    dispatch(markLearningExpFinished(cId, lexpId, _finished, _unitId, _weekId));

    _finished &&
      required &&
      GAEmitEvent(
        eventCategory.USER_ACTION,
        eventAction.MENU_CLICK,
        eventLabel.requiredActivity(name, 'Learning Experience', pathname + search),
        {
          [customDimensions[USER_ROLE]]: gaRoleLabels[role],
          [customDimensions[PROGRAM_NAME]]: programName,
          [customDimensions[UNIT_NUMBER]]: get(unit, 'units[0].order_number', null),
          [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
          [customDimensions[WEEK_NAME]]: get(week, `weeks[0].name`, null),
        },
      );
  };
  return (
    <BottomMenu menuHeading="Experience options">
      <div className={cn('menu-wrapper')}>
        {isFavorite ? (
          <div
            className={cn('menu-wrapper--subsection')}
            aria-hidden
            onClick={() => handleFavoriteClick(id, !isFavorite)}
          >
            <MarkedIcon style={{ color: 'rgb(84, 88, 90)' }} height="25" width="25" />
            <Text className={cn('ml-10', 'no-select')}>Remove from Library</Text>
          </div>
        ) : (
          <div
            className={cn('menu-wrapper--subsection')}
            aria-hidden
            onClick={() => handleFavoriteClick(id, !isFavorite)}
          >
            <UnmarkedIcon height="25" width="25" />
            <Text className={cn('ml-10', 'no-select')}>Add to Library</Text>
          </div>
        )}
        {isCompleted ? (
          <div
            className={cn('menu-wrapper--subsection')}
            aria-hidden
            onClick={() => handleFinishedClick(classroomId, id, !isCompleted, unitId, weekId)}
          >
            <CrossIcon height="25" width="25" />
            <Text className={cn('ml-10', 'no-select')}>Mark Uncompleted</Text>
          </div>
        ) : (
          <div
            className={cn('menu-wrapper--subsection')}
            aria-hidden
            onClick={() => handleFinishedClick(classroomId, id, !isCompleted, unitId, weekId)}
          >
            <CheckIcon height="25" width="25" />
            <Text className={cn('ml-10', 'no-select')}>Mark Completed</Text>
          </div>
        )}
      </div>
    </BottomMenu>
  );
};

const LearningExperienceMobile = () => {
  const dispatch = useDispatch();
  const { setBottomMenu, closeMenu } = useBottomMenuSetter();
  const { goBack, length, push, location } = useHistory();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isReverse, setIsReverse] = useState(false);
  const [currentChapter, setCurrentChapter] = useState(0);
  const { classroomId, unitId, weekId, learnExpId } = useParams();
  const { week, unit, isLoading, hasErrors, classrooms, classroom, learnExp } = useSelector(
    ({ classrooms: { classrooms, learningExperience, isLoading: _isLoading, hasErrors: _hasErrors } }) => {
      const _classroom = classrooms.find(({ id }) => +classroomId === id);
      return {
        week: get(_classroom, `unit_progress[${unitId}].week_progress[${weekId}]`, {}),
        unit: get(_classroom, `unit_progress[${unitId}]`, {}),
        isLoading: _isLoading,
        hasErrors: _hasErrors,
        classrooms,
        learnExp: learningExperience,
        classroom: _classroom || {},
      };
    },
  );
  const {
    user: { role },
  } = useSelector(({ auth }) => auth);
  const { id: schoolId } = useSelector(({ school }) => school);
  const { name: programName } = useSelector(({ program }) => program);

  const [isExpandedRes, setIsExpandedRes] = useState(false);

  const chapters = learnExp.learning_experience_chapters || [];

  const { week_progresses: weekProgresses } = get(classroom, `unit_progress.${unitId}`, {});

  useEffect(() => {
    schoolId && dispatch(getLearningExperience(classroomId, learnExpId)).catch(() => push('/classrooms'));
  }, [dispatch, location.pathname, schoolId]);

  useEffect(() => {
    if (classroom && isEmpty(weekProgresses) && !isLoading && !hasErrors)
      dispatch(getClassroomUnit(classroomId, unitId));
  }, [dispatch, classroomId, unitId, weekProgresses, isLoading, hasErrors, classroom]);

  useEffect(() => {
    if (!isEmpty(classrooms) && isEmpty(week) && !isLoading && !hasErrors)
      dispatch(getClassroomWeek(classroomId, unitId, weekId));
  }, [dispatch, classroomId, unitId, weekId, week, isLoading, hasErrors, classrooms]);

  const resourcesProps = useSpring({
    right: isExpandedRes ? '0%' : '-80%',
  });

  const handleNext = useCallback(
    ({ event }) => {
      if ([...event.target.classList].join('').includes('jw-')) return;
      setIsReverse(false);
      setCurrentChapter((currentChapter + 1) % chapters.length);
    },
    [currentChapter, chapters.length],
  );
  const handlePrev = useCallback(
    ({ event }) => {
      if ([...event.target.classList].join('').includes('jw-')) return;
      setIsReverse(true);
      setCurrentChapter(currentChapter - 1 < 0 ? chapters.length - 1 : currentChapter - 1);
    },
    [currentChapter, chapters.length],
  );

  const handlers = useSwipeable({
    onSwipedLeft: (e) => handleNext(e),
    onSwipedRight: (e) => handlePrev(e),
  });

  const transitions = useTransition(currentChapter, null, {
    from: { left: `${!isReverse ? '100%' : '-100%'}` },
    enter: { left: '0%' },
    leave: { left: `${isReverse ? '100%' : '-100%'}` },
  });

  return (
    <div className={cn('wrapper')}>
      <ChevronIcon
        className={cn('wrapper--chevron')}
        onClick={() => push(`/classroom/${classroomId}/unit/${unitId}/week/${weekId}?tab=experiences`)}
      />
      <div className={cn('wrapper--main')}>
        <Text className={cn('heading')} type="h5">
          {learnExp.name}
        </Text>
        <div>
          <Text className={cn('breadcrumbs')} type="body-xs">
            {classroom.name}
            &nbsp;&nbsp;|&nbsp;&nbsp;
            {`Unit ${unit.order_number}`}
            &nbsp;&nbsp;|&nbsp;&nbsp;
            {getWeekOrderNumber(week.order_number, false, true)}
          </Text>
        </div>
      </div>
      <DotsIcon
        className={cn('wrapper--dots')}
        onClick={() =>
          setBottomMenu(
            <NavigationMenu
              experience={learnExp}
              classroomId={classroomId}
              unitId={unitId}
              weekId={weekId}
              unit={unit}
              week={week}
              programName={programName}
            />,
          )
        }
      />

      {!!(
        (learnExp.activities || []).length +
        (learnExp.learning_experiences_links || []).length +
        (learnExp.printable_urls || []).length +
        (learnExp.external_links || []).length
      ) && (
        <animated.div style={resourcesProps} className={cn('resources-nav', { isFullscreen })}>
          <div className={cn('button-wrapper', { isExpandedRes })}>
            <div
              className={cn('button', { isExpandedRes })}
              aria-hidden
              onClick={() => setIsExpandedRes(!isExpandedRes)}
            >
              {!isExpandedRes && (
                <>
                  <ResourcesIcon height="20" width="20" />
                  &nbsp;
                  <span>
                    <Text type="h7">
                      {`(${
                        (learnExp.activities || []).length +
                        (learnExp.learning_experiences_links || []).length +
                        (learnExp.printable_urls || []).length +
                        (learnExp.external_links || []).length
                      })`}
                    </Text>
                  </span>
                </>
              )}
              {isExpandedRes && <CrossIcon />}
            </div>
          </div>
          <div className={cn('content-wrapper', { isFullscreen })}>
            <Text type="body-big" className="mb-10">
              Resources
            </Text>
            {learnExp.activities.map(({ name, id }, idx) => (
              <Link
                key={`res1-${idx}`}
                to={`/classroom/${classroomId}/unit/${unitId}/week/${weekId}/lesson-plan/${id}`}
                target="_blank"
                className={cn('resource', { isFullscreen })}
              >
                <InternalLinkIcon className={cn('res-icon')} />
                {name}
              </Link>
            ))}
            {learnExp.learning_experiences_links.map(({ name, id }, idx) => (
              <Link
                key={`res1-${idx}`}
                to={`/classroom/${classroomId}/unit/${unitId}/week/${weekId}/learning-experience/${id}`}
                target="_blank"
                className={cn('resource', { isFullscreen })}
              >
                <InternalLinkIcon className={cn('res-icon')} />
                {name}
              </Link>
            ))}
            {learnExp.printable_urls.map(({ attachment_url }, idx) => (
              <Link
                key={`res2-${idx}`}
                onClick={() => handlePrintableClick(attachment_url, role, programName, week, unit)}
                className={cn('resource', { isFullscreen })}
              >
                <PdfIcon className={cn('res-icon')} />
                {getTitleFromUri(attachment_url)}
              </Link>
            ))}
            {learnExp.external_links.map(({ id, name, url }, idx) => (
              <Link
                key={`res3-${idx}`}
                to={{ pathname: getPropperLink(url) }}
                target="_blank"
                className={cn('resource', { isFullscreen })}
              >
                <ExternalLinkIcon className={cn('res-icon')} />
                {name}
              </Link>
            ))}
          </div>
        </animated.div>
      )}
      <div {...handlers} className={cn('learning-exp-view', { isFullscreen })}>
        {isFullscreen && <CrossIcon className={cn('close-fullscreen')} onClick={() => setIsFullscreen(false)} />}
        {transitions.map(({ props, item }, key) => {
          const chapter = chapters[item] || {};
          return (
            <animated.div key={key} style={props} className={cn('learn-animate-wrap', { isFullscreen })}>
              <LearningExperienceViewMobile
                type={chapter.image_url ? 'image' : 'video'}
                src={chapter.image_url || chapter.link}
                experience={chapter}
                onClick={(e) => {
                  setIsFullscreen(true);
                  e.stopPropagation();
                }}
                week={week}
                unit={unit}
              />
            </animated.div>
          );
        })}
        <div className={cn('progress-dots-wrapper')}>
          {Array(chapters.length)
            .fill('')
            .map((_, idx) => (
              <div className={cn('dot', { isCurrent: idx === currentChapter })} />
            ))}
        </div>
      </div>
      <ReadingsMenu isExpanded={isExpanded} setIsExpanded={setIsExpanded} isFullscreen={isFullscreen}>
        <div
          dangerouslySetInnerHTML={{
            __html: !isEmpty(learnExp) && (learnExp.learning_experience_chapters[currentChapter] || {}).content,
          }}
        />
      </ReadingsMenu>
    </div>
  );
};

export default LearningExperienceMobile;
