import React from 'react';
import T from 'prop-types';
import classnames from 'classnames/bind';
// utils
import { buildUrl } from 'utils';
// styles
import styles from './UserAvatar.module.scss';

const cn = classnames.bind(styles);

const UserAvatar = ({ url, className, size = 'regular', zIndex = 0, isBordered }) => (
  <div className={cn('wrapper', className, { [size]: size, bordered: isBordered })} style={{ zIndex }}>
    <div className={cn('hover-filter')} />
    <img src={buildUrl(url)} className={cn('avatar')} alt="user-avatar" />
  </div>
);

UserAvatar.propTypes = {
  url: T.string,
  size: T.oneOf(['small', 'regular', 'large']),
  zIndex: T.number,
  className: T.string,
  isBordered: T.bool,
};

export default UserAvatar;
