import React, { useState } from 'react';
import T from 'prop-types';
import { useLocation } from 'react-router-dom';
// utils
import { isMobile } from 'react-device-detect';
// components
import Carousel from '@brainhubeu/react-carousel';
import Dots from './Dots';
// icons
import { ReactComponent as ArrowIcon } from 'icons/Arrows/LeftBlack.svg';
import { ReactComponent as FavoriteIcon } from 'icons/favorite.svg';
// styles
import classnames from 'classnames/bind';
import styles from './ImageCarousel.module.scss';
import '@brainhubeu/react-carousel/lib/style.css';

const cn = classnames.bind(styles);

const ImageCarousel = ({ images = [] }) => {
  const { pathname, search } = useLocation();
  const [currentIndex, setCurrentIndex] = useState(0);

  const generateImages = (imagesArray) =>
    imagesArray.map((img) =>
      img ? <img src={img} style={{ width: '100%' }} aria-label="image" /> : <FavoriteIcon width="100" />,
    );

  return (
    <div className={cn('wrapper', { mobile: isMobile })}>
      <Carousel
        value={currentIndex}
        onChange={(value) => setCurrentIndex(value)}
        slides={generateImages(images)}
        centered
        draggable={false}
      />
      {images.length > 1 && (
        <>
          <div
            aria-hidden
            className={cn('left-arrow--wrapper', { mobile: isMobile })}
            onClick={() => {
              currentIndex > 0 && setCurrentIndex((prev) => prev - 1);
            }}
          >
            <ArrowIcon className={cn('arrow-left')} />
          </div>
          <div
            aria-hidden
            className={cn('right-arrow--wrapper', { mobile: isMobile })}
            onClick={() => {
              currentIndex < images.length - 1 && setCurrentIndex((prev) => prev + 1);
            }}
          >
            <ArrowIcon className={cn('arrow-right')} />
          </div>
        </>
      )}
      <div className={cn('dots-container', { mobile: isMobile })}>
        {images.length > 1 && <Dots currentIndex={currentIndex} numDots={images.length} cn={cn} />}
      </div>
    </div>
  );
};

ImageCarousel.propTypes = {
  images: T.arrayOf(T.string).isRequired,
};

export default ImageCarousel;
