import React from 'react';
// hoc
import withClassroomContentIdChange from 'hoc/withClassroomContentIdChange';
// utils
import DisplayOnDesktop from 'containers/DisplayOnDesktop';
import DisplayOnMobile from 'containers/DisplayOnMobile';
// components
import BrowserView from './BrowserView';
import MobileView from './MobileView';

const Classroom = () => {
  return (
    <>
      <DisplayOnDesktop>
        <BrowserView />
      </DisplayOnDesktop>
      <DisplayOnMobile>
        <MobileView />
      </DisplayOnMobile>
    </>
  );
};

export default withClassroomContentIdChange(Classroom);
