import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// utils
import { isMobileOnly } from 'react-device-detect';
// styles
import classnames from 'classnames/bind';
import styles from './Footer.module.scss';

const cn = classnames.bind(styles);

const Footer = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  if (isMobileOnly && !['/sign-up', '/login'].some((el) => pathname.includes(el))) {
    return null;
  }

  const isLearningExp = pathname.includes('learning-experience');
  if (isLearningExp) return null;
  return (
    <footer className={cn('wrapper', 'isSmall', { isMobile: isMobileOnly })}>
      <div className={cn('copyright')}>{`© ${new Date().getFullYear()} Sesame Workshop`}</div>
      <div className={cn('links')}>
        <div onClick={() => push('/terms-of-use')}>Terms of Use</div>
        <div onClick={() => push('/privacy-policy')}>Privacy Policy</div>
        <div><a class='remove-all-styles' href='mailto: SELF.Curriculum@sesame.org'>Contact Us</a></div>
      </div>
    </footer>
  );
};

export default Footer;
