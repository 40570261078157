import React, { useState, useEffect, createRef, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// utils
import { buildFrontUrl } from 'utils';
import { prepareImage } from '../../helpers';
import { useBottomMenuSetter } from 'hooks/useBottomMenu';
import { determineDimensions, scrollTo } from '../../helpers';
import { useSelector, useDispatch } from 'react-redux';
import { markFavorite, getLessonPLans, getProgressesIds } from 'store/classrooms';
import { GRADE_COLORS_REVERTED } from 'constants/classrooms';
import {
  eventCategory,
  eventAction,
  eventLabel,
  USER_ROLE,
  PROGRAM_NAME,
  UNIT_NUMBER,
  UNIT_TITLE,
  WEEK_NAME,
  gaRoleLabels,
  customDimensions,
} from 'constants/googleAnalytics';
import { GAEmitEvent } from 'utils/googleAnalytics';
import queryString from 'query-string';
import { get } from 'lodash';
// icons
import { ReactComponent as ListIcon } from 'icons/list-white.svg';
// components
import Portal from 'components/Portal';
import BottomMenu from 'components/BottomMenu';
import HeartShapedBox from 'components/HeartShapedBox';
import Button from 'components/Button';
import Text from 'components/Text';
// styles
import classnames from 'classnames/bind';
import styles from './LessonPlanSection.module.scss';
import './LessonPlanSection.scss';

const cn = classnames.bind(styles);

const LessonPlansSection = () => {
  const bodies = useRef({});
  const linkResActionRef = useRef({});
  const [elRefs, setElRefs] = useState([]);
  const [scrolledToAnchor, setScrolledToAnchor] = useState(false);
  const [visibleSection, setVisibleSection] = useState();
  const [images, setImages] = useState({});
  const { classroomId, activityId, weekId, unitId } = useParams();
  const { pathname, search } = useLocation();
  const { sel_competency, activity_chapters, sel_review } = useSelector((state) => state.classrooms.lessonPlans);
  const { color: classroomColor, week, unit } = useSelector(({ classrooms: { classrooms = [] } }) => {
    const _clsrm = classrooms.find(({ id }) => id === +classroomId);

    return {
      color: get(_clsrm, 'color', ''),
      week: get(_clsrm, `unit_progress[${unitId}].week_progress[${weekId}].weeks[0]`, {}),
      unit: get(_clsrm, `unit_progress[${unitId}]`, {}),
    };
  });
  const { role } = useSelector(({ auth }) => auth.user);
  const { name: programName } = useSelector(({ program }) => program);
  const { chapterId } = queryString.parse(search);

  const dispatch = useDispatch();

  const { setBottomMenu, ...rest } = useBottomMenuSetter();

  useEffect(() => {
    activity_chapters &&
      setElRefs((elRefs) =>
        Array(activity_chapters.length)
          .fill()
          .map((_, i) => elRefs[i] || createRef()),
      );
    (activity_chapters || []).forEach(
      ({ image_url }, idx) => image_url && prepareImage(image_url, idx, setImages, 315),
    );
  }, [activity_chapters]);

  const sectionRefs = activity_chapters ? activity_chapters.map((_, i) => ({ section: i, ref: elRefs[i] })) : [];

  useEffect(() => {
    let to = null;
    if (activity_chapters && !scrolledToAnchor) {
      const idx = activity_chapters.findIndex(({ id }) => id === +chapterId);
      if (idx !== -1 && elRefs[idx] && elRefs[idx].current) {
        to = setTimeout(() => scrollTo(elRefs[idx].current, 300), 300);
        setScrolledToAnchor(true);
      }
    }

    return () => to && clearTimeout(to);
  }, [activity_chapters, elRefs, chapterId]);

  useEffect(() => {
    const handleScroll = () => {
      // + 15 to ensure proper color switch
      const scrollPosition = window.scrollY + 15;

      const selected = sectionRefs.find(({ section, ref }) => {
        const element = ref && ref.current;
        if (element) {
          const { offsetBottom, offsetTop } = determineDimensions(element);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
      });

      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && typeof visibleSection === 'number') {
        setVisibleSection(undefined);
      }
    };

    handleScroll();
    window.addEventListener('touchmove', handleScroll);
    return () => window.removeEventListener('touchmove', handleScroll);
  }, [visibleSection, sectionRefs]);

  const handleFavoriteClick = (type, id, toMark, chapterName) => {
    toMark &&
      GAEmitEvent(
        eventCategory.USER_ACTION,
        eventAction['FAVORITE'],
        eventLabel.toggleFavorite(chapterName, type, pathname + search),
        {
          [customDimensions[USER_ROLE]]: gaRoleLabels[role],
          [customDimensions[PROGRAM_NAME]]: programName,
          [customDimensions[UNIT_NUMBER]]: get(unit, 'order_number', null),
          [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
          [customDimensions[WEEK_NAME]]: get(week, 'name', null),
        },
      );
    return dispatch(markFavorite(type, id, toMark)).then(() => dispatch(getLessonPLans(classroomId, activityId)));
  };

  const generateChapterHeader = (chapterName, chapterNumber, isFavorite, chapterId) => (
    <div className={cn('main-section--chapter-header')}>
      <Text type="h4">
        {chapterNumber}.&nbsp;{chapterName}
      </Text>
      <Button
        className={cn('main-section--chapter-header--button')}
        type="favorite"
        round
        marked={isFavorite}
        onClick={(e) => {
          e.stopPropagation();
          handleFavoriteClick('ActivityChapter', chapterId, !isFavorite, chapterName);
        }}
      />
    </div>
  );

  const generateHtmlFromResponse = (HTMLString, idx) => {
    const ChapterBody = document.createElement('div');
    const parser = new DOMParser();
    let image = images[idx];
    const shouldRenderImage = image && activity_chapters[idx].image_url;

    const chapterBody = parser.parseFromString(HTMLString, 'text/html').body;

    const anchorTags = chapterBody.getElementsByTagName('a');
    const tables = chapterBody.getElementsByTagName('table');

    for (const table of tables) {
      const elWrapper = document.createElement('div');
      elWrapper.classList.add('table-wrapper');
      const parentEl = table.parentNode;
      parentEl.replaceChild(elWrapper, table);
      elWrapper.appendChild(table);
    }

    ChapterBody.appendChild(chapterBody);

    for (let a of anchorTags) {
      const [resourceId, resourceName] = `${a.href}`.split('/').reverse();
      if (!resourceId || !resourceName || !['lesson-plan', 'learning-experience'].includes(resourceName)) continue;

      if (linkResActionRef.current && linkResActionRef.current[`${resourceName}-${resourceId}-${idx}`]) continue;
      dispatch(getProgressesIds(+classroomId, +resourceId, resourceName === 'lesson-plan')).then(
        ({ unitId, weekId }) => {
          a.setAttribute(
            'href',
            buildFrontUrl(`/classroom/${classroomId}/unit/${unitId}/week/${weekId}/${resourceName}/${resourceId}`),
          );
          bodies.current = { ...bodies.current, [idx]: ChapterBody.innerHTML };
        },
      );
      linkResActionRef.current = { ...linkResActionRef.current, [`${resourceName}-${resourceId}-${idx}`]: true };
    }

    return [ChapterBody.innerHTML, shouldRenderImage ? image : null];
  };

  const generateChapter = (chapter, index) => {
    const [chapterBody, image] = generateHtmlFromResponse(chapter.text, index);
    const imageCaption = chapter.caption;

    return (
      <div key={index} ref={elRefs[index]} className={cn('main-section--chapter')}>
        {generateChapterHeader(chapter.name, chapter.order_number, chapter.favorite, chapter.id)}
        {image && (
          <div className={cn('main-section--image-wrapper')}>
            <img
              src={image.src}
              style={{ width: image.width, height: image.height }}
              className={cn('main-section--chapter-image')}
              aria-label="image"
              alt="Chapter image"
            />
            <Text className={cn('image-caption')} type="body-small" style={{ maxWidth: image.width }}>
              {imageCaption}
            </Text>
          </div>
        )}
        <div
          dangerouslySetInnerHTML={{ __html: bodies.current[index] ? bodies.current[index] : chapterBody }}
          style={{ maxWidth: '100%' }}
        />
      </div>
    );
  };

  const generateNavMenu = () => (
    <BottomMenu menuHeading="sections">
      <div className={cn('nav-menu')}>
        {(activity_chapters || []).map((acChapter, i) => (
          <Text
            key={i}
            className={cn('nav-menu--text', 'no-select', { ['nav-menu--text--active']: +visibleSection === +i })}
            onClick={() => scrollTo(elRefs[i].current && elRefs[i].current, 150)}
          >
            {acChapter.order_number}.&nbsp;{acChapter.name}
          </Text>
        ))}
      </div>
    </BottomMenu>
  );

  return (
    <div className={cn('wrapper', 'mb-60')}>
      <Portal>
        <div className="nav-button--lesson-plans-mobile" onClick={() => setBottomMenu(generateNavMenu())}>
          <ListIcon />
        </div>
      </Portal>
      <div
        className={cn(
          'main-section',
          `main-section--${GRADE_COLORS_REVERTED[classroomColor && classroomColor.toLowerCase()]}`,
        )}
      >
        {sel_competency && (
          <HeartShapedBox
            targetSel={sel_competency}
            lessonPlans
            className={cn('main-section--sel-header')}
            selReview={sel_review}
          />
        )}
        {activity_chapters && activity_chapters.map((chapter, i) => generateChapter(chapter, i))}
      </div>
    </div>
  );
};

export default LessonPlansSection;
