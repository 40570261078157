import React, { memo } from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './Text.module.scss';

const cn = classnames.bind(styles);

const tagsMap = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  h7: 'h6',
  h8: 'h6',
  h9: 'h6',
  h10: 'h6',
  h11: 'h6',
  body: 'p',
  'body-big': 'p',
  'body-small': 'p',
  'body-xs': 'p',
};

const Text = memo(({ type = 'body', bold = false, children = null, action, className, ...props }) => {
  const Component = tagsMap[type];

  return (
    <Component
      className={cn('text', className, {
        [`text--tag-${type}`]: type,
        [`text--bold`]: bold,
        action,
      })}
      {...props}
    >
      {children}
    </Component>
  );
});

Text.propTypes = {
  type: T.oneOf(Object.keys(tagsMap)),
  bold: T.bool,
  children: T.oneOfType([T.arrayOf(T.node), T.node]),
  action: T.bool,
  className: T.string,
};

export default Text;
