import React, { useEffect } from 'react';
// utils
import { isAndroid, isIOS13, isIPad13 } from 'react-device-detect';
import classnames from 'classnames/bind';
// icons
import { ReactComponent as DotsIcon } from 'icons/dots.svg';
import { ReactComponent as IosShareIcon } from 'icons/ios-share.svg';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
// components
import Modal from 'components/Modal';
import Text from 'components/Text';
// styles
import styles from './AddAsAppModal.module.scss';

const cn = classnames.bind(styles);

const IosModal = ({ onClose, isArrowed }) => (
  <div className={cn('modal-wrapper', { isArrowed })}>
    <CloseIcon className={cn('close-button')} onClick={onClose} />
    <Text>Add Sesame Learning to your phone&apos;s home screen to view like an iPhone app!</Text>
    <br />
    <Text bold>
      Just tap the
      <IosShareIcon className={cn('ios-share')} />
      button below and then &quot;Add to Home Screen&quot;
    </Text>
  </div>
);

const AndroidModal = ({ onClose, isArrowed }) => (
  <div className={cn('modal-wrapper', { isArrowed })}>
    <CloseIcon className={cn('close-button')} onClick={onClose} />
    <Text>Add Sesame Learning to your phone&apos;s home screen to view like an Android app!</Text>
    <br />
    <Text bold>
      Just tap the
      <DotsIcon className={cn('android-share')} />
      button and then &quot;Add to Home Screen&quot;
    </Text>
  </div>
);

const AddAsAppModal = () => {
  useEffect(() => {
    localStorage.setItem('add-app-modal-shown', true);
  }, []);
  return (
    <Modal location="bottom">
      {({ closeModal }) => (
        <>
          {isIOS13 && <IosModal isArrowed={!isIPad13} onClose={closeModal} />}
          {isAndroid && <AndroidModal onClose={closeModal} />}
        </>
      )}
    </Modal>
  );
};

export default AddAsAppModal;
