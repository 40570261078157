import React from 'react';
import T from 'prop-types';
// utils
import { TITLE_OPTIONS_LABELS, isAdminUser, USER_ROLE_LABELS, isSchoolAdmin } from 'constants/user';
import { buildUrl } from 'utils';
// hoc
import withTabSwitcher from 'hoc/withTabSwitcher';
// components
import Text from 'components/Text';
import AdminBadge from 'components/Badges/AdminBadge';
// styles
import classnames from 'classnames/bind';
import styles from './BrowserView.module.scss';

const cn = classnames.bind(styles);

const BrowserView = ({ userData, ...props }) => {
  const { title = '', first_name: firstName, avatar_url: imageUrl, role } = userData;

  const userTitle = title && TITLE_OPTIONS_LABELS[title.toLowerCase()];

  return (
    <div className={cn('header-wrapper')}>
      <img className={cn('avatar')} src={buildUrl(imageUrl)} aria-hidden alt="Avatar" />
      <div className="ml-15">
        <Text type="body-big">
          {userTitle}
          &nbsp;
          {firstName}
        </Text>
        <Text className={cn('title')}>My Profile</Text>
      </div>
      {isAdminUser(role) && (
        <AdminBadge
          className={cn('admin-badge')}
          schoolAdmin={isSchoolAdmin(role)}
          role={USER_ROLE_LABELS[role]}
          long
        />
      )}
    </div>
  );
};

BrowserView.propTypes = {
  userData: T.shape({
    avatar_url: T.string,
    email: T.string.isRequired,
    first_name: T.string.isRequired,
    last_name: T.string.isRequired,
    title: T.string,
  }),
};

export default withTabSwitcher(BrowserView);
