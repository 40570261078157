import React from 'react';
import T from 'prop-types';
// styles
import classnames from 'classnames/bind';
import styles from './HeartLoader.module.scss';

const cn = classnames.bind(styles);

const HeartLoader = ({ size = 'md', className }) => {
  let scale = 1;
  switch (size) {
    case 'bg':
      scale = 1.5;
      break;
    case 'md':
      scale = 1;
      break;
    case 'sm':
      scale = 0.8;
      break;
    case 'xs':
      scale = 0.5;
      break;
    default:
      break;
  }

  return (
    <div className={cn('lds-heart', className)} style={{ transform: `scale(${scale}) rotate(45deg)` }}>
      <div />
    </div>
  );
};

HeartLoader.propTypes = {
  size: T.oneOf(['bg', 'md', 'sm', 'xs']),
  className: T.string,
};

export default HeartLoader;
