import { buildUrl } from 'utils';

export const determineDimensions = (element) => {
  const { height } = element.getBoundingClientRect();
  const offsetTop = element.offsetTop;
  const offsetBottom = offsetTop + height;

  return { height, offsetTop, offsetBottom };
};

export const scrollTo = (element, offset = 100) => {
  const top = element.getBoundingClientRect().top + window.pageYOffset - offset;
  return window.scrollTo({ top: top, left: 0, behavior: 'smooth' });
};

export const prepareImage = (imgUrl, idx, setState, maxSize = 350) => {
  const img = new Image();
  img.onload = () => {
    let width = img.naturalWidth;
    let height = img.naturalHeight;

    if (width > maxSize) {
      height /= width / maxSize;
      width = maxSize;
    }

    if (height > maxSize) {
      width /= height / maxSize;
      height = maxSize;
    }

    img.width = width;
    img.height = height;

    setState((prev) => ({ ...prev, [idx]: img }));
  };
  img.src = buildUrl(imgUrl);
};

export const mockStickyBehavior = (element, offsetTrigger, absPosition) => {
  return () => {
    if (element) {
      if (window.pageYOffset > offsetTrigger) {
        element.style.position = 'fixed';
        element.style.top = absPosition - offsetTrigger + 'px';
        return;
      }
      element.style.position = 'absolute';
      element.style.top = absPosition + 'px';
    }
  };
};

export const getPrintStyles = (options) => `
  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }

  body {
    margin: 2em !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  button {
    display: none !important;
  }

  .head-print {
    display: inline-block !important;
  }

  .chapter-print {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    line-height: 28px;
    color: #151515;
    position: relative;
    margin-top: 40px !important;
  }

  .chapter-print-break {
    // break-inside: avoid-page !important;
  }

  .chapter-print .image-print {
    position: absolute;
    right: 0;
    top: 20px;
    border-radius: 5px;
    border-radius: 5px !important;
    max-width: 350px !important;
    max-height: 350px !important;
  }

  .header-print {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 20px 0 !important;
    line-height: 40px;
  }

  .sel-header-print {
    margin: 20px 0;
  }

  .sel-header-print h5 {
    min-width: fit-content !important;
  }

  .header-icon-print {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-150%, 25%);
  }

  table {
    // page-break-inside: avoid !important;
    // page-break: avoid !important;
    overflow: hidden;
    table-layout: fixed;
  }

  thead {
    display: table-row-group;
  }

  th {
    background-color: ${options.classroomColor};
  }

  tr {
    display: table-row !important;
  }

  td {
    padding: 20px 30px !important;
    line-height: 26px;
  }
`;
