import React from 'react';
// icons
import { ReactComponent as PlusIcon } from 'icons/add-white.svg';
// styles
import classnames from 'classnames/bind';
import styles from './Button.module.scss';

const cn = classnames.bind(styles);

const PlusFab = ({ iconSize = 25, className, onClick }) => {
  return (
    <div className={cn('fab-wrapper', className)} onClick={onClick}>
      <PlusIcon style={{ width: `${iconSize}px`, height: `${iconSize}px` }} />
    </div>
  );
};

export default PlusFab;
