import React, { useState, useEffect, createRef, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// utils
import { buildFrontUrl } from 'utils';
import { isIE } from 'react-device-detect';
import { GRADE_TITLES, GRADE_COLORS_REVERTED } from 'constants/classrooms';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { markFavorite, getLessonPLans, getProgressesIds } from 'store/classrooms';
import {
  eventCategory,
  eventAction,
  eventLabel,
  USER_ROLE,
  PROGRAM_NAME,
  UNIT_NUMBER,
  UNIT_TITLE,
  WEEK_NAME,
  gaRoleLabels,
  customDimensions,
} from 'constants/googleAnalytics';
import { GAEmitEvent } from 'utils/googleAnalytics';
import queryString from 'query-string';
// UI and styles
import HeartShapedBox from 'components/HeartShapedBox';
import Button from 'components/Button';
import Text from 'components/Text';
import { ReactComponent as ChapterIcon } from 'icons/chapter-icon.svg';
import classnames from 'classnames/bind';
import { prepareImage, scrollTo, determineDimensions, mockStickyBehavior } from '../../helpers';
import styles from './LessonPlansSection.module.scss';
import './LessonPlansSection.scss';
import { getWeekOrderNumber, isEdgeWeek } from 'utils';

const cn = classnames.bind(styles);

const LessonPlansSection = ({ printRef }) => {
  const dynamicUrls = useRef({});
  const linkResActionRef = useRef({});
  const [elRefs, setElRefs] = useState([]);
  const [scrolledToAnchor, setScrolledToAnchor] = useState(false);
  const [visibleSection, setVisibleSection] = useState();
  const [images, setImages] = useState({});
  const { classroomId, activityId, weekId, unitId } = useParams();
  const { pathname, search } = useLocation();
  const { sel_competency, activity_chapters, name: lessonName, sel_review } = useSelector(
    (state) => state.classrooms.lessonPlans,
  );
  const { chapterId } = queryString.parse(search);

  const { classroomColor, week, unit, gradeLevel } = useSelector(({ classrooms: { classrooms } }) => {
    const classroom = classrooms.length !== 0 && classrooms.find(({ id }) => id === +classroomId);
    return {
      classroomColor: get(classroom, 'color', '').toLowerCase(),
      week: get(classroom, `unit_progress[${unitId}].week_progress[${weekId}].weeks[0]`, {}),
      unit: get(classroom, `unit_progress[${unitId}]`, {}),
      gradeLevel: get(classroom, 'grade_level', ''),
    };
  });
  const { role } = useSelector(({ auth }) => auth.user);
  const { name: programName } = useSelector(({ program }) => program);

  const stickyRef = useRef();

  const dispatch = useDispatch();

  // IE pseudo sticky behavior
  useEffect(() => {
    if (isIE) {
      if (stickyRef.current) {
        // third parameter corresponds to styles top absolute offset
        window.addEventListener('scroll', mockStickyBehavior(stickyRef.current, 50, 130));

        return () => window.removeEventListener('scroll', mockStickyBehavior(stickyRef.current, 50, 130));
      }
    }
  }, []);

  useEffect(() => {
    activity_chapters &&
      setElRefs((elRefs) =>
        Array(activity_chapters.length)
          .fill()
          .map((_, i) => elRefs[i] || createRef()),
      );
    (activity_chapters || []).forEach(({ image_url }, idx) => image_url && prepareImage(image_url, idx, setImages));
  }, [activity_chapters]);

  const sectionRefs = activity_chapters ? activity_chapters.map((_, i) => ({ section: i, ref: elRefs[i] })) : [];

  useEffect(() => {
    let to = null;
    if (activity_chapters && !scrolledToAnchor) {
      const idx = activity_chapters.findIndex(({ id }) => id === +chapterId);
      if (idx !== -1 && elRefs[idx] && elRefs[idx].current) {
        to = setTimeout(() => scrollTo(elRefs[idx].current, 300), 300);
        setScrolledToAnchor(true);
      }
    }

    return () => to && clearTimeout(to);
  }, [activity_chapters, elRefs, chapterId]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset + window.innerHeight / 2 - 50;

      const selected = sectionRefs.find(({ section, ref }) => {
        const element = ref && ref.current;
        if (element) {
          const { offsetBottom, offsetTop } = determineDimensions(element);

          return scrollPosition >= offsetTop && scrollPosition <= offsetBottom;
        }
      });

      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && typeof visibleSection === 'number') {
        setVisibleSection(undefined);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [visibleSection, sectionRefs]);

  const handleFavoriteClick = (type, id, toMark, chapterName) => {
    toMark &&
      GAEmitEvent(
        eventCategory.USER_ACTION,
        eventAction['FAVORITE'],
        eventLabel.toggleFavorite(chapterName, type, pathname + search),
        {
          [customDimensions[USER_ROLE]]: gaRoleLabels[role],
          [customDimensions[PROGRAM_NAME]]: programName,
          [customDimensions[UNIT_NUMBER]]: get(unit, 'order_number', null),
          [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
          [customDimensions[WEEK_NAME]]: get(week, 'name', null),
        },
      );
    return dispatch(markFavorite(type, id, toMark)).then(() => dispatch(getLessonPLans(classroomId, activityId)));
  };

  const generateChapterHeader = (chapterName, chapterNumber, isFavorite, chapterId, placeholderWidth) => (
    <div className={cn('main-section--chapter-header', 'header-print')}>
      <ChapterIcon className={cn('main-section--chapter-header--icon', 'header-icon-print')} />
      <Text type="h2">
        {chapterNumber}
        .&nbsp;
        {chapterName}
      </Text>
      <Button
        className={cn('main-section--chapter-header--button')}
        type="favorite"
        marked={isFavorite}
        onClick={(e) => {
          e.stopPropagation();
          handleFavoriteClick('ActivityChapter', chapterId, !isFavorite, chapterName);
        }}
      />
      <div className="header-placeholder-print" style={{ width: `${placeholderWidth}px`, flexShrink: 0 }} />
    </div>
  );

  const generateHtmlFromResponse = (HTMLString, idx) => {
    const ChapterBody = document.createElement('div');
    const parser = new DOMParser();
    const image = images[idx];
    // this ensures proper content render, due to images state preservation
    const shouldRenderImage = image && activity_chapters[idx].image_url;
    let imagePlaceholder = document.createElement('div');

    if (shouldRenderImage) {
      imagePlaceholder.innerHTML = `<div class='chapter-placeholder-print' style='width: ${
        image.width + 40
      }px; height: ${image.height}px; float: right; margin-bottom: -20px;' />`;

      ChapterBody.appendChild(imagePlaceholder);
    }
    const chapterBody = parser.parseFromString(HTMLString, 'text/html').body;
    ChapterBody.appendChild(chapterBody);

    const anchorTags = chapterBody.getElementsByTagName('a');
    for (let a of anchorTags) {
      const [resourceId, resourceName] = `${a.href}`.split('/').reverse();
      if (!resourceId || !resourceName || !['lesson-plan', 'learning-experience'].includes(resourceName)) continue;

      if (dynamicUrls.current && dynamicUrls.current[`${resourceName}-${resourceId}-${idx}`]) {
        a.setAttribute('href', dynamicUrls.current[`${resourceName}-${resourceId}-${idx}`]);
      }

      if (linkResActionRef.current && linkResActionRef.current[`${resourceName}-${resourceId}-${idx}`]) continue;
      dispatch(getProgressesIds(+classroomId, +resourceId, resourceName === 'lesson-plan')).then(
        ({ unitId, weekId }) => {
          dynamicUrls.current = {
            ...dynamicUrls.current,
            [`${resourceName}-${resourceId}-${idx}`]: buildFrontUrl(
              `/classroom/${classroomId}/unit/${unitId}/week/${weekId}/${resourceName}/${resourceId}`,
            ),
          };
        },
      );
      linkResActionRef.current = { ...linkResActionRef.current, [`${resourceName}-${resourceId}-${idx}`]: true };
    }

    return [ChapterBody.innerHTML, image];
  };

  const generateChapter = (chapter, index) => {
    const [chapterBody, image] = generateHtmlFromResponse(chapter.text, index);
    const shouldRenderImage = image && activity_chapters[index].image_url;
    const imageCaption = chapter.caption;

    let placeholderWidth = 0;
    if (shouldRenderImage) placeholderWidth = image.width + 40;

    return (
      <div
        key={index}
        ref={elRefs[index]}
        className={cn('main-section--chapter', 'chapter-print', { 'chapter-print-break': index > 0 })}
      >
        {shouldRenderImage && (
          <div className={cn('main-section--image-wrapper')}>
            <img
              src={image.src}
              style={{ width: image.width, height: image.height }}
              className={cn(`chapter-image`, `image-print`)}
              aria-label="image"
              alt="Chapter image"
            />
            <Text className={cn('image-caption')} type="body-small" style={{ maxWidth: image.width }}>
              {imageCaption}
            </Text>
          </div>
        )}
        {generateChapterHeader(chapter.name, chapter.order_number, chapter.favorite, chapter.id, placeholderWidth)}

        <div dangerouslySetInnerHTML={{ __html: chapterBody }} />
      </div>
    );
  };

  return (
    <div className={cn('wrapper', { 'is-ie': isIE })}>
      <div id="sticky-menu" className={cn('sticky-menu', 'm-50', { 'is-ie': isIE })} ref={stickyRef}>
        {activity_chapters &&
          activity_chapters.map((acChapter, i) => (
            <Text
              key={i}
              className={cn('sticky-menu--text', { 'sticky-menu--text-active': visibleSection === i })}
              onClick={() => scrollTo(elRefs[i].current && elRefs[i].current, window.innerHeight / 2 - 50)}
            >
              {acChapter.order_number}
              .&nbsp;
              {acChapter.name}
            </Text>
          ))}
      </div>
      <div className={cn('content-container', 'm-50', { 'is-ie': isIE })}>
        <div className={cn('content-container--upper-bar')} style={{ backgroundColor: classroomColor }} />
        <div className={cn('main-section', `main-section--${GRADE_COLORS_REVERTED[classroomColor]}`)} ref={printRef}>
          <div className={cn('print-heading', 'head-print')}>
            <Text className={cn('print-heading--crumbs')}>
              {`${GRADE_TITLES[gradeLevel]} | Unit ${unit.order_number} | ${getWeekOrderNumber(
                week.order_number,
                true,
                true,
              )} ${!isEdgeWeek(week.order_number) ? week.name : ''}`}
            </Text>
            <Text type="h1">{lessonName}</Text>
          </div>
          {sel_competency && (
            <HeartShapedBox
              targetSel={sel_competency}
              lessonPlans
              className={cn('main-section--sel-header', 'sel-header-print')}
              selReview={sel_review}
            />
          )}
          {activity_chapters && activity_chapters.map((chapter, i) => generateChapter(chapter, i))}
        </div>
      </div>
    </div>
  );
};

export default LessonPlansSection;
