import React, { useState, useEffect, useRef } from 'react';
import T from 'prop-types';
// utils
import { toast } from 'react-toastify';
import { isIE, isFirefox, isMobile } from 'react-device-detect';
import { config } from 'constants/images';
import { buildUrl, isBrowser, getBrowserVersion } from 'utils';
// components
import HeartLoader from 'components/HeartLoader';
import Cropper from 'react-image-crop';
import Modal from 'components/Modal';
import Text from 'components/Text';
import Button from 'components/Button';
// styles
import classnames from 'classnames/bind';
import styles from './ImageCropperModal.module.scss';
import 'react-image-crop/lib/ReactCrop.scss';

const cn = classnames.bind(styles);

/** NOTE
 * 'timesLoaded' state makes possible to save image only after
 * the image is properly loaded. Turns out that cropper loads
 * the image properly in 2 takes so we only allow saving image
 * after condition 'timesLoaded >= 2' is met.
 */

const ImageCropperModal = ({ aspectRatio, imageUrl, onSave, imageFile = null, className }) => {
  return (
    <Modal className={cn('modal', className)}>
      {({ closeModal }) => {
        const [image, setImage] = useState(imageFile);
        const [imgCrop, setImgCrop] = useState({
          unit: '%',
          width: 50,
          x: 25,
          y: 25,
          aspect: aspectRatio,
        });
        const [croppedImg, setCroppedImg] = useState(null);
        const [timesLoaded, setTimesLoaded] = useState(0);
        const fileInputRef = useRef();
        const cropRef = useRef();
        const onCropChange = (crop) => setImgCrop(crop);

        const [unsupportedSafari, setUnsupportedSafari] = useState(false);

        const isFirstUpload = !(image || imageUrl);

        // IE and Firefox requires single init image load
        const hasImageLoaded = timesLoaded >= (isIE || isFirefox ? 1 : 2);

        useEffect(() => {
          if (isBrowser('safari')) {
            const version = getBrowserVersion() || '';
            const vd = version.split('.');
            if (+vd[0] === 13 && +vd[1] === 1) setUnsupportedSafari(true);
          }
        }, []);

        const handleSelectFile = (e) => {
          if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].size > config.maxSize)
              return toast.error(`File size is too big! Maximum size is ${Math.floor(config.maxSize / 1e6)}MB.`, {
                autoClose: 5000,
              });

            const reader = new FileReader();
            reader.onload = ({ target: { result } }) => {
              setImage(result);
            };
            reader.readAsDataURL(e.target.files[0]);
          }
        };

        const handleNewFileClick = () => fileInputRef.current && fileInputRef.current.click();

        const getCroppedImage = (image, crop) => {
          let customCrop = {};
          // allows to instantly save image on load
          if (crop.unit === '%') {
            const aspect = aspectRatio;
            const customWidth =
              image.width / aspect < image.height * aspect ? 1 : (image.height * aspect) / image.width;
            const customHeight = image.width / aspect > image.height * aspect ? 1 : image.width / aspect / image.height;
            const customX = (1 - customWidth) / 2;
            const customY = (1 - customHeight) / 2;

            customCrop = {
              unit: 'px',
              aspect,
              width: customWidth * image.width,
              height: customHeight * image.height,
              x: customX * image.width,
              y: customY * image.height,
            };
          }

          const usedCrop = Object.keys(customCrop).length === 0 ? crop : customCrop;

          // assures to avoid CORS
          image.crossOrigin = 'anonymous';
          const canvas = document.createElement('canvas');
          const scaleX = image.naturalWidth / image.width;
          const scaleY = image.naturalHeight / image.height;
          canvas.width = usedCrop.width;
          canvas.height = usedCrop.height;
          const ctx = canvas.getContext('2d');

          ctx.drawImage(
            image,
            usedCrop.x * scaleX,
            usedCrop.y * scaleY,
            usedCrop.width * scaleX,
            usedCrop.height * scaleY,
            0,
            0,
            usedCrop.width,
            usedCrop.height,
          );

          return new Promise((resolve) => {
            canvas.toBlob((blob) => {
              if (!blob) return;
              resolve(blob);
            });
          });
        };

        const saveCroppedImage = async (currentCrop) => {
          if (cropRef.current) {
            const croppedImageUrl = await getCroppedImage(cropRef.current, currentCrop);
            setCroppedImg(croppedImageUrl);
            setTimesLoaded((prev) => ++prev);
          }
        };

        const onImageLoaded = (image) => {
          cropRef.current = image;

          const aspect = aspectRatio;
          const width =
            image.width / aspect < image.height * aspect ? 100 : ((image.height * aspect) / image.width) * 100;
          const height =
            image.width / aspect > image.height * aspect ? 100 : (image.width / aspect / image.height) * 100;
          const x = (100 - width) / 2;
          const y = (100 - height) / 2;

          setImgCrop({ unit: '%', width, height, x, y, aspect });
          saveCroppedImage(imgCrop);

          return false;
        };

        return (
          <>
            <Modal.Header>
              <Text type="h3">Change Image</Text>
              <Modal.CloseButton onClick={closeModal} />
            </Modal.Header>
            <Modal.Body>
              <div className={cn('body-wrapper')}>
                {unsupportedSafari ? (
                  <div className={cn('unsupported-wrapper')}>
                    <Text type="h5" className={cn('text', 'mh-auto')}>
                      This feature requires Safari version 13.2 or newer.
                    </Text>
                  </div>
                ) : (
                  <>
                    <Cropper
                      src={image ? image : buildUrl(imageUrl)}
                      className={cn('image-cropper')}
                      crop={imgCrop}
                      onComplete={(crop) => saveCroppedImage(crop)}
                      onChange={onCropChange}
                      onImageLoaded={onImageLoaded}
                      keepSelection
                      style={{ display: hasImageLoaded ? 'block' : 'none' }}
                    />
                    {!hasImageLoaded && (
                      <div className={cn('loader-wrapper')}>
                        <HeartLoader size={isMobile ? 'sm' : 'md'} />
                      </div>
                    )}
                    <div className={cn('body-wrapper--buttons')}>
                      <Button
                        className={cn('body-wrapper--buttons--first')}
                        type="regular"
                        size="md"
                        label={isFirstUpload ? 'Choose Image' : 'New Image'}
                        variant="outlined"
                        onClick={handleNewFileClick}
                      />
                      {!isFirstUpload && (
                        <Button
                          className={cn('submit-button')}
                          type="regular"
                          size="md"
                          label={hasImageLoaded ? 'Save Image' : 'Loading...'}
                          disabled={!hasImageLoaded}
                          onClick={() => onSave(croppedImg, closeModal)}
                        />
                      )}
                      <input
                        type="file"
                        className={cn('file-input')}
                        accept={config.mimeTypes.join(', ')}
                        onChange={handleSelectFile}
                        ref={fileInputRef}
                      />
                    </div>
                  </>
                )}
              </div>
            </Modal.Body>
          </>
        );
      }}
    </Modal>
  );
};

ImageCropperModal.propTypes = {
  aspectRatio: T.number.isRequired,
  imageUrl: T.string,
  onSave: T.func.isRequired,
  imageFile: T.string,
  className: T.func,
};

export default ImageCropperModal;
