import React from 'react';
// components
import Text from 'components/Text';
// icons
import { ReactComponent as PlusIcon } from 'icons/rounded-plus.svg';
// styles
import classnames from 'classnames/bind';
import styles from './AddContentField.module.scss';

const cn = classnames.bind(styles);

const AddContentField = ({ text, onClick, className }) => {
  return (
    <div className={cn('wrapper', className)} onClick={onClick}>
      <PlusIcon className={cn('icon')} />
      <Text type="h4" className={cn('text', 'mt-20')}>
        {text}
      </Text>
    </div>
  );
};

export default AddContentField;
