import { OrientationModal } from 'containers/DisplayOnMobile/DisplayOnMobile';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CustomView, isIPad13, isTablet, isBrowser } from 'react-device-detect';
import { useDebouncedCallback } from 'use-debounce/lib';

const DisplayOnDesktop = ({ children }) => {
  const [initViewWidth] = useState(window.innerWidth);
  const initPort = window.innerHeight > window.innerWidth;
  const [isPort, setPort] = useState(initPort);
  const { pathname } = useLocation();
  const a = useDebouncedCallback((h, w) => setPort(h > w), 500);
  const isLearnExp = pathname && pathname.includes('learning-experience');
  useEffect(() => {
    window.addEventListener('resize', () => {
      a(window.innerHeight, window.innerWidth);
    });
  }, [a]);

  return (
    <CustomView condition={isIPad13 || initViewWidth >= 768 || isBrowser}>
      {isLearnExp}
      {children}
      {isLearnExp && (isIPad13 || isTablet) && isPort && <OrientationModal target="landscape" />}
    </CustomView>
  );
};

export default DisplayOnDesktop;
