export const OPTION_HEIGHT = 50;
const indent = 5; // distance between dropdown menu & dropdown trigger

function getTransformValue(isOpenTop, isOpenLeft) {
  if (isOpenTop && isOpenLeft) {
    return 'translate(-100%, -100%)';
  }
  if (isOpenTop) {
    return 'translateY(-100%)';
  }
  if (isOpenLeft) {
    return 'translateX(-100%)';
  }
  return '';
}

export function calcDropdownOffsets(dropdownTrigger, optionsCount, isOpenLeft, isHoverDropdown) {
  const coords = dropdownTrigger.getBoundingClientRect();
  const dropdownHeight = optionsCount * OPTION_HEIGHT;
  const availAreaToOpenDown = window.innerHeight - coords.bottom;
  const isOpenTop = availAreaToOpenDown < dropdownHeight;
  const heightWithIndent = isHoverDropdown ? coords.height : coords.height + indent;

  let top = isOpenTop ? coords.top + window.pageYOffset : coords.top + heightWithIndent + window.pageYOffset;
  const left = coords[isOpenLeft ? 'right' : 'left'];
  const transform = getTransformValue(isOpenTop, isOpenLeft);

  return { top, left, transform };
}
