import { get } from 'lodash';
import { DateTime } from 'luxon';
import makeRequest from 'utils/api';
import { RESET_STORE } from './common';

// Actions
export const FAVORITES_LOADING = 'czi/FAVORITES/LOADING';
export const FAVORITES_LOADED = 'czi/FAVORITES/LOADED';
export const FAVORITES_FAILED = 'czi/FAVORITES/FAILED';

export const REMOVE_FAVORITE_LOADING = 'czi/REMOVE_FAVORITE/LOADING';
export const REMOVE_FAVORITE_LOADED = 'czi/REMOVE_FAVORITE/LOADED';
export const REMOVE_FAVORITE_FAILED = 'czi/REMOVE_FAVORITE/FAILED';

// Action creators
export const favoritesLoading = () => ({ type: FAVORITES_LOADING });
export const favoritesLoaded = (payload) => ({ type: FAVORITES_LOADED, payload });
export const favoritesFailed = () => ({ type: FAVORITES_FAILED });

export const removeFavoriteLoading = () => ({ type: REMOVE_FAVORITE_LOADING });
export const removeFavoriteLoaded = (id) => ({ type: REMOVE_FAVORITE_LOADED, payload: { id } });
export const removeFavoriteFailed = () => ({ type: REMOVE_FAVORITE_FAILED });

// initialState
export const initialState = {
  favorites: [],
  isLoading: false,
  hasErrors: false,
};

// mapFavorites
const mapFavorites = ({
  resource: { favorite_activity_chapters: favAct, favorite_learning_experiences: favLearnExp },
  meta: { activity_chapter_path_parts: actPaths, learning_experience_path_parts: lernExpPaths },
}) =>
  [
    ...favAct.map(({ id, ...rest }) => ({
      id,
      ...rest,
      type: 'activity',
      classrooms: get(actPaths, id, []),
    })),
    ...favLearnExp.map(({ id, ...rest }) => ({
      id,
      ...rest,
      type: 'learning_experience',
      classrooms: get(lernExpPaths, id, []),
    })),
  ].sort((a, b) => {
    const dateA = a.favorite_created_at;
    const dateB = b.favorite_created_at;
    if (dateA <= dateB) return -1;
    if (dateA > dateB) return 1;
    return 0;
  });

// reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FAVORITES_LOADING:
    case REMOVE_FAVORITE_LOADING:
      return { ...state, isLoading: true, hasErrors: false };
    case REMOVE_FAVORITE_LOADED:
      return {
        ...state,
        favorites: state.favorites.filter(({ id }) => id !== action.payload.id),
        isLoading: false,
        hasErrors: false,
      };
    case FAVORITES_LOADED:
      return { ...state, favorites: mapFavorites(action.payload), isLoading: false, hasErrors: false };
    case REMOVE_FAVORITE_FAILED:
    case FAVORITES_FAILED:
      return { ...state, isLoading: false, hasErrors: true };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;

// side effects

export const getFavorites = () => (dispatch) => {
  dispatch(favoritesLoading());
  return makeRequest('/favorites')
    .then((res) => dispatch(favoritesLoaded(res)))
    .catch(() => dispatch(favoritesFailed()));
};

export const removeFavorite = (id, type) => (dispatch) => {
  dispatch(removeFavoriteLoading());
  return makeRequest(
    `/favorites/destroy_by_type/${type === 'activity' ? 'ActivityChapter' : 'LearningExperience'}/${id}`,
    { method: 'DELETE' },
  )
    .then((res) => dispatch(removeFavoriteLoaded(id)))
    .catch(() => dispatch(removeFavoriteFailed()));
};
