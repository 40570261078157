import React, { useState, useRef } from 'react';
// components
import Portal from 'components/Portal';
// hooks
import useClickOutside from 'hooks/useClickOutside';
// utils
import classnames from 'classnames/bind';
import { OPTION_HEIGHT, calcDropdownOffsets } from './helper';
// types
// styles
import styles from './PortalDropdown.module.scss';

const cn = classnames.bind(styles);

const liStyle = { height: OPTION_HEIGHT };

const PortalDropdown = ({
  className,
  options = [],
  optionsRenderer,
  isOpenLeft = false,
  hoverDropdown = false,
  children,
}) => {
  const menuRef = useRef(null);
  const triggerRef = useRef(null);
  const [data, setData] = useState({ left: 0, top: 0, transform: '', isOpen: false });
  const { isOpen, ...restStyles } = data;
  const onClose = () => {
    if (isOpen) setData((prevState) => ({ ...prevState, isOpen: false }));
  };

  const openMenu = () => setData((prev) => ({ ...prev, isOpen: true }));

  useClickOutside(menuRef, onClose, { triggerRef, isOpen });

  const onTriggerClick = (e) => {
    e.stopPropagation();
    if (!isOpen) {
      const { top, left, transform } = calcDropdownOffsets(e.currentTarget, options.length, isOpenLeft, hoverDropdown);
      setData({ top, left, transform, isOpen: !isOpen });
      return;
    }
    setData((prevState) => ({ ...prevState, isOpen: !isOpen }));
  };

  const defaultOptionsRenderer = ({ closeDropdown }) => {
    const handleItemClick = ({ onClick }) => (e) => {
      e.stopPropagation();
      if (onClick) onClick();
      closeDropdown();
    };
    return (
      <ul className={cn('list-ul')}>
        {options.map(
          ({ label, itemProps = {}, component: OptionComponent = 'div', isHidden }, index) =>
            !isHidden && (
              <li key={index} aria-hidden className={cn('list-li')} style={liStyle} onClick={closeDropdown}>
                <OptionComponent
                  className={cn('label', 'truncated-ellipsis', 'dropdown')}
                  {...itemProps}
                  onClick={handleItemClick(itemProps)}
                >
                  {label}
                </OptionComponent>
              </li>
            ),
        )}
      </ul>
    );
  };

  const renderer = optionsRenderer || defaultOptionsRenderer;

  return (
    <>
      <Portal>
        <div
          ref={menuRef}
          style={restStyles}
          className={cn('dropdown-wrapper', className, { 'is-open': isOpen, 'is-hover': hoverDropdown })}
          onMouseEnter={() => hoverDropdown && openMenu()}
          onMouseLeave={() => hoverDropdown && onClose()}
        >
          {renderer({ closeDropdown: onClose })}
        </div>
      </Portal>
      {children(isOpen, onTriggerClick, triggerRef, onClose, openMenu)}
    </>
  );
};

export default PortalDropdown;
