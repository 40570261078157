import React, { useEffect, useRef, useState } from 'react';
import T from 'prop-types';
// hooks
import { useSpring, animated } from 'react-spring';

const Collapse = ({ isActive, children, className }) => {
  const ref = useRef();
  const [height, setHeight] = useState('auto')
  const props = useSpring(
    isActive ? { height, opacity: 1, pointerEvents: 'all' } : { height: 0, opacity: 0, pointerEvents: 'none' },
  );

  useEffect(() => {
    if (ref.current) setHeight(ref.current.getBoundingClientRect().height)
  }, [ref]);

  return (
    <animated.div aria-hidden style={props} className={className}>
      <div aria-hidden ref={ref}>
        {children}
      </div>
    </animated.div>
  );
};

Collapse.propTypes = {
  isActive: T.bool,
  children: T.any,
};

export default Collapse;
