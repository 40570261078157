import { useModalSetter } from 'hooks/useModal';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const setModal = useModalSetter();
  useEffect(() => {
    window.scrollTo(0, 0);
    setModal(null); // close modal on route change
  }, [pathname, setModal]);

  return null;
};

export default ScrollToTop;
