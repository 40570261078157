import React, { useState, useEffect } from 'react';
// utils
import { inviteUsers } from 'store/classrooms';
import { updateUserClassrooms } from 'store/auth';
import { updateClassroom } from 'store/classrooms';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { buildUrl } from 'utils';
import { reduceToCommaSepStringByKey } from 'utils/string';
import { useTransition, animated } from '@react-spring/web';
import { difference } from 'lodash';
import { TEACHER_ROLE } from 'constants/user';
import { MODAL_KEYS } from 'constants/modal';
import { isMobile } from 'react-device-detect';
// components
import InviteByMailModal from 'components/AddUsers/InviteByMailModal';
import ModalAnimatedWrapper from 'components/Modal/ModalAnimationWrapper';
import Modal from 'components/Modal';
import WarningModal from 'components/Modal/WarningModal';
import Text from 'components/Text';
import Button from 'components/Button';
import SearchInput from 'components/Input/SearchInput';
// icons
import { ReactComponent as AddPersonIcon } from 'icons/add-person.svg';
// styles
import classnames from 'classnames/bind';
import styles from './BrowserView.module.scss';

const cn = classnames.bind(styles);

const ManageClassroomsModal = ({
  fullName,
  teacherId,
  notAssigned = [],
  assigned = [],
  programId,
  schoolId,
  _classroomId = null,
  _classroomName = null,
}) => {
  const [searchTouched, setSearchTouched] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [notAssignedData, setNotAssigned] = useState(notAssigned);
  const [assignedData, setAssigned] = useState(assigned);
  const [searchNotAssigned, setSearchNotAssigned] = useState(null);
  const [searchAssigned, setSearchAssigned] = useState(null);
  const [invitePerson, setInvitePerson] = useState(false);
  const [usersByEmail, setUsersByEmail] = useState([]);

  const reverseLogic = !!_classroomId;

  const [removeTeacher, setRemoveTeacher] = useState(null);
  const [warningModal, setWarningModal] = useState(false);
  const modalKey = reverseLogic ? MODAL_KEYS.assignUserToClassroom : MODAL_KEYS.assignClassroomToUser;

  const dispatch = useDispatch();

  const resourceToAdd = difference(assignedData, assigned);
  const resourceToRemove = difference(notAssignedData, notAssigned);

  let hasChanged = false;
  if (reverseLogic) {
    hasChanged = !!resourceToAdd.length || !!resourceToRemove.length || !!usersByEmail.length;
  } else {
    hasChanged = !!resourceToAdd.length || !!resourceToRemove.length;
  }

  let heightA = 50;
  const notAssignedTransitions = useTransition(
    (searchNotAssigned || notAssignedData).map((data) => ({
      ...data,
      y: (heightA += 60) - 120,
    })),
    {
      key: (item) => item.id,
      from: { height: 0, opacity: 0 },
      leave: ({ y }) => ({ y: isTyping ? 0 : 200 + 70, height: 0, opacity: 0 }),
      enter: ({ y, height }) => ({ y, height, opacity: 1 }),
      update: ({ y, height }) => ({ y, height }),
      config: {
        duration: isTyping ? 150 : 300,
      },
    },
  );

  let heightB = 50;
  const assignedTransitions = useTransition(
    (searchAssigned || assignedData).map((data) => ({
      ...data,
      y: (heightB += 60) - 120,
    })),
    {
      key: (item) => item.id,
      from: { height: 0, opacity: 0 },
      leave: ({ y }) => ({ y: isTyping ? 0 : -200, height: 0, opacity: 0 }),
      enter: ({ y, height }) => ({ y, height, opacity: 1 }),
      update: ({ y, height }) => ({ y, height }),
      config: {
        duration: isTyping ? 150 : 300,
      },
    },
  );

  const debouncedTypingIndicator = useDebouncedCallback(() => setIsTyping(false), 100);
  useEffect(() => debouncedTypingIndicator(), [searchPhrase]);

  const handleActionClick = (targetId, assigning = true) => {
    const targets = assigning ? notAssignedData : assignedData;
    const target = targets.find(({ id }) => id === targetId);

    const append = (prev) => (prev.find(({ id }) => id === target.id) ? [...(prev || [])] : [target, ...(prev || [])]);
    const remove = (prev) => (prev || []).filter(({ id }) => id !== targetId);

    setNotAssigned(assigning ? remove : append);
    setAssigned(assigning ? append : remove);
    setSearchNotAssigned(searchPhrase ? (assigning ? remove : append) : null);
    setSearchAssigned(searchPhrase ? (assigning ? append : remove) : null);
  };

  const handleSearch = (val) => {
    setSearchNotAssigned(
      notAssignedData.filter((target) =>
        (reverseLogic ? target.full_name || target.email : target.name).toLowerCase().includes(val.toLowerCase()),
      ),
    );
    setSearchAssigned(
      assignedData.filter((target) =>
        (reverseLogic ? target.full_name || target.email : target.name).toLowerCase().includes(val.toLowerCase()),
      ),
    );
  };

  const warningModalText = (
    <>
      {resourceToAdd.length > 0 && (
        <>
          {!reverseLogic ? (
            <Text type="h4">
              Are you sure you want to invite <strong>{fullName}</strong> to{' '}
              <strong>{reduceToCommaSepStringByKey('name', resourceToAdd)}</strong>?
            </Text>
          ) : (
            <Text type="h4">
              Are you sure you want to{' '}
              <strong>
                invite {resourceToAdd.length} member{resourceToAdd.length > 1 ? 's' : ''}
              </strong>{' '}
              to <strong>{_classroomName}</strong>?
            </Text>
          )}
        </>
      )}
      {resourceToRemove.length > 0 && (
        <>
          <br />
          {!reverseLogic ? (
            <Text type="h4">
              Are you sure you want to remove <strong>{fullName}</strong> from{' '}
              <strong>{reduceToCommaSepStringByKey('name', resourceToRemove)}</strong>?
            </Text>
          ) : (
            <Text type="h4">
              Are you sure you want to{' '}
              <strong>
                remove {resourceToRemove.length} member{resourceToRemove.length > 1 ? 's' : ''}
              </strong>{' '}
              from <strong>{_classroomName}</strong>?
            </Text>
          )}
        </>
      )}
      {usersByEmail.length > 0 && (
        <>
          <br />
          <Text type="h4">{`You are about to send ${usersByEmail.length} email invitation(s). ${
            resourceToRemove.length === 0 && resourceToAdd.length === 0 ? 'Are you sure?' : ''
          }`}</Text>
        </>
      )}
    </>
  );

  const handleSaveChanges = (onFinishCallback) => {
    const ids = assignedData.map(({ id }) => id);

    if (reverseLogic) {
      handleInviteTeachers()
        .then(dispatch(updateClassroom({ user_ids: [...ids] }, _classroomId)))
        .then(onFinishCallback);
      return;
    }
    return dispatch(updateUserClassrooms({ userId: teacherId, classroomIds: ids, programId, schoolId })).then(
      onFinishCallback,
    );
  };

  function handleInviteTeachers() {
    if (usersByEmail.length > 0) {
      const toInvite = usersByEmail.map((email) => ({
        email,
        programs_users_attributes: [{ program_id: programId }],
        schools_users_attributes: [{ school_id: schoolId, role: TEACHER_ROLE }],
        classroom_ids: [_classroomId],
      }));

      return dispatch(inviteUsers(toInvite, _classroomId));
    }
    return Promise.resolve();
  }

  return (
    <Modal className={cn('manage-modal')}>
      {({ closeModal, isBlockedModal }) => (
        <>
          <Modal.Header className="friendly">
            <Text type="h5">
              {isMobile
                ? reverseLogic
                  ? 'Manage Members'
                  : 'Manage Classrooms'
                : reverseLogic
                ? `Manage Members Assigned to ${_classroomName}`
                : `Manage classrooms assigned to ${fullName}`}
            </Text>
            <Modal.CloseButton className="friendly" onClick={closeModal} />
          </Modal.Header>
          <Modal.Body className={cn('modal-body', 'pv-20 ph-30')}>
            <SearchInput
              className={cn('search')}
              withClear
              boldIcon
              placeholder={reverseLogic ? 'Search Members' : 'Search Classrooms'}
              name="search"
              onChange={(val) => {
                setSearchPhrase(val);
                handleSearch(val);
                setIsTyping(true);
              }}
              onClear={() => setSearchTouched(false)}
              onFocus={() => setSearchTouched(true)}
            />
            {searchTouched && (
              <div className={cn('container')}>
                <section className={cn('not-assigned-wrapper', 'pv-10')}>
                  <Text className={cn('label', 'mb-20')} type="body-small" bold>
                    Not Assigned
                  </Text>
                  <ul className={cn('classrooms-wrapper')}>
                    {notAssignedTransitions((style, properties, t, index) => (
                      <animated.div style={{ zIndex: notAssigned.length - index, ...style }}>
                        <li className={cn('item')}>
                          <div
                            className={cn('image-wrapper')}
                            style={{ backgroundColor: !reverseLogic && properties.color }}
                          >
                            {!reverseLogic ? (
                              properties.cover_image ? (
                                <img
                                  className={cn('image')}
                                  src={buildUrl(properties.cover_image.image_url)}
                                  alt="Cover"
                                  aria-hidden
                                />
                              ) : (
                                <div className={cn('placeholder')} style={{ backgroundColor: properties.color }} />
                              )
                            ) : (
                              <img
                                className={cn('image')}
                                src={buildUrl(properties.avatar_url)}
                                alt="Avatar"
                                aria-hidden
                              />
                            )}
                          </div>
                          <div className={cn('texts')}>
                            <Text type="h6">
                              {reverseLogic ? properties.full_name || properties.email : properties.name}
                            </Text>
                            <Text type="body-small">
                              {reverseLogic ? properties.email : properties.school.schoolName}
                            </Text>
                          </div>
                          <Text
                            className={cn('action-text')}
                            bold
                            onClick={() => handleActionClick(reverseLogic ? properties.id : properties.id)}
                          >
                            Add
                          </Text>
                        </li>
                      </animated.div>
                    ))}
                  </ul>
                </section>
                <section className={cn('assigned-wrapper', 'pv-10 ph-30')}>
                  <Text className={cn('label', 'mb-20')} type="body-small" bold>
                    Assigned
                  </Text>
                  {assignedData.length === 0 ? (
                    <Text className={cn('label', 'center-text', 'mt-40')} type="h5">
                      {reverseLogic
                        ? 'There are no teacher assignments to show'
                        : 'There are no classroom assignments to show'}
                    </Text>
                  ) : (
                    <ul className={cn('classrooms-wrapper')}>
                      {assignedTransitions((style, properties, t, index) => (
                        <animated.div style={{ zIndex: notAssigned.length - index, ...style }}>
                          <li className={cn('item')}>
                            <div
                              className={cn('image-wrapper')}
                              style={{ backgroundColor: !reverseLogic && properties.color }}
                            >
                              {!reverseLogic ? (
                                properties.cover_image ? (
                                  <img
                                    className={cn('image')}
                                    src={buildUrl(properties.cover_image.image_url)}
                                    alt="Cover"
                                    aria-hidden
                                  />
                                ) : (
                                  <div className={cn('placeholder')} style={{ backgroundColor: properties.color }} />
                                )
                              ) : (
                                <img
                                  className={cn('image')}
                                  src={buildUrl(properties.avatar_url)}
                                  alt="Avatar"
                                  aria-hidden
                                />
                              )}
                            </div>
                            <div className={cn('texts')}>
                              <Text type="h6">
                                {reverseLogic ? properties.full_name || properties.email : properties.name}
                              </Text>
                              <Text type="body-small">
                                {reverseLogic ? properties.email : properties.school.schoolName}
                              </Text>
                            </div>
                            <Text
                              className={cn('action-text')}
                              bold
                              onClick={() =>
                                !removeTeacher &&
                                setRemoveTeacher(
                                  reverseLogic
                                    ? {
                                        id: properties.id,
                                        fullName: properties.full_name || properties.email,
                                        classroomName: _classroomName,
                                      }
                                    : { fullName, classroomName: properties.name, classroomId: properties.id },
                                )
                              }
                            >
                              Remove
                            </Text>
                          </li>
                        </animated.div>
                      ))}
                    </ul>
                  )}
                </section>
              </div>
            )}
            {reverseLogic && (
              <div className={cn('invite-wrapper')}>
                <div
                  className={cn('add-person-wrapper', !searchTouched && 'mt-20')}
                  onClick={() => setInvitePerson(true)}
                >
                  <div className={cn('icon')}>
                    <AddPersonIcon />
                  </div>
                  <Text type="h6" className={cn('invite-member', 'ml-20')}>
                    Invite a New Member to SELF
                  </Text>
                </div>
                {usersByEmail.length > 0 &&
                  usersByEmail.map((email, idx) => (
                    <div key={`email-${idx}`} className={cn('email-row', 'mb-15')}>
                      <Text>{email}</Text>
                      <Text
                        action
                        className="ml-auto"
                        onClick={() => setRemoveTeacher({ email, classroomName: _classroomName })}
                      >
                        Remove
                      </Text>
                    </div>
                  ))}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className={cn('manage-modal-footer')}>
            <Button
              className={cn('save-button', 'ph-40')}
              size={isMobile ? 'bg' : 'md'}
              label="Save"
              disabled={!hasChanged}
              onClick={() =>
                hasChanged
                  ? isBlockedModal(modalKey)
                    ? handleSaveChanges(closeModal)
                    : setWarningModal(true)
                  : closeModal()
              }
            />
          </Modal.Footer>
          <ModalAnimatedWrapper>
            {removeTeacher && (
              <Modal className={cn('remove-modal')} onClickOutside={() => setRemoveTeacher(null)}>
                {(_) => (
                  <>
                    <Modal.Header className="simple">
                      <Text type="h4">Remove member</Text>
                      <Modal.CloseButton className="simple" onClick={() => setRemoveTeacher(null)} />
                    </Modal.Header>
                    <Modal.Body className="pl-30 pr-20">
                      <Text>
                        Are you sure you wish to remove <strong>{removeTeacher.fullName || removeTeacher.email}</strong>{' '}
                        from <strong>{removeTeacher.classroomName}</strong>?
                      </Text>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        className={cn('cancel-button', 'mr-15 ph-25')}
                        variant="filled"
                        size="md"
                        label="Cancel"
                        onClick={() => setRemoveTeacher(null)}
                      />
                      <Button
                        variant="filled-red"
                        size="md"
                        label="Remove"
                        onClick={() => {
                          setRemoveTeacher(null);
                          if (removeTeacher.email) {
                            setUsersByEmail((prev) => [...prev.filter((mail) => mail !== removeTeacher.email)]);
                            return;
                          }
                          handleActionClick(reverseLogic ? removeTeacher.id : removeTeacher.classroomId, false);
                        }}
                      />
                    </Modal.Footer>
                  </>
                )}
              </Modal>
            )}
          </ModalAnimatedWrapper>
          <ModalAnimatedWrapper>
            {warningModal && (
              <WarningModal
                onSave={() => {
                  handleSaveChanges(closeModal);
                  setWarningModal(false);
                }}
                modalText={warningModalText}
                modalKey={modalKey}
                onCancel={() => setWarningModal(false)}
                saveLabel="Confirm"
              />
            )}
          </ModalAnimatedWrapper>
          <ModalAnimatedWrapper>
            {reverseLogic && invitePerson && (
              <InviteByMailModal
                setState={setUsersByEmail}
                onClickOutside={() => setInvitePerson(false)}
                onClose={() => setInvitePerson(false)}
              />
            )}
          </ModalAnimatedWrapper>
        </>
      )}
    </Modal>
  );
};

export default ManageClassroomsModal;
