import React, { useState } from 'react';
// utils
import { isMobile } from 'react-device-detect';
// components
import MultiMailField from 'components/MultiMailField';
import Text from 'components/Text';
import Button from 'components/Button';
import Modal from 'components/Modal';
// styles
import classnames from 'classnames/bind';
import styles from './AddUsers.module.scss';

const cn = classnames.bind(styles);

const InviteByMailModal = ({ setState, onClickOutside, onClose }) => {
  return (
    <Modal className={cn('invite-modal')} onClickOutside={onClickOutside}>
      {(_) => {
        const [emails, setEmails] = useState([]);

        return (
          <>
            <Modal.Header className="friendly">
              <Text type={isMobile ? 'h5' : 'h4'}>Invite a New Person to SELF</Text>
              <Modal.CloseButton className="friendly" onClick={onClose} />
            </Modal.Header>
            <Modal.Body className="pv-20 ph-40">
              <Text type="h9" className="mb-5">
                Enter a Program or School Email Address
              </Text>
              <MultiMailField
                placeholder="Enter an email"
                emails={emails}
                setEmails={setEmails}
                className={cn('mv-10')}
              />
            </Modal.Body>
            <Modal.Footer className="friendly">
              <Button
                className="ph-30"
                size="bg"
                label="Add"
                disabled={!emails.length}
                onClick={() => {
                  onClose();
                  setState((prev) => [...new Set([...prev, ...emails])]);
                }}
              />
            </Modal.Footer>
          </>
        );
      }}
    </Modal>
  );
};

export default InviteByMailModal;
