import React, { useEffect } from 'react';
// utils
import { setInstanceIds } from 'utils/auth';
import { updateCurrentRole } from 'store/auth';
import { useSelector, useDispatch } from 'react-redux';
import { isProgramAdmin, TEACHER_ROLE } from 'constants/user';

const RoleUpdateService = () => {
  const { role: currRole, roles = [] } = useSelector(({ auth }) => auth.user);
  const { id: programId } = useSelector(({ program }) => program);
  const { id: schoolId } = useSelector(({ school }) => school);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isProgramAdmin(currRole)) {
      return;
    }

    if (!schoolId) {
      dispatch(updateCurrentRole(TEACHER_ROLE));
      return;
    }

    const currentRoleObj = roles.find(({ id, type }) => +id === +schoolId && type === 'school');
    if (currentRoleObj) {
      const { role } = currentRoleObj;
      dispatch(updateCurrentRole(role));
      setInstanceIds({ 'school-id': schoolId });
    }
  }, [schoolId, roles, dispatch]);

  useEffect(() => {
    const programRoleObj = roles.find(({ id, type }) => +id === +programId && type === 'program');

    if (programRoleObj) {
      const { role } = programRoleObj;
      dispatch(updateCurrentRole(role));
      setInstanceIds({ 'program-id': programId });
    }
  }, [programId, schoolId, roles, dispatch]);

  return <></>;
};

export default RoleUpdateService;
