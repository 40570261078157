import React, { useState, useRef, useEffect } from 'react';
import T from 'prop-types';
// components
import { animated, config, useTransition } from 'react-spring';
// hooks
import { useBottomMenuSetter } from 'hooks/useBottomMenu';
// icons
import { ReactComponent as CloseIcon } from 'icons/rectangle.svg';
// styles
import styles from './BottomMenu.module.scss';
import classnames from 'classnames/bind';

const cn = classnames.bind(styles);

const BottomMenuWrapper = ({ children, animationDuration = 300 }) => {
  const { closeMenu, isMenu, ...rest } = useBottomMenuSetter();

  const [menuDragOffset, setMenuDragOffset] = useState(0);
  const [prevTouch, setPrevTouch] = useState(null);
  // helper state to allow bottom parameter animation only on drag release
  const [animateBottom, setAnimateBottom] = useState(false);

  const menuRef = useRef();

  useEffect(() => isMenu && setMenuDragOffset(0), [isMenu]);

  useEffect(() => {
    const touchEventHandler = (ev) => {
      if (menuRef.current) {
        if (menuRef.current.contains(ev.target)) {
          ev.preventDefault();
          ev.stopImmediatePropagation();
        }
      }
    };

    window.addEventListener('touchmove', touchEventHandler, { passive: false });

    return () => window.removeEventListener('touchmove', touchEventHandler, { passive: false });
  }, []);

  const transition = useTransition(children || [], null, {
    from: { opacity: -1.5, transform: 'translateY(100%)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: -1, transform: 'translateY(100%)', pointerEvents: 'all' },
    config: (_, state) =>
      ['leave', 'enter'].includes(state) ? { duration: animationDuration, tension: 400 } : config.default,
  });

  const handleTouchStart = (e) => {
    setPrevTouch(e.touches[0].clientY);
  };

  const handleMenuDrag = (e) => {
    animateBottom && setAnimateBottom(false);
    if (menuRef.current) {
      const offset = prevTouch - e.touches[0].clientY;

      setMenuDragOffset(offset < 0 ? menuDragOffset + offset : menuDragOffset < 0 ? menuDragOffset + offset : 0);
      setPrevTouch(e.touches[0].clientY);
    }
  };

  const handleTouchEnd = (e) => {
    e.stopPropagation();
    if (menuRef.current) {
      const draggedPastHalf = Math.abs(menuDragOffset) > menuRef.current.getBoundingClientRect().height / 2;

      if (draggedPastHalf) {
        closeMenu();
        return;
      }
      setAnimateBottom(true);
      setMenuDragOffset(0);
    }
  };

  return (
    <>
      {transition.map(({ item, key, props: { transform, opacity, pointerEvents } }) => (
        <animated.div
          className={cn('bottom-menu-wrapper')}
          onClick={closeMenu}
          onTouchEnd={closeMenu}
          key={key}
          style={{ opacity, pointerEvents }}
        >
          <animated.div
            ref={menuRef}
            className={cn('animation-wrapper', { 'animate-bottom': animateBottom })}
            style={{ transform, bottom: menuDragOffset < 0 && `${menuDragOffset}px` }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleMenuDrag}
            onTouchEnd={handleTouchEnd}
          >
            <div className={cn('draggable-placeholder')} onClick={(e) => e.stopPropagation()}>
              <CloseIcon className={cn('icon')} aria-hidden onClick={closeMenu} />
            </div>
            {item}
          </animated.div>
        </animated.div>
      ))}
    </>
  );
};

BottomMenuWrapper.propTypes = {
  children: T.any,
  animationDuration: T.number,
};

export default BottomMenuWrapper;
