import React, { useState, useRef, useEffect, createRef, useMemo } from 'react';
import T from 'prop-types';
import { useLocation } from 'react-router-dom';
// utils
import { isIE } from 'react-device-detect';
import { determineInitTabIndex } from '../helpers';
import queryString from 'query-string';
import { capitalizeEachWord } from 'utils/string';
import { useTransition, animated } from '@react-spring/web';
// components
import Text from 'components/Text';
// styles
import classnames from 'classnames/bind';
import styles from './withTabSwitcher.module.scss';

const cn = classnames.bind(styles);

const withTabSwitcher = (WrappedComponent) => {
  const EnhancedComponent = ({
    tabOptions,
    stickyHeader = false,
    placeTabsMiddle = false,
    makeNavExpandable = false,
    ...props
  }) => {
    const [activeTab, setActiveTab] = useState({ title: tabOptions[0].title, target: null });
    const [expandToNav, setExpandToNav] = useState(false);
    const sliderRef = useRef();
    const contentRef = useRef();

    const [emptyPlaceholderHeight, setEmptyPlaceholderHeight] = useState(0);
    const tabNavRef = useRef();

    const tabsRefs = tabOptions.map((_) => createRef());
    const { search, pathname } = useLocation();
    const { tab: searchTabName } = queryString.parse(search);

    const isExpanded = makeNavExpandable && expandToNav;

    // apply slider width on init
    useEffect(() => {
      const initRefIndex = determineInitTabIndex(tabOptions, searchTabName);
      // init positioning
      tabsRefs[initRefIndex].current && tabsRefs[initRefIndex].current.click();
      setActiveTab((prev) => ({ ...prev, target: tabsRefs[initRefIndex].current }));
      // eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
      const updateSliderOnResize = () => {
        const itemXPos = activeTab.target.offsetLeft;
        sliderRef.current.style.left = `${itemXPos}px`;
      };

      window.addEventListener('resize', updateSliderOnResize);

      return () => window.removeEventListener('resize', updateSliderOnResize);
    }, [activeTab.target]);

    /**
     * These two effects only take care of expandable nav for week page
     */
    useEffect(() => {
      if (makeNavExpandable) {
        const determineExpansionHeight = () => {
          if (contentRef.current) {
            const { top: offsetTop } = contentRef.current.getBoundingClientRect();
            setExpandToNav(offsetTop <= 145);
          }
        };

        window.addEventListener('scroll', determineExpansionHeight);

        return () => window.removeEventListener('scroll', determineExpansionHeight);
      }
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (makeNavExpandable) {
        activeTab.target && activeTab.target.click();
      }
    }, [expandToNav]);

    // grab init height of tab and set the expandable empty placeholder height
    useEffect(() => {
      const additionalHeight = !!props.targetSel ? 0 : 40;
      const tabNavRefHeight = tabNavRef.current && tabNavRef.current.getBoundingClientRect().height;
      if (tabNavRefHeight) {
        setEmptyPlaceholderHeight(tabNavRefHeight + additionalHeight);
      }
    }, [tabNavRef.current, props]);

    const handleNavItemClick = (e, title) => {
      setActiveTab({ title, target: e.target });
      const sliderLeftOffset = e.target.offsetLeft;
      const sliderWidth = e.target.offsetWidth;
      sliderRef.current.style.left = `${sliderLeftOffset}px`;
      sliderRef.current.style.width = `${sliderWidth}px`;
    };

    const getNavItems = (options) =>
      options.map((option, i) => (
        <div key={i} ref={tabsRefs[i]} onClick={(e) => handleNavItemClick(e, option.title)}>
          <Text className={cn('nav-item', { 'active-tab': option.title === activeTab.title })} type="body">
            {capitalizeEachWord(option.title)}
          </Text>
        </div>
      ));

    const memoizedTab = useMemo(() => activeTab, [activeTab.title]);

    const transitions = useTransition(null, {
      keys: memoizedTab,
      from: { opacity: 0 },
      enter: { opacity: 1 },
      config: {
        duration: 300,
        tension: 250,
      },
    });

    // This function determines which sub-component to render (active component)
    // Wrapped in FadeIn applies spring fade in animation upon tab switch
    // Important to pass props to child component as they contain store data
    const renderProperComponent = (optionsArray) => {
      const Component =
        optionsArray.filter(({ title }) => title === memoizedTab.title)[0] &&
        optionsArray.filter(({ title }) => title === memoizedTab.title)[0].component;
      return typeof Component === 'function' ? (
        <>
          {transitions((style) => (
            <animated.div style={{ ...style }}>
              <div style={!placeTabsMiddle ? { maxWidth: 1400, margin: 'auto' } : null}>
                <Component {...props} activeTab={memoizedTab} />
              </div>
            </animated.div>
          ))}
        </>
      ) : (
        <>
          {transitions((style) => (
            <animated.div style={{ ...style }}>
              <div style={!placeTabsMiddle ? { maxWidth: 1400, margin: 'auto' } : null}>{Component}</div>
            </animated.div>
          ))}
        </>
      );
    };

    const EmptyPlaceHolder = () => (
      <div
        style={{
          width: '100%',
          height: emptyPlaceholderHeight,
          display: isExpanded ? 'block' : 'none',
        }}
      />
    );
    const renderNavSection = () => (
      <>
        <section
          ref={tabNavRef}
          id="page-tab-nav"
          className={cn('page-tab-nav', {
            'page-tab-nav--border': placeTabsMiddle,
            'page-tab-nav--sticky': stickyHeader,
            'page-tab-nav--expanded': isExpanded,
            'page-tab-nav--middle': placeTabsMiddle || isExpanded,
            'is-ie': isIE,
          })}
          style={
            !placeTabsMiddle && !isExpanded
              ? {
                  maxWidth: 1400,
                  margin: 'auto',
                }
              : null
          }
        >
          <div
            className={cn('nav-items', {
              'nav-items--expanded': isExpanded,
            })}
          >
            {getNavItems(tabOptions)}
          </div>
          <div
            className={cn('horizontal-line', {
              'horizontal-line--hidden': placeTabsMiddle || isExpanded,
            })}
          >
            <hr ref={sliderRef} />
          </div>
        </section>
      </>
    );

    return (
      <div className={cn('content-wrapper')}>
        <WrappedComponent {...props} activeTab={activeTab.title} expandToNav={expandToNav} />
        {renderNavSection()}
        <EmptyPlaceHolder />
        <div className={cn('tab-content')} ref={contentRef}>
          {renderProperComponent(tabOptions)}
        </div>
      </div>
    );
  };

  EnhancedComponent.propTypes = {
    tabOptions: T.arrayOf(T.object).isRequired,
    stickyHeader: T.bool,
    placeTabsMiddle: T.bool,
    makeNavExpandable: T.bool,
  };

  return EnhancedComponent;
};

withTabSwitcher.propTypes = {
  WrappedComponent: T.element,
};

export default withTabSwitcher;
