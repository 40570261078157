const MR = 'mr';
const MRS = 'mrs';
const MISS = 'miss';
const MS = 'ms';
const MX = 'mx';
const DR = 'dr';

export const TITLE_OPTIONS_LABELS = {
  [MS]: 'Ms.',
  [MRS]: 'Mrs.',
  [MX]: 'Mx.',
  [MR]: 'Mr.',
  [MISS]: 'Miss',
  [DR]: 'Dr.',
};

export const TITLE_OPTIONS = [MS, MRS, MX, MR, DR].map((title) => ({
  label: TITLE_OPTIONS_LABELS[title],
  value: title,
}));

export const PROGRAM_ADMIN_ROLE = 'program_admin';
export const ADMIN_ROLE = 'school_admin';
export const TEACHER_ROLE = 'teacher';

export const USER_ROLE_LABELS = {
  [PROGRAM_ADMIN_ROLE]: 'Program Admin',
  [ADMIN_ROLE]: 'School Admin',
  [TEACHER_ROLE]: 'Teacher',
};

export const USER_ROLE_OPTIONS = [ADMIN_ROLE, TEACHER_ROLE].map((role) => ({
  label: USER_ROLE_LABELS[role],
  value: role,
}));

export const USER_ROLE_BASIC_OPTIONS = [
  { label: 'Admin User', value: 'admin' },
  { label: 'Teacher', value: TEACHER_ROLE },
];

const adminRoles = [PROGRAM_ADMIN_ROLE, ADMIN_ROLE];

export const isAdminUser = (role) => adminRoles.includes(role);
export const isProgramAdmin = (role) => role === PROGRAM_ADMIN_ROLE;
export const isSchoolAdmin = (role) => role === ADMIN_ROLE;

export const hasAdminRole = (roles) => roles.find(({ role }) => adminRoles.includes(role));
export const hasProgramAdminRole = (roles) => roles.find(({ role }) => role === PROGRAM_ADMIN_ROLE);
export const hasSchoolAdminRole = (roles) => roles.find(({ role }) => role === ADMIN_ROLE);
export const hasTeacherRole = (roles) => roles.find(({ role }) => role === TEACHER_ROLE);

export const ACCEPTED_STATUS = 'accepted';
export const PENDING_STATUS = 'pending';

export const USER_STATUSES = {
  [ACCEPTED_STATUS]: 'active',
  [PENDING_STATUS]: 'pending',
  [null]: 'not assigned',
};
