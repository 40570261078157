import React from 'react';
import { useParams } from 'react-router-dom';
// utils
import { isMobile, isIE } from 'react-device-detect';
import { getTitleFromUri } from 'utils/string';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { buildUrl } from 'utils';
import {
  eventCategory,
  eventAction,
  eventLabel,
  USER_ROLE,
  PROGRAM_NAME,
  UNIT_NUMBER,
  UNIT_TITLE,
  WEEK_NAME,
  gaRoleLabels,
  customDimensions,
} from 'constants/googleAnalytics';
import { GAEmitEvent } from 'utils/googleAnalytics';
// components
import Text from 'components/Text';
import PrintableBox from 'components/PrintableBox';
// styles
import classnames from 'classnames/bind';
import styles from './Printables.module.scss';

const cn = classnames.bind(styles);

const Printables = ({ week: { weeks = [] } = {}, unit: { order_number, units = [] } = {} }) => {
  const { classroomId } = useParams();
  const { printable_urls } = useSelector((state) => state.classrooms.lessonPlans);
  const { color: classroomColor } = useSelector(({ classrooms }) => {
    const _classroom = classrooms.classrooms.length && classrooms.classrooms.find(({ id }) => id === +classroomId);
    return {
      color: get(_classroom, 'color', ''),
    };
  });
  const { role } = useSelector(({ auth }) => auth.user);
  const { name: programName } = useSelector(({ program }) => program);

  const handlePrintableClick = (uri) => {
    const customDims = {
      [customDimensions[USER_ROLE]]: gaRoleLabels[role],
      [customDimensions[PROGRAM_NAME]]: programName,
      [customDimensions[UNIT_NUMBER]]: order_number,
      [customDimensions[UNIT_TITLE]]: !!units[0] && units[0].name,
      [customDimensions[WEEK_NAME]]: !!weeks[0] && weeks[0].name,
    };
    GAEmitEvent(eventCategory.PDF, eventAction.DOWNLOAD, eventLabel.gettingReady(getTitleFromUri(uri)), customDims);
    GAEmitEvent(
      eventCategory.USER_ACTION,
      eventAction.CLICK,
      eventLabel.click('Professional Prep', getTitleFromUri(uri)),
      customDims,
    );

    return window.open(buildUrl(uri), '_blank');
  };

  return (
    <div className={cn('wrapper', { 'is-ie': isIE })}>
      {(printable_urls || []).length ? (
        <div className={cn('section-container')}>
          <div className={cn('section-container--upper-bar')} style={{ backgroundColor: classroomColor }}></div>
          <div className={cn('printables-container')}>
            {printable_urls.map(({ attachment_url, attachment_size }, i) => (
              <PrintableBox
                key={i}
                className={cn('printables-container--printable')}
                title={getTitleFromUri(attachment_url)}
                fileSize={attachment_size}
                handleClick={() => handlePrintableClick(attachment_url)}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className={cn('no-printables')}>
          <Text type={isMobile ? 'h5' : 'h3'}>There are no Printables for this Lesson Plan</Text>
        </div>
      )}
    </div>
  );
};

export default Printables;
