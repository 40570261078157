import React, { useState } from 'react';
import T from 'prop-types';
// utils
import { isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { ADMIN_ROLE, USER_ROLE_BASIC_OPTIONS, PROGRAM_ADMIN_ROLE, TEACHER_ROLE } from 'constants/user';
import { MODAL_KEYS } from 'constants/modal';
import { getDefaultValue } from 'utils/object';
import { isEmpty } from 'lodash';
// components
import Modal from 'components/Modal';
import ModalAnimWrapper from 'components/Modal/ModalAnimationWrapper';
import Text from 'components/Text';
import Button from 'components/Button';
import MultiMailField from 'components/MultiMailField';
import SelectBox from 'components/SelectBox';
import WarningModal from 'components/Modal/WarningModal';
// styles
import classnames from 'classnames/bind';
import styles from './BrowserView.module.scss';
import { useSelector } from 'react-redux';

const cn = classnames.bind(styles);

const InviteAdminModal = ({ name, onSubmit, onlyAdmin = false, isProgram }) => {
  const [emails, setEmails] = useState([]);
  const [warningModal, setWarningModal] = useState(false);
  const { id: schoolId } = useSelector((state) => state.school);
  const { id: programId } = useSelector((state) => state.program);

  const { register, errors, setValue, handleSubmit, clearErrors, getValues } = useForm();

  const dropdownOptions = [
    { label: 'Teacher', value: TEACHER_ROLE },
    { label: isProgram ? 'Program Admin' : 'School Admin', value: isProgram ? PROGRAM_ADMIN_ROLE : ADMIN_ROLE },
  ];

  let modalKey;
  if (isProgram) {
    if (onlyAdmin) {
      modalKey = MODAL_KEYS.programInviteAdmin;
    } else {
      modalKey = MODAL_KEYS.programInviteUser;
    }
  } else {
    if (onlyAdmin) {
      modalKey = MODAL_KEYS.schoolInviteAdmin;
    } else {
      modalKey = MODAL_KEYS.schoolInviteUser;
    }
  }

  const parseResponseData = (type, emails) =>
    emails.reduce(
      (acc, email) =>
        isProgram
          ? [
              ...acc,
              {
                email,
                programs_users_attributes: [
                  { program_id: programId, role: type.role === 'teacher' ? null : type.role },
                ],
              },
            ]
          : [
              ...acc,
              {
                email,
                programs_users_attributes: [{ program_id: programId }],
                schools_users_attributes: [{ school_id: schoolId, ...type }],
              },
            ],
      [],
    );

  return (
    <Modal className={cn('program-invite-modal')}>
      {({ closeModal, isBlockedModal }) => {
        const handleSubmitForm = (data) => {
          if (isBlockedModal(modalKey)) {
            const userRole = !isEmpty(data) ? data : isProgram ? { role: PROGRAM_ADMIN_ROLE } : { role: ADMIN_ROLE };
            onSubmit(parseResponseData(userRole, emails));
            closeModal();
            return;
          }

          setWarningModal(true);
        };

        return (
          <>
            <Modal.Header className="friendly">
              <Text type="h5">{name ? `Invite People to ${name}` : 'Invite People'}</Text>
              <Modal.CloseButton className="friendly" onClick={closeModal} />
            </Modal.Header>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              <Modal.Body className={cn('modal-body')}>
                <Text className="mb-5" type="body-small">
                  To:
                </Text>
                <MultiMailField
                  className={cn('email-container')}
                  placeholder="name@example.com"
                  emails={emails}
                  setEmails={setEmails}
                />
                {!onlyAdmin && (
                  <SelectBox
                    className={cn('mt-15')}
                    options={dropdownOptions}
                    label="User Type"
                    name="role"
                    errors={errors}
                    hookFormProps={{
                      register,
                      setValue,
                      clearErrors,
                    }}
                    defaultValue={getDefaultValue(dropdownOptions, TEACHER_ROLE)}
                    isSearchable={false}
                  />
                )}
              </Modal.Body>
              {isMobile ? (
                <Button
                  className={cn('submit-button')}
                  size="bg"
                  label="Invite Users"
                  isSubmit
                  disabled={!emails.length}
                />
              ) : (
                <Modal.Footer className="friendly">
                  <Button className="mr-20 ph-30" size="md" variant="filled" label="Cancel" onClick={closeModal} />
                  <Button className="ph-40" size="md" label="Add" isSubmit disabled={!emails.length} />
                </Modal.Footer>
              )}
            </form>
            <ModalAnimWrapper>
              {warningModal && (
                <WarningModal
                  onSave={() => {
                    const userRole = !isEmpty(getValues())
                      ? getValues()
                      : isProgram
                      ? { role: PROGRAM_ADMIN_ROLE }
                      : { role: ADMIN_ROLE };
                    onSubmit(parseResponseData(userRole, emails));
                  }}
                  modalText={`You are about to send ${emails.length} email invitation(s). Are you sure?`}
                  modalKey={modalKey}
                  onCancel={() => setWarningModal(false)}
                  saveLabel="Add Users"
                />
              )}
            </ModalAnimWrapper>
          </>
        );
      }}
    </Modal>
  );
};

InviteAdminModal.propTypes = {
  name: T.string.isRequired,
  onSubmit: T.func.isRequired,
  onlyAdmin: T.bool,
  isProgram: T.bool.isRequired,
};

export default InviteAdminModal;
