import 'core-js/stable';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
// pages
import App from 'pages/App';
// components
import AppProvider from 'components/AppProvider/AppProvider';
import Div100vh from 'react-div-100vh';
// styles
import 'styles/main.scss';
import 'styles/toast.scss';


ReactDOM.render(
  <React.StrictMode>
    <Div100vh style={{ minHeight: 600 }}>
      <AppProvider>
        <App />
      </AppProvider>
    </Div100vh>
  </React.StrictMode>,
  document.getElementById('root'),
);
