import React, { useState } from 'react';
import T from 'prop-types';
import classnames from 'classnames/bind';
// icons
import { ReactComponent as EyeOpen } from 'icons/eye-open.svg';
import { ReactComponent as EyeSlash } from 'icons/eye-slash.svg';
// components
import Input from './Input';
// styles
import styles from './Input.module.scss';

const cn = classnames.bind(styles);

const PasswordInput = ({ label, ...props }) => {
  const [isVisible, setIsvisible] = useState(false);
  const hadleOnEyeClick = () => setIsvisible(!isVisible);
  const type = isVisible ? 'text' : 'password';
  const labelNode = (
    <div className={cn('password-label')}>
      {label}
      <span className={cn('eye-toggle')} aria-hidden onClick={hadleOnEyeClick}>
        {isVisible ? (
          <>
            <EyeSlash className={cn('eye-icon')} />
            Hide
          </>
        ) : (
          <>
            <EyeOpen className={cn('eye-icon')} />
            Show
          </>
        )}
      </span>
    </div>
  );

  return <Input label={labelNode} type={type} {...props} />;
};

PasswordInput.propTypes = {
  label: T.string,
};

export default PasswordInput;
