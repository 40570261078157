import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import T from 'prop-types';
// hooks
import { useTableSortedData } from 'hooks/useTableSortedData';
import { useModalSetter } from 'hooks/useModal';
// hoc
import withTabSwitcher from 'hoc/withTabSwitcher';
// utils
import queryString from 'query-string';
import { difference } from 'lodash';
import { useForm } from 'react-hook-form';
import { getDefaultValue } from 'utils/object';
import { updateUserClassrooms } from 'store/auth';
import { useSelector, useDispatch } from 'react-redux';
import {
  TITLE_OPTIONS_LABELS,
  TITLE_OPTIONS,
  isProgramAdmin,
  isSchoolAdmin,
  USER_ROLE_LABELS,
  TEACHER_ROLE,
} from 'constants/user';
import { buildUrl } from 'utils';
import {
  renderClassroomNameColumn,
  renderSchoolColumn,
  renderLevelColumn,
  renderCurrentLocation,
  renderStatusColumn,
} from '../../MyProfile/BrowserView/MyClassrooms';
// containers
import TablePagination from 'containers/TablePagination';
// components
import UserActionWarningModal from 'pages/AdminPanel/UserActionWarningModal';
import AddContentField from 'components/AddContentField';
import SelectBox from 'components/SelectBox';
import DotsDropdown from 'components/Dropdown/DotsDropdown';
import Table from 'components/Table';
import Button from 'components/Button';
import Text from 'components/Text';
import AdminBadge from 'components/Badges/AdminBadge';
import SearchInput from 'components/Input/SearchInput';
import Input from 'components/Input';
import ManageClassroomsModal from './ManageClassroomsModal';
// icons
import { ReactComponent as AddIcon } from 'icons/add-white.svg';
import { ReactComponent as Chevron } from 'icons/chevron.svg';
// styles
import classnames from 'classnames/bind';
import styles from './BrowserView.module.scss';

const cn = classnames.bind(styles);

export const Classrooms = ({ classrooms, allClassrooms, fullName, programId, schoolId, classroom_ids }) => {
  const { userId } = useParams();
  const {
    push,
    location: { search },
  } = useHistory();
  const { range } = queryString.parse(search);
  const isSchoolScope = range === 'school';

  const dispatch = useDispatch();

  const { sortState, handleSortClick, handleSearchChange, dataSorted } = useTableSortedData(
    classrooms,
    'name',
    'status',
  );

  const setModal = useModalSetter();

  const dropdownOptions = (classroom) => [
    {
      label: classroom.completed ? 'Manage Classroom' : 'Set Up Classroom',
      itemProps: {
        onClick: () => push(classroom.completed ? `/manage-classroom/${classroom.id}` : '/add-classroom'),
      },
    },
    {
      label: <span className={cn('warn-text')}>Remove User From Classroom</span>,
      itemProps: {
        onClick: () =>
          dispatch(
            updateUserClassrooms({
              userId: +userId,
              classroomIds: classroom_ids.filter((_id) => +_id !== +classroom.id),
              programId,
              schoolId,
            }),
          ),
      },
    },
  ];

  const renderButtonsColumn = (props, className, width) => {
    return (
      <div
        className={cn(className, 'buttons-column')}
        style={{ minWidth: width, flexBasis: width, justifyContent: 'flex-end' }}
      >
        <DotsDropdown options={dropdownOptions(props)} className={cn('table-dropdown')} />
      </div>
    );
  };

  const columns = [
    { label: 'Classroom', renderer: renderClassroomNameColumn, isBordered: false, width: 350 },
    { label: 'School', renderer: renderSchoolColumn, width: 240 },
    { label: 'Level', renderer: renderLevelColumn, width: 200 },
    { label: 'Current Week', renderer: renderCurrentLocation, width: 250 },
    { label: 'Status', renderer: renderStatusColumn, handleSort: handleSortClick, sorted: sortState },
    { label: '', renderer: renderButtonsColumn, width: 100 },
  ].filter(({ label }) => (isSchoolScope && label === 'School' ? false : true));

  return (
    <section className={cn('classrooms-section')}>
      <div className="ph-60 pb-60">
        {classrooms.length > 0 ? (
          <>
            <div className={cn('classrooms-heading-wrapper')}>
              <Text type="h5" className={cn('title', 'mv-25')}>
                Classrooms
              </Text>
              <SearchInput name={'classroomSearch'} onChange={handleSearchChange} />
            </div>
            <TablePagination pageSize={10} data={dataSorted}>
              {({ paginatedData }) => (
                <Table className={cn('classrooms-table')} columns={columns} data={paginatedData} />
              )}
            </TablePagination>
          </>
        ) : (
          <AddContentField
            className={cn('add-container', 'mt-20')}
            text={`Assign a Classroom.`}
            onClick={() =>
              setModal(
                <ManageClassroomsModal
                  fullName={fullName}
                  teacherId={+userId}
                  notAssigned={difference(allClassrooms, classrooms)}
                  assigned={classrooms}
                  programId={programId}
                  schoolId={schoolId}
                />,
              )
            }
          />
        )}
      </div>
    </section>
  );
};

export const UserInfo = ({ programId, schoolId, targetRoleObj, onRevoke, onDisable }) => {
  const { userId } = useParams();
  const { first_name: firstName, last_name: lastName, title, email, is_active: isActive, full_name } = useSelector(
    ({ auth: { userProfileInfo = {} } = {} }) => userProfileInfo,
  );
  const { id: myId } = useSelector(({ auth }) => auth.user);

  const { register, errors, clearErrors, ...restForm } = useForm();

  const dispatch = useDispatch();

  const setModal = useModalSetter();

  return (
    <section className={cn('info-section', 'mt-40 pb-40')}>
      <div className={cn('creds-wrapper')}>
        <SelectBox
          label="Title"
          name="title"
          hookFormProps={{ register, errors, clearErrors, ...restForm }}
          className={cn('title-select')}
          options={TITLE_OPTIONS}
          defaultValue={title && getDefaultValue(TITLE_OPTIONS, title)}
          isDisabled
        />
        <div className={cn('sub-wrapper')}>
          <Input label="First Name" name="firstName" className={cn('input', 'mr-40')} disabled value={firstName} />
          <Input label="Last Name" name="lastName" className={cn('input')} disabled value={lastName} />
          <Input label="Program Email Address" disabled name="email" className={cn('input')} placeholder={email} />
        </div>
      </div>
      <div className={cn('revoke-wrapper')}>
        {+myId !== +userId && targetRoleObj && targetRoleObj.role && targetRoleObj.role !== TEACHER_ROLE && (
          <Button
            className="ml-auto"
            size="md"
            variant="black"
            label={`Revoke ${isProgramAdmin(targetRoleObj.role) ? 'Program' : 'School'} Admin Role`}
            onClick={() =>
              setModal(
                <UserActionWarningModal
                  headerLabel="Revoke User"
                  confirmLabel="Revoke"
                  onConfirm={() => onRevoke({ adminId: userId })}
                  renderBody={() => (
                    <Text>
                      Are you sure you wish to revoke <strong>{full_name}'s</strong> admin privileges?
                    </Text>
                  )}
                />,
              )
            }
          />
        )}
      </div>
      {+myId !== +userId && targetRoleObj && (
        <div className={cn('disable-wrapper', 'pv-25 ph-20 mt-60')}>
          <div className={cn('sub-wrapper')}>
            <Text>{`${targetRoleObj.is_active ? 'Disable' : 'Activate'} User`}</Text>
            <Text type="body-small" className="mt-5">
              {targetRoleObj.is_active
                ? `Disabling this user will revoke this user's access.`
                : `Activating this user will restore this user's access.`}
            </Text>
          </div>
          <Button
            size="md"
            variant="black"
            label={`${targetRoleObj.is_active ? 'Disable' : 'Activate'} User`}
            onClick={() =>
              targetRoleObj.is_active
                ? setModal(
                    <UserActionWarningModal
                      onConfirm={onDisable}
                      renderBody={() => (
                        <Text>
                          Are you sure you wish to disable <strong>{full_name}</strong>? This action will revoke user's
                          access.
                        </Text>
                      )}
                      headerLabel="Disable User"
                      confirmLabel="Disable"
                    />,
                  )
                : onDisable(false)
            }
          />
        </div>
      )}
    </section>
  );
};

const BrowserView = ({
  fullName,
  imageUrl,
  title,
  classrooms,
  allClassrooms,
  currProgram: { name: programName },
  currSchool: { name: schoolName },
  programId,
  schoolId,
  targetRoleObj,
}) => {
  const { userId } = useParams();
  const {
    push,
    location: { search },
  } = useHistory();
  const { role: myRole } = useSelector(({ auth }) => auth.user);

  const { range } = queryString.parse(search);

  const setModal = useModalSetter();

  return (
    <div className={cn('wrapper')}>
      <div className={cn('breadcrumbs', 'mb-25')}>
        <Text
          className={cn('crumb', { link: isProgramAdmin(myRole) })}
          onClick={() => isProgramAdmin(myRole) && push('/program-panel')}
        >
          {programName}
        </Text>
        {schoolName && range === 'school' && (
          <>
            <Chevron className={cn('chevron')} />
            <Text className={cn('crumb', 'link')} onClick={() => push('/school-panel')}>
              {schoolName}
            </Text>
          </>
        )}
        <Chevron className={cn('chevron')} />
        <Text className={cn('crumb')}>{fullName}</Text>
      </div>
      <div className={cn('heading-wrapper')}>
        <img className={cn('avatar')} src={buildUrl(imageUrl)} alt="Avatar" aria-hidden />
        {title && (
          <Text type="h2" className="ml-15 mr-20">{`${TITLE_OPTIONS_LABELS[title.toLowerCase()]} ${fullName}`}</Text>
        )}
        {targetRoleObj && targetRoleObj.role && targetRoleObj.role !== TEACHER_ROLE && (
          <AdminBadge
            className={cn('admin-badge')}
            schoolAdmin={isSchoolAdmin(targetRoleObj.role)}
            role={USER_ROLE_LABELS[targetRoleObj.role]}
            long
          />
        )}
        <Button
          size="md"
          className={cn('btn-add', 'pl-10 pr-25 ml-auto')}
          label={
            <div className={cn('add-label')}>
              <AddIcon className="mr-5" />
              <Text bold>{classrooms.length > 0 ? 'Manage Classrooms' : 'Assign a classroom'}</Text>
            </div>
          }
          onClick={() =>
            setModal(
              <ManageClassroomsModal
                fullName={fullName}
                teacherId={+userId}
                notAssigned={difference(allClassrooms, classrooms)}
                assigned={classrooms}
                programId={programId}
                schoolId={schoolId}
              />,
            )
          }
        />
      </div>
    </div>
  );
};

Classrooms.propTypes = {
  classrooms: T.arrayOf(T.object).isRequired,
  allClassrooms: T.arrayOf(T.object).isRequired,
  fullName: T.string.isRequired,
};

UserInfo.propTypes = {
  programId: T.number.isRequired,
  schoolId: T.number.isRequired,
};

BrowserView.propTypes = {
  imageUrl: T.string.isRequired,
  title: T.string.isRequired,
  role: T.string.isRequired,
  classrooms: T.arrayOf(T.object).isRequired,
  allClassrooms: T.arrayOf(T.object).isRequired,
  currProgram: T.object.isRequired,
  currSchool: T.object.isRequired,
};

export default withTabSwitcher(BrowserView);
