import React, { useState, useEffect } from 'react';
import classnames from 'classnames/bind';
// icons
import { ReactComponent as CheckIcon } from 'icons/check.svg';
// styles
import Tooltip from 'components/Tooltip';
import styles from './CheckButton.module.scss';

const cn = classnames.bind(styles);

const CheckButton = ({ isChecked: _isChecked = false, onChange, className, tooltip, isPreview, disabled = false }) => {
  const [isChecked, setIsChecked] = useState(_isChecked);

  const handleClick = () => {
    if (disabled) return;
    if (onChange && !isPreview) {
      onChange(!isChecked);
    }
    !isPreview && setIsChecked(!isChecked);
  };

  useEffect(() => setIsChecked(_isChecked), [_isChecked]);

  return (
    <div className={cn('wrapper', className, { checked: isChecked, isPreview })} aria-hidden onClick={handleClick}>
      {tooltip && (
        <Tooltip text={tooltip}>
          <CheckIcon className={cn('check')} />
        </Tooltip>
      )}
      {!tooltip && <CheckIcon className={cn('check')} />}
    </div>
  );
};

export default CheckButton;
