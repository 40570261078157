import React, { useState } from 'react';
import classnames from 'classnames/bind';
// icons
import { ReactComponent as GridIcon } from 'icons/grid.svg';
import { ReactComponent as ListIcon } from 'icons/list.svg';
// styles
import styles from './ToggleView.module.scss';

const cn = classnames.bind(styles);

const ToggleView = ({ onChange, defaultIsActive, className }) => {
  const [isActive, setIsActive] = useState(defaultIsActive);

  const handleClick = (value) => () => {
    if (isActive !== value) {
      setIsActive(value);
      if (onChange) onChange(value);
    }
  };

  return (
    <div className={cn('wrapper', className)} aria-hidden onClick={handleClick}>
      <div className={cn('col', 'col-left', { active: isActive })} aria-hidden onClick={handleClick(true)}>
        <ListIcon />
      </div>
      <div className={cn('col', 'col-right', { active: !isActive })} aria-hidden onClick={handleClick(false)}>
        <GridIcon />
      </div>
    </div>
  );
};

export default ToggleView;
