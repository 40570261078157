import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
// utils
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import cn from 'classnames';
import { GAInitialize, GATrackPageView, GASetDimension } from 'utils/googleAnalytics';
import { USER_ROLE, customDimensions, gaRoleLabels } from 'constants/googleAnalytics';
// pages
import Login from 'pages/Auth/Login';
import Logout from 'pages/Auth/Logout';
import Classrooms from 'pages/Classrooms';
import Classroom from 'pages/Classroom';
import PasswordReset from 'pages/Auth/PasswordReset';
import Registration from 'pages/Auth/Registration';
import MyProfile from 'pages/MyProfile';
import LessonPlans from 'pages/LessonPlans';
import Week from 'pages/Week';
import ManageClassroom from 'pages/ManageClassroom';
import AddClassroom from 'pages/AddClassroom';
import AdminPanel from 'pages/AdminPanel';
import UserProfile from 'pages/UserProfile';
import TermsOfUse from 'pages/TermsOfUse';
import PrivacyPolicy from 'pages/PrivacyPolicy';
// components
import Navbar from 'components/Navbar';
import Favorites from 'pages/Favorites';
// mobile pages
// components
import CreatePassword from 'pages/Auth/CreatePassword/CreatePassword';
import LearningExperience from 'pages/LearningExperience';
import AdminRegistration from 'pages/Auth/AdminRegistration';
import ProgramSwitcher from 'pages/ProgramSwitcher';

const ROUTES_WITH_NAV = [
  '/classrooms',
  '/profile',
  '/classroom/:id/unit/:unitId',
  '/classroom/:id/unit/:unitId/week/:weekId',
  '/unit',
  '/manage-classroom/:classroomId',
  '/my-school',
  '/favorites',
  '/program-panel',
  '/school-panel',
  '/user-profile/:userId',
];

isMobile &&
  ROUTES_WITH_NAV.push([
    '/add-classroom',
    '/manage-classroom/:classroomId',
    '/profile/personal-data',
    '/profile/manage-password',
    '/profile/classrooms',
    '/my-school/invite-user',
  ]);

const App = () => {
  const { pathname, search } = useLocation();
  const { role } = useSelector(({ auth }) => auth.user);

  useEffect(() => GAInitialize({ gaOptions: { siteSpeedSampleRate: 100 } }), []);

  useEffect(() => {
    GASetDimension(customDimensions[USER_ROLE], gaRoleLabels[role]);
    GATrackPageView(pathname + search);
  }, [pathname, search]);

  return (
    <div className={cn('app-router', { isLearningExp: pathname && pathname.includes('learning-experience') })}>
      <Route exact path={ROUTES_WITH_NAV} component={Navbar} />
      <Switch>
        <Route path="/switch-program" component={ProgramSwitcher} />
        {/* <Route path="/sign-up" component={Registration} /> */}
        <Route path="/sign-up" component={AdminRegistration} />
        <Route exact path="/confirm-reset-password/:token" component={CreatePassword} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/password-reset" component={PasswordReset} />
        <Route exact path="/set-up-classroom/:classroomId" component={AddClassroom} />
        <Route exact path="/classrooms" component={Classrooms} />
        <Route exact path="/classroom/:classroomId/unit/:unitId" component={Classroom} />
        <Route exact path="/crassroom/:classroomId" component={Classrooms} />
        <Route exact path="/classroom/:classroomId/unit/:unitId/week/:weekId" component={Week} />
        <Route exact path="/favorites" component={Favorites} />
        <Route
          exact
          path="/classroom/:classroomId/unit/:unitId/week/:weekId/learning-experience/:learnExpId"
          component={LearningExperience}
        />
        <Route exact={!isMobile} path="/profile" component={MyProfile} />
        <Route
          exact
          path="/classroom/:classroomId/unit/:unitId/week/:weekId/lesson-plan/:activityId"
          component={LessonPlans}
        />
        <Route exact path="/manage-classroom/:classroomId" component={ManageClassroom} />
        <Route exact path="/program-panel" render={() => <AdminPanel mode="program" />} />
        <Route exact path="/school-panel" render={() => <AdminPanel mode="school" />} />
        <Route exact path="/user-profile/:userId" component={UserProfile} />
        <Route exact path="/terms-of-use" component={TermsOfUse} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Redirect from="/" to="/classrooms" />
      </Switch>
    </div>
  );
};

export default App;
