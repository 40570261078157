import React from 'react';
// utils
import { updateUserData } from 'store/auth';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// containers
import MobileSubcomponentWrapper from 'containers/MobileSubcomponentWrapper';
// components
import PasswordInput from 'components/Input/PasswordInput';
import Button from 'components/Button';
// styles
import classnames from 'classnames/bind';
import styles from './MobileView.module.scss';

const cn = classnames.bind(styles);

const schema = yup.object().shape({
  currentPassword: yup.string().test({
    name: 'passwordValidation',
    test(value) {
      if (value && value.length >= 6) return true;
      return this.createError({
        message: 'Password must be at least 6 characters',
        path: this.path,
      });
    },
  }),
  password: yup.string().min(6, 'Password must be at least 6 characters'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const ManagePasswordSection = ({ minHeight }) => {
  const dispatch = useDispatch();
  const { id: userId } = useSelector(({ auth: { user } = {} }) => user);

  const { register, getValues, handleSubmit, errors, clearErrors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = ({ currentPassword, password, passwordConfirmation }) =>
    dispatch(
      updateUserData({
        current_password: currentPassword,
        password,
        password_confirmation: passwordConfirmation,
        id: userId,
      }),
    );

  const clearErrorsOnTyping = (field) => clearErrors(field);

  return (
    <MobileSubcomponentWrapper headingTitle="Manage Password" fixedHeading>
      <div className={cn('passwords-container')} style={{ minHeight: minHeight ? `${minHeight}px` : '100vh' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PasswordInput
            name="currentPassword"
            label="Current password"
            inputRef={register({ required: true })}
            errors={errors}
            onChange={() => clearErrorsOnTyping('currentPassword')}
          />
          <PasswordInput
            name="password"
            label="Change password"
            inputRef={register({ required: true })}
            errors={errors}
            onChange={() => clearErrorsOnTyping('password')}
            disabled={!getValues('currentPassword')}
          />
          <PasswordInput
            name="passwordConfirmation"
            label="Confirm new password"
            inputRef={register({ required: true })}
            errors={errors}
            onChange={() => clearErrorsOnTyping('passwordConfirmation')}
            disabled={!getValues('currentPassword')}
          />
          <Button className={cn('submit-button')} size="bg" label="Save Changes" isSubmit />
        </form>
      </div>
    </MobileSubcomponentWrapper>
  );
};

export default ManagePasswordSection;
