import React, { useCallback, useEffect, useState } from 'react';
// utils
import { getToggleView, setToggleView } from 'utils/toggleView';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { isProgramAdmin, TITLE_OPTIONS_LABELS, USER_ROLE_LABELS } from 'constants/user';
import { GRADE_COLORS, GRADE_TITLES } from 'constants/classrooms';
import { buildUrl, getWeekOrderNumber, isEdgeWeek } from 'utils';
import { GAEmitEvent } from 'utils/googleAnalytics';
import {
  eventCategory,
  eventAction,
  eventLabel,
  customDimensions,
  USER_ROLE,
  PROGRAM_NAME,
  UNIT_NUMBER,
  UNIT_TITLE,
  WEEK_NAME,
  gaRoleLabels,
} from 'constants/googleAnalytics';
import queryString from 'query-string';
// containers
import FadeInWrapper from 'containers/FadeInWrapper';
// components
import DotsDropdown from 'components/Dropdown/DotsDropdown';
import ToggleView from 'components/ToggleView';
import Table from 'components/Table';
import Text from 'components/Text';
import Button from 'components/Button';
import Link from 'components/Link';
import UserAvatar from 'components/UserAvatar';
import YouAreHereBadge from 'components/Badges/YouAreHereBadge';
// styles
import classnames from 'classnames/bind';
import { getUser } from 'store/auth';
import { UnitSelect } from 'pages/Classroom/BrowserView/BrowserView';
import { ProgramAvatar } from 'pages/ProgramSwitcher/ProgramSwitcher';
import { clearSchoolId, updateSchoolId } from 'store/school';
import { get } from 'lodash';
import { buildMixedName } from '../helpers';
import styles from './BrowserView.module.scss';

const cn = classnames.bind(styles);

const mockOptions = (
  classroom,
  push,
  location,
  isLicenseActive,
  dispatch,
  school,
  userRole,
  lastSchool,
  draftClassroom,
  programName,
) => {
  const options = [
    {
      label: 'Launch Unit',
      itemProps: {
        onClick: () => {
          if (isLicenseActive) {
            localStorage.setItem('lastSchool', lastSchool);
            dispatch(updateSchoolId(school));
            push(
              `/classroom/${classroom.id}/unit/${
                classroom.current_unit
                  ? classroom.current_unit.id
                  : get(classroom, `unit_progresses[${classroom.unit_progresses.length - 1}].id`)
              }`,
            );
          }
        },
      },
    },
    {
      label: 'Launch Week',
      itemProps: {
        onClick: () => {
          if (isLicenseActive) {
            GAEmitEvent(
              eventCategory.USER_ACTION,
              eventAction.MENU_CLICK,
              eventLabel.launchWeek('Launch Week', location.pathname + location.search),
              {
                [customDimensions[USER_ROLE]]: gaRoleLabels[userRole],
                [customDimensions[PROGRAM_NAME]]: programName,
                [customDimensions[UNIT_NUMBER]]: get(classroom, 'current_unit.order_number', null),
                [customDimensions[UNIT_TITLE]]: get(classroom, 'current_unit.units[0].name', null),
                [customDimensions[WEEK_NAME]]: get(classroom, `current_week.weeks[0].name`, null),
              },
            );

            localStorage.setItem('lastSchool', lastSchool);
            dispatch(updateSchoolId(school));
            return push(
              `/classroom/${classroom.id}/unit/${
                classroom.current_unit
                  ? classroom.current_unit.id
                  : get(classroom, `unit_progresses[${classroom.unit_progresses.length - 1}].id`)
              }/week/${classroom.current_week ? classroom.current_week.id : 0}`,
            );
          }
        },
      },
    },
    {
      label: 'Change Cover Image',
      itemProps: {
        onClick: () => {
          localStorage.setItem('lastSchool', lastSchool);
          dispatch(updateSchoolId(school));
          push(`/set-up-classroom/${classroom.id}?cover_image_mode=true`);
        },
      },
    },
    {
      label: 'Manage Classroom',
      itemProps: {
        onClick: () => {
          localStorage.setItem('lastSchool', lastSchool);
          dispatch(updateSchoolId(school));
          push({
            pathname: `/manage-classroom/${classroom.id}`,
            state: { from: '/classrooms' },
          });
        },
      },
    },
  ];

  return draftClassroom
    ? options.slice(2)
    : classroom.current_week
    ? options
    : [...options.slice(0, 1), ...options.slice(2)];
};

const mockOptions2 = (
  classroom,
  push,
  dispatch,
  school,
  userRole,
  location,
  lastSchool,
  draftClassroom,
  programName,
) => {
  const options = [
    {
      label: classroom.all_finished ? 'Relaunch Unit' : 'Launch Unit',
      itemProps: {
        onClick: () => {
          localStorage.setItem('lastSchool', lastSchool);
          dispatch(updateSchoolId(school));
          push(
            `/classroom/${classroom.id}/unit/${
              classroom.current_unit
                ? classroom.current_unit.id
                : get(classroom, `unit_progresses[${classroom.unit_progresses.length - 1}].id`)
            }`,
          );
        },
      },
    },
    {
      label: 'Launch Week',
      itemProps: {
        onClick: () => {
          GAEmitEvent(
            eventCategory.USER_ACTION,
            eventAction.MENU_CLICK,
            eventLabel.launchWeek('Launch Week', location.pathname + location.search),
            {
              [customDimensions[USER_ROLE]]: gaRoleLabels[userRole],
              [customDimensions[PROGRAM_NAME]]: programName,
              [customDimensions[UNIT_NUMBER]]: get(classroom, 'current_unit.order_number', null),
              [customDimensions[UNIT_TITLE]]: get(classroom, 'current_unit.units[0].name', null),
              [customDimensions[WEEK_NAME]]: get(classroom, `current_week.weeks[0].name`, null),
            },
          );
          localStorage.setItem('lastSchool', lastSchool);
          dispatch(updateSchoolId(school));
          return push(
            `/classroom/${classroom.id}/unit/${
              classroom.current_unit
                ? classroom.current_unit.id
                : get(classroom, `unit_progresses[${classroom.unit_progresses.length - 1}].id`)
            }/week/${classroom.current_week ? classroom.current_week.id : 0}`,
          );
        },
      },
    },
    {
      label: 'Change Cover Image',
      itemProps: {
        onClick: () => {
          localStorage.setItem('lastSchool', lastSchool);
          dispatch(updateSchoolId(school));
          push(`/set-up-classroom/${classroom.id}?cover_image_mode=true`);
        },
      },
    },
    {
      label: 'Manage Classroom',
      itemProps: {
        onClick: () => {
          localStorage.setItem('lastSchool', lastSchool);
          dispatch(updateSchoolId(school));
          push({
            pathname: `/manage-classroom/${classroom.id}`,
            state: { from: '/classrooms' },
          });
        },
      },
    },
  ];

  return draftClassroom
    ? options.slice(2)
    : classroom.current_week
    ? options
    : [...options.slice(0, 1), ...options.slice(2)];
};

const renderClassroomNameColumn = (props, className, width) => {
  const draftClassroom = props.unit_progresses.length === 0;

  return (
    <div className={cn('classroom-col')} style={{ minWidth: width, flexBasis: width }}>
      <div
        className={cn('classroom-col-image')}
        style={{ backgroundColor: props.grade_level ? GRADE_COLORS[props.grade_level] : '#e3e3e3' }}
      >
        {props.completed && props.cover_image && (
          <img className={cn('image')} src={buildUrl(props.cover_image.image_url)} alt="Img" />
        )}
      </div>
      <div className={cn('classroom-name-col')}>
        <Text type="h11">{GRADE_TITLES[props.grade_level]}</Text>
        <Link
          disabled={draftClassroom}
          to={
            props.completed
              ? `/classroom/${props.id}/unit/${
                  props.current_unit
                    ? props.current_unit.id
                    : get(props, `unit_progresses[${props.unit_progresses.length - 1}].id`)
                }`
              : '/add-classroom'
          }
        >
          <Text type="h6">{props.name}</Text>
        </Link>
      </div>
    </div>
  );
};

const renderUnitColumn = (props, className, width) => {
  const draftClassroom = props.unit_progresses.length === 0;

  return (
    <div className={cn(className, 'unit-column')} style={{ minWidth: width, flexBasis: width }}>
      {props.completed && !draftClassroom && (
        <>
          <Link
            to={`/classroom/${props.id}/unit/${
              props.current_unit
                ? props.current_unit.id
                : get(props, `unit_progresses[${props.unit_progresses.length - 1}].id`)
            }`}
          >
            <Text bold>
              {`Unit ${
                props.current_unit
                  ? props.current_unit.order_number
                  : get(props, `unit_progresses[${props.unit_progresses.length - 1}].order_number`)
              }: `}
              {buildMixedName(
                props.current_unit
                  ? props.current_unit.units
                  : get(props, `unit_progresses[${props.unit_progresses.length - 1}].units`),
              )}
            </Text>
          </Link>
          <Text>
            {buildMixedName(
              props.current_unit
                ? props.current_unit.units
                : get(props, `unit_progresses[${props.unit_progresses.length - 1}].units`),
              'description',
            )}
          </Text>
        </>
      )}
    </div>
  );
};

const BrowserView = () => {
  const [isTableView, setIsTableView] = useState(getToggleView('classrooms'));
  const { push, location } = useHistory();
  const { id: schoolId } = useSelector((state) => state.school);

  const {
    user: {
      title,
      first_name: firstName,
      email,
      school_license_active: isLicenseActive,
      avatar_url: avatarUrl,
      school_ids,
      role,
      roles,
      classroom_ids,
      reloadNeeded,
    },
  } = useSelector((state) => state.auth);
  const { classrooms, isLoading, hasErrors, isLoadedClassrooms } = useSelector((state) => ({
    ...state.classrooms,
    classrooms: state.classrooms.classrooms.filter(({ id, is_active }) => classroom_ids.includes(id) && is_active),
  }));

  const { id: currentProgramId, image_url: programImageUrl, name: programName } = useSelector((state) => state.program);
  const schools = useSelector((state) =>
    state.program.schools.filter(({ id }) => school_ids.includes(id) || isProgramAdmin(role)),
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (reloadNeeded) {
      dispatch(getUser());
    }
  }, [dispatch, reloadNeeded]);

  useEffect(() => {
    if (!isLoading && !hasErrors && !classrooms.length && email && !isLoadedClassrooms) {
      dispatch(getUser());
    }
  }, [dispatch, isLoading, classrooms, hasErrors, email, isLoadedClassrooms]);
  const { search } = useLocation();

  const { school: lastSchool } = queryString.parse(search);

  const renderButtonsColumn = (props, className, width) => {
    const draftClassroom = props.unit_progresses.length === 0;

    return (
      <div className={cn(className, 'buttons-column')} style={{ minWidth: width, flexBasis: width }}>
        {props.completed && !props.all_finished && !draftClassroom && (
          <>
            <Button
              label="Launch Unit"
              onClick={() => {
                localStorage.setItem('lastSchool', lastSchool);
                dispatch(updateSchoolId(schools.find(({ id }) => id === get(props, 'school.id', 0))));
                push(
                  `/classroom/${props.id}/unit/${
                    props.current_unit
                      ? props.current_unit.id
                      : get(props, `unit_progresses[${props.unit_progresses.length - 1}].id`)
                  }`,
                );
              }}
              size="s"
              disabled={!isLicenseActive}
              variant="filled"
            />
            {props.current_week && (
              <Button
                onClick={() => {
                  GAEmitEvent(
                    eventCategory.USER_ACTION,
                    eventAction.MENU_CLICK,
                    eventLabel.launchWeek('Launch Week', location.pathname + location.search),
                    {
                      [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                      [customDimensions[PROGRAM_NAME]]: programName,
                      [customDimensions[UNIT_NUMBER]]: get(props, 'current_unit.order_number', null),
                      [customDimensions[UNIT_TITLE]]: get(props, 'current_unit.units[0].name', null),
                      [customDimensions[WEEK_NAME]]: get(props, `current_week.weeks[0].name`, null),
                    },
                  );
                  localStorage.setItem('lastSchool', lastSchool);
                  dispatch(updateSchoolId(schools.find(({ id }) => id === get(props, 'school.id', 0))));
                  return push(
                    `/classroom/${props.id}/unit/${props.current_unit && props.current_unit.id}/week/${
                      props.current_week && props.current_week.id
                    }`,
                  );
                }}
                className="ml-15"
                disabled={!isLicenseActive}
                label="Launch Week"
                size="s"
              />
            )}
          </>
        )}

        {!props.completed && !props.all_finished && (
          <Button
            label={role === 'teacher' ? 'Set Up Classroom' : 'Manage'}
            onClick={() => {
              localStorage.setItem('lastSchool', lastSchool);
              dispatch(updateSchoolId(schools.find(({ id }) => id === get(props, 'school.id', 0))));
              if (role === 'teacher') {
                push(`/set-up-classroom/${props.id}`);
              } else {
                push(`/manage-classroom/${props.id}`);
              }
            }}
            size="s"
            disabled={!isLicenseActive}
          />
        )}

        {props.completed && props.all_finished && !draftClassroom && (
          <Button
            label="Relaunch Unit"
            onClick={() => {
              localStorage.setItem('lastSchool', lastSchool);
              dispatch(updateSchoolId(schools.find(({ id }) => id === get(props, 'school.id', 0))));
              push(
                `/classroom/${props.id}/unit/${
                  props.current_unit
                    ? props.current_unit.id
                    : get(props, `unit_progresses[${props.unit_progresses.length - 1}].id`)
                }`,
              );
            }}
            size="s"
            disabled={!isLicenseActive}
            variant="filled"
          />
        )}

        {props.completed && (
          <DotsDropdown
            options={mockOptions2(
              props,
              push,
              dispatch,
              schools.find(({ id }) => id === get(props, 'school.id', 0)),
              role,
              location,
              lastSchool,
              draftClassroom,
              programName,
            )}
            className={cn('table-dropdown')}
          />
        )}
      </div>
    );
  };

  const renderCurrentSpot = (props, className, width) => (
    <div
      aria-hidden
      className={cn(className, 'current-spot-column')}
      style={{ minWidth: width, flexBasis: width }}
      onClick={() => {
        localStorage.setItem('lastSchool', lastSchool);
        dispatch(updateSchoolId(schools.find(({ id }) => id === get(props, 'school.id', 0))));
      }}
    >
      {props.completed && !props.all_finished && (
        <Link
          isPrimary
          disabled={!isLicenseActive}
          to={`/classroom/${props.id}/unit/${props.current_unit && props.current_unit.id}/week/${
            props.current_week && props.current_week.id
          }`}
          className={cn('link')}
        >
          <Text>
            {props.current_week && `${getWeekOrderNumber(props.current_week.order_number, true, true)} `}
            {props.current_week &&
              !isEdgeWeek(props.current_week.order_number) &&
              buildMixedName(props.current_week.weeks)}
          </Text>
        </Link>
      )}
    </div>
  );

  const renderFakeNameCol = (props, className, width) => (
    <div className={cn('classroom-col')} style={{ minWidth: width, flexBasis: width }}>
      <div className={cn('classroom-col-image')} style={{ backgroundColor: props.color }} />
      <div className={cn('classroom-name-col')}>
        <Text type="h11">{GRADE_TITLES[props.grade_level]}</Text>
        <Link disabled to={`/set-up-classroom/${props.id}`}>
          <Text type="h6">{props.name}</Text>
        </Link>
      </div>
    </div>
  );

  const renderFakeButtonsCol = (props, className, width) => (
    <div className={cn(className, 'buttons-column')} style={{ minWidth: width, flexBasis: width }}>
      <Button className="ml-15" onClick={() => push(`/add-classroom`)} label="Set Up Classroom" size="s" />
      <DotsDropdown options={[{}]} className={cn('table-dropdown')} />
    </div>
  );

  const columns = [
    { label: 'Classroom', renderer: renderClassroomNameColumn, isBordered: false, width: 270 },
    { label: 'Unit', renderer: renderUnitColumn, width: 270 },
    { label: 'Current Spot', renderer: renderCurrentSpot, width: 270 },
    { label: '', renderer: renderButtonsColumn, width: 320 },
  ];

  const ALL_SCHOOLS_OPTION = () => ({
    label: `All classrooms (${
      schools
        .map((school) => school.classrooms)
        .flat()
        .filter(({ id, is_active }) => classroom_ids.includes(id) && is_active).length
    })`,
    value: {
      id: 0,
      classrooms: {
        length: schools
          .map((school) => school.classrooms)
          .filter(({ id, is_active }) => classroom_ids.includes(id) && is_active)
          .flat().length,
      },
      avatar_url: programImageUrl,
      name: 'All Programs',
    },
  });

  const [selectedSchool, setSelectedSchool] = useState(ALL_SCHOOLS_OPTION());

  const getRoleForSchool = (schoolId) => {
    if (!role) return '';
    if (isProgramAdmin(role)) return '';

    const assocRole = roles.find(({ id, type }) => +schoolId === +id && type === 'school')?.role;

    return `• ${USER_ROLE_LABELS[assocRole]}`;
  };

  const schoolSelectOptions = [
    ALL_SCHOOLS_OPTION(),
    ...schools.map((school) => ({
      label: `${school.name} (${
        school.classrooms.filter(({ id, is_active }) => classroom_ids.includes(id) && is_active).length
      }) ${getRoleForSchool(school.id)}`,
      value: school,
    })),
  ];

  const isAnyClassroom =
    schools.filter((school) => school.classrooms.find(({ id }) => classroom_ids.includes(id))).length > 0;

  const schoolsWithClassrooms = schools.filter((school) =>
    school.classrooms.map(({ id }) => id).reduce((prev, next) => prev || classroom_ids.includes(next), false),
  );

  const renderSchoolValue = ({ value, label }) => (
    <div className={cn('school-value-wrapper')}>
      <ProgramAvatar className={cn('avatar')} imageUrl={value.avatar_url} name={value.name} />
      <div>
        <Text type="h6">
          {title && `${TITLE_OPTIONS_LABELS[title.toLowerCase()]} `}
          {firstName}
        </Text>
        <Text type="h2">{label}</Text>
      </div>
    </div>
  );

  const renderSchoolOption = ({ label }) => <Text>{label}</Text>;
  const handleSchoolSelect = useCallback(
    ({ value }) => {
      if (value.id === 0) {
        dispatch(clearSchoolId());
      } else {
        localStorage.setItem('lastSchool', lastSchool);
        dispatch(updateSchoolId(value));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (schoolId !== selectedSchool.value.id) {
      const school = schools && schools.find(({ id }) => id === schoolId);
      const hasClassrooms =
        school &&
        school.classrooms.map(({ id }) => id).reduce((prev, next) => prev || classroom_ids.includes(next), false);
      if (school) {
        push({
          search: '',
        });
        localStorage.removeItem('lastSchool');
        return setSelectedSchool({
          value: school,
          label: `${get(school, 'name')} (${
            get(school, 'classrooms', []).filter(({ id, is_active }) => classroom_ids.includes(id) && is_active).length
          })`,
        });
      }
      if (
        !hasClassrooms &&
        typeof schoolId === 'number' &&
        selectedSchool.value.id === 0 &&
        localStorage.getItem('lastSchool') === 'all'
      ) {
        dispatch(clearSchoolId());
        setSelectedSchool(schoolSelectOptions[0]);
        push({
          search: '?school=all',
        });
        return;
      }
      if (selectedSchool.value.id !== 0) {
        handleSchoolSelect(schoolSelectOptions[0]);
        setSelectedSchool(schoolSelectOptions[0]);
        push({
          search: '?school=all',
        });
      }
    }
  }, [
    push,
    schoolId,
    selectedSchool,
    dispatch,
    schools,
    setSelectedSchool,
    schoolSelectOptions,
    classroom_ids,
    handleSchoolSelect,
  ]);

  return (
    <div className={cn('wrapper')}>
      <div className={cn('classrooms-header', 'mb-30')}>
        {
          <UnitSelect
            onSelect={handleSchoolSelect}
            className={cn('school-select')}
            renderValue={renderSchoolValue}
            renderOption={renderSchoolOption}
            value={selectedSchool}
            options={schoolSelectOptions}
          />
        }
        {((selectedSchool.value.classrooms && selectedSchool.value.classrooms.length > 0) ||
          (selectedSchool.value.id === 0 && isAnyClassroom)) && (
          <ToggleView
            className={cn('toggle-view')}
            defaultIsActive={isTableView}
            onChange={(value) => {
              setIsTableView(value);
              setToggleView('classrooms', value);

              GAEmitEvent(eventCategory.USER_ACTION, eventAction.MENU_CLICK, eventLabel.view(value), {
                [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                [customDimensions[PROGRAM_NAME]]: programName,
              });
            }}
          />
        )}
      </div>
      {!isTableView && (
        <FadeInWrapper>
          <div className={cn('classrooms-wrapper')}>
            {classrooms.map((classroom, idx) => {
              const draftClassroom = classroom.unit_progresses.length === 0;

              return (
                <>
                  {classroom.completed ? (
                    <div
                      key={`classroom-${idx}`}
                      className={cn('card')}
                      style={{ height: draftClassroom && 'fit-content' }}
                    >
                      <div
                        aria-hidden
                        className={cn('image', { draft: draftClassroom })}
                        onClick={() => {
                          if (draftClassroom) return;

                          localStorage.setItem('lastSchool', lastSchool);
                          dispatch(updateSchoolId(classroom.school));
                          push(
                            `/classroom/${classroom.id}/unit/${
                              classroom.current_unit
                                ? classroom.current_unit.id
                                : get(classroom, `unit_progresses[${classroom.unit_progresses.length - 1}].id`)
                            }`,
                          );
                        }}
                        style={{
                          backgroundColor: GRADE_COLORS[classroom.grade_level],
                        }}
                      >
                        {classroom.cover_image && (
                          <img
                            className={cn('image-element')}
                            src={classroom.cover_image && buildUrl(classroom.cover_image.image_url)}
                            alt="classroom image"
                            aria-hidden
                          />
                        )}
                        <div className={cn('image-backdrop')} />
                        <div className={cn('image-content')}>
                          <div className={cn('classroom-name')}>
                            <Text className={cn('classroom-name__white')} type="h7">
                              {GRADE_TITLES[classroom.grade_level]}
                            </Text>
                            <Text className={cn('classroom-name__white')} type="h3">
                              {classroom.name}
                            </Text>
                            <Text className={cn('classroom-name__white')} type="h7">
                              {classroom.school.name}
                            </Text>
                          </div>
                          <DotsDropdown
                            className={cn('dropdown')}
                            options={mockOptions(
                              classroom,
                              push,
                              location,
                              isLicenseActive,
                              dispatch,
                              schools.find(({ id }) => id === get(classroom, 'school.id', 0)),
                              role,
                              lastSchool,
                              draftClassroom,
                              programName,
                            )}
                          />
                        </div>
                      </div>
                      <div className={cn('body')}>
                        {draftClassroom ? (
                          <div>
                            <Text type="h4">Classroom unavailable</Text>
                            <Text>An Administrator is currently editing content.</Text>
                          </div>
                        ) : (
                          <div className={cn('title-row')}>
                            <div className={cn('title-col')}>
                              <Text type="h5" className="mb-5">
                                {`Unit ${
                                  classroom.current_unit
                                    ? classroom.current_unit.order_number
                                    : get(
                                        classroom,
                                        `unit_progresses[${classroom.unit_progresses.length - 1}].order_number`,
                                      )
                                }: `}
                                {buildMixedName(
                                  classroom.current_unit
                                    ? classroom.current_unit.units
                                    : get(classroom, `unit_progresses[${classroom.unit_progresses.length - 1}].units`),
                                )}
                              </Text>
                              <Text>
                                {classroom.current_unit
                                  ? classroom.current_unit.units[0].description
                                  : get(classroom, `unit_progresses[${classroom.unit_progresses.length - 1}].units[0]`)
                                      .description}
                              </Text>
                              <div className={cn('current-spot', 'mt-15')}>
                                {classroom.current_week && !classroom.all_finished && (
                                  <>
                                    <YouAreHereBadge />
                                    <Link
                                      className={cn('ml-5', 'week-link')}
                                      disabled={!isLicenseActive}
                                      to={`/classroom/${classroom.id}/unit/${
                                        classroom.current_unit
                                          ? classroom.current_unit.id
                                          : get(
                                              classroom,
                                              `unit_progresses[${classroom.unit_progresses.length - 1}].id`,
                                            )
                                      }`}
                                      onClick={() => {
                                        localStorage.setItem('lastSchool', lastSchool);
                                        dispatch(updateSchoolId(classroom.school));
                                      }}
                                      isPrimary
                                    >
                                      {`${getWeekOrderNumber(classroom.current_week.order_number, true, true)} `}
                                      {!isEdgeWeek(classroom.current_week.order_number) &&
                                        buildMixedName(classroom.current_week.weeks)}
                                    </Link>
                                  </>
                                )}
                                {/* {!classroom.started && <div className={cn('not-started')}>not started</div>} */}
                              </div>
                            </div>
                            <div className={cn('teachers-col')}>
                              {classroom.users &&
                                classroom.users.map(({ avatar_url: avatarUrl }, userIdx) => (
                                  <UserAvatar
                                    isBordered={!userIdx}
                                    key={`user-${userIdx}`}
                                    zIndex={classroom.users.length - userIdx}
                                    url={avatarUrl}
                                    className={cn('teacher-avatar')}
                                  />
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                      {!draftClassroom && (
                        <div className={cn('footer')}>
                          <Button
                            type="regular"
                            size="s"
                            variant="filled"
                            disabled={!isLicenseActive}
                            label={!classroom.all_finished ? 'Launch Unit' : 'Relaunch Unit'}
                            onClick={() => {
                              localStorage.setItem('lastSchool', lastSchool);
                              dispatch(updateSchoolId(classroom.school));
                              push(
                                `/classroom/${classroom.id}/unit/${
                                  classroom.current_unit
                                    ? classroom.current_unit.id
                                    : get(classroom, `unit_progresses[${classroom.unit_progresses.length - 1}].id`)
                                }`,
                              );
                            }}
                          />
                          {!classroom.all_finished && classroom.current_week && (
                            <Button
                              className="ml-10"
                              type="regular"
                              size="s"
                              disabled={!isLicenseActive}
                              label="Launch Week"
                              onClick={() => {
                                GAEmitEvent(
                                  eventCategory.USER_ACTION,
                                  eventAction.MENU_CLICK,
                                  eventLabel.launchWeek('Launch Week', location.pathname + location.search),
                                  {
                                    [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                                    [customDimensions[PROGRAM_NAME]]: programName,
                                    [customDimensions[UNIT_NUMBER]]: get(classroom, 'current_unit.order_number', null),
                                    [customDimensions[UNIT_TITLE]]: get(classroom, 'current_unit.units[0].name', null),
                                    [customDimensions[WEEK_NAME]]: get(classroom, `current_week.weeks[0].name`, null),
                                  },
                                );
                                localStorage.setItem('lastSchool', lastSchool);
                                dispatch(updateSchoolId(classroom.school));
                                return push(
                                  `/classroom/${classroom.id}/unit/${
                                    classroom.current_unit && classroom.current_unit.id
                                  }/week/${classroom.current_week && classroom.current_week.id}`,
                                );
                              }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      key={`placeholder-classroom-${idx}`}
                      style={{ backgroundColor: GRADE_COLORS[classroom.grade_level] }}
                      className={cn('card', 'placeholder-classroom')}
                    >
                      <div className={cn('text-wrapper')} style={{ color: !classroom.grade_level && '#535759' }}>
                        <Text>{GRADE_TITLES[classroom.grade_level]}</Text>
                        <Text bold type="h3">
                          {classroom.name}
                        </Text>
                        <Text>{classroom.school.name}</Text>
                      </div>
                      <div
                        aria-hidden
                        onClick={() => {
                          localStorage.setItem('lastSchool', lastSchool);
                          dispatch(updateSchoolId(classroom.school));
                          push(`/set-up-classroom/${classroom.id}`);
                        }}
                        className={cn('set-up-classroom')}
                      >
                        Set Up Classroom
                      </div>
                    </div>
                  )}
                </>
              );
            })}
            {!!(classrooms.length % 2) && <div className={cn('card', 'classroom-aligner')} />}
          </div>
        </FadeInWrapper>
      )}
      {isTableView && (
        <FadeInWrapper>
          <>
            {(selectedSchool.value.id !== 0 || schoolsWithClassrooms.length === 1) && (
              <Table columns={columns} data={classrooms.filter(({ id }) => classroom_ids.includes(id))} />
            )}
            {schoolsWithClassrooms.length > 1 && selectedSchool.value.id === 0 && (
              <>
                {schoolsWithClassrooms.map((school, idx) => (
                  <div key={`classroom-school-${idx}`} className="mv-40">
                    <Text type="h3">{school.name}</Text>
                    <Table columns={columns} data={school.classrooms.filter(({ id }) => classroom_ids.includes(id))} />
                  </div>
                ))}
              </>
            )}
          </>
        </FadeInWrapper>
      )}
    </div>
  );
};

BrowserView.propTypes = {};

export default BrowserView;
