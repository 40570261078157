import React from 'react';
import { useHistory } from 'react-router-dom';
import T from 'prop-types';
// hooks
import { useMeasure } from 'hooks/useMeasure';
// utils
import { capitalizeEachWord } from 'utils/string';
import { isMobile } from 'react-device-detect';
// components
import Text from 'components/Text';
// icons
import { ReactComponent as ChevronIcon } from 'icons/chevron-grey.svg';
// styles
import classnames from 'classnames/bind';
import styles from './MobileSubcomponentWrapper.module.scss';

const cn = classnames.bind(styles);

const MobileSubcomponentWrapper = ({
  headingTitle,
  className,
  onReturnClick = null,
  withReturn = true,
  fixedHeading = false,
  children,
}) => {
  if (!isMobile) return children;
  const { goBack } = useHistory();

  const [bind, { height: headingHeight }] = useMeasure();

  return (
    <div className={cn('component-wrapper')}>
      <div {...bind} className={cn('heading-wrapper', { fixed: fixedHeading })}>
        {withReturn && (
          <ChevronIcon
            className={cn('go-back')}
            onClick={(typeof onReturnClick === 'function' && onReturnClick) || goBack}
          />
        )}
        <Text className={cn('heading')} type="h5">
          {capitalizeEachWord(headingTitle)}
        </Text>
      </div>
      <div className={cn('content-wrapper', className)} style={{ marginTop: fixedHeading && `${headingHeight}px` }}>
        {children}
      </div>
    </div>
  );
};

MobileSubcomponentWrapper.propTypes = {
  headingTitle: T.string.isRequired,
  className: T.func,
  onClick: T.func,
  withReturn: T.bool,
  fixedHeading: T.bool,
  children: T.any,
};

export default MobileSubcomponentWrapper;
