import React, { Fragment, useState } from 'react';
import classnames from 'classnames/bind';
// components
import Text from 'components/Text';
// icons
import { ReactComponent as ArrowIcon } from 'icons/Arrows/LeftBlack.svg';
// styles
import Collapse from 'components/Navbar/Collapse';
import styles from './Table.module.scss';

const cn = classnames.bind(styles);

const TableRowExpandable = ({ children, renderExpandable, item }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className={cn('table__row-wrapper', { bordered: isExpanded })}>
      <div className={cn('table__row')}>{children({ isExpanded, setIsExpanded })}</div>
      {renderExpandable && <Collapse isActive={isExpanded}>{renderExpandable(item, isExpanded)}</Collapse>}
    </div>
  );
};

const Table = ({ data, columns, className, headerClassName, renderExpandable, isSmall }) => {
  const isWithExpand = !!renderExpandable;
  return (
    <div className={cn('table-container', className)}>
      <div
        className={cn('table', { isSmall })}
        style={{ minWidth: columns.reduce((a, { width }) => a + (width || 120), 0) }}
      >
        <div className={cn('table__header', headerClassName)}>
          {columns.map(({ label, width, handleSort, sorted }, idx) => (
            <div
              className={cn('table__cell')}
              key={`table-header-${idx}`}
              style={{ minWidth: width, flexBasis: width }}
            >
              <div
                className={cn('cell-wrapper', { sortable: typeof handleSort === 'function' })}
                onClick={typeof handleSort === 'function' ? handleSort : undefined}
              >
                <Text type={isSmall && 'h11'}>{label}</Text>
                {typeof handleSort === 'function' && <ArrowIcon className={cn('sort-icon', { ascending: !sorted })} />}
              </div>
            </div>
          ))}
        </div>
        <div className={cn('table__body')}>
          {data.map((item, rowIdx) => (
            <TableRowExpandable key={`table-row-${rowIdx}`} renderExpandable={renderExpandable} item={item}>
              {({ setIsExpanded, isExpanded }) =>
                columns.map(({ dataPath, renderer, isBordered = true, width, placeholder = 'N/A' }, cellIdx) => (
                  <Fragment key={`cell-${cellIdx}`}>
                    {dataPath && (
                      <div
                        className={cn('table__cell', { bordered: isBordered && !isExpanded })}
                        style={{ minWidth: width, flexBasis: width }}
                      >
                        <div className={cn('table-text')}>
                          {item[dataPath] && <Text type={isSmall ? 'h9' : 'h6'}>{item[dataPath]}</Text>}
                          {!item[dataPath] && placeholder}
                        </div>
                      </div>
                    )}
                    {renderer &&
                      renderer(item, cn('table__cell', { bordered: isBordered && !isExpanded }), width, {
                        isExpanded,
                        setIsExpanded,
                      })}
                  </Fragment>
                ))
              }
            </TableRowExpandable>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Table;
