import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import createLogger from 'redux-logger';
import rootReducer from './rootReducer';

const middlewares = [thunk, ...(process.env.WITH_LOGGER ? createLogger() : [])];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initializeStore = (initialState) =>
  createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));

export default initializeStore;

