import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// utils
import { isAdminUser } from 'constants/user';
import { getSchoolTeachers } from 'store/classrooms';
import { get, difference } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
// containers
import DisplayOnDesktop from 'containers/DisplayOnDesktop';
import DisplayOnMobile from 'containers/DisplayOnMobile';
// components
import BrowserView from './BrowserView';
import MobileView from './MobileView';
import { ClassroomInfo, People } from './BrowserView/BrowserView';
import { Teachers, ClassroomInfo as ClassroomInfoMobile } from './MobileView/MobileView';

const ManageClassroom = () => {
  const { classroomId } = useParams();

  const { role } = useSelector(({ auth }) => auth.user);
  const { id: programId } = useSelector(({ program }) => program);
  const { id: schoolId } = useSelector(({ school }) => school);
  const classroom = useSelector(({ classrooms }) => {
    const _classroom = classrooms.classrooms.length && classrooms.classrooms.find(({ id }) => id === +classroomId);
    return {
      classroomName: get(_classroom, 'name', ''),
      classroomColor: get(_classroom, 'color', ''),
      gradeLevel: get(_classroom, 'grade_level', ''),
      classroomTeachers: get(classrooms, 'schoolTeachers', []).filter(({ id }) =>
        get(_classroom, 'user_ids', []).includes(id),
      ),
      imageUrl: get(_classroom, 'cover_image.image_url', ''),
      hasErrors: classrooms.hasErrors,
      isLoading: classrooms.isLoading,
      schoolTeachers: get(classrooms, 'schoolTeachers', []),
      currentUnit: get(_classroom, 'current_unit', {}),
      currentWeek: get(_classroom, 'current_week', ''),
      started: get(_classroom, 'started', ''),
      completed: get(_classroom, 'completed', false),
      isActive: get(_classroom, 'is_active'),
      userIds: get(_classroom, 'user_ids', []),
    };
  });

  const dispatch = useDispatch();
  const isAdmin = isAdminUser(role);

  useEffect(() => {
    isAdmin && dispatch(getSchoolTeachers());
  }, [dispatch, classroom.userIds]);

  let MOBILE_TAB_OPTIONS = [
    { title: 'teachers', component: Teachers },
    { title: 'classroom info', component: ClassroomInfoMobile },
  ];

  let BROWSER_TAB_OPTIONS = [
    { title: 'people', component: People },
    {
      title: 'classroom info',
      component: ClassroomInfo,
    },
  ];

  if (!isAdmin) {
    MOBILE_TAB_OPTIONS.splice(0, 1);
    BROWSER_TAB_OPTIONS.splice(0, 1);
  }

  return (
    <>
      <DisplayOnDesktop>
        <BrowserView tabOptions={BROWSER_TAB_OPTIONS} />
      </DisplayOnDesktop>
      <DisplayOnMobile>
        <MobileView
          tabOptions={MOBILE_TAB_OPTIONS}
          classroom={{
            ...classroom,
            classroomId: +classroomId,
            notAssignedTeachers: difference(classroom.schoolTeachers, classroom.classroomTeachers),
          }}
          programId={programId}
          schoolId={schoolId}
          isAdmin={isAdmin}
        />
      </DisplayOnMobile>
    </>
  );
};

export default ManageClassroom;
