import React from 'react';
import { useHistory, Switch, Route, Redirect } from 'react-router-dom';
// utils
import { useSelector } from 'react-redux';
import { useTransition, animated } from 'react-spring';
// hooks
import { useMeasure } from 'hooks/useMeasure';
// containers
import SlideInWrapper from 'containers/SlideInWrapper';
// components
import ClassroomsSection from './ClassroomsSection';
import ManagePasswordSection from './ManagePasswordSection';
import PersonalDataSection from './PersonalDataSection';
import SwitchProgramSection from './SwitchProgramSection';
import MainSection from './MainSection';
// styles
import classnames from 'classnames/bind';
import styles from './MobileView.module.scss';

const cn = classnames.bind(styles);

const routes = {
  PERSONAL_DATA: '/profile/personal-data',
  MANAGE_PASSWORD: '/profile/manage-password',
  CLASSROOMS: '/profile/classrooms',
  SWITCH_PROGRAM: '/profile/switch-program',
  ADD_CLASSROOM: '/classrooms',
  PROFILE: '/profile',
};

const MobileView = () => {
  const { location } = useHistory();

  const [bind, { height: mainSectionHeight }] = useMeasure();

  const { programs = [] } = useSelector(({ auth }) => auth.user);

  const transition = useTransition(location, (location) => location.pathname, {
    from: { transform: 100, life: '0' },
    enter: { transform: 0, life: '100%' },
    leave: { transform: 100, life: '0' },
    config: { duration: 200 },
  });

  return (
    <>
      <SlideInWrapper horizontal="left" shouldAnimate>
        <MainSection {...bind} />
        {transition.map(({ item, key, props: { transform, ...props } }) => (
          <animated.div
            key={key}
            style={{ ...props, transform: transform.interpolate((t) => (t ? `translateX(${t}%)` : 'none')) }}
            className={cn('animation-wrapper')}
          >
            <Switch location={item}>
              <Route
                exact
                path={routes.PERSONAL_DATA}
                component={() => <PersonalDataSection minHeight={mainSectionHeight} />}
              />
              <Route
                exact
                path={routes.MANAGE_PASSWORD}
                component={() => <ManagePasswordSection minHeight={mainSectionHeight} />}
              />
              <Route
                exact
                path={routes.CLASSROOMS}
                component={() => <ClassroomsSection minHeight={mainSectionHeight} />}
              />
              {programs.length > 1 && (
                <Route
                  exact
                  path={routes.SWITCH_PROGRAM}
                  component={() => <SwitchProgramSection minHeight={mainSectionHeight} />}
                />
              )}
              <Redirect to={routes.PROFILE} />
            </Switch>
          </animated.div>
        ))}
      </SlideInWrapper>
    </>
  );
};

export default MobileView;
