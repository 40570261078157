import React from 'react';
// utils
import { useSelector } from 'react-redux';
// components
import Navbar from 'components/Navbar';
import Logo from 'components/Logo';
import Text from 'components/Text';
import { useHistory } from 'react-router-dom';
// styles
import classnames from 'classnames/bind';
import styles from './TermsOfUse.module.scss';
import Link from 'components/Link';

const cn = classnames.bind(styles);

const TermOfUse = () => {
  const { email } = useSelector(({ auth }) => auth.user);
  const { push } = useHistory();
  return (
    <>
      {!!email && <Navbar />}
      {!email && (
        <Link to="/">
          <Logo type="vFull" className={cn('logo')} />
        </Link>
      )}
      <section className={cn('container')}>
        <div className={cn('wrapper')}>
          <Text type="h1" className="mb-40">
            Terms of Use
          </Text>
          <Text className={cn('text', 'mb-30')}>
            Thank you for visiting our web sites located at sesameworkshop.org and sesamestreet.org, which we will refer
            to collectively below as the&nbsp;<strong>“Sites.”</strong>&nbsp;Through the Sites, Sesame Workshop (also
            referred to as “we,” “us” or “our”) aims to extend our mission to use educational media to help children
            reach their highest potential.&nbsp;We offer the Sites to you (referred to as “you” or “user”) and your
            children, as applicable, under the Terms of Use described below.&nbsp;&nbsp;By using and accessing the
            Sites, you, on your own behalf and on behalf of your children, are agreeing to these Terms of Use, which
            will be considered a binding, legal agreement (referred to herein as&nbsp;<strong>“Terms of Use”</strong>
            &nbsp;or <strong>"Agreement”</strong>) between you and Sesame Workshop.&nbsp;If you do not agree to these
            terms, please discontinue your access to and use of the Sites immediately.
          </Text>
          <Text className={cn('text', 'mb-70')}>
            We reserve the right, in our sole discretion, to change these terms at any time, and will publish a revised
            version of these Terms of Use on the Sites. &nbsp;You are responsible for regularly reviewing these Terms of
            Use. &nbsp;Continued access to and use of the Sites following any such change means you accept and will
            abide by the changes.
          </Text>
          <Text type="h4" className="mb-15">
            Ownership of Sites and Site Content
          </Text>
          <Text className={cn('text', 'mb-70')}>
            The Sites are owned and operated by Sesame Workshop, a nonprofit educational organization with charitable,
            tax-exempt status, and with offices at 1900 Broadway, New York, NY 10023, USA. &nbsp;Sesame Workshop and/or
            its subsidiaries, affiliated companies, distributors, vendors, contractors, licensors and/or licensees
            (collectively&nbsp;<strong>“SW Parties”</strong>) own the copyright in all elements of the Sites and all
            related intellectual property rights, including, but not limited to, all trademark rights, patent rights and
            moral rights. &nbsp;The elements of the Sites (collectively,&nbsp;<strong>“Site Content”</strong>) include
            without limitation the content, software, code, data, art, graphics, animation, photographs, images, text,
            music, sound effects, all audio and audiovisual elements, look-and-feel, design, layout, organization,
            presentation, user interface, navigation, trade dress and stylistic convention of the Sites.&nbsp;Your use
            of the Sites does not give you ownership of any Site Content.
          </Text>
          <Text type="h4" className="mb-15">
            Trademarks
          </Text>
          <Text className={cn('text', 'mb-70')}>
            All trademarks, logos, service marks and trade names (collectively,&nbsp;<strong>“Trademarks”</strong>)
            displayed on the Sites or on Site Content are registered or unregistered Trademarks of Sesame Workshop, the
            SW Parties and/or others, and may not be used unless authorized by the Trademark owner. &nbsp;All Trademarks
            not owned by us that appear on the Sites or on the Site Content, if any, are the property of their
            respective owners. &nbsp;Nothing contained on the Sites should be construed as granting, by implication,
            estoppel, or otherwise, any license or right to use any Trademark displayed on the Sites.
          </Text>
          <Text type="h4" className="mb-15">
            Use of Site Content
          </Text>
          <Text className={cn('text', 'mb-70')}>
            You may access and view the Site Content only for your personal, noncommercial use on a single computer or
            other Internet compatible device to enable you to use the Sites, provided that you do not remove or alter
            any copyright or other legal notices from the Site or any Site Content.&nbsp;You may not (except where we
            have given you express permission or you are otherwise permitted by law) modify, copy, distribute, download,
            upload, post, broadcast or transmit, display, disassemble, perform, reproduce, publish, license, decompile,
            reverse engineer, create derivative works from, transfer, sell, or make other use of any of the Site
            Content. &nbsp;Any use of the Site Content, other than as explicitly permitted in this paragraph, is
            unauthorized and may be a violation of copyright or other proprietary rights or other applicable federal or
            state laws of the United States or other countries.
          </Text>
          <Text type="h4" className="mb-15">
            Consideration
          </Text>
          <Text className={cn('text', 'mb-70')}>
            You acknowledge and agree that your access to and use of the Sites, the Site Content and the services
            provided through the Sites are valuable benefits that you receive by agreeing to and complying with the
            terms and conditions of this Agreement.
          </Text>
          <Text type="h4" className="mb-15">
            User Submissions
          </Text>
          <Text className={cn('text', 'mb-70')}>
            We do not ask for nor do we wish to receive any confidential, secret or proprietary information or other
            material from you through the Sites, by email or in any other way.&nbsp;If you post, upload, transmit or
            submit any materials, content (including, for example, any photographs), information or ideas to Sesame
            Workshop (“Your Content”), (i) you represent and warrant that Your Content is original to you, that no other
            party has any rights thereto, and that any “moral rights” in Your Content have been waived, and (ii) you
            grant to Sesame Workshop a non-exclusive, fully-paid, royalty-free, unrestricted, perpetual, irrevocable,
            fully transferable, assignable and sublicensable, and worldwide license in all current and future media to
            reproduce, modify, adapt, publish, publicly perform and display, distribute, sublicense, create derivative
            works, sell, and otherwise use Your Content for any purpose Sesame Workshop chooses, commercial or
            otherwise, in its sole discretion, without any compensation to you and in accordance with our&nbsp;
            <a href="http://www.sesameworkshop.org/privacy-policy.html">Privacy Policy</a>. We cannot be responsible for
            maintaining Your Content that you provide to us, and we may delete or destroy Your Content at any time.
          </Text>
          <Text type="h4" className="mb-15">
            User Information
          </Text>
          <Text className={cn('text', 'mb-70')}>
            During the course of your use of the Sites, you may be asked to provide certain personalized information to
            us (such information referred to herein as&nbsp;<strong>“User Information”</strong>).&nbsp;Our information
            collection and use policies with respect to the privacy of such User Information are set forth in our&nbsp;
            <a href="http://www.sesameworkshop.org/privacy-policy.html">Privacy Policy</a>, which is incorporated herein
            by reference for all purposes.&nbsp;You acknowledge and agree that you are solely responsible for the
            accuracy and content of User Information, and you agree to keep it up to date.
          </Text>
          <Text type="h4" className="mb-15">
            Prohibited User Conduct
          </Text>
          <Text className={cn('text', 'mb-70')}>
            You warrant and agree that, while accessing or using the Sites, you will not:
          </Text>
          <ul>
            <li>
              <Text className={cn('text', 'mt-20')}>
                impersonate any person or entity or misrepresent your affiliation with any other person or entity,
                whether actual or fictitious, including anyone from the Sites or Sesame Workshop or the SW Parties;
              </Text>
            </li>
            <li>
              <Text className={cn('text', 'mt-20')}>
                insert your own or a third party’s advertising, branding or other promotional content into any of the
                Site Content, materials or services;
              </Text>
            </li>
            <li>
              <Text className={cn('text', 'mt-20')}>
                obtain or attempt to gain unauthorized access to other computer systems, materials, information or any
                services available on or through the Sites through any means, including through means not intentionally
                made publicly available or provided for through the Sites;
              </Text>
            </li>
            <li>
              <Text className={cn('text', 'mt-20')}>
                engage in spidering, “screen scraping,” “database scraping,” harvesting of e-mail addresses or other
                personal information, or any other automatic or unauthorized means of accessing, logging-in or
                registering on the Sites, or obtaining lists of users or other information from or through the Sites,
                including, without limitation, any information residing on any server or database connected to the
                Sites;
              </Text>
            </li>
            <li>
              <Text className={cn('text', 'mt-20')}>
                use the Sites or its features and services in any manner that could interrupt, damage, disable,
                overburden or impair the Sites or interfere with any other party’s use and enjoyment of the Sites,
                including, without limitation, sending mass unsolicited messages or “flooding” servers with requests;
              </Text>
            </li>
            <li>
              <Text className={cn('text', 'mt-20')}>
                use the Sites or their services in violation of the intellectual property or other proprietary or legal
                rights of Sesame Workshop or the SW Parties or any third party;
              </Text>
            </li>
            <li>
              <Text className={cn('text', 'mt-20')}>
                use the Sites or its services in violation of any applicable law; or
              </Text>
            </li>
            <li>
              <Text className={cn('text', 'mt-20')}>
                attempt (or encourage or support any one else’s attempt) to circumvent, reverse engineer, decrypt, or
                otherwise alter or interfere with the Sites or their services.
              </Text>
            </li>
          </ul>
          <Text className={cn('text', 'mv-70')}>
            You alone are responsible for the content and consequences of any of your activities while you are visiting
            or using the Sites.
          </Text>
          <Text type="h4" className="mb-15">
            Links From The Sites to Third Party Sites
          </Text>
          <Text className={cn('text', 'mb-70')}>
            As a convenience for users, the Sites may contain links to Internet sites maintained by third parties
            (“Linked Sites”). &nbsp;You acknowledge and agree that Sesame Workshop and the SW Parties do not operate or
            control in any respect, or necessarily endorse, the information, content, products, services, advertising,
            or other materials that may be found on a Linked Site. &nbsp;When you link to a Linked Site, you become
            subject to their terms of use and&nbsp;
            <a href="http://www.sesameworkshop.org/privacy-policy.html">privacy policy</a>
            &nbsp;rather than to ours.&nbsp;You assume sole and complete responsibility for, and we do not accept any
            responsibility for any losses or penalties incurred as a result of, your use of Linked Sites, and any
            reliance on the contents of a Linked Site is at your own risk.
          </Text>
          <Text type="h4" className="mb-15">
            Links To The Sites From Third Party Sites
          </Text>
          <Text className={cn('text', 'mb-70')}>
            You agree that if you include a link from any other web site to the Sites, such link shall open in a new
            browser window and shall link to the full version of an HTML formatted page of the Sites.&nbsp;You are not
            permitted to link directly to any image hosted on the Sites, such as using an “in-line” linking method to
            cause the image hosted by us to be displayed on another web site.&nbsp;You agree not to link from any other
            web site to the Sites in any manner such that the Sites, or any page of the Sites, is “framed,” surrounded
            or obfuscated by any third party content, materials or branding.&nbsp;We may require that any link to the
            Sites be discontinued, and to revoke your right to link to the Sites from any other web site at any time.
          </Text>
          <Text type="h4" className="mb-15">
            Right to Monitor and Editorial Control
          </Text>
          <Text className={cn('text', 'mb-70')}>
            Sesame Workshop and the SW Parties reserve the right, but do not have an obligation, to monitor and/or
            review all materials posted to the Sites or through the Sites’ services or features by users, and Sesame
            Workshop and the SW Parties are not responsible for any such materials posted by users.&nbsp;However, Sesame
            Workshop and the SW Parties reserve the right at all times to disclose any information as necessary to
            satisfy any law, regulation or government request, or to edit, refuse to post or to remove any information
            or materials, in whole or in part, that in the sole discretion of Sesame Workshop and the SW Parties are
            objectionable or in violation of this Agreement, the policies of Sesame Workshop and the SW Parties or
            applicable law.&nbsp;We may also impose limits on certain features or restrict your access to part or all of
            the features or services without notice or penalty if we believe you are in breach of the guidelines set
            forth in this paragraph, our terms and conditions or applicable law, or for any other reason without notice
            or liability.
          </Text>
          <Text type="h4" className="mb-15">
            Indemnification
          </Text>
          <Text className={cn('text', 'mb-70')}>
            You agree to indemnify and hold harmless Sesame Workshop and the SW Parties, and its and their respective
            affiliates, directors, officers, employees and agents from and against any and all claims, liabilities, and
            expenses (including reasonable legal fees) that may arise from your use of the Sites in any way, your
            placement or transmission of any message, content, information, software or other materials on, to or
            through the Sites or your violation of law or breach of the terms of this Agreement.&nbsp;Sesame Workshop
            and the SW Parties reserve the right to assume the exclusive defense and control of any matter otherwise
            subject to indemnification by you, and in such case, you agree to cooperate with the defense of any such
            claims by Sesame Workshop and the SW Parties.
          </Text>
          <Text type="h4" className="mb-15">
            Copyright Agent
          </Text>
          <Text className={cn('text', 'mb-70')}>
            We respect the intellectual property rights of others, and require that the people who use the Sites do the
            same.&nbsp;If you believe that your work has been copied in a way that constitutes copyright infringement,
            please forward the following information to our Copyright Agent, designated as such pursuant to the Digital
            Millennium Copyright Act, 17 U.S.C. § 512(c)(2), named below:
          </Text>
          <ul>
            <li>
              <Text className={cn('text', 'mt-20')}>Your address, telephone number, and email address;</Text>
            </li>
            <li>
              <Text className={cn('text', 'mt-20')}>
                A description of the copyrighted work that you claim has been infringed;
              </Text>
            </li>
            <li>
              <Text className={cn('text', 'mt-20')}>
                A description of where the alleged infringing material is located;
              </Text>
            </li>
            <li>
              <Text className={cn('text', 'mt-20')}>
                A statement by you that you have a good faith belief that the disputed use is not authorized by the
                copyright owner, its agent, or the law;
              </Text>
            </li>
            <li>
              <Text className={cn('text', 'mt-20')}>
                An electronic or physical signature of the person authorized to act on behalf of the owner of the
                copyright interest; and
              </Text>
            </li>
            <li>
              <Text className={cn('text', 'mt-20')}>
                A statement by you, made under penalty of perjury, that the above information in your Notice is accurate
                and that you are the copyright owner or authorized to act on the copyright owner’s behalf.
              </Text>
            </li>
          </ul>
          <Text className={cn('text', 'mt-70', 'mb-30')}>Copyright Agent:</Text>
          <Text className={cn('text', 'mb-70')}>
            David K. Chan
            <br />
            One Lincoln Plaza
            <br />
            New York, NY 10023
            <br />
            Voice: 212-875-6299
            <br />
            Fax: 212-875-6117
            <br />
            e-mail: copyrightdmca@sesame.org
          </Text>
          <Text type="h4" className="mb-15">
            General Disclaimer and Limitation of Liability
          </Text>
          <Text className={cn('text', 'mb-30')}>
            Sesame Workshop controls and operates the Sites from offices in the United States of America.&nbsp;We make
            no representation that Site Content is appropriate or authorized for use in all countries, states,
            provinces, counties or any other jurisdictions. &nbsp;If you choose to access the Sites, you do so on your
            own initiative and risk, and you are responsible for compliance with all applicable laws if and to the
            extent such laws and restrictions are applicable.
          </Text>
          <Text className={cn('text', 'mb-30')}>
            The Sites provide information of a general nature only and you are responsible for determining whether it
            applies to your specific situation.&nbsp;Sesame Workshop specifically disclaims any liability concerning any
            action that any person may take based on any information or guidance provided at the Sites.
          </Text>
          <Text className={cn('text', 'mb-30')}>
            <strong>
              The Sites provide information of a general nature only and you are responsible for determining whether it
              applies to your specific situation.&nbsp;Sesame Workshop specifically disclaims any liability concerning
              any action that any person may take based on any information or guidance provided at the Sites.
            </strong>
          </Text>
          <Text className={cn('text', 'mb-30')}>
            Sesame Workshop does not guarantee the accuracy, timeliness, correctness, completeness, performance or
            fitness for a particular purpose of the Sites or any of the Site Content. Sesame Workshop is not liable for
            any errors, omissions, or inaccurate Site Content on the Sites.
          </Text>
          <Text className={cn('text', 'mb-30')}>
            THE SITES AND THE SITE CONTENT ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTIES OF ANY KIND,
            EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY FOR INFORMATION, DATA, DATA
            PROCESSING SERVICES, UPTIME OR UNINTERRUPTED ACCESS, ANY WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY OR
            USEFULNESS OF SITE CONTENT AND ANY IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR
            A PARTICULAR PURPOSE, TITLE OR NONINFRINGEMENT, WHICH ARE EXCLUDED FROM THIS AGREEMENT TO THE EXTENT THAT
            THEY MAY BE EXCLUDED AS A MATTER OF LAW.&nbsp;SESAME WORKSHOP DOES NOT WARRANT THAT THE SITES OR THE
            SERVICES, CONTENT, FUNCTIONS OR MATERIALS CONTAINED THEREIN WILL BE TIMELY, SECURE, UNINTERRUPTED, OR
            OPERATED FREE OF DELAYS IN TRANSMISSION, FAILURE IN PERFORMANCE, COMPUTER VIRUSES, INACCURACIES, ERRORS, OR
            DEFECTS. &nbsp;YOU USE THE SITES AT YOUR OWN RISK.&nbsp;SESAME WORKSHOP ALSO ASSUMES NO RESPONSIBILITY, AND
            SHALL NOT BE LIABLE FOR, ANY DAMAGES TO, OR VIRUSES THAT MAY INFECT, YOUR COMPUTER EQUIPMENT OR OTHER
            PROPERTY ON ACCOUNT OF YOUR ACCESS TO, USE OF, OR BROWSING IN THE SITES OR YOUR DOWNLOADING OF ANY
            MATERIALS, DATA, TEXT, IMAGES, VIDEO, OR AUDIO FROM THE SITES. &nbsp;You are responsible for implementing
            sufficient procedures and virus checks (including anti-virus and other security checks) to satisfy your
            particular requirements for the accuracy and security of data input and output. &nbsp;SESAME WORKSHOP MAKES
            NO WARRANTY THAT THE SITES WILL MEET USERS’ REQUIREMENTS.&nbsp;NO ADVICE, RESULTS OR INFORMATION, WHETHER
            ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR THROUGH THE SITES SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE
            HEREIN.
          </Text>
          <Text className={cn('text', 'mb-70')}>
            EXCEPT AS SET OUT BELOW, UNDER NO CIRCUMSTANCES, INCLUDING BUT NOT LIMITED TO BREACH OF CONTRACT, TORT OR
            NEGLIGENCE, WILL SESAME WORKSHOP OR THE SW PARTIES BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, PUNITIVE,
            INCIDENTAL OR CONSEQUENTIAL DAMAGES THAT ARISE OUT OF OR IN CONNECTION WITH THE USE OF THE SITES OR WITH THE
            DELAY OR INABILITY TO USE THE SITES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS OR SERVICES OBTAINED THROUGH
            THE SITES, OR OTHERWISE ARISING OUT OF THE USE OF THE SITES, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES. &nbsp;BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR
            CONSEQUENTIAL OR INCIDENTAL DAMAGES, THOSE ASPECTS OF THE ABOVE LIMITATION MAY NOT APPLY TO YOU. &nbsp;IN NO
            EVENT SHALL SESAME WORKSHOP OR THE SW PARTIES BE LIABLE FOR OR IN CONNECTION WITH ANY CONTENT POSTED,
            TRANSMITTED, EXCHANGED OR RECEIVED BY OR ON BEHALF OF ANY USER OR OTHER PERSON ON OR THROUGH THE SITES
            (INCLUDING, WITHOUT LIMITATION, ANY SUBMITTED MATERIALS). IN NO EVENT SHALL SESAME WORKSHOP’S OR THE SW
            PARTIES’ TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED, IN THE AGGREGATE, THE
            AMOUNT, IF ANY, PAID BY YOU TO US FOR YOUR USE OF THE SITES OR FOR ANY OF YOUR ACTIVITIES ON THE SITES.
          </Text>
          <Text type="h4" className="mb-15">
            Termination
          </Text>
          <Text className={cn('text', 'mb-70')}>
            Sesame Workshop may terminate, change, suspend or discontinue any aspect of the Sites at any
            time.&nbsp;Sesame Workshop may restrict, suspend or terminate your access to the Sites and/or this Agreement
            if we reasonably believe or suspect you have acted inappropriately on the Sites or you are in breach of
            these Terms of Use or applicable law, or for any other reason without notice or
            liability.&nbsp;Additionally, Sesame Workshop may terminate use or access privileges to the Sites of users
            who are repeat infringers of intellectual property rights.&nbsp;You may terminate this Agreement by
            discontinuing your use of and access to the Sites.&nbsp;This Agreement is effective until terminated by you,
            or by Sesame Workshop for any reason, with or without notice.&nbsp;Upon any termination of this Agreement,
            you agree to discontinue your use and access of the Sites and to immediately destroy all materials obtained
            from it.
          </Text>
          <Text type="h4" className="mb-15">
            Miscellaneous
          </Text>
          <Text className={cn('text', 'mb-30')}>
            This Agreement shall be deemed to include all other notices, policies, disclaimers, and other terms
            contained in the Sites, including our&nbsp;
            <a href="http://www.sesameworkshop.org/privacy-policy.html">Privacy Policy</a>; provided, however, that in
            the event of a conflict between such other terms and the terms of this Agreement, the terms of this
            Agreement shall prevail.
          </Text>
          <Text className={cn('text', 'mb-30')}>
            You agree that Sesame Workshop may assign any of its rights and/or transfer, sub-contract or delegate any of
            its obligations under this Agreement. &nbsp;This Agreement is personal to you and you may not transfer or
            assign it to a third party.
          </Text>
          <Text className={cn('text', 'mb-30')}>
            This Agreement is governed by and is construed in accordance with the laws of the State of New York, U.S.A,
            without regard to conflicts of law provisions. &nbsp;You consent to the exclusive jurisdiction and venue of
            courts in New York County, New York, U.S.A. in all disputes arising out of or relating to your use of the
            Sites. &nbsp;Notwithstanding the foregoing, Sesame Workshop shall have the right to bring action against
            visitors of the Sites in courts of competent jurisdiction in the jurisdiction in which such visitors reside
            or are located: (i) to seek injunctive relief against such visitor, (ii) to obtain a judgment against such
            visitor where a judgment by a United States court will, or may, not be enforced by the jurisdiction in which
            such visitor resides or is located, or (iii) to enforce a judgment obtained against such visitor in&nbsp;a
            New York court.
          </Text>
          <Text className={cn('text', 'mb-30')}>
            Our failure to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of
            such right or provision.
          </Text>
          <Text className={cn('text', 'mb-30')}>
            If any part of this Agreement is determined to be invalid or unenforceable under applicable law including
            but not limited to the warranty disclaimers and liability limitations stated above, then the invalid or
            unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely
            matches the intent of the original provision and the remainder of the Agreement shall continue in effect.
          </Text>
          <Text className={cn('text', 'mb-30')}>
            This Agreement constitutes the entire agreement between you and Sesame Workshop with respect to the Sites
            and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or
            written, between you and Sesame Workshop with respect to the Sites. &nbsp;A printed version of this
            Agreement and of any notice given in electronic form will be admissible in judicial or administrative
            proceedings based upon or relating to this Agreement to the same extent as other business documents and
            records originally generated and maintained in printed form.
          </Text>
          <Text className={cn('text', 'mb-30')}>
            TM/® 2023 Sesame Workshop. Copyright © 2023 Sesame Workshop. All rights reserved under International and
            Pan-American Copyright Conventions.
          </Text>
          <Text className={cn('text', 'mb-30')}>
            Any rights not expressly granted in this Agreement are reserved to Sesame Workshop.
          </Text>
          <Text className={cn('text', 'mb-70')}>
            <strong>Effective date: August 4, 2008</strong>
          </Text>
        </div>
      </section>
    </>
  );
};

export default TermOfUse;
