import { getTitleFromUri } from 'utils/string';
import { API_URL, PUBLIC_URL } from '../constants';

export const buildUrl = (url) => (url ? `${API_URL}${url}` : '');
export const buildFrontUrl = (url) => (url ? `${PUBLIC_URL}${url}` : '');

export const obtainCyclicNumber = (cycle, num) => num - cycle * Math.floor(num / cycle) || cycle;

export const isEdgeWeek = (orderNumber) => {
  const weekNumber = obtainCyclicNumber(7, orderNumber);
  if (weekNumber === 1 || weekNumber === 7) return true;
  return false;
};

export const getWeekOrderNumber = (num, withSemi, withTextOnEmpty) => {
  const weekNumber = obtainCyclicNumber(7, num);

  switch (weekNumber) {
    case 1:
      return withTextOnEmpty ? 'Unit Launch' : '';
    case 7:
      return withTextOnEmpty ? 'Unit Celebration' : '';
    default:
      return `Week ${weekNumber - 1}${withSemi ? ': ' : ''}`;
  }
};

export const removePrintableDuplicates = (printables) => {
  const uniquePrintables = printables.reduce((accumulator, current) => {
    if (checkIfAlreadyExist(current)) {
      return accumulator;
    }
    return accumulator.concat([current]);

    function checkIfAlreadyExist(currentVal) {
      return accumulator.some(
        (item) =>
          getTitleFromUri(item.attachment_url) === getTitleFromUri(currentVal.attachment_url) &&
          item.attachment_size === currentVal.attachment_size,
      );
    }
  }, []);

  return uniquePrintables;
};

export const clearHtml = (string = '') => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = string;
  return tmp.textContent || tmp.innerText || '';
};

export const scrollTo = (element, offset = 100) => {
  const top = element.getBoundingClientRect().top + window.pageYOffset - offset;
  return window.scrollTo({ top, left: 0, behavior: 'smooth' });
};

/**
 * Helper function to obtain browser and its version
 * @argument onlyNumber - determines whether to return only version number
 */
window.navigator.browserFromUA = function (onlyNumber = false) {
  var ua = navigator.userAgent;
  var tem;
  var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\S*)/i)) != null) M.splice(1, 1, tem[1]);
  return onlyNumber ? M[1] : M.join(' ');
};

export const isBrowser = (browserName) =>
  window.navigator.browserFromUA().toLowerCase().includes(browserName.toLowerCase());

export const getBrowserVersion = () => window.navigator.browserFromUA(true);

export default { buildUrl };
