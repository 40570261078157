import React, { useState } from 'react';
import classnames from 'classnames/bind';
// components
import Text from 'components/Text';
import Logo from 'components/Logo';
import Input, { PasswordInput } from 'components/Input';
import Button from 'components/Button';
import CheckBox from 'components/CheckBox';
import Link from 'components/Link';
// utils
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// hooks
import { useForm } from 'react-hook-form';
// styles
import { useDispatch } from 'react-redux';
import { signIn } from 'store/auth';
import styles from './Login.module.scss';

const cn = classnames.bind(styles);

const schema = yup.object().shape({
  email: yup.string().email(),
  password: yup.string().required(),
});

const Login = () => {
  const [isRemember, setIsRemember] = useState(true);
  const dispath = useDispatch();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSignIn = (data) => dispath(signIn(data, isRemember));
  const handleCheckboxClick = () => setIsRemember(!isRemember);

  return (
    <form className={cn('wrapper')} onSubmit={handleSubmit(handleSignIn)}>
      <Logo type="vFull" className={cn('logo')} />
      <Text className="mb-25" type="h3">
        Log in
      </Text>
      <Input
        errors={errors}
        name="email"
        label="Email"
        placeholder="you@example.com"
        inputRef={register({ required: true })}
      />
      <PasswordInput errors={errors} name="password" label="Password" inputRef={register({ required: true })} />
      <Button isSubmit className={cn('mh-auto mt-30', 'log-in-button')} type="regular" size="bg" label="Log in" />
      <div className={cn('box-link')}>
        <CheckBox className="mt-25" label="Keep me logged in" isChecked={isRemember} onClick={handleCheckboxClick} />
        <Link to="/password-reset" isPrimary className="mt-25">
          Forgot password?
        </Link>
      </div>
    </form>
  );
};

export default Login;
