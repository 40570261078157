import React, { useEffect } from 'react';
// hooks
import { useDispatch, useSelector } from 'react-redux';
// components
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { getUser } from 'store/auth';
// utils
import { isProgramAdmin, isAdminUser, hasProgramAdminRole, hasAdminRole } from 'constants/user';
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';
import { clearAuthData, cachedProgramId } from 'utils/auth';
import HeartLoader from 'components/HeartLoader';

const ALLOWED_ROUTES = [
  'login',
  'sign-up',
  'password-reset',
  'confirm-reset-password',
  'admin-sign-up',
  'terms-of-use',
  'privacy-policy',
];
const SIGN_UP_ROUTES = ['sign-up'];

const UNIVERSAL_ROUTES = ['terms-of-use', 'privacy-policy'];

const SCHOOL_ADMIN_ROUTES = ['program-panel', 'school-panel', 'user-profile'];

const Auth = ({ children }) => {
  const {
    isLoading,
    hasErrors,
    user: { email, role, roles, readyToBeSigned, reloadNeeded, classroom_ids },
  } = useSelector(({ auth }) => auth);
  const { pathname } = useLocation();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { id: programId, completed } = useSelector((state) => state.program);
  const authCookie = Cookies.get('auth');
  const actualPath = pathname.split('/')[1];
  const isAuthenticated = authCookie && !isEmpty(JSON.parse(authCookie));
  const isAllowedPage = ALLOWED_ROUTES.includes(actualPath);
  const isSigningUp = SIGN_UP_ROUTES.includes(actualPath);
  const isUniversalPage = UNIVERSAL_ROUTES.includes(actualPath);
  const isRegistrationUncompleted = localStorage.getItem('uncompletedRegistration');
  useEffect(() => {
    const isUserLoaded = !!email;
    if (isAuthenticated && !isUserLoaded && !isLoading && !hasErrors) {
      dispatch(getUser());

      return;
    }
    if (isAuthenticated && !programId && readyToBeSigned && !isRegistrationUncompleted && !isLoading) {
      return push('/switch-program');
    }
    if (isAuthenticated && hasErrors && !isLoading) {
      clearAuthData();
      push('/login');
    }
  }, [
    dispatch,
    reloadNeeded,
    isAuthenticated,
    email,
    isLoading,
    actualPath,
    hasErrors,
    push,
    isRegistrationUncompleted,
    programId,
    readyToBeSigned,
  ]);
  useEffect(() => {
    if (
      isAuthenticated &&
      isAllowedPage &&
      !isSigningUp &&
      actualPath !== 'sign-up' &&
      actualPath !== 'my-school' &&
      !isUniversalPage
    ) {
      if (!cachedProgramId) {
        push('/switch-program');
        return;
      }
      if (!!classroom_ids.length && hasProgramAdminRole(roles)) {
        push('/program-panel');
        return;
      }
      push('/classrooms');
    }
  }, [isAuthenticated, push, actualPath, isAllowedPage, isSigningUp]);

  useEffect(() => {
    if (email) {
      if (SCHOOL_ADMIN_ROUTES.some((path) => actualPath.includes(path)) && !hasAdminRole(roles)) {
        push('/classrooms');
      }
    }
  }, [email, actualPath, isAuthenticated, push, roles]);

  useEffect(() => {
    if (isRegistrationUncompleted && !isSigningUp) {
      push('/sign-up?continues=true');
    }
  }, [isRegistrationUncompleted, push, isSigningUp]);

  if (isLoading && !isSigningUp)
    return (
      <div className="Loader-container">
        <HeartLoader className="center-hor-ver" size="bg" />
      </div>
    );

  if (!isAuthenticated && !isAllowedPage) {
    return <Redirect to="/login" />;
  }

  return children;
};

export default Auth;
