import React from 'react';
import classnames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
// hooks
import { useTableSortedData } from 'hooks/useTableSortedData';
// containers
import TablePagination from 'containers/TablePagination';
// components
import Table from 'components/Table';
import Text from 'components/Text';
import Link from 'components/Link';
import Button from 'components/Button/Button';
import SearchInput from 'components/Input/SearchInput';
// constants
import { GRADE_COLORS, GRADE_TITLES } from 'constants/classrooms';
// utils
import { buildMixedName } from 'pages/Classrooms/helpers';
import { buildUrl, getWeekOrderNumber, isEdgeWeek } from 'utils';
// styles
import styles from './BrowserView.module.scss';
import { updateSchoolId } from 'store/school';

const cn = classnames.bind(styles);

export const renderLevelColumn = (props, className, width) => (
  <div className={className} style={{ minWidth: width, flexBasis: width }}>
    <div className={cn('level-column')}>
      {props.grade_level ? (
        <Text>
          {GRADE_TITLES[props.grade_level]}
          &nbsp;|&nbsp;Unit 1
        </Text>
      ) : (
        <span style={{ color: '#54585a' }}>N/A</span>
      )}
    </div>
  </div>
);

export const renderClassroomNameColumn = (props, className, width) => {
  const draftClassroom = props.unit_progresses.length === 0;

  return (
    <div className={cn('classroom-col')} style={{ minWidth: width, flexBasis: width }}>
      <div className={cn('classroom-col-image')} style={{ backgroundColor: props.color }}>
        {props.cover_image ? (
          <img className={cn('image')} src={buildUrl(props.cover_image.image_url)} alt="Img" />
        ) : (
          <div className={cn('placeholder')} style={{ backgroundColor: GRADE_COLORS[props.grade_level] }} />
        )}
      </div>
      <div className={cn('classroom-name-col')}>
        {props.completed ? (
          <Link
            disabled={draftClassroom}
            to={`/classroom/${props.id}/unit/${props.current_unit && props.current_unit.id}`}
          >
            <Text type="h6">{props.name}</Text>
          </Link>
        ) : (
          <Text type="h6">{props.name}</Text>
        )}
      </div>
    </div>
  );
};

export const renderSchoolColumn = (props, className, width) => (
  <div className={className} style={{ minWidth: width, flexBasis: width }}>
    <Text>{props.school.name}</Text>
  </div>
);

export const renderCurrentLocation = (props, className, width) => {
  const dispatch = useDispatch();
  return (
    <div className={cn(className, 'current-spot-column')} style={{ minWidth: width, flexBasis: width }}>
      {props.completed ? (
        <>
          {props.current_week ? (
            <Link
              isPrimary
              to={`/classroom/${props.id}/unit/${props.current_unit && props.current_unit.id}`}
              className={cn('link')}
              onClick={() => dispatch(updateSchoolId(props.school))}
            >
              <Text>
                {`${getWeekOrderNumber(props.current_week && props.current_week.order_number, false, true)} `}
                {!isEdgeWeek(props.current_week.order_number) &&
                  `| ${buildMixedName(props.current_week ? props.current_week.weeks : [])}`}
              </Text>
            </Link>
          ) : (
            'N/A'
          )}
        </>
      ) : (
        <span style={{ color: '#54585a' }}>N/A</span>
      )}
    </div>
  );
};

export const renderStatusColumn = (props, className, width) => (
  <div className={className} style={{ minWidth: width, flexBasis: width }}>
    <Text>{props.is_active ? (props.status === 'active' ? 'Active' : 'Incomplete') : 'Disabled'}</Text>
  </div>
);

const MyClassrooms = () => {
  const { id: programId } = useSelector(({ program }) => program);

  const classrooms = useSelector(({ auth: { user: { classroom_ids = [], school_ids = [], programs = [] } } = {} }) => {
    const currProgram = (programs || []).find(({ id }) => +id === +programId);

    const classroomsMerged =
      (currProgram || {}).schools &&
      currProgram.schools
        .filter(({ id }) => (school_ids || []).some((_id) => +_id === +id))
        .reduce((acc, { classrooms }) => [...acc, ...classrooms], []);
    const assignedClassrooms =
      classroom_ids &&
      classroom_ids.map((classroomId) => classroomsMerged.find(({ id }) => classroomId === id)).filter((_) => !!_);

    return assignedClassrooms;
  });

  const { sortState, handleSortClick, handleSearchChange, dataSorted } = useTableSortedData(
    classrooms,
    'name',
    'status',
  );

  const dispatch = useDispatch();

  const renderButtonsColumn = (props, className, width) => (
    <div className={cn(className, 'buttons-column')} style={{ minWidth: width, flexBasis: width }}>
      <Link
        to={{
          pathname: props.completed ? `/manage-classroom/${props.id}` : `/set-up-classroom/${props.id}`,
          state: { from: '/profile' },
        }}
        onClick={() => dispatch(updateSchoolId(props.school))}
      >
        <Button label={props.completed ? 'Manage' : 'Set Up Classroom'} size="s" />
      </Link>
    </div>
  );

  const columns = [
    { label: 'Classroom', renderer: renderClassroomNameColumn, isBordered: false, width: 350 },
    { label: 'School', renderer: renderSchoolColumn, width: 240 },
    { label: 'Level', renderer: renderLevelColumn, width: 150 },
    { label: 'Current Week', renderer: renderCurrentLocation, width: 220 },
    { label: 'Status', renderer: renderStatusColumn, handleSort: handleSortClick, sorted: sortState },
    { label: '', renderer: renderButtonsColumn, width: 160 },
  ];

  return (
    <div className={cn('ph-60 pb-60', 'wrapper')}>
      <div className={cn('classrooms-heading-wrapper')}>
        <Text type="h5" className={cn('title', 'mv-25')}>
          Classrooms
        </Text>
        <SearchInput name="classroomSearch" onChange={handleSearchChange} />
      </div>
      <TablePagination pageSize={10} data={dataSorted}>
        {({ paginatedData }) => <Table columns={columns} data={paginatedData} />}
      </TablePagination>
    </div>
  );
};

export default MyClassrooms;
