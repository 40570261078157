import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
// actions
import { signUp } from 'store/auth';
import { createClassroom } from 'store/classrooms';
// hooks
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
// components
import Logo from 'components/Logo';
import { Step1, Step2 } from './Steps';
// styles
import styles from './Registration.module.scss';

const cn = classnames.bind(styles);

const Registration = () => {
  const dispatch = useDispatch();
  const {
    user: { id },
  } = useSelector(({ auth }) => auth);
  const { push } = useHistory();

  const handleComplete = (data) => {
    dispatch(signUp(data));
  };

  useEffect(() => {
    if (id) push('/sign-up/create-classroom');
  }, [id, push]);

  const handleComplete2 = (data) => {
    dispatch(createClassroom(data)).then(() => {
      push('/classrooms');
    });
  };

  return (
    <div className={cn('wrapper')}>
      <Logo type="vFull" className={cn('logo')} />
      <Route exact path="/sign-up" component={() => <Step1 onSubmit={handleComplete} />} />
      <Route exact path="/sign-up/create-classroom" component={() => <Step2 onSubmit={handleComplete2} />} />
    </div>
  );
};

export default Registration;
