import React, { useState, useRef, useCallback } from 'react';
import T from 'prop-types';
// utils
import { toast } from 'react-toastify';
import { useSpring, animated } from 'react-spring';
import { useDropzone } from 'react-dropzone';
import { config } from 'constants/images';
// components
import Text from 'components/Text';
// icons
import { ReactComponent as UploadIcon } from 'icons/upload.svg';
// styles
import classnames from 'classnames/bind';
import styles from './DropzoneBox.module.scss';
import { isTablet } from 'react-device-detect';

const cn = classnames.bind(styles);

const DropzoneBox = ({ handleFileChange, setCropperProps }) => {
  const [animationState, setAnimationState] = useState(false);
  const fileInputRef = useRef();

  const onDropAccepted = useCallback((acceptedFiles) => {
    if (acceptedFiles[0]) {
      if (acceptedFiles[0].size > config.maxCoverSize)
        return toast.error('File size is too big! Maximum size is 700kB.', { autoClose: 5000 });

      const fileReader = new FileReader();
      fileReader.onload = () => {
        setCropperProps({ aspectRatio: 21 / 9, onSave: handleFileChange, imageFile: fileReader.result });
      };
      fileReader.readAsDataURL(acceptedFiles[0]);
      // this allows to upload same image twice in a row
      if (trueInputRef.current) trueInputRef.current.value = '';
    }
  }, []);

  const onDropRejected = useCallback((event) => {
    const { errors: err } = event[0];
    if (err) return toast.error(err[0].message, { autoClose: 5000 });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    multiple: false,
    accept: config.mimeTypes,
    noClick: true,
  });

  const { y } = useSpring({
    from: { y: animationState || isDragActive ? 10 : 0 },
    y: animationState || isDragActive ? 0 : 10,
    config: {
      duration: 200,
    },
  });

  const inputProps = getInputProps();

  const trueInputRef = inputProps.ref || fileInputRef;

  return (
    <>
      <div
        className={cn('dropzone-wrapper', 'box')}
        onMouseEnter={() => setAnimationState(true)}
        onMouseLeave={() => setAnimationState(false)}
        {...getRootProps()}
        style={{
          backgroundColor: isDragActive && '#e3e3e3',
          border: isDragActive && '2px dashed #00b341',
        }}
      >
        <animated.div style={{ transform: y.interpolate((y) => `translateY(${y}px)`) }}>
          <UploadIcon className="mv-10" />
        </animated.div>
        <Text className="mv-10" type="h5">
          Drop your own photo here
        </Text>
        <Text className={cn('dropzone-wrapper--small-text')}>
          or{' '}
          <span
            className={cn('dropzone-wrapper--upload-text')}
            onClick={() => trueInputRef.current && trueInputRef.current.click()}
          >
            {isTablet ? 'tap here' : 'click here'}
          </span>{' '}
          to choose a file
        </Text>
        <input type="file" className={cn('file-input')} accept={config.mimeTypes.join(', ')} {...getInputProps()} />
      </div>
    </>
  );
};

DropzoneBox.propTypes = {
  handleFileChange: T.func.isRequired,
  setCropperProps: T.func.isRequired,
};

export default DropzoneBox;
