import React, { useState, useCallback, useEffect } from 'react';
// utils
import { getAlphabeticalSort } from 'utils/string';
import { union, isEqual } from 'lodash';

export const useTableSortedData = (_data = [], searchKey, sortKey) => {
  const [sortState, setSortState] = useState(true);
  const [dataTemp, setDataTemp] = useState(_data);
  const [data, setData] = useState(_data);
  const [phrase, setPhrase] = useState('');

  useEffect(() => {
    if (!isEqual(dataTemp, _data)) {
      setData(_data);
      setDataTemp(_data);
      filterByPhrase();
    }
  }, [_data]);

  useEffect(() => filterByPhrase(), [phrase]);

  function filterByPhrase() {
    let matchingRows = [];
    if (Array.isArray(searchKey)) {
      const results = searchKey.map((key) =>
        _data.filter((el) => el[key] && el[key].toLowerCase().includes(phrase.toLowerCase())),
      );
      matchingRows = union(...results);
    } else {
      matchingRows = _data.filter((el) => el[searchKey] && el[searchKey].toLowerCase().includes(phrase.toLowerCase()));
    }

    return setData(matchingRows);
  }

  const handleSortClick = useCallback(() => setSortState((prev) => !prev), [_data]);

  const handleSearchChange = (value) => setPhrase(value);

  const dataSorted = data.sort((a, b) => getAlphabeticalSort(a[sortKey], b[sortKey], sortState));

  return { sortState, handleSortClick, handleSearchChange, dataSorted };
};
