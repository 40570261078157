import React from 'react';
import classnames from 'classnames/bind';
// components
import Text from 'components/Text';
import Input from 'components/Input/Input';
import Logo from 'components/Logo';
import Link from 'components/Link/Link';
import Button from 'components/Button/Button';
// hooks
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// validation
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// requests
import { resetPassword } from 'store/auth';
// styles
import styles from './PasswordReset.module.scss';

const cn = classnames.bind(styles);

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

const PasswordReset = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const { push } = useHistory();

  const handleSendReset = ({ email }) => {
    dispatch(resetPassword(email)).then(() => {
      push('/login');
    });
  };

  return (
    <form className={cn('wrapper')} onSubmit={handleSubmit(handleSendReset)}>
      <Logo type="vFull" className={cn('logo')} />
      <Text className="mb-25" type="h3">
        Password reset
      </Text>
      <Text className="mb-25">
        Enter the email you use to sign in to Sesame Learning, and we&apos;ll email you instructions on how to reset
        your password.
      </Text>
      <Input
        errors={errors}
        placeholder="you@example.com"
        name="email"
        label="Email"
        inputRef={register({ required: true })}
      />
      <Button
        isSubmit
        className={cn('mh-auto mt-30', 'reset-button')}
        type="regular"
        size="bg"
        label="Send Password Reset Instructions"
      />
      <Link to="/login" isPrimary className={cn('link', 'mt-25')}>
        Return to login
      </Link>
    </form>
  );
};

export default PasswordReset;
