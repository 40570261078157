import React from 'react';
import T from 'prop-types';
import cn from 'classnames';
import './Logo.scss';
// logos
import { ReactComponent as AppLogoHFull } from 'icons/logo/StartWithSesame_H_Full.svg';
import { ReactComponent as AppLogoHAbrv } from 'icons/logo/StartWithSesame_H_Abrv.svg';
import { ReactComponent as AppLogoVFull } from 'icons/logo/StartWithSesame_V_Full.svg';
import { ReactComponent as AppLogoVAbrv } from 'icons/logo/StartWithSesame_V_Abrv.svg';

const Logo = ({ type = 'hAbrv', leftOffset = null, className = '' }) => {
  let AppLogo;
  switch (type) {
    case 'hAbrv':
      AppLogo = AppLogoHAbrv;
      break;
    case 'hFull':
      AppLogo = AppLogoHFull;
      break;
    case 'vAbrv':
      AppLogo = AppLogoVAbrv;
      break;
    case 'vFull':
      AppLogo = AppLogoVFull;
      break;
    default:
      break;
  }

  return <AppLogo className={cn('logo', className)} style={{ left: leftOffset }} />;
};

Logo.propTypes = {
  type: T.string,
  leftOffset: T.number,
  className: T.string,
};

export default Logo;
