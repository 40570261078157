import React from 'react';
import T from 'prop-types';
import classnames from 'classnames/bind';
// components
import Text from 'components/Text';
// styles
import styles from './Badges.module.scss';

const cn = classnames.bind(styles);

const YouAreHereBadge = ({ className }) => (
  <div className={cn('you-are-here', className)}>
    <Text type="h11">You are here</Text>
  </div>
);

YouAreHereBadge.propTypes = {
  className: T.string,
};

export default YouAreHereBadge;
