import { TEACHER_ROLE, ADMIN_ROLE, PROGRAM_ADMIN_ROLE } from 'constants/user';

export const eventCategory = {
  USER_ACTION: 'User Action',
  PDF: 'PDF',
  VIDEO: 'Video',
};

export const eventAction = {
  CLICK: 'Click',
  MENU_CLICK: 'MenuClick',
  SIGN_IN: 'Sign In',
  DOWNLOAD: 'Download',
  FAVORITE: 'AddToMyLibrary',
  UNFAVORITE: 'Unfavorite',
  PLAY: 'Play',
  PAUSE: 'Pause',
  END: 'End',
};

export const eventLabel = {
  click: (contentType, contentTitle) => `${contentType}_${contentTitle}`,
  header: (clickText) => `Header_${clickText}`,
  signIn: (isSuccessful) => `Sign In Attempt_${isSuccessful ? 'Successful' : 'Unsuccessful'}`,
  printables: (contentTitle) => `Printables_${contentTitle}`,
  gettingReady: (contentTitle) => `Professional Prep_${contentTitle}`,
  learningExperiences: (contentTitle) => `Learning Experiences_${contentTitle}`,
  carouselClick: (pageUrl) => `Carousel Click_${pageUrl}`,
  launchWeek: (clickText, pageUrl) => `${clickText}_${pageUrl}`,
  markWeekCompleted: (clickText, pageUrl) => `${clickText}_${pageUrl}`,
  toggleFavorite: (contentTitle, contentType, pageUrl) => `${contentTitle}_${contentType}_${pageUrl}`,
  view: (isList) => `View_${isList ? 'List' : 'Grid'}`,
  requiredActivity: (contentTitle, contentType, pageUrl) => `Required_${contentTitle}_${contentType}_${pageUrl}`,
};

// custom dimensions
export const USER_ROLE = 'USER_ROLE';
export const PROGRAM_NAME = 'PROGRAM_NAME';
export const UNIT_NUMBER = 'UNIT_NUMBER';
export const UNIT_TITLE = 'UNIT_TITLE;';
export const WEEK_NAME = 'WEEK_NAME';

export const customDimensions = {
  [USER_ROLE]: 'dimension1',
  [PROGRAM_NAME]: 'dimension2',
  [UNIT_NUMBER]: 'dimension3',
  [UNIT_TITLE]: 'dimension4',
  [WEEK_NAME]: 'dimension5',
};

export const gaRoleLabels = {
  [TEACHER_ROLE]: 'Teacher',
  [ADMIN_ROLE]: 'School Administrator',
  [PROGRAM_ADMIN_ROLE]: 'Program Administrator',
};
