import React, { useState, useRef } from 'react';
import T from 'prop-types';
// utils
import { config } from 'constants/images';
import classnames from 'classnames/bind';
import { buildUrl } from 'utils';
import useClickOutside from 'hooks/useClickOutside';
// UI and styles
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import Cropper from 'react-image-crop';
import Button from 'components/Button';
import styles from './CoverImageCropper.module.scss';
import 'react-image-crop/lib/ReactCrop.scss';

const cn = classnames.bind(styles);

const ImageCropper = ({ aspectRatio, imageUrl, onSave, imageFile = null, onClose }) => {
  const [image, setImage] = useState(imageFile);
  const [imgCrop, setImgCrop] = useState({
    unit: '%',
    width: 50,
    x: 25,
    y: 25,
    aspect: aspectRatio,
  });
  const [croppedImg, setCroppedImg] = useState(null);
  const fileInputRef = useRef();
  const cropRef = useRef();
  const wrapperRef = useRef();

  useClickOutside(wrapperRef, onClose, { isOpen: true });

  const onCropChange = (crop) => setImgCrop(crop);

  const handleSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > config.maxSize) return;

      const reader = new FileReader();
      reader.onload = ({ target: { result } }) => {
        setImage(result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleNewFileClick = () => fileInputRef.current && fileInputRef.current.click();

  const getCroppedImage = (image, crop) => {
    let customCrop = {};
    // allows to instantly save image on load
    if (crop.unit === '%') {
      const aspect = aspectRatio;
      const customWidth = image.width / aspect < image.height * aspect ? 1 : (image.height * aspect) / image.width;
      const customHeight = image.width / aspect > image.height * aspect ? 1 : image.width / aspect / image.height;
      const customX = (1 - customWidth) / 2;
      const customY = (1 - customHeight) / 2;

      customCrop = {
        unit: 'px',
        aspect,
        width: customWidth * image.width,
        height: customHeight * image.height,
        x: customX * image.width,
        y: customY * image.height,
      };
    }

    const usedCrop = Object.keys(customCrop).length === 0 ? crop : customCrop;

    // assures to avoid CORS
    image.crossOrigin = 'anonymous';
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(usedCrop.width * scaleX);
    canvas.height = Math.ceil(usedCrop.height * scaleY);
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      usedCrop.x * scaleX,
      usedCrop.y * scaleY,
      usedCrop.width * scaleX,
      usedCrop.height * scaleY,
      0,
      0,
      usedCrop.width * scaleX,
      usedCrop.height * scaleY,
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) return;
        resolve(blob);
      });
    });
  };

  const saveCroppedImage = async (currentCrop) => {
    if (cropRef.current) {
      const croppedImageUrl = await getCroppedImage(cropRef.current, currentCrop);
      setCroppedImg(croppedImageUrl);
    }
  };

  const onImageLoaded = (image) => {
    cropRef.current = image;

    const aspect = aspectRatio;
    const width = image.width / aspect < image.height * aspect ? 100 : ((image.height * aspect) / image.width) * 100;
    const height = image.width / aspect > image.height * aspect ? 100 : (image.width / aspect / image.height) * 100;
    const x = (100 - width) / 2;
    const y = (100 - height) / 2;

    setImgCrop({ unit: '%', width, height, x, y, aspect });
    saveCroppedImage(imgCrop);

    return false;
  };
  return (
    <div className={cn('container')}>
      <div className={cn('main-wrapper')} ref={wrapperRef}>
        <CloseIcon className={cn('main-wrapper--icon')} onClick={onClose} />
        <div className={cn('body-wrapper')}>
          <Cropper
            src={image || buildUrl(imageUrl)}
            className={cn('image-cropper')}
            crop={imgCrop}
            onComplete={(crop) => saveCroppedImage(crop)}
            onChange={onCropChange}
            onImageLoaded={onImageLoaded}
            keepSelection
          />
          <div className={cn('body-wrapper--buttons')}>
            <Button
              className={cn('body-wrapper--buttons--first')}
              type="regular"
              size="md"
              label="New Image"
              variant="outlined"
              onClick={handleNewFileClick}
            />
            <Button
              type="regular"
              size="md"
              label="Save Image"
              onClick={() => {
                onClose();
                onSave(croppedImg);
              }}
            />
            <input
              type="file"
              className={cn('file-input')}
              accept={config.mimeTypes.join(', ')}
              onChange={handleSelectFile}
              ref={fileInputRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ImageCropper.propTypes = {
  aspectRatio: T.number.isRequired,
  imageUrl: T.string,
  onSave: T.func.isRequired,
  imageFile: T.string,
  onClose: T.func.isRequired,
};

export default ImageCropper;
