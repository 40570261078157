import React from 'react';
import T from 'prop-types';
// components
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import Text from 'components/Text';
// icons
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ReactComponent as PapperIcon } from 'icons/papper-plane.svg';
// styles
import classnames from 'classnames/bind';
import styles from './MultiMailField.module.scss';
import 'react-multi-email/style.css';

const cn = classnames.bind(styles);

const MultiMailField = ({ placeholder = '', emails, setEmails, className }) => {
  return (
    <ReactMultiEmail
      placeholder={placeholder}
      emails={emails}
      className={cn('container', className)}
      onChange={(_emails) => setTimeout(() => setEmails(_emails), 100)}
      validateEmail={(email) => isEmail(email)}
      getLabel={(email, index, removeEmail) => (
        <div key={index} className={cn('email-wrap')}>
          <PapperIcon className={cn('icon', 'mr-5')} />
          <Text className={cn('email')}>{email}</Text>
          <CloseIcon className={cn('delete', 'ml-5')} onClick={() => removeEmail(index)} />
        </div>
      )}
    />
  );
};

MultiMailField.propTypes = {
  placeholder: T.string,
  emails: T.arrayOf(T.string).isRequired,
  setEmails: T.func.isRequired,
  className: T.string,
};

export default MultiMailField;
