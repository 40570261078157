import React from 'react';
import classnames from 'classnames/bind';
// components
import Text from 'components/Text';
// styles
import styles from './Badges.module.scss';

const cn = classnames.bind(styles);

const RequiredBadge = ({ className }) => (
  <div className={cn('required-badge', className)}>
    <Text bold type="h11">Required</Text>
  </div>
);

export default RequiredBadge;
