import React from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
// hoc
import withTabSwitcher from 'hoc/withTabSwitcher';
// utils
import { markWeekCompleted } from 'store/classrooms';
import { useDispatch, useSelector } from 'react-redux';
import { TITLE_OPTIONS_LABELS } from 'constants/user';
import { get } from 'lodash';
import {
  eventCategory,
  eventAction,
  eventLabel,
  USER_ROLE,
  PROGRAM_NAME,
  UNIT_NUMBER,
  UNIT_TITLE,
  WEEK_NAME,
  gaRoleLabels,
  customDimensions,
} from 'constants/googleAnalytics';
import { GAEmitEvent } from 'utils/googleAnalytics';
// components
import Button from 'components/Button';
import HeartShapedBox from 'components/HeartShapedBox';
import Text from 'components/Text';
import Link from 'components/Link/Link';
import TruncateHint from 'components/TruncateHint/TruncateHint';
// styles
import classnames from 'classnames/bind';
import { getWeekOrderNumber } from 'utils';
import { GRADE_TITLES } from 'constants/classrooms';
import styles from './BrowserView.module.scss';
import DraftBadge from 'components/Badges/DraftBadge';

const cn = classnames.bind(styles);

// like other components, props will be adjusted upon connecting with actual data
const BrowserView = ({
  color,
  weekImage,
  weekHeading = '',
  weekText = '',
  targetSel,
  weekBoundaries = '',
  classroom,
  unit,
  expandToNav,
}) => {
  const dispatch = useDispatch();
  const {
    params: { classroomId, unitId, weekId },
  } = useRouteMatch();
  const { pathname, search } = useLocation();

  const {
    user: { title, first_name: fN, school_license_active: sla, role },
  } = useSelector(({ auth }) => auth);
  const { name: programName } = useSelector(({ program }) => program);

  const { week } = useSelector(({ classrooms: { classrooms } }) => {
    const classroom = classrooms.find(({ id }) => +classroomId === id);
    return {
      week: get(classroom, `unit_progress[${unitId}].week_progress[${weekId}]`, {}),
    };
  });
  const isDraft = get(week, 'weeks[0].draft', false);

  const selReview = get(week, 'weeks[0].sel_review', undefined);

  const { week_progresses: weekProgresses, order_number: currentUnitOrder } = get(
    classroom,
    `unit_progress.${unitId}`,
    {},
  );
  const isCurrentUnit = get(classroom, 'current_unit.id') === +unitId;
  const weekProgRev = weekProgresses ? [...weekProgresses].reverse() : [];
  const lastUnfinished =
    weekProgRev.find(
      ({ finished }, idx) =>
        (!finished && weekProgRev[idx + 1] && weekProgRev[idx + 1].finished) || !weekProgresses[idx + 1],
    ) || {};

  const currentUnitOrderNumber = get(classroom, 'current_unit.order_number');

  const isFuture = isCurrentUnit
    ? lastUnfinished.order_number < week.order_number
    : currentUnitOrderNumber < currentUnitOrder;

  const buildNavSection = () => (
    <div className={cn('content-left--nav-section')}>
      <Link to="/classrooms">
        <Text className={cn('content-left--nav-section--link-text')}>My Classrooms</Text>
      </Link>
      <span className={cn('content-left--nav-section--arrow')}>&rsaquo;</span>
      <Text className={cn('content-left--nav-section--link-text')}>{classroom && classroom.name}</Text>
      <span className={cn('content-left--nav-section--arrow')}>&rsaquo;</span>
      <Link to={`/classroom/${classroomId}/unit/${unitId}`}>
        <Text className={cn('content-left--nav-section--link-text')}>
          {GRADE_TITLES[get(week, 'weeks[0].grade_level', undefined)]}
          &nbsp;
          {`Unit ${unit.order_number}`}
        </Text>
      </Link>
      <span className={cn('content-left--nav-section--arrow')}>&rsaquo;</span>
      <Text className={cn('content-left--nav-section--link-text')}>
        {getWeekOrderNumber(week.weeks && week.weeks[0] && week.weeks[0].order_number, false, true)}
      </Text>
    </div>
  );

  return (
    <div className={cn('wrapper')}>
      {buildNavSection()}
      <div className={cn('image-container')} style={{ backgroundColor: color }}>
        {isDraft && <DraftBadge className={cn('week-draft-badge')} size="lg" />}
        <div className={cn('image-overflow')}>
          <img src={weekImage} aria-label="image" />
        </div>
        <div className={cn('color-bar')} style={{ backgroundColor: color }} />
      </div>
      <div className={cn('week-nav-content', 'p-4')}>
        <div className={cn('week-nav-content--left')}>
          <Text className={cn('owner', 'mb-10')} type="h10">
            {title && TITLE_OPTIONS_LABELS[title.toLowerCase()]}
            &nbsp;
            {fN}
            &apos;s&nbsp;
            {classroom && `| ${classroom.name}`}
            &nbsp;
            {unit &&
              unit.order_number &&
              `| ${GRADE_TITLES[get(week, 'weeks[0].grade_level', undefined)]} Unit ${unit.order_number}`}
          </Text>
          <div>
            <Text
              type="h1"
              bold
              className={cn('week-nav-content--heading', {
                'week-nav-content--heading--expanded': expandToNav,
              })}
              style={{ color }}
            >
              <TruncateHint text={weekHeading} lines={2} />
            </Text>
          </div>
          <Text type="h6" className={cn('week-nav-content--text')}>
            <TruncateHint text={weekText} lines={3} />
          </Text>
        </div>
        <div className={cn('week-nav-content--right')}>
          {targetSel && <HeartShapedBox className={cn('heart-box')} targetSel={targetSel} selReview={selReview} />}
          <div
            className={cn('button-date-container', {
              'button-date-container--expanded': expandToNav,
            })}
          >
            <Button
              className={cn({ preview: isFuture })}
              onClick={() => {
                !week.finished &&
                  GAEmitEvent(
                    eventCategory.USER_ACTION,
                    eventAction.MENU_CLICK,
                    eventLabel.markWeekCompleted('Mark Week Completed', pathname + search),
                    {
                      [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                      [customDimensions[PROGRAM_NAME]]: programName,
                      [customDimensions[UNIT_NUMBER]]: currentUnitOrder,
                      [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
                      [customDimensions[WEEK_NAME]]: get(week, `weeks[0].name`, null),
                    },
                  );
                return dispatch(markWeekCompleted(classroomId, unitId, weekId, !week.finished));
              }}
              type="markable"
              marked={week.finished}
              disabled={isFuture}
              size="md"
              label={isFuture ? 'Preview Only' : week.finished ? 'Week Completed' : 'Mark Week Completed'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTabSwitcher(BrowserView);
