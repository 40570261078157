import React from 'react';
import T from 'prop-types';
import classnames from 'classnames/bind';
// icons
import { ReactComponent as CalendarIcon } from 'icons/calendar.svg';
// styles
import styles from './Input.module.scss';

const cn = classnames.bind(styles);

// TODO: add error view
const Input = ({
  label = null,
  className = '',
  type = 'text',
  inputRef,
  errors = {},
  name,
  isDate = false,
  ...props
}) => {
  const error = errors[name] && errors[name].message;
  return (
    <div className={cn('wrapper', className, { 'has-error': error })}>
      {label && <div className={cn('label-wrapper')}>{label}</div>}
      <input type={type} className={cn('input')} ref={inputRef} name={name} {...props} />
      {error && <span className={cn('error')}>{error}</span>}
      {isDate && <CalendarIcon className={cn('calendar-icon')} />}
    </div>
  );
};

Input.propTypes = {
  label: T.oneOfType([T.element, T.string]),
  className: T.string,
  name: T.string,
  type: T.string,
  errors: T.object,
  inputRef: T.any,
};

export default Input;
