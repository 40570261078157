import { DateTime } from 'luxon';

export const buildMixedName = (entities, field = 'name') => {
  const names = [...new Set(entities.map((entity) => entity[field]))];

  return names.join(' • ');
};

export const getWeekTimeBounds = (week) => {
  return {};
  const start = DateTime.fromFormat(week.start_date, 'MM-dd-yyyy').startOf('day');
  const now = DateTime.local().startOf('day');
  const end = DateTime.fromFormat(week.end_date, 'MM-dd-yyyy').startOf('day');

  return {
    isPast: now > end,
    isCurrent: start <= now && now <= end,
    isFuture: start > now,
  };
};

export default {};
