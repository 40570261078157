import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { updateUserData as updateCredentials, updateUserAvatar as updateAvatar } from 'store/auth';
// UI and styles
import DisplayOnDesktop from 'containers/DisplayOnDesktop';
import DisplayOnMobile from 'containers/DisplayOnMobile';
import BrowserView from './BrowserView';
import MobileView from './MobileView';
import ProfileSection from './BrowserView/ProfileSection';
import MyClassrooms from './BrowserView/MyClassrooms';

const BROWSER_TAB_OPTIONS = [
  { title: 'classrooms', component: MyClassrooms },
  { title: 'my info', component: ProfileSection },
];

const MyProfile = ({ userData, updateUserData, userDataLoading, updateUserAvatar }) => {
  return (
    <>
      <DisplayOnDesktop>
        <BrowserView
          userData={userData}
          tabOptions={BROWSER_TAB_OPTIONS}
          updateUserData={updateUserData}
          userDataLoading={userDataLoading}
          updateUserAvatar={updateUserAvatar}
        />
      </DisplayOnDesktop>
      <DisplayOnMobile>
        <MobileView />
      </DisplayOnMobile>
    </>
  );
};

MyProfile.propTypes = {
  userData: T.shape({
    id: T.number,
    avatar_url: T.string,
    email: T.string.isRequired,
    first_name: T.string.isRequired,
    last_name: T.string.isRequired,
    school_id: T.number.isRequired,
    title: T.string.isRequired,
  }),
  updateUserData: T.func.isRequired,
  userDataLoading: T.bool.isRequired,
  updateUserAvatar: T.func.isRequired,
};

MyProfile.defaultProps = {
  userData: T.shape({
    avatarUrl: '',
  }),
};

const mapStateToProps = (state) => ({
  userData: state.auth.user,
  userDataLoading: state.auth.userDataLoading,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserData: (data) => dispatch(updateCredentials(data)),
  updateUserAvatar: (imageUrl) => dispatch(updateAvatar(imageUrl)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
