import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
// utils
import { updateProgramId } from 'store/program';
import { buildUrl } from 'utils';
import { obtainInitials } from 'utils/string';
import { useSelector, useDispatch } from 'react-redux';
// containers
import MobileSubcomponentWrapper from 'containers/MobileSubcomponentWrapper';
// components
import Text from 'components/Text';
import SearchInput from 'components/Input/SearchInput';
// icons
import { ReactComponent as CheckIcon } from 'icons/check.svg';
// styles
import classnames from 'classnames/bind';
import styles from './MobileView.module.scss';

const cn = classnames.bind(styles);

const SwitchProgramSection = ({ minHeight }) => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const {
    user: { email, first_name: firstName, programs = [], role },
  } = useSelector(({ auth }) => auth);
  const { id: currProgId } = useSelector(({ program }) => program);

  const { goBack } = useHistory();
  const dispatch = useDispatch();

  const filteredPrograms = programs.filter(({ name }) => name.toLowerCase().includes(searchPhrase.toLowerCase()));

  const handleProgramSwitch = useCallback(
    (program) => {
      dispatch(updateProgramId(program));
      goBack();
    },
    [dispatch, goBack],
  );

  return (
    <MobileSubcomponentWrapper headingTitle="Select a program" fixedHeading>
      <section className={cn('switcher-container')} style={{ minHeight: minHeight ? `${minHeight}px` : '100vh' }}>
        <SearchInput
          value={searchPhrase}
          onChange={setSearchPhrase}
          className={cn('search-input')}
          wrapperClassName={cn('search-wrapper', 'mb-30')}
        />
        <div className={cn('hr')} />
        {filteredPrograms.map((program) => (
          <>
            <div className={cn('program-row')} onClick={() => handleProgramSwitch(program)}>
              {program.avatar_url ? (
                <img className={cn('avatar-wrapper')} src={buildUrl(program.avatar_url)} aria-hidden />
              ) : (
                <div className={cn('placeholder-wrapper')}>{obtainInitials(program.name)}</div>
              )}
              <div className={cn('texts', 'ml-10')}>
                <Text>{program.name}</Text>
              </div>
              {program.id === currProgId && <CheckIcon className={cn('check-icon', 'ml-auto')} />}
            </div>
            <div className={cn('hr')} />
          </>
        ))}
      </section>
    </MobileSubcomponentWrapper>
  );
};

export default SwitchProgramSection;
