import React from 'react';
// utils
import { isMobile } from 'react-device-detect';
// components
import Modal from 'components/Modal';
import Text from 'components/Text';
import Button from 'components/Button';
//styles
import classnames from 'classnames/bind';
import styles from './Modal.module.scss';

const cn = classnames.bind(styles);

const WarningModal = ({ onSave, onCancel, cancelLabel = 'Cancel', saveLabel = 'Save', modalText, modalKey }) => {
  return (
    <Modal className={cn('warning-modal')} modalKey={modalKey} onClickOutside={onCancel}>
      {({ closeModal, blockModalChecked, setBlockModalChecked, handleBlockModal }) => (
        <>
          <Modal.Header className="simple">
            <div />
            <Modal.CloseButton className="simple" onClick={onCancel} />
          </Modal.Header>
          <Modal.Body className={cn('body-wrapper')}>
            <Text type="h4">{modalText}</Text>
            <div className={cn('image')}></div>
          </Modal.Body>
          <Modal.Footer
            className={cn('body-footer')}
            checkable
            checked={blockModalChecked}
            handleChange={setBlockModalChecked}
          >
            <div className={cn('buttons-wrapper')}>
              <Button size={isMobile ? 'bg' : 'md'} variant="filled" label={cancelLabel} onClick={onCancel} />
              <Button
                className={!isMobile ? 'ml-20' : ''}
                size={isMobile ? 'bg' : 'md'}
                label={saveLabel}
                onClick={() => {
                  onSave();
                  handleBlockModal(modalKey, blockModalChecked);
                  closeModal();
                }}
              />
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default WarningModal;
