export const handleBlockModal = (key, hide = false) => {
  const blockedModals = localStorage.getItem('blockedModals');
  if (hide) {
    const newBlockedModals = blockedModals ? [...JSON.parse(blockedModals), key] : [key];
    localStorage.setItem('blockedModals', JSON.stringify(newBlockedModals));
  }
};

export const isBlockedModal = (key) => {
  const blockedModals = localStorage.getItem('blockedModals');
  return blockedModals && JSON.parse(blockedModals).some((modalKey) => modalKey === key);
};
