import React, { useEffect } from 'react';
// components
import AddAsAppModal from 'components/AddAsAppModal';
// utils
import DisplayOnDesktop from 'containers/DisplayOnDesktop';
import DisplayOnMobile from 'containers/DisplayOnMobile';
import { isMobile } from 'react-device-detect';
import { useModalSetter } from 'hooks/useModal';
// components
import BrowserView from './BrowserView';
import MobileView from './MobileView';

const Classrooms = () => {
  const setModal = useModalSetter();

  useEffect(() => {
    if (isMobile && !localStorage.getItem('add-app-modal-shown')) setModal(<AddAsAppModal />);
  }, [setModal]);

  return (
    <>
      <DisplayOnDesktop>
        <BrowserView />
      </DisplayOnDesktop>
      <DisplayOnMobile>
        <MobileView />
      </DisplayOnMobile>
    </>
  );
};

export default Classrooms;
