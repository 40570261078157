import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
// utils
import { formatDate, getWeekTimeBounds } from '../helpers';
import { getClassroomUnit, markWeekCompleted } from 'store/classrooms';
import { GRADE_COLORS, GRADE_TITLES } from 'constants/classrooms';
import { TITLE_OPTIONS_LABELS } from 'constants/user';
import { useSpring, animated, config } from 'react-spring';
import { buildUrl, getWeekOrderNumber } from 'utils';
import { get, isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
  eventCategory,
  eventAction,
  eventLabel,
  USER_ROLE,
  PROGRAM_NAME,
  UNIT_NUMBER,
  UNIT_TITLE,
  WEEK_NAME,
  gaRoleLabels,
  customDimensions,
} from 'constants/googleAnalytics';
import { GAEmitEvent } from 'utils/googleAnalytics';
// hooks
import { useTempStatuses } from 'hooks/useTempStatuses';
import { useBottomMenuSetter } from 'hooks/useBottomMenu';
import { useMeasure } from 'hooks/useMeasure';
// components
import YouAreHereBadge from 'components/Badges/YouAreHereBadge';
import CheckButton from 'components/CheckButton';
import Link from 'components/Link';
import BottomMenu from 'components/BottomMenu';
import Text from 'components/Text';
// icons
import { ReactComponent as CheckIcon } from 'icons/check.svg';
import { ReactComponent as CircleIcon } from 'icons/circle.svg';
import { ReactComponent as ChevronIcon } from 'icons/chevron.svg';
// styles
import classnames from 'classnames/bind';
import styles from './MobileView.module.scss';
import DraftBadge from 'components/Badges/DraftBadge';
import Week from 'pages/Week/Week';

const cn = classnames.bind(styles);

const springConfig = { ...config.stiff, duration: 200, tension: 270 };

const UnitsDrawer = ({ units = [], currentUnit = {}, classroomId, push, closeMenu }) => (
  <BottomMenu menuHeading="units">
    {units.map(({ id, order_number, units = [] }) => (
      <div className={cn('drawer-unit')} key={id}>
        {id === currentUnit.id && <CheckIcon className={cn('drawer-unit--check')} />}
        <Text
          className={cn('drawer-unit--text')}
          onClick={() => {
            if (id === currentUnit.id) return;
            push(`/classroom/${classroomId}/unit/${id}`);
            closeMenu();
          }}
        >{`Unit ${order_number} | ${units[0] && units[0].name}`}</Text>
      </div>
    ))}
  </BottomMenu>
);

const MobileView = () => {
  const {
    push,
    location: { pathname, search },
  } = useHistory();
  const { classroomId, unitId } = useParams();
  const dispatch = useDispatch();
  const { first_name, title = '', role } = useSelector(({ auth: { user = {} } = {} }) => user);
  const { name, imageUrl, gradeLevel, unit, unitProgresses, isLoading, hasErrors } = useSelector(
    ({ classrooms = {} }) => {
      const classroom =
        (classrooms.classrooms.length && classrooms.classrooms.find(({ id, ...rest }) => +id === +classroomId)) || {};

      return {
        name: get(classroom, 'name', ''),
        imageUrl: get(classroom, 'cover_image.image_url', ''),
        gradeLevel: get(classroom, 'grade_level', ''),
        unit: get(classroom, `unit_progress[${unitId}]`, {}),
        unitProgresses: get(classroom, 'unit_progresses', []),
        isLoading: classrooms.isLoading,
        hasErrors: classrooms.hasErrors,
      };
    },
  );
  const isDraft = get(unit, 'units[0].draft', false);
  const { classroom } = useSelector(({ classrooms: { classrooms } }) => ({
    classroom: classrooms.find(({ id }) => +classroomId === id),
  }));
  const { week_progresses: weekProgresses, order_number: currentUnitOrder } = get(
    classroom,
    `unit_progress.${unitId}`,
    {},
  );
  const { name: programName } = useSelector(({ program }) => program);

  const { setBottomMenu, closeMenu, isMenu } = useBottomMenuSetter();

  const [weekStatuses, setWeekStatuses] = useTempStatuses(weekProgresses, [weekProgresses]);
  const color = GRADE_COLORS[get(unit, 'units[0].grade_level', '')];
  useEffect(
    () => isEmpty(weekProgresses) && !isLoading && !hasErrors && dispatch(getClassroomUnit(classroomId, unitId)),
    [dispatch, classroomId, unitId, weekProgresses, isLoading, hasErrors],
  );

  const [bind, { height: imageWrapperHeight }] = useMeasure();
  const [shrinkHeader, setShrinkHeader] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 20) return setShrinkHeader(true);
      return setShrinkHeader(false);
    };

    window.addEventListener('touchmove', handleScroll);

    return () => window.removeEventListener('touchmove', handleScroll);
  }, []);

  const heightStyles = useSpring({
    config: springConfig,
    from: { height: imageWrapperHeight },
    to: { height: shrinkHeader ? 100 : imageWrapperHeight },
  });
  const scaleProps = useSpring({
    config: springConfig,
    x: shrinkHeader ? 100 / imageWrapperHeight : 1,
  });

  const renderWeekRow = (week) => {
    const isCurrentUnit = get(classroom, 'current_unit.id') === +unitId;
    const weekProgRev = [...weekProgresses].reverse();
    const currentUnitOrderNumber = get(classroom, 'current_unit.order_number');
    const lastUnfinished =
      weekProgRev.find(
        ({ finished }, idx) =>
          (!finished && weekProgRev[idx + 1] && weekProgRev[idx + 1].finished) || !weekProgresses[idx + 1],
      ) || {};

    const isCurrent = isCurrentUnit && week.id === lastUnfinished.id;
    const isFuture = isCurrentUnit
      ? lastUnfinished.order_number < week.order_number
      : currentUnitOrderNumber < currentUnitOrder;
    const isPast = isCurrentUnit ? !isCurrent && !isFuture : currentUnitOrder < currentUnitOrderNumber;

    return (
      <article className={cn('week-row')} key={week.id}>
        <CheckButton
          className={cn('check-button')}
          isChecked={week.finished}
          isPreview={isFuture && !classroom.all_finished}
          onChange={() => {
            !week.finished &&
              GAEmitEvent(
                eventCategory.USER_ACTION,
                eventAction.MENU_CLICK,
                eventLabel.markWeekCompleted('Mark Week Completed', pathname + search),
                {
                  [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                  [customDimensions[PROGRAM_NAME]]: programName,
                  [customDimensions[UNIT_NUMBER]]: get(unit, 'units[0].order_number', null),
                  [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
                  [customDimensions[WEEK_NAME]]: get(week, `weeks[0].name`, null),
                },
              );
            setWeekStatuses((prev) => ({ ...prev, [week.id]: !weekStatuses[week.id] }));
            return dispatch(markWeekCompleted(classroomId, unit.id, week.id, !week.finished));
          }}
        />
        <div className={cn('hr-line')} />
        <Link
          className={cn('clickable', { completed: weekStatuses[week.id] })}
          to={`/classroom/${classroomId}/unit/${unitId}/week/${week.id}`}
          noHoverColor
        >
          <div className={cn('texts')}>
            <div className={cn('week-name')}>
              {isCurrent && <YouAreHereBadge className={cn('week-name--badge')} />}
              <Text className={cn('week-name--text', { current: isCurrent })}>
                {week.weeks && getWeekOrderNumber(week.weeks[0].order_number, false, true)}
              </Text>
              {get(week, 'weeks[0].draft', false) && <DraftBadge size="xsm" style={{ marginLeft: 'auto' }} />}
            </div>
            <Text className={cn('texts--description', { current: isCurrent })}>
              {week.weeks && week.weeks[0].description}
            </Text>
          </div>
          <Text className={cn('date-bounds')}>
            {week.start_date && `${formatDate(week.start_date)} - ${formatDate(week.end_date)}`}
          </Text>
        </Link>
      </article>
    );
  };

  return (
    <section className={cn('container')}>
      <div className={cn('header')} style={{ height: `${imageWrapperHeight}px` }}>
        <animated.div style={{ ...heightStyles, overflow: 'hidden', position: 'fixed', width: '100%', zIndex: 5 }}>
          <div
            {...bind}
            className={cn('cover-wrapper')}
            style={{ background: `linear-gradient(0deg, rgba(0, 0, 0, 0) 22%, rgba(0, 0, 0, 0.35) 100% ) ${color}` }}
          >
            <animated.div
              style={{
                transform: scaleProps.x.interpolate((x) => `scale(${x})`),
                transformOrigin: '50% 0%',
                display: 'flex',
              }}
            >
              {isDraft && <DraftBadge className={cn('draft-badge')} />}
              <img
                className={cn('cover-wrapper--image')}
                src={buildUrl(imageUrl)}
                alt="classroom cover"
                aria-label="classroom cover"
              />
            </animated.div>
          </div>
        </animated.div>
        <div className={cn('chevron-wrapper')} onClick={() => push('/classrooms')}>
          <ChevronIcon className={cn('chevron-wrapper--icon')} />
        </div>
        <div className={cn('headings')}>
          <Text className={cn('headings--name')} type="h5">
            {name}
          </Text>
          <Text type="body-xs">{GRADE_TITLES[gradeLevel]}</Text>
        </div>
      </div>
      <div className={cn('content-wrapper', 'p-30')}>
        <Text className={cn('bread-crumbs')} type="body-small">{`${
          TITLE_OPTIONS_LABELS[title.toLowerCase()]
        } ${first_name}'s ${GRADE_TITLES[gradeLevel]} | ${name} | Unit ${unit.order_number}`}</Text>
        <div
          className={cn('unit-select', 'mv-20')}
          onClick={() =>
            setBottomMenu(
              <UnitsDrawer
                units={unitProgresses}
                currentUnit={unit}
                classroomId={classroomId}
                push={push}
                closeMenu={closeMenu}
              />,
            )
          }
        >
          <div className={cn('left-section')}>
            <Text className={cn('left-section--text')} type="h5" style={{ color }}>{`Unit ${unit.order_number}: ${
              unit.units && unit.units[0].name
            }`}</Text>
            <Text className={cn('left-section--text')}>{unit.units && unit.units[0].description}</Text>
          </div>
          <div className={cn('right-section')}>
            <CircleIcon className={cn('circle')} />
            <ChevronIcon className={cn('chevron', { open: isMenu })} />
          </div>
        </div>
        <div className={cn('weeks-wrapper')}>
          {(weekProgresses || []).map((week) => renderWeekRow(week))}
          <div className={cn('hr-line')} />
        </div>
      </div>
    </section>
  );
};

export default MobileView;
