export const MODAL_KEYS = {
  createSchool: 'add-school-program',
  createClassroom: 'add-classroom-school',
  programInviteAdmin: 'invite-user-program-only-admin',
  programInviteUser: 'invite-user-program',
  schoolInviteAdmin: 'invite-user-school-only-admin',
  schoolInviteUser: 'invite-user-school',
  assignUserToClassroom: 'assign-users-to-classroom',
  assignClassroomToUser: 'assign-classroom-to-user',
};
