import ReactGA from 'react-ga4';

const ga4Id = process.env.REACT_APP_GA4_ID;

export const GAInitialize = (options = {}) => ReactGA.initialize(ga4Id, { ...options });

export const GATrackPageView = (url, additionalParams = {}) => ReactGA.pageview(url, { ...additionalParams });

export const GAEmitEvent = (category, action, label, additionalParams = {}) =>
  ReactGA.event({ category, action, label, ...additionalParams });

export const GASetDimension = (name, value) => ReactGA.set({ [name]: value });

export const GAHasLoaded = ReactGA._hasLoadedGA;
