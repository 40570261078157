import axios from 'axios';
// utils
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { clearAuthData, getAuthData, setAuthData, setInstanceIds, getInstanceData } from './auth';
// constants
import { API_URL } from '../constants';

axios.defaults.baseURL = `${API_URL}/api`;

const makeRequest = (url, { method = 'get', headers = {}, ...otherOptions } = {}) => {
  const authData = getAuthData();
  const instanceData = getInstanceData();
  return axios({
    method,
    url,
    headers: {
      ...authData,
      ...instanceData,
      ...headers,
    },
    ...otherOptions,
  })
    .then((response) => {
      if (isEmpty(authData)) {
        setAuthData(response.headers, otherOptions.isRemembered);
      }
      if (isEmpty(instanceData)) {
        setInstanceIds(response.headers);
      }

      return response.data;
    })
    .catch((error) => {
      handleResponseError(error);
    });
};

function handleResponseError(error) {
  if (error.response) {
    const {
      config: { method } = {},
      data: { errors, program, school, registration = false } = {},
      status,
    } = error.response;

    // requires such destructuring due to Rails way of packing errors
    let errorMsg;
    if (typeof errors === 'object' && !Array.isArray(errors) && !isEmpty(errors)) {
      errorMsg = Object.values(errors)[0][0];
    } else if (Array.isArray(errors)) {
      errorMsg = errors[0];
    } else {
      errorMsg = errors;
    }

    switch (status) {
      case 404:
        if (method === 'get' && !registration) {
          window.location = '/classrooms';
          return;
        }
        toast.error(errorMsg, { autoClose: 5000 });
        return;
      case 403:
        if (method === 'get' && (program === 'disabled' || school === 'disabled')) {
          Cookies.remove('instanceIds');
          window.location = '/switch-program';
        } else {
          Cookies.remove('auth');
          toast.error(errorMsg, { autoClose: 3000 });
          setTimeout(() => {
            window.location = '/login';
          }, 3000);
        }
        return;
      case 401:
        if (errorMsg === 'Invalid login credentials. Please try again.') {
          toast.error(errorMsg, { autoClose: 3000 });
          return;
        }
        clearAuthData();
        toast.error(errorMsg, { autoClose: 3000 });
        return;
      case 422:
        toast.error(errorMsg, { autoClose: 3000 });
        return;
      default:
        toast.error('Something went wrong.', { autoClose: 3000 });
        throw new Error({ status, errorMsg });
    }
  }
  return Promise.reject(error);
}

export default makeRequest;
