import { combineReducers } from 'redux';
// reducers
import auth from './auth';
import classrooms from './classrooms';
import favorites from './favorites';
import school from './school';
import program from './program';

// NOTE: keep in ALPHABETICAL order
const rootReducer = combineReducers({ auth, classrooms, favorites, school, program });

export default rootReducer;
