import React, { useEffect, useRef, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
// hoc
import withClassroomContentIdChange from 'hoc/withClassroomContentIdChange';
// utils
import { getPrintStyles } from './helpers';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import DisplayOnDesktop from 'containers/DisplayOnDesktop';
import DisplayOnMobile from 'containers/DisplayOnMobile';
import { getLessonPLans, getClassroomUnit, getClassroomWeek, clearLessonPlan } from 'store/classrooms';
import { isEmpty, get } from 'lodash';
// UI and styles
import UserNotes from 'pages/LessonPlans/UserNotes';
import LessonPlanSectionMobile from './MobileView/LessonPlanSection';
import MobileView from './MobileView';
import BrowserView from './BrowserView';
import LessonPlanSectionBrowser from './BrowserView/LessonPlanSection';
import Printables from './Printables';

const BROWSER_TAB_OPTIONS = [
  { title: 'PD resource', component: LessonPlanSectionBrowser },
  { title: 'my notes', component: UserNotes },
  { title: 'printables', component: Printables },
];

const MOBILE_TAB_OPTIONS = [
  { title: 'PD resource', component: LessonPlanSectionMobile },
  { title: 'my notes', component: UserNotes },
  { title: 'printables', component: Printables },
];

let hackyPrintMemo;

const LessonPlan = () => {
  const history = useHistory();
  const { classroomId, unitId, weekId, activityId } = useParams();
  const dispatch = useDispatch();
  const { week, unit, isLoading, hasErrors, classrooms, classroom } = useSelector(
    ({ classrooms: { classrooms, isLoading: _isLoading, hasErrors: _hasErrors } }) => {
      const _classroom = classrooms.find(({ id }) => +classroomId === id);
      return {
        week: get(_classroom, `unit_progress[${unitId}].week_progress[${weekId}]`, {}),
        unit: get(_classroom, `unit_progress[${unitId}]`, {}),
        isLoading: _isLoading,
        hasErrors: _hasErrors,
        classrooms,
        classroom: _classroom,
      };
    },
  );
  const { id: schoolId } = useSelector(({ school }) => school);

  const { week_progresses: weekProgresses } = get(classroom, `unit_progress.${unitId}`, {});

  useEffect(() => {
    schoolId && dispatch(getLessonPLans(classroomId, activityId)).catch(() => history.push('/classrooms'));
    return () => {
      dispatch(clearLessonPlan());
    };
  }, [dispatch, history.location.pathname, schoolId]);

  useEffect(() => {
    if (classroom && isEmpty(weekProgresses) && !isLoading && !hasErrors)
      dispatch(getClassroomUnit(classroomId, unitId));
  }, [dispatch, classroomId, unitId, weekProgresses, isLoading, hasErrors, classroom]);

  useEffect(() => {
    if (!isEmpty(classrooms) && isEmpty(week) && !isLoading && !hasErrors)
      dispatch(getClassroomWeek(classroomId, unitId, weekId));
  }, [dispatch, classroomId, unitId, weekId, week, isLoading, hasErrors, classrooms]);

  const printRef = useRef();

  if (printRef.current) {
    hackyPrintMemo = printRef.current;
  }

  const handlePrint = useReactToPrint({
    content: () => hackyPrintMemo,
    pageStyle: getPrintStyles({ classroomColor: classroom && classroom.color }),
  });

  return (
    <>
      <DisplayOnDesktop>
        <BrowserView
          tabOptions={BROWSER_TAB_OPTIONS}
          placeTabsMiddle
          stickyHeader
          printRef={printRef}
          handlePrint={handlePrint}
          week={week}
          unit={unit}
        />
      </DisplayOnDesktop>
      <DisplayOnMobile>
        <MobileView tabOptions={MOBILE_TAB_OPTIONS} week={week} unit={unit} />
      </DisplayOnMobile>
    </>
  );
};

export default withClassroomContentIdChange(LessonPlan);
