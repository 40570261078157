import React, { useState } from 'react';
import T from 'prop-types';
// utils
import { isMobile } from 'react-device-detect';
// components
import Modal from 'components/Modal';
import Text from 'components/Text';
import Button from 'components/Button';
// styles
import classnames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { firstEverLoginCLEAR } from 'store/auth';
import { useSwipeable } from 'react-swipeable';
import styles from './WelcomeModal.module.scss';

const cn = classnames.bind(styles);

const stepTexts = {
  0: (programName) => (
    <Text type="h4">
      Congratulations, your program is registered! Now let's get <strong>{programName}</strong> set up. You're only
      three steps away!
    </Text>
  ),
  1: () => (
    <>
      <Text type="h4">
        <strong>Step 1</strong>
      </Text>
      <Text type="h4">Enter your school(s) names below. Then select a school to begin setting up classrooms.</Text>
    </>
  ),
  2: () => (
    <>
      <Text type="h4">
        <strong>Step 2</strong>
      </Text>
      <Text type="h4">Set up each classroom. Remember, you have one classroom per license.</Text>
    </>
  ),
  3: () => (
    <Text type="h4">
      Your school(s) and classrooms are set up! You can add educators to your classrooms when you're ready, or continue
      setting up each classroom.
    </Text>
  ),
};

const WelcomeModal = ({ programName }) => {
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();

  const handleStepChange = (direction) =>
    direction === 'forward'
      ? setStep((prev) => (prev + 1 !== 4 ? prev + 1 : prev))
      : setStep((prev) => (prev - 1 !== -1 ? prev - 1 : prev));

  const handleCloseModal = (closeModal) => () => {
    dispatch(firstEverLoginCLEAR());
    closeModal();
  };
  const handlers = useSwipeable({
    onSwipedLeft: () => handleStepChange('forward'),
    onSwipedRight: () => handleStepChange('backward'),
  });

  return (
    <Modal className={cn('wrapper')}>
      {({ closeModal }) => (
        <>
          <Modal.Header className="simple">
            <div />
            <Modal.CloseButton className="simple" onClick={handleCloseModal(closeModal)} />
          </Modal.Header>
          <Modal.Body>
            <div {...handlers} className={cn('body-wrapper')}>
              {stepTexts[step](programName)}
              <div className={cn('image', 'mt-30', { lift: step === 3 })}>Illustration</div>
            </div>
          </Modal.Body>
          <Modal.Footer className="friendly">
            <div className={cn('footer-wrapper')}>
              <Button
                className={cn('nav-button', { hidden: step === 0 })}
                type="pagination"
                size="s"
                onClick={() => handleStepChange('backward')}
              />
              <div className={cn('dots-wrapper')}>
                {Array(4)
                  .fill()
                  .map((_, idx) => (
                    <div key={`dot-${idx}`} className={cn('dot', { current: idx === step })} />
                  ))}
              </div>
              <Button
                className={cn('nav-button', 'forward', { hidden: step === 3 })}
                type="pagination"
                size="s"
                onClick={() => handleStepChange('forward')}
              />
              {step === 3 && (
                <Button
                  className={cn('nav-button', 'accept', !isMobile && 'ph-30')}
                  size={isMobile ? 'md' : 'bg'}
                  label="Continue"
                  onClick={handleCloseModal(closeModal)}
                />
              )}
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

WelcomeModal.propTypes = {
  programName: T.string.isRequired,
};

export default WelcomeModal;
