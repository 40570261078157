import React from 'react';
// hoc
import withClassroomContentIdChange from 'hoc/withClassroomContentIdChange';
// components
import DisplayOnDesktop from 'containers/DisplayOnDesktop';
import DisplayOnMobile from 'containers/DisplayOnMobile';
import BrowserView from './BrowserView';
import MobileView from './MobileView';

const LearningExp = () => (
  <>
    <DisplayOnDesktop>
      <BrowserView />
    </DisplayOnDesktop>
    <DisplayOnMobile>
      <MobileView />
    </DisplayOnMobile>
  </>
);

export default withClassroomContentIdChange(LearningExp);
