import React from 'react';
import T from 'prop-types';
// UI and styles
import Text from 'components/Text';
import { ReactComponent as RocketIcon } from 'icons/Badges/rocket.svg';
import { ReactComponent as ImageIcon } from 'icons/Badges/image.svg';
import { ReactComponent as BookIcon } from 'icons/Badges/book.svg';
import { ReactComponent as PlayIcon } from 'icons/Badges/play.svg';
import { ReactComponent as HeartIcon } from 'icons/heart.svg';
import { ReactComponent as SmileyIcon } from 'icons/smiley-icon.svg';
import { ReactComponent as ScissorsIcon } from 'icons/Badges/scissors.svg';
import classnames from 'classnames/bind';
import styles from './SmallBadge.module.scss';

const cn = classnames.bind(styles);

const BADGES = {
  launch: {
    icon: RocketIcon,
  },
  image: {
    icon: ImageIcon,
  },
  'read-aloud': {
    icon: BookIcon,
  },
  'interactive read-aloud': {
    icon: BookIcon,
  },
  video: {
    icon: PlayIcon,
  },
  'learning center': {
    icon: ScissorsIcon,
  },
  'unit project': {
    icon: ScissorsIcon,
  },
  'brain break': {
    icon: SmileyIcon,
  },
  sel: {
    icon: HeartIcon,
  },
  'sel vocabulary': {
    icon: HeartIcon,
  },
  required: {
    icon: null,
  },
};

const SmallBadge = ({ type, className }) => {
  const IconComponent = BADGES[type] ? BADGES[type].icon : null;
  return (
    <div className={cn('wrapper', className, { 'wrapper--required': type === 'required' })}>
      {IconComponent && <IconComponent className={cn('icon-component')} />}
      <Text className={cn('badge-text')}>{type}</Text>
    </div>
  );
};

export const badgeTypes = Object.keys(BADGES);

SmallBadge.propTypes = {
  type: T.oneOf(badgeTypes).isRequired,
};

export default SmallBadge;
