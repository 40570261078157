import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
// utils
import { useModalSetter } from 'hooks/useModal';
import { isMobile } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GRADE_TITLES, GRADE_COLORS } from 'constants/classrooms';
import { updateClassroom } from 'store/classrooms';
// containers
import SlideInWrapper from 'containers/SlideInWrapper';
import MobileSubcomponentWrapper from 'containers/MobileSubcomponentWrapper';
import DisplayOnMobile from 'containers/DisplayOnMobile';
import DisplayOnDesktop from 'containers/DisplayOnDesktop';
// components
import Modal from 'components/Modal';
import Text from 'components/Text';
import Input from 'components/Input';
import SelectBox from 'components/SelectBox';
import Button from 'components/Button';
// styles
import classnames from 'classnames/bind';
import Div100vh from 'react-div-100vh';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import ClassroomCoverPicker from 'pages/ManageClassroomCover/BrowserView/BrowserView';
import ClassroomCoverPickerMobile from 'pages/ManageClassroomCover/MobileView';
import { buildUrl } from 'utils';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import queryString from 'query-string';
import styles from './AddClassroom.module.scss';
import { ReactComponent as YouAreHereBaseIcon } from './YouAreHereBase.svg';

const cn = classnames.bind(styles);

const schema = yup.object().shape({
  name: yup.string().required('Please provide classroom name.'),
  grade_level: yup.string().required('Please choose grade level.').nullable(),
});

const AddClassroomModal = () => {
  const userData = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const { cover_image_mode: cIM } = queryString.parse(location.search);

  const isCoverImageMode = !!cIM;
  const {
    params: { classroomId },
  } = useRouteMatch();

  const { name: classroomName = '', grade_level: classroomGrade, cover_image: coverImage, started } = useSelector(
    ({ classrooms }) => classrooms.classrooms.find(({ id }) => id === +classroomId) || {},
  );

  const { push, goBack } = useHistory();
  const [currentStep, setCurrentStep] = useState(isCoverImageMode ? 2 : 1);
  const [currentGradeLevel, setCurrentGradeLevel] = useState(classroomGrade || '');
  const [currentImage, setCurrentImage] = useState(coverImage || {});

  const { register, handleSubmit, errors, setValue, clearErrors, getValues } = useForm({
    defaultValues: {
      name: classroomName,
      grade_level: classroomGrade,
    },
    resolver: yupResolver(schema),
  });
  const selectGradeTitles = Object.entries(GRADE_TITLES).reduce((acc, [k, v]) => [...acc, { value: k, label: v }], []);

  const setModal = useModalSetter();

  const [currentClassroomName, setCurrentClassroomName] = useState(classroomName);

  useEffect(() => {
    setCurrentGradeLevel(classroomGrade);
    setCurrentClassroomName(classroomName);
  }, [setValue, classroomName, classroomGrade, setCurrentClassroomName]);

  const handleFormSubmit = async (data) => {
    if (currentStep === 1) {
      setCurrentStep(2);
      return;
    }

    if (!currentImage) {
      return toast.error('Cover image is required!');
    }

    let bodyData = Object.create({});
    if (data.name !== classroomName) {
      Object.assign(bodyData, { name: data.name });
    }
    if (data.grade_level !== classroomGrade) {
      Object.assign(bodyData, { grade_level: data.grade_level });
    }

    await dispatch(updateClassroom({ ...bodyData, cover_image_id: currentImage.id, completed: true }, classroomId));
    goBack();
  };

  const gradeColor = currentGradeLevel ? `${GRADE_COLORS[currentGradeLevel]}` : '#e3e3e3';

  const handleGradeChange = ({ value }) => {
    setValue('grade_level', value);
    setCurrentGradeLevel(value);
  };

  const handleNameChange = ({ target: { value } }) => {
    setValue('name', value);
    setCurrentClassroomName(value);
  };

  const handleImageSelect = useCallback((img) => setCurrentImage(img), []);

  const PreviewModal = () => (
    <Modal className={cn('preview-modal')}>
      {({ closeModal }) => (
        <div className={cn('outer-wrapper')} style={{ background: `${gradeColor}20` }}>
          <Modal.Body className={cn('preview-modal-body')}>
            <Modal.CloseButton className={cn('close')} onClick={closeModal} />
            <div className={cn('preview-wrapper')}>
              <Text type="body-big">Preview</Text>
              <div className={cn('preview-card')}>
                <div
                  className={cn('top-wrapper')}
                  style={{
                    backgroundImage: currentImage && `url(${buildUrl(currentImage.image_url)}`,
                    backgroundColor: gradeColor,
                    color: !currentGradeLevel && '#535759',
                  }}
                >
                  <Text type="h11" className="mb-5">
                    {GRADE_TITLES[currentGradeLevel] || '[Grade]'}
                  </Text>
                  <Text type="h7">{currentClassroomName || '[Classroom Name]'}</Text>
                </div>
                <div className={cn('bottom-wrapper')}>
                  <UserAvatar className={cn('avatar')} size="small" url={userData.avatar_url} />
                  <div className={cn('bar', 'mb-10')} style={{ width: 125, height: 16 }} />
                  <div className={cn('bar', 'mb-10')} style={{ width: 205, height: 11 }} />
                  <div className={cn('pill')}>
                    <YouAreHereBaseIcon className={cn('orange-bar')} />
                    <div className={cn('bar')} style={{ width: 172, height: 16 }} />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      )}
    </Modal>
  );

  return (
    <>
      <DisplayOnMobile>
        <SlideInWrapper horizontal="right" shouldAnimate>
          <MobileSubcomponentWrapper
            headingTitle={!isCoverImageMode ? `Set Up ${currentClassroomName}` : `${currentClassroomName}`}
            onReturnClick={() => goBack()}
          >
            <Text bold type="h6" style={{ display: 'inline' }}>{`0${currentStep}`}</Text>
            <Text type="h6" style={{ display: 'inline' }}>
              &nbsp;/&nbsp;02
            </Text>
            <div className={cn('heading', 'mb-15')}>
              <Text type="h3">Classroom Setup</Text>
              <Button size="xs" label="Preview" variant="black" onClick={() => setModal(<PreviewModal />)} />
            </div>
            <form className={cn('mobile-form')} onSubmit={handleSubmit(handleFormSubmit)}>
              <div className={cn('mobile-step1', { isHidden: currentStep === 2 })}>
                <Input
                  label="Classroom Name"
                  placeholder="Ex. The Bumblebees"
                  name="name"
                  value={currentClassroomName}
                  onChange={handleNameChange}
                  inputRef={register({ required: true })}
                  errors={errors}
                />
                <SelectBox
                  options={selectGradeTitles}
                  label="Grade Level"
                  placeholder="Choose a grade level"
                  name="grade_level"
                  errors={errors}
                  isDisabled={started}
                  value={currentGradeLevel ? { label: GRADE_TITLES[currentGradeLevel], value: currentGradeLevel } : ''}
                  onChange={handleGradeChange}
                  hookFormProps={{
                    register,
                    setValue,
                    clearErrors,
                  }}
                />
              </div>
              {currentStep === 2 && <ClassroomCoverPickerMobile onSelect={handleImageSelect} />}
              <div className={cn('buttons-wrapper')}>
                {currentStep === 2 && (
                  <Button size="md" label="Back" variant="outlined" onClick={() => setCurrentStep(1)} />
                )}
                <Button
                  className={`${currentStep === 1 && 'ml-auto'}`}
                  size="md"
                  label={currentStep === 1 ? 'Next Step' : 'Finish Setup'}
                  isSubmit
                />
              </div>
            </form>
          </MobileSubcomponentWrapper>
        </SlideInWrapper>
      </DisplayOnMobile>
      <DisplayOnDesktop>
        <Div100vh>
          <form style={{ height: '100%' }} onSubmit={handleSubmit(handleFormSubmit)}>
            <Modal className={cn('modal')}>
              {() => (
                <>
                  <Modal.Header>
                    <Text type="h3">
                      {!isCoverImageMode && 'Set Up '}
                      {classroomName}
                    </Text>
                    <Modal.CloseButton onClick={() => goBack()} />
                  </Modal.Header>
                  <Modal.Body className={cn('modal-body')}>
                    <div className={cn('body-wrapper', 'p-10', { isHidden: currentStep !== 1 })}>
                      <Text className="mb-5">{`Step ${currentStep}/2`}</Text>
                      <Text className="mb-15" type="h3">
                        Classroom Setup
                      </Text>
                      <Input
                        label="Classroom Name"
                        placeholder="Ex. The Bumblebees"
                        name="name"
                        value={currentClassroomName}
                        onChange={handleNameChange}
                        inputRef={register({ required: true })}
                        errors={errors}
                      />
                      <SelectBox
                        options={selectGradeTitles}
                        label="Grade Level"
                        placeholder="Choose a grade level"
                        name="grade_level"
                        errors={errors}
                        isDisabled={started}
                        value={
                          currentGradeLevel ? { label: GRADE_TITLES[currentGradeLevel], value: currentGradeLevel } : ''
                        }
                        onChange={handleGradeChange}
                        hookFormProps={{
                          register,
                          setValue,
                          clearErrors,
                        }}
                      />
                    </div>
                    {currentStep === 2 && (
                      <>
                        <div className={cn('title-wrapper')}>
                          {!isCoverImageMode && <Text className="mb-5">{`Step ${currentStep}/2`}</Text>}
                          <Text className="mb-15" type="h3">
                            Choose classroom cover art
                          </Text>
                        </div>
                        <div className={cn('picker-wrapper')}>
                          <ClassroomCoverPicker onSelect={handleImageSelect} />
                        </div>
                      </>
                    )}
                    <div className={cn('preview')} style={{ background: `${gradeColor}20` }}>
                      <Text className="mb-60" type="body-big">
                        Preview
                      </Text>
                      <div className={cn('preview-card')}>
                        <div
                          className={cn('top-wrapper')}
                          style={{
                            backgroundImage: currentImage && `url(${buildUrl(currentImage.image_url)}`,
                            backgroundColor: gradeColor,
                            color: !currentGradeLevel && '#535759',
                          }}
                        >
                          <Text type="h11" className="mb-5">
                            {GRADE_TITLES[currentGradeLevel] || '[Grade]'}
                          </Text>
                          <Text type="h7">{currentClassroomName || '[Classroom Name]'}</Text>
                        </div>
                        <div className={cn('bottom-wrapper')}>
                          <UserAvatar className={cn('avatar')} size="small" url={userData.avatar_url} />
                          <div className={cn('bar', 'mb-10')} style={{ width: 125, height: 16 }} />
                          <div className={cn('bar', 'mb-10')} style={{ width: 205, height: 11 }} />
                          <div className={cn('pill')}>
                            <YouAreHereBaseIcon className={cn('orange-bar')} />
                            <div className={cn('bar')} style={{ width: 172, height: 16 }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className={cn('footer')}>
                    <div className={cn('button-wrapper')}>
                      {!isCoverImageMode && (
                        <Button
                          variant="black"
                          className={cn('submit-button')}
                          size="md"
                          label="Back"
                          onClick={() => (currentStep === 1 ? goBack() : setCurrentStep(1))}
                        />
                      )}
                      {currentStep === 1 && (
                        <Button className={cn('submit-button')} size="md" label="Continue" isSubmit />
                      )}
                      {currentStep === 2 && (
                        <Button
                          className={cn('submit-button')}
                          size="md"
                          label={`${isCoverImageMode ? 'Continue' : 'Finish Setup'}`}
                          isSubmit
                        />
                      )}
                    </div>
                  </Modal.Footer>
                </>
              )}
            </Modal>
          </form>
        </Div100vh>
      </DisplayOnDesktop>
    </>
  );
};

export default AddClassroomModal;
