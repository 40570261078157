import React, { useState } from 'react';
import T from 'prop-types';
// components
import Text from 'components/Text';
import Button from 'components/Button';
import DayPicker from 'react-day-picker';
// utils
import { isMobileOnly } from 'react-device-detect';
import { DateTime } from 'luxon';
// STYLES AND UI
import 'react-day-picker/lib/style.css';
import './DayPicker.scss';
import classnames from 'classnames/bind';

const cn = classnames.bind();

function getWeekDays(weekStart) {
  const days = [weekStart];
  for (let i = 1; i < 5; i += 1) {
    days.push(DateTime.fromJSDate(weekStart).plus({ days: i }).toJSDate());
  }
  return days;
}

function getWeekRange(date) {
  const from = DateTime.fromJSDate(date).startOf('week').toJSDate();
  return {
    from,
    to: DateTime.fromJSDate(from).plus({ days: 4 }).toJSDate(),
  };
}

const DayPickerComponent = React.forwardRef(({ handleCancelClick, handleAcceptClick, className, currentDate }, ref) => {
  const initialSelectRange = currentDate ? getWeekDays(DateTime.fromISO(currentDate).toJSDate()) : [];

  const [hoverRange, setHoverRange] = useState(undefined);
  const [selectedDays, setSelectedDays] = useState(initialSelectRange);

  const handleDayChange = (date) => {
    if ([6, 7].includes(DateTime.fromJSDate(date).weekday)) return;
    setSelectedDays(getWeekDays(getWeekRange(date).from));
  };

  const handleDayEnter = (date) => {
    if ([6, 7].includes(DateTime.fromJSDate(date).weekday)) return;
    setHoverRange(getWeekRange(date));
  };

  const handleDayLeave = () => {
    setHoverRange(undefined);
  };

  const handleWeekClick = (days) => {
    setSelectedDays(days);
  };

  const formatWeekdayLabels = ({ weekday, className, localeUtils, locale }) => {
    const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
    return (
      <div className={className} title={weekdayName}>
        {isMobileOnly ? weekdayName.slice(0, 1) : weekdayName.slice(0, 2)}
      </div>
    );
  };

  const onAcceptClick = () => handleAcceptClick(DateTime.fromJSDate(selectedDays[0]).toISODate());

  const daysAreSelected = selectedDays.length > 0;

  const modifiers = {
    hoverRange,
    selectedRange: daysAreSelected && {
      from: selectedDays[0],
      to: selectedDays[4],
    },
    hoverRangeStart: hoverRange && hoverRange.from,
    hoverRangeEnd: hoverRange && hoverRange.to,
    selectedRangeStart: daysAreSelected && selectedDays[0],
    selectedRangeEnd: daysAreSelected && selectedDays[4],
  };

  return (
    <div className={cn('picker-content-wrapper', className)} ref={ref}>
      <div className="action-section">
        {isMobileOnly && <div className="exit-button" aria-hidden onClick={handleCancelClick} />}
        <div className="content-left">
          <span>Start date</span>
          {isMobileOnly && daysAreSelected && (
            <Text className="start-date-year">{DateTime.fromJSDate(selectedDays[0]).toFormat('yyyy')}</Text>
          )}
          {isMobileOnly ? (
            <Text type="h2" className="start-date-header">
              {daysAreSelected && `Mon, ${DateTime.fromJSDate(selectedDays[0]).toFormat('LLL dd')}`}
            </Text>
          ) : (
            <Text type="h4" className="start-date-header">
              {daysAreSelected && `Mon, ${DateTime.fromJSDate(selectedDays[0]).toFormat('LLL dd yyyy')}`}
            </Text>
          )}
        </div>
        <div className="content-right">
          {isMobileOnly ? null : (
            <Button
              id="accept-button"
              type="regular"
              size="md"
              variant="filled"
              label="Cancel"
              onClick={handleCancelClick}
            />
          )}
          <Button type="regular" size="md" label="OK" onClick={onAcceptClick} />
        </div>
      </div>
      <div className="day-picker">
        <DayPicker
          selectedDays={selectedDays}
          showOutsideDays={false}
          modifiers={modifiers}
          onDayClick={handleDayChange}
          onDayMouseEnter={handleDayEnter}
          onDayMouseLeave={handleDayLeave}
          onWeekClick={handleWeekClick}
          numberOfMonths={isMobileOnly ? 1 : 2}
          disabledDays={[{ daysOfWeek: [0, 6] }]}
          weekdayElement={(args) => formatWeekdayLabels(args)}
          month={currentDate ? DateTime.fromISO(currentDate).toJSDate() : DateTime.local().toJSDate()}
        />
      </div>
    </div>
  );
});

DayPickerComponent.propTypes = {
  handleCancelClick: T.func.isRequired,
  handleAcceptClick: T.func.isRequired,
};

export default DayPickerComponent;
