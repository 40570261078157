import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
// utils
import { useDispatch, useSelector } from 'react-redux';
import {
  getClassroomUnit,
  getClassroomWeek,
  markLearningExpFinished,
  markLessonPlanCompleted,
  markWeekCompleted,
} from 'store/classrooms';
import { GRADE_COLORS, GRADE_TITLES } from 'constants/classrooms';
import { buildUrl, clearHtml, getWeekOrderNumber, isEdgeWeek } from 'utils';
import { TITLE_OPTIONS_LABELS } from 'constants/user';
import { get, isEmpty, orderBy } from 'lodash';
import Printables from 'pages/Week/Printables';
import {
  eventCategory,
  eventAction,
  eventLabel,
  USER_ROLE,
  PROGRAM_NAME,
  UNIT_NUMBER,
  UNIT_TITLE,
  WEEK_NAME,
  gaRoleLabels,
  customDimensions,
} from 'constants/googleAnalytics';
import { GAEmitEvent } from 'utils/googleAnalytics';
// hoc
import withTabSwitcher from 'hoc/withTabSwitcher/tabSwtichTMP';
// containers
import FadeInWrapper from 'containers/FadeInWrapper/FadeInWrapper';
// icons
import { ReactComponent as CheckIcon } from 'icons/check.svg';
import { ReactComponent as Circle } from 'icons/circle.svg';
import { ReactComponent as Chevron } from 'icons/chevron.svg';
import { ReactComponent as HeartIcon } from 'icons/heart.svg';
import { ReactComponent as ExclamationIcon } from 'icons/exclamation.svg';
import { ReactComponent as WarnIcon } from 'icons/warning-white.svg';
// components
import Text from 'components/Text';
import Link from 'components/Link';
import Table from 'components/Table';
import RequiredBadge from 'components/Badges/RequiredBadge';
import CheckButton from 'components/CheckButton';
import YouAreHereBadge from 'components/Badges/YouAreHereBadge';
import Button from 'components/Button/Button';
import TruncateHint from 'components/TruncateHint/TruncateHint';
// styles
import classnames from 'classnames/bind';
import Tooltip from 'components/Tooltip';
import { isMobile } from 'react-device-detect';
import DraftBadge from 'components/Badges/DraftBadge';
import useOnClickOutside from 'hooks/useClickOutside';
import { buildMixedName } from '../../Classrooms/helpers';
import { formatDate } from '../helpers';
import styles from './BrowserView.module.scss';

const cn = classnames.bind(styles);

const checkRequiredActivities = (id, weeks) => {
  if (!weeks[id]) return false;
  return weeks[id].activities && weeks[id].activities.find(({ finished, required }) => required && !finished);
};

const checkRequiredLearningExps = (id, weeks) => {
  if (!weeks[id]) return false;
  return (
    weeks[id].learning_experiences &&
    weeks[id].learning_experiences.find(({ finished, required }) => required && !finished)
  );
};

const renderValue = (value, classroom) => (
  <div className={cn('select-value')}>
    <Text type="h2" bold style={{ color: GRADE_COLORS[classroom.grade_level] }}>
      {`Unit ${value.value ? value.value.order_number : ''}: `}
      {value.label}
    </Text>
    <Text className="mt-5" type="h6">
      {get(value, 'value.units[0].description', '')}
    </Text>
  </div>
);

const renderOption = (option, idx) => (
  <Text>
    {`Unit ${get(option, 'value.order_number')}: `}
    {option.label}
  </Text>
);

export const UnitSelect = ({ options, value = {}, classroom = {}, renderValue, className, onSelect, renderOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setIsOpen(false), { isOpen });

  const handleValueClick = (option, isCurrent) => () => {
    if (isCurrent) return;
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <div className={cn('select-relative-wrapper')} ref={dropdownRef}>
      <div className={cn('select', className)} aria-hidden onClick={handleClick}>
        {renderValue(value, classroom)}
        <div className={cn('dropdown-button')}>
          <Circle />
          <div className={cn('chevron-wrapper')}>
            <Chevron className={cn('chevron', { rotated: isOpen })} />
          </div>
        </div>
      </div>
      {isOpen && (
        <FadeInWrapper>
          <div className={cn('options-wrapper')}>
            {options.map((option, idx) => {
              const isCurrent = value && value.value.id === option.value.id;
              return (
                <div
                  key={`option-${idx}`}
                  aria-hidden
                  className={cn('option')}
                  onClick={handleValueClick(option, isCurrent)}
                >
                  {renderOption(option, idx)}
                  {isCurrent && <CheckIcon className={cn('check', 'ml-10')} />}
                </div>
              );
            })}
          </div>
        </FadeInWrapper>
      )}
    </div>
  );
};

const GettingReady = ({ weekId, isPreview }) => {
  const {
    params: { classroomId, unitId },
  } = useRouteMatch();

  const {
    push,
    location: { pathname, search },
  } = useHistory();
  const dispatch = useDispatch();
  const { week, unit } = useSelector(({ classrooms: { classrooms } }) => {
    const classroom = classrooms.find(({ id }) => +classroomId === id);
    return {
      week: get(classroom, `unit_progress[${unitId}].week_progress[${weekId}]`, {}),
      unit: get(classroom, `unit_progress[${unitId}]`, {}),
    };
  });
  const { role } = useSelector(({ auth }) => auth.user);
  const { name: programName } = useSelector(({ program }) => program);

  const pageSize = 5;
  const pagesAmount = week.activities ? Math.ceil(week.activities.length / pageSize) : 0;
  const [currentPage, setCurrentPage] = useState(0);
  const paginatedData = week.activities
    ? week.activities.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
    : [];
  const nextPage = () => setCurrentPage(Math.abs((currentPage + 1) % pagesAmount));
  const prevPage = () => setCurrentPage(Math.abs((currentPage - 1) % pagesAmount));

  const renderLessonPlanName = (props, className, width) => (
    <div className={cn('lesson-plan-col')} style={{ minWidth: width, flexBasis: width }}>
      <CheckButton
        className={cn('check')}
        aria-hidden
        isPreview={isPreview}
        tooltip={isPreview ? 'Preview Only' : props.finished ? 'Mark Incomplete' : 'Complete Task'}
        onChange={(finished) => {
          dispatch(markLessonPlanCompleted(classroomId, props.id, finished, { uPId: unitId, wPId: weekId }));
          finished &&
            props.required &&
            GAEmitEvent(
              eventCategory.USER_ACTION,
              eventAction.MENU_CLICK,
              eventLabel.requiredActivity(props.name, 'Professional Prep', pathname + search),
              {
                [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                [customDimensions[PROGRAM_NAME]]: programName,
                [customDimensions[UNIT_NUMBER]]: get(unit, 'units[0].order_number', null),
                [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
                [customDimensions[WEEK_NAME]]: get(week, `weeks[0].name`, null),
              },
            );
        }}
        isChecked={props.finished}
      />
      <div className={cn('body-wrap')}>
        <Link
          to={`/classroom/${classroomId}/unit/${unitId}/week/${weekId}/lesson-plan/${props.id}`}
          onClick={() =>
            GAEmitEvent(
              eventCategory.USER_ACTION,
              eventAction.CLICK,
              eventLabel.click(
                'Professional Prep',
                `${props.name}_/classroom/${classroomId}/unit/${unitId}/week/${weekId}/lesson-plan/${props.id}`,
              ),
              {
                [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                [customDimensions[PROGRAM_NAME]]: programName,
                [customDimensions[UNIT_NUMBER]]: get(unit, 'units[0].order_number', null),
                [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
                [customDimensions[WEEK_NAME]]: get(week, `weeks[0].name`, null),
              },
            )
          }
        >
          <div className={cn('sel-wrap', { active: props.sel_competency })}>
            <Text>{props.name}</Text>
            {props.sel_competency && <HeartIcon className={cn('heart')} />}
          </div>
        </Link>
      </div>
    </div>
  );

  const renderGettingReadyButtonsCol = (props, className, width) => (
    <div className={cn(className, 'getting-ready-buttons-col')} style={{ minWidth: width, flexBasis: width }}>
      {props.required && <RequiredBadge className="mr-10" />}
      <Button
        size="xs"
        label={isPreview ? 'Preview Only' : 'View'}
        onClick={() => {
          GAEmitEvent(
            eventCategory.USER_ACTION,
            eventAction.CLICK,
            eventLabel.click(
              'Professional Prep',
              `${props.name}_/classroom/${classroomId}/unit/${unitId}/week/${weekId}/lesson-plan/${props.id}`,
            ),
            {
              [customDimensions[USER_ROLE]]: gaRoleLabels[role],
              [customDimensions[PROGRAM_NAME]]: programName,
              [customDimensions[UNIT_NUMBER]]: get(unit, 'units[0].order_number', null),
              [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
              [customDimensions[WEEK_NAME]]: get(week, `weeks[0].name`, null),
            },
          );
          push(`/classroom/${classroomId}/unit/${unitId}/week/${weekId}/lesson-plan/${props.id}`);
        }}
      />
    </div>
  );

  const renderDescriptionCol = (props, className, width) => (
    <div className={className} style={{ minWidth: width, flexBasis: width, whiteSpace: 'normal' }}>
      <TruncateHint text={props.description} lines={2} />
    </div>
  );

  const gettingReadyColumns = [
    { label: 'PD Resources', renderer: renderLessonPlanName, isBordered: false, width: 300 },
    { label: 'Descriptions', renderer: renderDescriptionCol, width: 450 },
    { label: '', renderer: renderGettingReadyButtonsCol, width: 200 },
  ];
  return (
    <>
      {paginatedData.length ? (
        <Table className="mt-20" isSmall columns={gettingReadyColumns} data={paginatedData} />
      ) : (
        <Text className={cn('empty-placeholder')} type={isMobile ? 'h5' : 'h3'}>
          There are no Lesson Plans for this Week
        </Text>
      )}
      <div className={cn('pagination-row', 'mt-10')}>
        {pagesAmount > 1 && (
          <>
            <Button type="pagination" className="mr-20" size="s" onClick={prevPage} />
            {`${currentPage + 1}/${pagesAmount}`}
            <Button type="pagination" className="ml-20" size="s" reversed onClick={nextPage} />
          </>
        )}
      </div>
    </>
  );
};

const LearningExperiences = ({ weekId, isPreview }) => {
  const {
    params: { classroomId, unitId },
  } = useRouteMatch();
  const {
    push,
    location: { pathname, search },
  } = useHistory();
  const { role } = useSelector(({ auth }) => auth.user);
  const { name: programName } = useSelector(({ program }) => program);

  const dispatch = useDispatch();
  const { week, unit } = useSelector(({ classrooms: { classrooms } }) => {
    const classroom = classrooms.find(({ id }) => +classroomId === id);
    return {
      week: get(classroom, `unit_progress[${unitId}].week_progress[${weekId}]`, {}),
      unit: get(classroom, `unit_progress[${unitId}]`, {}),
    };
  });

  const pageSize = 5;
  const orderedLearningExperiences = orderBy(week.learning_experiences, ({ order_number: ordNum }) => ordNum);
  const pagesAmount = orderedLearningExperiences ? Math.ceil(orderedLearningExperiences.length / pageSize) : 0;
  const [currentPage, setCurrentPage] = useState(0);
  const paginatedData = orderedLearningExperiences
    ? orderedLearningExperiences.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
    : [];
  const nextPage = () => setCurrentPage(Math.abs((currentPage + 1) % pagesAmount));
  const prevPage = () => setCurrentPage(Math.abs((currentPage - 1) % pagesAmount));

  const renderLearningExperienceName = (props, className, width) => (
    <div className={cn('lesson-plan-col')} style={{ minWidth: width, flexBasis: width }}>
      <CheckButton
        tooltip={isPreview ? 'Preview Only' : props.finished ? 'Mark Incomplete' : 'Complete Task'}
        isPreview={isPreview}
        className={cn('check')}
        onChange={(finished) => {
          dispatch(markLearningExpFinished(classroomId, props.id, finished, unitId, weekId));
          !props.finished &&
            props.required &&
            GAEmitEvent(
              eventCategory.USER_ACTION,
              eventAction.MENU_CLICK,
              eventLabel.requiredActivity(props.name, 'Learning Experience', pathname + search),
              {
                [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                [customDimensions[PROGRAM_NAME]]: programName,
                [customDimensions[UNIT_NUMBER]]: get(unit, 'units[0].order_number', null),
                [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
                [customDimensions[WEEK_NAME]]: get(week, `weeks[0].name`, null),
              },
            );
        }}
        isChecked={props.finished}
      />
      <div className={cn('body-wrap')}>
        <Link
          to={`/classroom/${classroomId}/unit/${unitId}/week/${weekId}/learning-experience/${props.id}`}
          onClick={() =>
            GAEmitEvent(
              eventCategory.USER_ACTION,
              eventAction.CLICK,
              eventLabel.click(
                'Learning Experience',
                `${props.name}_/classroom/${classroomId}/unit/${unitId}/week/${weekId}/learning-experience/${props.id}`,
              ),
              {
                [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                [customDimensions[PROGRAM_NAME]]: programName,
                [customDimensions[UNIT_NUMBER]]: get(unit, 'units[0].order_number', null),
                [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
                [customDimensions[WEEK_NAME]]: get(week, `weeks[0].name`, null),
              },
            )
          }
        >
          <div className={cn('sel-wrap', { active: props.sel_competency })}>
            <Text>{props.name}</Text>
            {props.sel_competency && <HeartIcon className={cn('heart')} />}
          </div>
        </Link>
      </div>
    </div>
  );

  const renderLearningExpButtonsCol = (props, className, width) => (
    <div className={cn(className, 'getting-ready-buttons-col')} style={{ minWidth: width, flexBasis: width }}>
      {props.required && <RequiredBadge className="mr-10" />}
      <Button
        size="xs"
        label={isPreview ? 'Preview Only' : 'View'}
        onClick={() => {
          GAEmitEvent(
            eventCategory.USER_ACTION,
            eventAction.CLICK,
            eventLabel.click(
              'Learning Experience',
              `${props.name}_/classroom/${classroomId}/unit/${unitId}/week/${weekId}/learning-experience/${props.id}`,
            ),
            {
              [customDimensions[USER_ROLE]]: gaRoleLabels[role],
              [customDimensions[PROGRAM_NAME]]: programName,
              [customDimensions[UNIT_NUMBER]]: get(unit, 'units[0].order_number', null),
              [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
              [customDimensions[WEEK_NAME]]: get(week, `weeks[0].name`, null),
            },
          );
          push(`/classroom/${classroomId}/unit/${unitId}/week/${weekId}/learning-experience/${props.id}`);
        }}
      />
    </div>
  );

  const renderDescriptionCol = (props, className, width) => (
    <div className={className} style={{ minWidth: width, flexBasis: width, whiteSpace: 'normal' }}>
      <TruncateHint text={clearHtml(props.description)} lines={2} />
    </div>
  );

  const learningExperiencesColumns = [
    { label: 'Focused Experiences', renderer: renderLearningExperienceName, isBordered: false, width: 300 },
    { label: 'Descriptions', renderer: renderDescriptionCol, width: 450 },
    { label: '', renderer: renderLearningExpButtonsCol, width: 150 },
  ];

  return (
    <>
      {isPreview && (
        <div className={cn('complete-alert')}>
          <WarnIcon className={cn('warn-icon')} />
          To activate these learning experiences, please complete week
        </div>
      )}
      <Table className="mt-20" isSmall columns={learningExperiencesColumns} data={paginatedData} />
      <div className={cn('pagination-row', 'mt-10')}>
        {pagesAmount > 1 && (
          <>
            <Button type="pagination" className="mr-20" size="s" onClick={prevPage} />
            {`${currentPage + 1}/${pagesAmount}`}
            <Button type="pagination" className="ml-20" size="s" reversed onClick={nextPage} />
          </>
        )}
      </div>
    </>
  );
};

const ExpandInner = withTabSwitcher(({ isExpanded, weekId }) => {
  const dispatch = useDispatch();
  const {
    params: { classroomId, unitId },
  } = useRouteMatch();

  useEffect(() => {
    if (isExpanded) dispatch(getClassroomWeek(classroomId, unitId, weekId));
  }, [dispatch, classroomId, unitId, weekId, isExpanded]);
  return null;
});

const PrintablesSection = ({ weekId }) => {
  const {
    params: { classroomId, unitId },
  } = useRouteMatch();

  const { week, unit } = useSelector(({ classrooms: { classrooms } }) => {
    const classroom = classrooms.find(({ id }) => +classroomId === id);
    return {
      week: get(classroom, `unit_progress[${unitId}].week_progress[${weekId}]`, {}),
      unit: get(classroom, `unit_progress[${unitId}]`, {}),
    };
  });
  return <Printables week={week} unit={unit} />;
};

const ExpandedWeek = ({ isExpanded, item, color, isStarted, classroom }) => {
  const weekRef = useRef();
  const {
    params: { unitId },
  } = useRouteMatch();

  const { week_progresses: weekProgresses, order_number: currentUnitOrder } = get(
    classroom,
    `unit_progress[${unitId}]`,
    {},
  );
  const isCurrentUnit = get(classroom, 'current_unit.id') === +unitId;
  const weekProgRev = [...weekProgresses].reverse();
  const lastUnfinished =
    weekProgRev.find(
      ({ finished }, idx) =>
        (!finished && weekProgRev[idx + 1] && weekProgRev[idx + 1].finished) || !weekProgresses[idx + 1],
    ) || {};
  const isCurrent = isCurrentUnit && item.id === lastUnfinished.id;

  const currentUnitOrderNumber = get(classroom, 'current_unit.order_number');

  const isFuture = isCurrentUnit
    ? lastUnfinished.order_number < item.order_number
    : currentUnitOrderNumber < currentUnitOrder;

  const expandTabOptons = [
    { title: 'Professional Prep', component: <GettingReady weekId={item.id} isPreview={isFuture} /> },
    { title: 'Learning experiences', component: <LearningExperiences weekId={item.id} isPreview={isFuture} /> },
    { title: 'Printables', component: PrintablesSection },
  ];

  useEffect(() => {
    let t;
    if (weekRef.current && isStarted && isCurrent) {
      t = setTimeout(() => {
        window.scrollTo({ top: weekRef.current.offsetTop + weekRef.current.offsetHeight / 2, behavior: 'smooth' });
      }, 800);
    }
    return () => {
      clearTimeout(t);
    };
  }, []);

  return (
    <div className={cn('expanded-week-wrapper')} ref={weekRef}>
      <div className={cn('expanded-header', 'mb-10')} style={{ backgroundColor: color }}>
        <div
          style={{
            backgroundImage: item.weeks[0] && `url(${buildUrl(item.weeks[0].image_url)})`,
          }}
          className={cn('header-image')}
        />
        <div className={cn('expanded-text-wrapper')}>
          <Text type="h8">{item.weeks && item.weeks[0].description}</Text>
        </div>
      </div>
      <ExpandInner weekId={item.id} tabOptions={expandTabOptons} isExpanded={isExpanded} />
    </div>
  );
};

const BrowserView = () => {
  const dispatch = useDispatch();
  const {
    params: { classroomId, unitId },
  } = useRouteMatch();

  const {
    push,
    location: { pathname, search },
  } = useHistory();

  const {
    user: { title, first_name: firstName, role },
  } = useSelector(({ auth }) => auth);
  const { classroom, isLoading, hasErrors } = useSelector(
    ({ classrooms: { classrooms, isLoading: _isLoading, hasErrors: _hasErrors } }) => ({
      classroom: classrooms.find(({ id }) => +classroomId === id),
      isLoading: _isLoading,
      hasErrors: _hasErrors,
    }),
  );
  const { week_progresses: weekProgresses, units, order_number: currentUnitOrder } = get(
    classroom,
    `unit_progress.${unitId}`,
    {},
  );
  const { week } = useSelector(({ classrooms: { classrooms } }) => {
    const classroom = classrooms.find(({ id }) => +classroomId === id);
    return {
      week: get(classroom, `unit_progress[${unitId}].week_progress`, {}),
    };
  });
  const { name: programName } = useSelector(({ program }) => program);

  const isDraftUnit = get(units, '[0].draft', false);

  useEffect(() => {
    if (isEmpty(weekProgresses) && !isLoading && !hasErrors) dispatch(getClassroomUnit(classroomId, unitId));
  }, [dispatch, classroomId, unitId, weekProgresses, isLoading, hasErrors]);
  const selectOptions = classroom
    ? classroom.unit_progresses.map((unit) => ({ label: unit.units[0].name, value: unit }))
    : [{}];
  const color = classroom && GRADE_COLORS[classroom.grade_level];

  const renderWeeklyLessonsColumn = (props, className, width) => {
    const isCurrentUnit = get(classroom, 'current_unit.id') === +unitId;
    const currentUnitOrderNumber = get(classroom, 'current_unit.order_number');
    const weekProgRev = [...weekProgresses].reverse();
    const lastUnfinished =
      weekProgRev.find(
        ({ finished }, idx) =>
          (!finished && weekProgRev[idx + 1] && weekProgRev[idx + 1].finished) || !weekProgresses[idx + 1],
      ) || {};

    const isCurrent = isCurrentUnit && props.id === lastUnfinished.id;
    const isFuture = isCurrentUnit
      ? lastUnfinished.order_number < props.order_number
      : currentUnitOrderNumber < currentUnitOrder;
    const isPast = isCurrentUnit ? !isCurrent && !isFuture : currentUnitOrder < currentUnitOrderNumber;

    return (
      <div className={cn('weekly-lessons-col')} style={{ minWidth: width, flexBasis: width }}>
        <CheckButton
          className={cn('check')}
          isPreview={isFuture && !classroom.all_finished}
          isChecked={props.finished}
          tooltip={
            isFuture && !classroom.all_finished
              ? 'Preview Only'
              : props.finished
              ? 'Mark Week Incomplete'
              : 'Mark Week Completed'
          }
          aria-hidden
          onChange={(finished) => {
            !props.finished &&
              GAEmitEvent(
                eventCategory.USER_ACTION,
                eventAction.MENU_CLICK,
                eventLabel.markWeekCompleted('Mark Week Completed', pathname + search),
                {
                  [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                  [customDimensions[PROGRAM_NAME]]: programName,
                  [customDimensions[UNIT_NUMBER]]: get(units, '[0].order_number', null),
                  [customDimensions[UNIT_TITLE]]: get(units, '[0].name', null),
                  [customDimensions[WEEK_NAME]]: get(props, `weeks[0].name`, null),
                },
              );
            return dispatch(markWeekCompleted(classroomId, unitId, props.id, finished));
          }}
        />
        <div className={cn('body-wrap')}>
          {isCurrent && !classroom.all_finished && <YouAreHereBadge className="mr-5" />}
          <Link className="flex" to={`/classroom/${classroomId}/unit/${unitId}/week/${props.id}`}>
            <Text type="h6" bold={isCurrent && !classroom.all_finished}>
              {props.weeks[0] &&
                `${getWeekOrderNumber(props.weeks[0].order_number, true, true)} ${
                  !isEdgeWeek(props.weeks[0].order_number) ? props.weeks[0].name : ''
                }`}
            </Text>
            {(week[props.id] && week[props.id].activities
              ? checkRequiredActivities(props.id, week) || checkRequiredLearningExps(props.id, week)
              : props.unfinished_required_activities || props.unfinished_required_learning_experiences) &&
              isPast && (
                <Tooltip className={cn('tooltip')} text="Week contains unfinished required tasks">
                  <ExclamationIcon className={cn('exclamation-icon')} />
                </Tooltip>
              )}
            {get(props, 'weeks[0].draft', false) && <DraftBadge />}
          </Link>
        </div>
      </div>
    );
  };

  const renderTargetSELColumn = (props, className, width) => (
    <div className={className} style={{ minWidth: width, flexBasis: width }}>
      {buildMixedName(props.weeks, 'sel_competency') || '--'}
    </div>
  );

  const renderDatesColumn = (props, className, width) => (
    <div className={className} style={{ minWidth: width, flexBasis: width }}>
      {`${formatDate(props.start_date)} - ${formatDate(props.end_date)}`}
      {}
    </div>
  );

  const renderButtons = (props, className, width, { setIsExpanded, isExpanded }) => {
    const isCurrentUnit = get(classroom, 'current_unit.id') === +unitId;
    const currentUnitOrderNumber = get(classroom, 'current_unit.order_number');
    const lastUnfinished = weekProgresses.find(({ finished }) => !finished) || {};

    const isCurrent = isCurrentUnit && props.id === lastUnfinished.id;
    const isFuture = isCurrentUnit
      ? lastUnfinished.order_number < props.order_number
      : currentUnitOrderNumber < currentUnitOrder;
    const isPast = isCurrentUnit ? !isCurrent && !isFuture : currentUnitOrder < currentUnitOrderNumber;

    useEffect(() => {
      let isMounted = true;
      if (isCurrent && !isExpanded && isMounted) {
        setIsExpanded(true);
      }

      return () => {
        isMounted = false;
      };
    }, [setIsExpanded, isCurrent]);

    const handleClick = () => {
      const currentEventLabel = isCurrent
        ? 'Launch Week'
        : isFuture && !isCurrent
        ? 'Preview Week'
        : isPast
        ? 'Relaunch Week'
        : '';
      GAEmitEvent(
        eventCategory.USER_ACTION,
        eventAction.MENU_CLICK,
        eventLabel.launchWeek(currentEventLabel, pathname + search),
        {
          [customDimensions[USER_ROLE]]: gaRoleLabels[role],
          [customDimensions[PROGRAM_NAME]]: programName,
          [customDimensions[UNIT_NUMBER]]: get(units, '[0].order_number', null),
          [customDimensions[UNIT_TITLE]]: get(units, '[0].name', null),
          [customDimensions[WEEK_NAME]]: get(props, `weeks[0].name`, null),
        },
      );
      return push(`/classroom/${classroomId}/unit/${unitId}/week/${props.id}`);
    };
    return (
      <div className={cn(className, 'buttons-col')} style={{ minWidth: width, flexBasis: width }}>
        {isCurrent && <Button label="Launch Week" onClick={handleClick} size="s" />}
        {isFuture && <Button size="s" onClick={handleClick} variant="filled" label="Preview Week" />}
        {isPast && <Button size="s" variant="filled" onClick={handleClick} label="Relaunch Week" />}
        <Chevron
          onClick={() => setIsExpanded(!isExpanded)}
          className={cn('ml-10', 'chevron-expand', { rotated: isExpanded })}
        />
      </div>
    );
  };

  const columns = [
    { label: 'Weeks', renderer: renderWeeklyLessonsColumn, isBordered: false, width: 450 },
    { label: 'SEL Competencies', renderer: renderTargetSELColumn },
    { label: '', renderer: renderButtons, width: 180 },
  ];

  return (
    <div className={cn('wrapper')}>
      <div className={cn('nav-path')}>
        <Link to="/classrooms" underlined>
          <Text>My Classrooms</Text>
        </Link>
        &nbsp;&rsaquo;&nbsp;
        {classroom && classroom.name}
      </div>
      <div className={cn('header')} style={{ background: classroom && GRADE_COLORS[classroom.grade_level] }}>
        <div className={cn('header-content')}>
          <div className={cn('header-left-col')}>
            <div className={cn('header-name')}>
              <Text type="h8">
                {title && TITLE_OPTIONS_LABELS[title.toLowerCase()]}
                &nbsp;
                {firstName}
                &apos;s&nbsp;
                {classroom && GRADE_TITLES[classroom.grade_level]}
                &nbsp;
                {classroom && `| ${classroom.name}`}
              </Text>
            </div>
            <div className={cn('unit-select-wrapper')}>
              <UnitSelect
                renderValue={renderValue}
                renderOption={renderOption}
                onSelect={(option) => push(`/classroom/${classroom.id}/unit/${option.value.id}`)}
                value={selectOptions.find(({ value }) => value && value.id === +unitId) || ''}
                options={selectOptions}
                classroom={classroom}
              />
            </div>
          </div>
          {classroom && classroom.cover_image && (
            <img className={cn('cover-image')} src={buildUrl(classroom.cover_image.image_url)} alt="img" />
          )}
        </div>
        {isDraftUnit && <DraftBadge size="lg" className={cn('unit-draft-badge')} />}
      </div>
      <div className={cn('content-wrapper')}>
        <Table
          columns={columns}
          data={weekProgresses || []}
          renderExpandable={(item, isExpanded) => (
            <ExpandedWeek
              color={color}
              item={item}
              isStarted={classroom.started}
              isExpanded={isExpanded}
              classroom={classroom}
              weekProgresses={weekProgresses}
            />
          )}
        />
      </div>
    </div>
  );
};

export default BrowserView;
