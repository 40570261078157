import React from 'react';
// UI and styles
import { ReactComponent as WarningIcon } from 'icons/warning.svg';
import Button from 'components/Button';
import Text from 'components/Text';
import Modal from 'components/Modal';
import classnames from 'classnames/bind';
import styles from './WarningModal.module.scss';

const cn = classnames.bind(styles);

const WarningModal = ({ modalLanguage, onAccept }) => {
  const { headerText, bodyText, buttonText } = modalLanguage;

  return (
    <Modal className={cn('modal')}>
      {({ closeModal }) => {
        const handleAcceptClick = () => {
          onAccept();
          closeModal();
        };
        return (
          <>
            <Modal.Header className={cn('modal--header')}>
              <div className={cn('content-left')}>
                <WarningIcon className={cn('content-left--icon')} />
                <Text className={cn('content-left--text', 'ml-10')} type="h5">
                  {headerText}
                </Text>
              </div>
              <Modal.CloseButton onClick={closeModal} />
            </Modal.Header>
            <Modal.Body className={cn('modal--body', 'p-40')}>
              <div className={cn('text-wrapper')}>{bodyText}</div>
              <div className={cn('buttons-wrapper')}>
                <Button
                  className={cn('buttons-wrapper--accept')}
                  size="md"
                  variant="filled"
                  label={buttonText}
                  onClick={handleAcceptClick}
                />
                <Button className={cn('buttons-wrapper--cancel')} size="md" label="Cancel" onClick={closeModal} />
              </div>
            </Modal.Body>
          </>
        );
      }}
    </Modal>
  );
};

export default WarningModal;
