import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// utils
import { useSpring, animated } from 'react-spring';

/** NOTE
 * Due to css transform property blocking any fixed position of
 * children, 'deactivate' flag is being introduced in order to
 * flush any transform property onRest (animation completed)
 */

const SlideInWrapper = ({
  horizontal = null,
  vertical = null,
  shouldAnimate = false,
  config = {},
  allowReset = false,
  children,
}) => {
  let translateXPos = horizontal === 'left' ? -100 : horizontal === 'right' ? 100 : 0;
  let translateYPos = vertical === 'top' ? -100 : vertical === 'bottom' ? 100 : 0;

  const [deactivate, set] = useState(false);

  useEffect(() => set(false), [allowReset]);

  let slideStyles = useSpring({
    from: { transform: `translate(${translateXPos}%, ${translateYPos}%)` },
    to: { transform: 'translate(0%, 0%)' },
    reset: allowReset,
    config: {
      ...config,
    },
    onRest: () => set(true),
  });
  if (deactivate) slideStyles = {};

  if (!shouldAnimate) return children;

  return <animated.div style={slideStyles}>{children}</animated.div>;
};

SlideInWrapper.propTypes = {
  horizontal: T.oneOf(['right', 'left']),
  vertical: T.oneOf(['top', 'bottom']),
  config: T.object,
  allowReset: T.bool,
  children: T.any,
};

export default SlideInWrapper;
