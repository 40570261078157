import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// utils
import { testForPhrase } from 'utils/string';
import { updateSchoolId } from 'store/school';
import { updateProgramId } from 'store/program';
import { classroomsLoaded } from 'store/classrooms';
import { mapCurrentUserClassrooms } from 'store/auth';
import { useSelector, useDispatch } from 'react-redux';

const withClassroomContentIdChange = (WrappedComponent) => () => {
  const { pathname } = useLocation();
  const { classroomId } = useParams();
  const { programs = [], schools = [], isLoading, hasErrors, ...restUser } = useSelector(({ auth }) => auth.user);
  const { id: schoolId } = useSelector(({ school }) => school);

  const dispatch = useDispatch();

  const getInstancesFromCid = (classroomId) => {
    const _school = schools.find(({ classrooms = [] }) => classrooms.some(({ id }) => +id === +classroomId));
    if (!_school) return [null, null];

    const _program = programs.find(({ schools = [] }) => schools.some(({ id }) => +id === +_school.id));

    return [_school, _program];
  };

  useEffect(() => {
    if (!testForPhrase(/classroom\/\d*/, pathname)) return;

    if (isLoading || hasErrors) return;

    const [school, program] = getInstancesFromCid(classroomId, schools, programs);
    if (!school || +school.id === +schoolId) return;

    dispatch(updateProgramId(program));
    dispatch(updateSchoolId(school));
    dispatch(classroomsLoaded(mapCurrentUserClassrooms({ programs, schools, isLoading, hasErrors, ...restUser })));
  }, [dispatch, classroomId, isLoading, hasErrors, pathname]);

  return (
    <>
      <WrappedComponent />
    </>
  );
};

export default withClassroomContentIdChange;
