import _ from 'lodash';

/** function that translates any wierd Ruby snake_case or other ugly ones into to the best camelCase
 * @param {object} obj an object to translate to camelCase
 * @returns {object} object with original objectValue, but with camelCased keys
 */

export function translateParams(obj) {
  let translated = {};
  for (let k in obj) {
    let val = obj[k];
    if (typeof val === 'object' && !Array.isArray(val) && !!val && Object.keys(val).length) {
      val = translateParams(val);
    }
    translated[_.camelCase(k)] = val;
  }
  return translated;
}

export const getDefaultValue = (options = [], value = '', key = 'value') =>
  options.filter((el) => el[key] === value.toLowerCase())[0];
