import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
// styles
import Div100vh from 'react-div-100vh';
import { useDispatch, useSelector } from 'react-redux';
import { clearCompleted } from 'store/common';
import Fairy from './fairy-muppet.png';
import styles from './ItIsEaster.module.scss';

const cn = classnames.bind(styles);

const ItIsEaster = () => {
  const [isShown, setIsShown] = useState(false);
  const dispatch = useDispatch();

  const { completedCount } = useSelector(({ classrooms }) => classrooms);
  useEffect(() => {
    let isMounted = true;
    let d;

    if (completedCount !== 0 && completedCount % 30 === 0) {
      if (isMounted) setIsShown(true);
      d = setTimeout(() => {
        if (isMounted) setIsShown(false);
        dispatch(clearCompleted());
      }, 2500);
    }

    return () => {
      isMounted = false;
      clearTimeout(d);
    };
  }, [completedCount, dispatch]);

  if (!isShown) return null;
  return (
    <Div100vh className={cn('easter-wrapper')}>
      <img src={Fairy} alt="fairy" className={cn('fairy')} />
    </Div100vh>
  );
};

export default ItIsEaster;
