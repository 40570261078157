import { toast } from 'react-toastify';
import makeRequest from 'utils/api';
import { hasMajorRole } from 'utils/users';
import { setInstanceIds, cachedSchoolId } from 'utils/auth';
import { isSchoolAdmin, USER_STATUSES } from 'constants/user';
import { inviteRespondWithToasts, RESET_STORE } from './common';
import { toggleDisableClassroomProgramLoaded } from './program';

// Actions
export const SCHOOL_LOADING = 'czi/SCHOOL/LOADING';
export const SCHOOL_LOADED = 'czi/SCHOOL/LOADED';
export const SCHOOL_FAILED = 'czi/SCHOOL/FAILED';

export const INVITE_USER_LOADING = 'czi/SCHOOL/USER_INVITE/LOADING';
export const INVITE_USER_LOADED = 'czi/SCHOOL/USER_INVITE/LOADED';
export const INVITE_USER_FAILED = 'czi/SCHOOL/USER_INVITE/FAILED';

export const USER_UPDATE_LOADING = 'czi/SCHOOL/USER_UPDATE/LOADING';
export const USER_UPDATE_LOADED = 'czi/SCHOOL/USER_UPDATE/LOADING';
export const USER_UPDATE_FAILED = 'czi/SCHOOL/USER_UPDATE/LOADING';

export const DELETE_USER_LOADED = 'czi/SCHOOL/DELETE_USER/LOADED';

export const SCHOOL_UPDATE_LOADED = 'czi/SCHOOL_UPDATE/LOADED';

export const SCHOOL_ID_UPDATTE_LOADED = 'czi/SCHOOL_ID_UPDATE/LOADED';

export const DISABLE_SCHOOL_LOADED = 'czi/DISABLE_SCHOOL/LOADED';
export const ACTIVATE_SCHOOL_LOADED = 'czi/ACTIVATE_SCHOOL/LOADED';

export const DISABLE_CLASSROOM_LOADED = 'czi/DISABLE_CLASSROOM/LOADED';
export const ACTIVATE_CLASSROOM_LOADED = 'czi/ACTIVATE_CLASSROOM/LOADED';

export const CREATE_CLASSROOM_LOADED = 'czi/CREATE_CLASSROOM/LOADED';

export const REVOKE_SCHOOL_ADMIN_LOADED = 'czi/REVOKE_SCHOOL_ADMIN/LOADED';

export const RESET_SCHOOL = 'czi/RESET_SCHOOL/LOADED';

// Action creators
export const schoolLoading = () => ({ type: SCHOOL_LOADING });
export const schoolLoaded = (payload) => ({ type: SCHOOL_LOADED, payload });
export const schoolFailed = () => ({ type: SCHOOL_FAILED });

export const inviteUserLoading = () => ({ type: INVITE_USER_LOADING });
export const inviteUserLoaded = (invited, failed) => ({ type: INVITE_USER_LOADED, payload: { invited, failed } });
export const inviteUserFailed = () => ({ type: INVITE_USER_FAILED });

export const userUpdateLoaded = (payload) => ({ type: USER_UPDATE_LOADED, payload });
export const userDeleteLoaded = (payload) => ({ type: DELETE_USER_LOADED, payload });

export const schoolUpdateLoaded = (payload) => ({ type: SCHOOL_UPDATE_LOADED, payload });

export const schoolIdUpdateLoaded = (payload) => ({ type: SCHOOL_ID_UPDATTE_LOADED, payload });
export const schoolResetLoaded = () => ({ type: RESET_SCHOOL });

export const createClassroomLoaded = (payload) => ({ type: CREATE_CLASSROOM_LOADED, payload });

export const disableSchoolLoaded = (payload) => ({ type: DISABLE_SCHOOL_LOADED, payload });
export const activateSchoolLoaded = (payload) => ({ type: ACTIVATE_SCHOOL_LOADED, payload });

export const disableClassroomLoaded = (payload) => ({ type: DISABLE_CLASSROOM_LOADED, payload });
export const activateClassroomLoaded = (payload) => ({ type: ACTIVATE_CLASSROOM_LOADED, payload });

export const revokeSchoolAdminLoaded = (payload) => ({ type: REVOKE_SCHOOL_ADMIN_LOADED, payload });

// initialState
export const initialState = {
  id: cachedSchoolId(),
  users: [],
  schoolInfo: {},
  classrooms: [],
  failedToInvite: {},
  isLoading: false,
  hasErrors: false,
};

// mapper
const mapSchoolInfo = ({ users, ...schoolInfo }) => {
  const parsedUsers = users.map(({ ...usr }) => {
    const userRole = usr.roles.reduce((acc, curr) => {
      const { role, id, type } = curr;
      if (!role) return acc;
      if (+id !== +schoolInfo.id && type === 'school') return acc;
      if (acc && hasMajorRole(acc, role)) return acc;
      return role;
    }, '');

    const activeFlag = usr.roles.find(({ id, type }) => +id === +schoolInfo.id && type === 'school')?.is_active;

    return {
      ...usr,
      status: activeFlag ? USER_STATUSES[usr.invitation_status] : activeFlag === undefined ? 'active' : 'disabled',
      full_name: usr.last_name ? `${usr.first_name} ${usr.last_name}` : null,
      role: userRole,
      is_active: activeFlag === undefined ? true : !!activeFlag,
      schoolScopeRoleless: activeFlag === undefined,
    };
  });

  const parsedClassrooms = (schoolInfo.classrooms || []).map(({ user_ids = [], ...rest }) => {
    const classroomTeachers = user_ids.map((_id) => parsedUsers.find(({ id: userId }) => _id === userId));

    return { user_ids, ...rest, users: classroomTeachers.filter((a) => !!a) };
  });

  return {
    ...schoolInfo,
    classroomsAvailable: schoolInfo.classroom_limit - schoolInfo.classrooms_number,
    users: parsedUsers,
    adminUsers: parsedUsers.filter(({ role }) => isSchoolAdmin(role)),
    classrooms: parsedClassrooms,
  };
};

// utils
export const updateSchoolId = (school) => (dispatch) => {
  setInstanceIds({ 'school-id': school.id });
  dispatch(schoolIdUpdateLoaded(school));
};

export const clearSchoolId = () => (dispatch) => {
  setInstanceIds({ 'school-id': {} });
  dispatch(schoolResetLoaded());
};

// reducer
const reducer = (state = initialState, action) => {
  let newUsers;
  switch (action.type) {
    case SCHOOL_LOADING:
      return { ...state, isLoading: true, hasErrors: false };
    case SCHOOL_LOADED:
    case DISABLE_SCHOOL_LOADED:
    case ACTIVATE_SCHOOL_LOADED:
      return { ...state, ...mapSchoolInfo(action.payload), isLoading: false, hasErrors: false };
    case DELETE_USER_LOADED: {
      newUsers = state.users.filter(({ id }) => +id !== +action.payload);
      return {
        ...state,
        ...mapSchoolInfo({ ...state, users: newUsers }),
        isLoading: false,
        hasErrors: false,
      };
    }
    case INVITE_USER_LOADING:
      return { ...state, failedToInvite: {}, isLoading: true, hasErrors: false };
    case USER_UPDATE_LOADED: {
      const updatedUserIdx = state.users.findIndex(({ id }) => +id === +action.payload.id);
      newUsers = [
        ...state.users.slice(0, updatedUserIdx),
        { ...state.users[updatedUserIdx], ...action.payload },
        ...state.users.slice(updatedUserIdx + 1),
      ];
      return {
        ...state,
        ...mapSchoolInfo({ ...state, users: newUsers }),
        isLoading: false,
        hasErrors: false,
      };
    }
    case INVITE_USER_LOADED:
      const invited = action.payload.invited.map((user) => ({ ...user, classrooms: [...(user.classrooms || [])] }));
      return {
        ...state,
        ...mapSchoolInfo({ ...state, users: [...state.users, ...invited] }),
        failedToInvite: action.payload.failed,
        hasErrors: false,
        isLoading: false,
      };
    case SCHOOL_UPDATE_LOADED: {
      return { ...state, ...mapSchoolInfo(action.payload), classrooms: state.classrooms };
    }
    case SCHOOL_FAILED:
      return { ...state, hasErrors: true, isLoading: false };
    case SCHOOL_ID_UPDATTE_LOADED:
      return { ...state, ...action.payload };
    case CREATE_CLASSROOM_LOADED:
      return {
        ...state,
        ...mapSchoolInfo(state),
        classrooms: [...state.classrooms, action.payload],
        classrooms_number: state.classrooms_number + 1,
      };
    case DISABLE_CLASSROOM_LOADED:
      state.classrooms_number--;
      const { id: _classroomId } = action.payload;
      const _replaceIdx = state.classrooms.findIndex(({ id }) => id === _classroomId);

      return {
        ...state,
        ...mapSchoolInfo({
          ...state,
          classrooms: [
            ...state.classrooms.slice(0, _replaceIdx),
            action.payload,
            ...state.classrooms.slice(_replaceIdx + 1),
          ],
        }),
        hasErrors: false,
        isLoading: false,
      };
    case ACTIVATE_CLASSROOM_LOADED:
      state.classrooms_number++;
      const { id: classroomId } = action.payload;
      const replaceIdx = state.classrooms.findIndex(({ id }) => id === classroomId);

      return {
        ...state,
        ...mapSchoolInfo({
          ...state,
          classrooms: [
            ...state.classrooms.slice(0, replaceIdx),
            action.payload,
            ...state.classrooms.slice(replaceIdx + 1),
          ],
        }),
        hasErrors: false,
        isLoading: false,
      };
    case REVOKE_SCHOOL_ADMIN_LOADED:
      const revokedUserIdx = state.users.findIndex(({ id }) => +id === +action.payload.id);
      const userClsrms = state.users[revokedUserIdx].classrooms || [];
      newUsers = [
        ...state.users.slice(0, revokedUserIdx),
        { ...action.payload, classrooms: userClsrms },
        ...state.users.slice(revokedUserIdx + 1),
      ];

      return { ...state, ...mapSchoolInfo({ ...state, users: newUsers }) };
    case RESET_SCHOOL:
      return {
        ...initialState,
        id: undefined,
      };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;

// api

export const getSchoolInfo = (schoolId) => (dispatch) => {
  dispatch(schoolLoading());
  return makeRequest(`/school/${schoolId}`)
    .then(({ resource }) => dispatch(schoolLoaded(resource)))
    .catch(() => dispatch(schoolFailed()));
};

export const updateSchoolInfo = (resource, schoolId, isAvatar = false) => (dispatch) => {
  const options = isAvatar ? { method: 'PUT', data: resource } : { method: 'PUT', data: { resource } };
  return makeRequest(`/school/${schoolId}`, options)
    .then(({ resource: res }) => {
      dispatch(schoolUpdateLoaded(res));
      toast.success('Updated successfully');
    })
    .catch(() => {});
};

export const inviteUser = (resource) => (dispatch) => {
  dispatch(inviteUserLoading());
  return makeRequest('/users/invite', { method: 'POST', data: { invitations: { users_attributes: resource } } })
    .then(({ invited_users: invited, failed_invitations: failed }) => {
      dispatch(inviteUserLoaded(invited));
      return { failed, invited };
    })
    .then(({ failed, invited }) => {
      inviteRespondWithToasts({ invited, failed });
      return { failed, invited };
    })
    .catch((err) => {});
};

export const reinviteUser = (resource) => (dispatch) =>
  makeRequest('/users/reinvite', { method: 'POST', data: { resource } })
    .then(() => toast.success('Reinvite was sent!'))
    .catch(() => {});

export const deleteUser = (id, instanceIds) => (dispatch) =>
  makeRequest(`/users/${id}`, { method: 'delete', data: { ...instanceIds } })
    .then((res) => dispatch(userDeleteLoaded(id)))
    .then(() => toast.success('You have removed this user'))
    .catch(() => {});

export const createClassroom = (data) => (dispatch) =>
  makeRequest('/classrooms', { method: 'POST', data: { resource: data } })
    .then(({ resource }) => dispatch(createClassroomLoaded(resource)))
    .catch(() => {});

export const disableClassroom = (classroomId) => (dispatch) =>
  makeRequest(`/classroom/${classroomId}/disable`, { method: 'PUT' })
    .then(({ resource }) => {
      dispatch(disableClassroomLoaded(resource));
      dispatch(toggleDisableClassroomProgramLoaded(resource));
    })
    .then(() => toast.success('Classroom disabled'))
    .catch(() => {});

export const activateClassroom = (classroomId) => (dispatch) =>
  makeRequest(`/classroom/${classroomId}/activate`, { method: 'PUT' })
    .then(({ resource }) => {
      dispatch(activateClassroomLoaded(resource));
      dispatch(toggleDisableClassroomProgramLoaded(resource));
    })
    .then(() => toast.success('Classroom activated'))
    .catch(() => {});

export const disableSchool = (schoolId) => (dispatch) =>
  makeRequest(`/school/${schoolId}/disable`, { method: 'PUT' })
    .then(({ resource }) => dispatch(disableSchoolLoaded(resource)))
    .then(() => toast.success('School disabled'))
    .catch(() => {});

export const activateSchool = (schoolId) => (dispatch) =>
  makeRequest(`/school/${schoolId}/activate`, { method: 'PUT' })
    .then(({ resource }) => dispatch(activateSchoolLoaded(resource)))
    .then(() => toast.success('School activated'))
    .catch(() => {});
