import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// utils
import { revokeAdmin, toggleDisableUser } from 'store/common';
import { getUserProfileInfo, revokeAdminRoleLoaded, userProfileInfoLoaded } from 'store/auth';
import { isProgramAdmin, TEACHER_ROLE } from 'constants/user';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
// containers
import DisplayOnDesktop from 'containers/DisplayOnDesktop';
import DisplayOnMobile from 'containers/DisplayOnMobile';
// components
import MobileView from './MobileView';
import BrowserView from './BrowserView';
import { ClassroomsMobile, UserInfoMobile } from './MobileView/MobileView';
import { Classrooms, UserInfo } from './BrowserView/BrowserView';

const BROWSER_TAB_OPTIONS = [
  { title: 'classrooms', component: Classrooms },
  { title: 'user info', component: UserInfo },
];

const MOBILE_TAB_OPTIONS = [
  { title: 'classrooms', component: ClassroomsMobile },
  { title: 'user info', component: UserInfoMobile },
];

const UserProfile = () => {
  const {
    push,
    location: { search },
  } = useHistory();
  const { programId, schoolId } = useSelector((state) => ({ programId: state.program.id, schoolId: state.school.id }));
  const {
    full_name: fullName = '',
    first_name: firstName = '',
    last_name: lastName = '',
    email,
    avatar_url: imageUrl = '',
    is_active: isActive,
    title = '',
    role = '',
    roles = [],
    assignedClassrooms = [],
    classroomsMerged = [],
    currProgram = {},
    currSchool = {},
    myInfo: { role: myRole },
    classroom_ids = [],
  } = useSelector(({ auth: { userProfileInfo = {}, user = {} } = {} }) => {
    const currProgram = (user.programs || []).find(({ id }) => +id === +programId);
    const currSchool = (currProgram || {}).schools && currProgram.schools.find(({ id }) => +id === +schoolId);

    const classroomsMerged =
      (currProgram || {}).schools &&
      currProgram.schools
        .filter(({ id }) => (userProfileInfo.school_ids || []).some((_id) => +_id === +id))
        .reduce((acc, { classrooms }) => [...acc, ...classrooms], []);
    const assignedClassrooms =
      userProfileInfo.classroom_ids &&
      userProfileInfo.classroom_ids
        .map((classroomId) => classroomsMerged.find(({ id }) => classroomId === id))
        .filter((_) => !!_);

    return { ...userProfileInfo, currProgram, currSchool, classroomsMerged, assignedClassrooms, myInfo: user };
  });

  const { userId } = useParams();
  const { range } = queryString.parse(search);
  const dispatch = useDispatch();

  let classrooms = [],
    allClassrooms = [];
  switch (range) {
    case 'program':
      if (myRole && !isProgramAdmin(myRole)) {
        push('/classrooms');
        return null;
      }
      classrooms = assignedClassrooms;
      allClassrooms = classroomsMerged;
      break;
    case 'school':
      classrooms = assignedClassrooms.filter(({ school_id }) => +school_id === +schoolId);
      allClassrooms = classroomsMerged.filter(({ school_id }) => +school_id === +schoolId);
      break;
    default:
      push('/classrooms');
      return null;
  }

  useEffect(() => dispatch(getUserProfileInfo({ userId, programId, schoolId })), []);

  let targetRoleObj = null;
  if (range === 'program') {
    targetRoleObj = roles.find(({ id, type }) => +id === +programId && type === range);
  } else {
    targetRoleObj = roles.find(({ id, type }) => +id === +schoolId && type === range);
  }

  const handleRevokeAdmin = ({ adminId }) => {
    const requestData =
      range === 'program'
        ? {
            programs_users_attributes: [{ id: targetRoleObj.join_table_record_id, role: null }],
          }
        : { schools_users_attributes: [{ id: targetRoleObj.join_table_record_id, role: TEACHER_ROLE }] };

    return dispatch(revokeAdmin(adminId, programId, schoolId, { ...requestData })).then((res) =>
      dispatch(revokeAdminRoleLoaded(res)),
    );
  };

  const handleToggleDisableUser = (disable = true) => {
    return dispatch(
      toggleDisableUser(
        +userId,
        {
          [range === 'program' ? 'programs_users_attributes' : 'schools_users_attributes']: {
            id: targetRoleObj.join_table_record_id,
            is_active: !disable,
          },
        },
        range === 'program' ? { program_id: programId } : { program_id: programId, school_id: schoolId },
        disable,
      ),
    ).then((res) => dispatch(userProfileInfoLoaded(res)));
  };

  return (
    <>
      <DisplayOnDesktop>
        <BrowserView
          tabOptions={BROWSER_TAB_OPTIONS}
          fullName={fullName}
          imageUrl={imageUrl}
          title={title}
          role={role}
          classrooms={classrooms}
          allClassrooms={allClassrooms}
          currProgram={currProgram}
          currSchool={currSchool}
          programId={programId}
          schoolId={schoolId}
          classroom_ids={classroom_ids}
          targetRoleObj={targetRoleObj}
          onRevoke={handleRevokeAdmin}
          onDisable={handleToggleDisableUser}
        />
      </DisplayOnDesktop>
      <DisplayOnMobile>
        <MobileView
          tabOptions={MOBILE_TAB_OPTIONS}
          fullName={fullName}
          firstName={firstName}
          lastName={lastName}
          email={email}
          isActive={isActive}
          imageUrl={imageUrl}
          title={title}
          role={role}
          classrooms={classrooms}
          allClassrooms={allClassrooms}
          currProgram={currProgram}
          currSchool={currSchool}
          programId={programId}
          schoolId={schoolId}
          targetRoleObj={targetRoleObj}
          onRevoke={handleRevokeAdmin}
          onDisable={handleToggleDisableUser}
        />
      </DisplayOnMobile>
    </>
  );
};

export default UserProfile;
