import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// components
import Text from 'components/Text';
// styles
import styles from './BottomMenu.module.scss';

const cn = classnames.bind(styles);

const BottomMenu = ({ children, menuHeading = '', className = '' }) => {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div id="context-bottom-menu" className={cn('bottom-menu', className)}>
        <Text className={cn('bottom-menu--heading', 'mb-10')} type="body-small" bold>
          {menuHeading.toUpperCase()}
        </Text>
        <div className={cn('bottom-menu--hr', 'mb-10')} />
        {children}
      </div>
    </div>
  );
};

BottomMenu.propTypes = {
  children: T.any,
  menuHeading: T.string,
  className: T.string,
};

export default BottomMenu;
