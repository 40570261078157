import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
// hoc
import withClassroomContentIdChange from 'hoc/withClassroomContentIdChange';
// utils
import DisplayOnDesktop from 'containers/DisplayOnDesktop';
import DisplayOnMobile from 'containers/DisplayOnMobile';
import { useDispatch, useSelector } from 'react-redux';
import { getClassroomUnit, getClassroomWeek, markLessonPlanCompleted } from 'store/classrooms';
import { get, isEmpty, orderBy } from 'lodash';
import { DateTime } from 'luxon';
import { GRADE_COLORS } from 'constants/classrooms';
import { buildUrl, getWeekOrderNumber, isEdgeWeek } from 'utils';
import { buildMixedName } from 'pages/Classrooms/helpers';
import {
  eventCategory,
  eventAction,
  eventLabel,
  USER_ROLE,
  PROGRAM_NAME,
  UNIT_NUMBER,
  UNIT_TITLE,
  WEEK_NAME,
  gaRoleLabels,
  customDimensions,
} from 'constants/googleAnalytics';
import { GAEmitEvent } from 'utils/googleAnalytics';
// icons
import { ReactComponent as HeartIcon } from 'icons/heart.svg';
// components
import Table from 'components/Table';
import Text from 'components/Text';
import Link from 'components/Link';
import CheckButton from 'components/CheckButton';
import TruncateHint from 'components/TruncateHint/TruncateHint';
import Button from 'components/Button/Button';
// styles
import classnames from 'classnames/bind';
import RequiredBadge from 'components/Badges/RequiredBadge';
import DraftBadge from 'components/Badges/DraftBadge';
import styles from './Week.module.scss';
import Printables from './Printables';
import LearningExperiences from './LearningExperiences';
import BrowserView from './BrowserView';
import MobileView from './MobileView';
import GettingReadyMobile from './MobileView/GettingReadyMobile';

const cn = classnames.bind(styles);

const GettingReady = () => {
  const {
    params: { classroomId, unitId, weekId },
  } = useRouteMatch();

  const {
    push,
    location: { pathname, search },
  } = useHistory();
  const dispatch = useDispatch();

  const { week, unit, classroom_ } = useSelector(({ classrooms: { classrooms } }) => {
    const classroom = classrooms.find(({ id }) => +classroomId === id);
    return {
      week: get(classroom, `unit_progress[${unitId}].week_progress[${weekId}]`, {}),
      unit: get(classroom, `unit_progress[${unitId}]`, {}),
      classroom_: classroom,
    };
  });
  const { role } = useSelector(({ auth }) => auth.user);
  const { name: programName } = useSelector(({ program }) => program);

  const { week_progresses: weekProgresses, order_number: currentUnitOrder } = get(
    classroom_,
    `unit_progress.${unitId}`,
    {},
  );
  const isCurrentUnit = get(classroom_, 'current_unit.id') === +unitId;
  const weekProgRev = weekProgresses ? [...weekProgresses].reverse() : [];
  const lastUnfinished =
    weekProgRev.find(
      ({ finished }, idx) =>
        (!finished && weekProgRev[idx + 1] && weekProgRev[idx + 1].finished) || !weekProgresses[idx + 1],
    ) || {};

  const currentUnitOrderNumber = get(classroom_, 'current_unit.order_number');

  const isFuture = isCurrentUnit
    ? lastUnfinished.order_number < week.order_number
    : currentUnitOrderNumber < currentUnitOrder;

  const renderLessonPlanName = (props, className, width) => (
    <div className={cn('lesson-plan-col')} style={{ minWidth: width, flexBasis: width }}>
      <CheckButton
        className={cn('check')}
        isPreview={isFuture}
        tooltip={isFuture ? 'Preview Only' : props.finished ? 'Mark Incomplete' : 'Complete Task'}
        isChecked={props.finished}
        onChange={() => {
          dispatch(markLessonPlanCompleted(classroomId, props.id, !props.finished, { uPId: unitId, wPId: weekId }));
          !props.finished &&
            props.required &&
            GAEmitEvent(
              eventCategory.USER_ACTION,
              eventAction.MENU_CLICK,
              eventLabel.requiredActivity(props.name, 'Professional Prep', pathname + search),
              {
                [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                [customDimensions[PROGRAM_NAME]]: programName,
                [customDimensions[UNIT_NUMBER]]: get(unit, 'units[0].order_number', null),
                [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
                [customDimensions[WEEK_NAME]]: get(week, `weeks[0].name`, null),
              },
            );
        }}
      />
      <div className={cn('body-wrap')}>
        <Link
          className="flex"
          to={`/classroom/${classroomId}/unit/${unitId}/week/${weekId}/lesson-plan/${props.id}`}
          onClick={() =>
            GAEmitEvent(
              eventCategory.USER_ACTION,
              eventAction.CLICK,
              eventLabel.click(
                'Professional Prep',
                `${props.name}_/classroom/${classroomId}/unit/${unitId}/week/${weekId}/lesson-plan/${props.id}`,
              ),
              {
                [customDimensions[USER_ROLE]]: gaRoleLabels[role],
                [customDimensions[PROGRAM_NAME]]: programName,
                [customDimensions[UNIT_NUMBER]]: get(unit, 'units[0].order_number', null),
                [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
                [customDimensions[WEEK_NAME]]: get(week, `weeks[0].name`, null),
              },
            )
          }
        >
          <div className={cn('sel-wrap', { active: props.sel_competency })}>
            <Text type="h6">{props.name}</Text>
            {props.sel_competency && <HeartIcon className={cn('heart')} />}
          </div>
          {get(props, 'draft', false) && <DraftBadge className={cn('lesson-plan-draft-badge')} />}
        </Link>
      </div>
    </div>
  );

  const renderGettingReadyButtonsCol = (props, className, width) => (
    <div className={cn(className, 'getting-ready-buttons-col')} style={{ minWidth: width, flexBasis: width }}>
      {props.required && <RequiredBadge className="mr-15" />}
      <Button
        size="md"
        label={isFuture ? 'Preview Only' : 'Read'}
        onClick={() => {
          GAEmitEvent(
            eventCategory.USER_ACTION,
            eventAction.CLICK,
            eventLabel.click(
              'Professional Prep',
              `${props.name}_/classroom/${classroomId}/unit/${unitId}/week/${weekId}/lesson-plan/${props.id}`,
            ),
            {
              [customDimensions[USER_ROLE]]: gaRoleLabels[role],
              [customDimensions[PROGRAM_NAME]]: programName,
              [customDimensions[UNIT_NUMBER]]: get(unit, 'units[0].order_number', null),
              [customDimensions[UNIT_TITLE]]: get(unit, 'units[0].name', null),
              [customDimensions[WEEK_NAME]]: get(week, `weeks[0].name`, null),
            },
          );
          push(`/classroom/${classroomId}/unit/${unitId}/week/${weekId}/lesson-plan/${props.id}`);
        }}
      />
    </div>
  );

  const renderDescriptionCol = (props, className, width) => (
    <div
      className={cn(className)}
      style={{ minWidth: width, flexBasis: width, whiteSpace: 'normal', paddingBottom: 17 }}
    >
      <Text type="h6">
        <TruncateHint text={props.description} lines={2} />
      </Text>
    </div>
  );

  const gettingReadyColumns = [
    { label: 'PD Resources', renderer: renderLessonPlanName, isBordered: false, width: 400 },
    { label: 'Descriptions', renderer: renderDescriptionCol, width: 500 },
    { label: '', renderer: renderGettingReadyButtonsCol, width: 200 },
  ];

  return <Table className="mt-20 ph-60" columns={gettingReadyColumns} data={week.activities || []} />;
};

const formatDate = (date) => DateTime.fromFormat(date, 'MM-dd-yyyy').toFormat('MMM dd');

const Week = () => {
  const dispatch = useDispatch();
  const {
    params: { classroomId, unitId, weekId },
  } = useRouteMatch();

  const { week, isLoading, hasErrors, classrooms, classroom, unit } = useSelector(
    ({ classrooms: { classrooms: classrooms_, isLoading: _isLoading, hasErrors: _hasErrors } }) => {
      const _classroom = classrooms_.find(({ id }) => +classroomId === id);
      return {
        unit: get(_classroom, `unit_progress[${unitId}]`, {}),
        week: get(_classroom, `unit_progress[${unitId}].week_progress[${weekId}]`, {}),
        isLoading: _isLoading,
        hasErrors: _hasErrors,
        classrooms: classrooms_,
        classroom: _classroom,
      };
    },
  );
  const { week_progresses: weekProgresses, order_number: currentUnitOrder } = get(
    classroom,
    `unit_progress.${unitId}`,
    {},
  );

  const isCurrentUnit = get(classroom, 'current_unit.id') === +unitId;
  const weekProgRev = weekProgresses ? [...weekProgresses].reverse() : [];
  const lastUnfinished =
    weekProgRev.find(
      ({ finished }, idx) =>
        (!finished && weekProgRev[idx + 1] && weekProgRev[idx + 1].finished) || !weekProgresses[idx + 1],
    ) || {};

  const currentUnitOrderNumber = get(classroom, 'current_unit.order_number');

  const isFuture = isCurrentUnit
    ? lastUnfinished.order_number < week.order_number
    : currentUnitOrderNumber < currentUnitOrder;

  const BROWSER_TAB_OPTIONS = [
    { title: 'professional prep', component: <GettingReady weekId={weekId} /> },
    {
      title: 'learning experiences',
      component: (
        <LearningExperiences
          isPreview={isFuture}
          experiences={orderBy(week.learning_experiences, ({ order_number: ordNum }) => ordNum)}
        />
      ),
    },
    { title: 'printables', component: Printables },
  ];

  const MOBILE_TAB_OPTIONS = [
    { title: 'professional prep', component: GettingReadyMobile },
    { title: 'experiences', component: LearningExperiences },
    { title: 'printables', component: Printables },
  ];

  useEffect(() => {
    if (classroom && isEmpty(weekProgresses) && !isLoading && !hasErrors)
      dispatch(getClassroomUnit(classroomId, unitId));
  }, [dispatch, classroomId, unitId, weekProgresses, isLoading, hasErrors, classroom]);

  useEffect(() => {
    if (!isEmpty(classrooms) && isEmpty(week) && !isLoading && !hasErrors)
      dispatch(getClassroomWeek(classroomId, unitId, weekId));
  }, [dispatch, classroomId, unitId, weekId, week, isLoading, hasErrors, classrooms]);

  return (
    <>
      <DisplayOnDesktop>
        <BrowserView
          classroom={classroom}
          unit={unit}
          week={week}
          targetSel={!isEmpty(week) && buildMixedName(week.weeks, 'sel_competency')}
          weekImage={!isEmpty(week) && week.weeks[0] && buildUrl(week.weeks[0].image_url)}
          color={!isEmpty(week) && GRADE_COLORS[week.weeks[0].grade_level]}
          weekHeading={
            !isEmpty(week) &&
            `${getWeekOrderNumber(week.weeks[0].order_number, true, true)} ${
              !isEdgeWeek(week.weeks[0].order_number) ? week.weeks[0].name : ''
            }`
          }
          weekText={!isEmpty(week) && week.weeks[0].description}
          weekMarkedCompleted={week.finished}
          tabOptions={BROWSER_TAB_OPTIONS}
          makeNavExpandable
        />
      </DisplayOnDesktop>
      <DisplayOnMobile>
        <MobileView
          tabOptions={MOBILE_TAB_OPTIONS}
          classroom={classroom}
          unit={unit}
          week={week}
          experiences={orderBy(week.learning_experiences, ({ order_number: ordNum }) => ordNum)}
        />
      </DisplayOnMobile>
    </>
  );
};

export default withClassroomContentIdChange(Week);
