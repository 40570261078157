import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
// utils
import { isProgramAdmin } from 'constants/user';
import { useDispatch, useSelector } from 'react-redux';
import { getLetterInitials } from 'utils/string';
import { buildUrl } from 'utils';
import { updateProgramId } from 'store/program';
// components
import Logo from 'components/Logo';
import SearchInput from 'components/Input/SearchInput';
import Text from 'components/Text';
// icons
import { ReactComponent as ChevronIcon } from 'icons/chevron-grey.svg';
// styles
import classnames from 'classnames/bind';
import styles from './ProgramSwitcher.module.scss';

const cn = classnames.bind(styles);

export const ProgramAvatar = ({ imageUrl, name, className }) =>
  imageUrl ? (
    <img className={cn('program-avatar', className, 'is-avatar')} src={buildUrl(imageUrl)} alt="Program avatar" />
  ) : (
    <div className={cn('program-avatar', className)}>{getLetterInitials(name)}</div>
  );

const ProgramSwitcher = () => {
  const [search, setSearch] = useState('');
  const {
    user: { email, first_name: firstName, programs, roles, ...rest },
  } = useSelector(({ auth }) => auth);

  const dispatch = useDispatch();
  const { push } = useHistory();

  const getRedirectUrl = useCallback(
    ({ id: programId }) => {
      const programRoleObj = roles.find(({ id, type }) => +id === +programId && type === 'program');
      if (isProgramAdmin(programRoleObj?.role)) {
        return '/program-panel';
      }
      return '/classrooms';
    },
    [roles],
  );

  const filteredData = programs.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()));

  const handleProgramSwitch = useCallback(
    (program) => {
      dispatch(updateProgramId(program));
      push(getRedirectUrl(program));
    },
    [dispatch, push],
  );

  useEffect(() => {
    if (programs.length === 1) {
      handleProgramSwitch(programs[0]);
    }
  }, [programs, handleProgramSwitch]);

  if (programs.length === 1) {
    push(getRedirectUrl(programs[0]));
    return null;
  }

  return (
    <div className={cn('wrapper')}>
      <Logo className={cn('logo')} />
      <div className={cn('switcher-wrapper')}>
        <Text type="h3">{`Welcome back, ${firstName}!`}</Text>
        <Text className="mt-15">Select a program</Text>
        <SearchInput
          value={search}
          onChange={setSearch}
          wrapperClassName={cn('search', 'mt-30 mb-20')}
          style={{ width: '100%' }}
        />
        {filteredData.map((program, idx) => (
          <div
            key={`program-${idx}`}
            aria-hidden
            className={cn('program-wrapper')}
            onClick={() => handleProgramSwitch(program)}
          >
            <ProgramAvatar className={cn('avatar')} imageUrl={program.avatar_url} name={program.name} />
            <Text className={cn('name')} type="body-big">
              {program.name}
            </Text>
            <ChevronIcon className={cn('chevron')} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgramSwitcher;
