import React from 'react';
// utils
import { buildUrl } from 'utils';
import { obtainInitials } from 'utils/string';
// icons
import { ReactComponent as MenuIcon } from 'icons/dots.svg';
// styles
import classnames from 'classnames/bind';
import styles from './MobileItemRow.module.scss';

const cn = classnames.bind(styles);

const MobileItemRow = ({
  imageUrl,
  name,
  imageColor = null,
  textsRenderer,
  onMenuClick,
  badgeRenderer,
  showMenu = true,
  ...props
}) => {
  return (
    <article className={cn('row-wrapper', 'mv-15')} {...props}>
      <div className={cn('image-wrapper')} style={{ backgroundColor: imageColor && imageColor }}>
        {imageUrl ? (
          <img className={cn('image')} src={buildUrl(imageUrl)} alt="item-image" aria-hidden />
        ) : (
          <div className={cn('image-placeholder')}>{obtainInitials(name)}</div>
        )}
      </div>
      <div className={cn('texts-wrapper', 'ml-15')}>{textsRenderer()}</div>
      {showMenu && (
        <div className={cn('menu-wrapper')} onClick={onMenuClick}>
          <MenuIcon />
        </div>
      )}
      {badgeRenderer && <div className={cn('badge')}>{badgeRenderer()}</div>}
    </article>
  );
};

export default MobileItemRow;
