import React, { useState } from 'react';
// components
import Text from 'components/Text';
// icons
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import { ReactComponent as MinusIcon } from 'icons/minus.svg';
// styles
import classnames from 'classnames/bind';
import styles from './NumericIncrementInput.module.scss';

const cn = classnames.bind(styles);

const NumericIncrementInput = ({ initValue, bottomLimit = 0, topLimit, name, inputRef = null, small = false }) => {
  const [number, setNumber] = useState(initValue);

  const handleDecrementClick = () => {
    setNumber((prev) => (prev > bottomLimit ? --prev : topLimit));
  };
  const handleIncrementClick = () => {
    setNumber((prev) => (prev < topLimit ? ++prev : bottomLimit));
  };

  return (
    <div className={cn('increment-wrapper')}>
      <div
        className={cn('btn-circle', { small })}
        onClick={handleDecrementClick}
        onMouseDown={(e) => e.preventDefault()}
      >
        <MinusIcon />
      </div>
      <Text className={cn('no-select', 'number', `mh-${small ? 10 : 25}`)} type="h5">
        {number}
      </Text>
      <div
        className={cn('btn-circle', { small })}
        onClick={handleIncrementClick}
        onMouseDown={(e) => e.preventDefault()}
      >
        <PlusIcon />
      </div>
      <input hidden readOnly name={name} ref={inputRef} value={number} />
    </div>
  );
};

export default NumericIncrementInput;
