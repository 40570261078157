import React, { useEffect } from 'react';
import T from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import 'smoothscroll-anchor-polyfill';
// utils
import 'utils/toBlobPolyfill';
import { isMobile } from 'react-device-detect';
import cn from 'classnames';
// components
import RoleUpdateService from 'containers/RoleUpdateService';
import LicenseValidator from 'components/LicenseValidator';
import { ToastContainer, Slide, Bounce } from 'react-toastify';
import ScrollToTop from 'components/ScrollToTop';
import ItIsEaster from 'components/ItIsEaster';
import Footer from 'components/Footer';
// store
import initializeStore from 'store';
import Auth from 'containers/Auth/Auth';
// hooks
import { BottomMenuProvider } from 'hooks/useBottomMenu';
import { ModalProvider } from 'hooks/useModal';

const store = initializeStore();

const AppProvider = ({ children }) => {
  useEffect(() => smoothscroll.polyfill(), []);

  return (
    <div className={cn('AppContainer')}>
      <Provider store={store}>
        <Router>
          <ItIsEaster />
          <ToastContainer
            autoClose={1300}
            closeButton={null}
            position={isMobile ? 'bottom-center' : 'bottom-left'}
            closeOnClick
            pauseOnHover
            hideProgressBar
            transition={isMobile ? Slide : Bounce}
          />
          <Auth>
            <RoleUpdateService />
            <LicenseValidator />
            <ModalProvider>
              <ScrollToTop />
              <BottomMenuProvider>
                {children}
                <Footer />
              </BottomMenuProvider>
            </ModalProvider>
          </Auth>
        </Router>
      </Provider>
    </div>
  );
};

AppProvider.propTypes = {
  children: T.element.isRequired,
};

export default AppProvider;
